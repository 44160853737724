import React from 'react';
import { ContentElement } from '../Helpers/ContentElement';
import styled from 'styled-components';
import { Paper } from '@material-ui/core';
import { EmailProject } from '../../../../../../../store/EmailProjects/types';
import { AdminEmailTemplate } from '../../../../../../../store/AdminEmailTemplates/types';
import { AdPlacementStatus, ProjectModelV2 } from '../../../types';

type ContentComponentsProps = {
  project: EmailProject | AdminEmailTemplate;
  model: ProjectModelV2;
  adSpaces: AdPlacementStatus[];
  inProjectEditor: boolean;
};

export const ContentComponents: React.FC<ContentComponentsProps> = ({ model, project, adSpaces, inProjectEditor }) => {
  return (
    <StyledPaper>
      {inProjectEditor &&
        adSpaces.map((adSpace, i) => {
          if (adSpace.placed) return null;
          return (
            <ContentElement
              key={i}
              componentType={'ad-space'}
              extraLabel={`${adSpace.width}x${adSpace.height}`}
              data={adSpace}
            />
          );
        })}
      <ContentElement componentType={'text'} />
      <ContentElement componentType={'image'} />
      <ContentElement componentType={'article-horizontal'} />
      <ContentElement componentType={'article-vertical'} />
      <ContentElement componentType={'separator'} />
      <ContentElement componentType={'cta-button'} />
      <ContentElement componentType={'download-attachment'} />
      <ContentElement componentType={'logo-horizontal'} />
      {!inProjectEditor && <ContentElement componentType={'print-project-cover'} />}
      <ContentElement componentType={'social-links'} />
      {!inProjectEditor && <ContentElement componentType={'ad-space'} />}
      <ContentElement componentType={'upcoming-events'} />
      <ContentElement componentType={'month-calendar'} />
    </StyledPaper>
  );
};

const StyledPaper = styled(Paper)`
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 15px;
  padding: 30px;
`;
