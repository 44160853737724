import { ApplicationRoute } from '../../../../typings';
import linksConstants from '../../../../config/app/linksConstants';
import BulletinEditorPage from './BulletinEditorPage';

const BulletinEditorRoutes: ApplicationRoute[] = [
  {
    path: linksConstants.PROJECTS.BULLETIN.EDIT(':projectId'),
    exact: false,
    name: 'bulletinEditor',
    public: false,
    show: true,
    component: BulletinEditorPage,
    scope: []
  }
];

export default BulletinEditorRoutes;
