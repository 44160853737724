import React, { FunctionComponent } from 'react';
import Switch from '@material-ui/core/Switch';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import { Box, FormControlLabel, Radio, RadioGroup, TextField } from '@material-ui/core';
import { validateUrl } from '../../../../../../../utils';

type ArticleHorizontalProps = {
  showImage: boolean;
  setShowImage: (val: boolean) => void;

  showReadMore: boolean;
  setShowReadMore: (val: boolean) => void;

  customReadMore: boolean;
  setCustomReadMore: (val: boolean) => void;

  customReadMoreText: string;
  setCustomReadMoreText: (val: string) => void;

  customReadMoreLink: string;
  setCustomReadMoreLink: (val: string) => void;

  imagePosition?: 'left' | 'right';
  setImagePosition?: React.Dispatch<React.SetStateAction<'left' | 'right'>>;
};

const ReadMoreConfigPopover: FunctionComponent<ArticleHorizontalProps> = ({
  showImage,
  setShowImage,
  showReadMore,
  setShowReadMore,
  customReadMore,
  setCustomReadMore,
  customReadMoreLink,
  customReadMoreText,
  setCustomReadMoreLink,
  setCustomReadMoreText,
  imagePosition,
  setImagePosition
}) => {
  const { t } = useTranslation();

  return (
    <div className="toggle-show-read-more">
      <div onClick={() => setShowImage(!showImage)}>
        {t('common.showImage')}: <Switch checked={showImage} onChange={(e) => setShowImage(e.target.checked)} />
      </div>
      {showImage && imagePosition && setImagePosition && (
        <Box display="flex" alignItems="center">
          {t('common.imagePosition')}:{' '}
          <RadioGroup
            aria-label="image-position"
            name="image-position"
            value={imagePosition}
            onChange={(e) => setImagePosition(e.target.value as 'left' | 'right')}
            row
            style={{ marginLeft: 5 }}
          >
            <FormControlLabel value="left" control={<Radio size="small" />} label={t('common.left')} />
            <FormControlLabel value="right" control={<Radio size="small" />} label={t('common.right')} />
          </RadioGroup>
        </Box>
      )}
      <div onClick={() => setShowReadMore(!showReadMore)}>
        {t('common.showReadMoreLink')}:{' '}
        <Switch checked={showReadMore} onChange={(e) => setShowReadMore(e.target.checked)} />
      </div>
      {showReadMore && (
        <div>
          <div onClick={() => setCustomReadMore(!customReadMore)}>
            {t('common.customReadMore')}:{' '}
            <Switch checked={customReadMore} onChange={(e) => setCustomReadMore(e.target.checked)} />
          </div>
          {customReadMore && (
            <CustomReadMoreWrapper>
              <div>
                <TextField
                  variant="outlined"
                  size="small"
                  label={t('common.title')}
                  inputProps={{
                    maxLength: 60
                  }}
                  placeholder={t('common.title')}
                  value={customReadMoreText}
                  onChange={(e) => setCustomReadMoreText(e.target.value)}
                  required
                  error={!customReadMoreText}
                />
              </div>
              <div>
                <TextField
                  variant="outlined"
                  label={t('common.newUrl')}
                  size="small"
                  placeholder="https://example.com"
                  value={customReadMoreLink}
                  onChange={(e) => setCustomReadMoreLink(e.target.value)}
                  required
                  error={!customReadMoreLink || !validateUrl(customReadMoreLink)}
                />
              </div>
            </CustomReadMoreWrapper>
          )}
        </div>
      )}
    </div>
  );
};

const CustomReadMoreWrapper = styled.div`
  padding: 0 10px;

  > div {
    margin-bottom: 10px;
  }
`;

export default ReadMoreConfigPopover;
