import React, { FunctionComponent, useEffect, useState } from 'react';
import styled from 'styled-components';
import { NoResultsFound, PageContainer, PageContent } from '../PageStyledComponents';
import PageHeader from '../../Shared/Layout/PageHeader';
import { useTypedSelector } from '../../../utils';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { push } from 'connected-react-router';
import linksConstants from '../../../config/app/linksConstants';
import Loader from '../../Shared/Loading/Loader';
import { Button } from '@material-ui/core';
import { RouteComponentProps } from 'react-router';
import { hasPermission, hasPermissionToAnyChannel } from '../../../utils/permissions';
import { User } from '../../../store/Auth/types';
import { emailProjectsOperations } from '../../../store/EmailProjects';
import { TableSearchStoreModule } from '../../../store/TableSearch/types';
import { PaginationStoreModule } from '../../../store/Pagination/types';
import DataTable from '../../Shared/DataTable/DataTable';
import { EmailProject } from '../../../store/EmailProjects/types';
import ProjectNameCell from './WorkInProgressTable/ProjectNameCell';
import { usePaginationWatch } from '../../Shared/DataTable/Pagination/usePaginationWatch';
import { useTableSearchWatch } from '../../Shared/DataTable/useTableSearchWatch';
import LatestNoteCell from './WorkInProgressTable/LatestNoteCell';
import LatestActivityCell from './WorkInProgressTable/LatestActivityCell';
import PublicationDateCell from './WorkInProgressTable/PublicationDateCell';
import ProjectStatusCell from './WorkInProgressTable/ProjectStatusCell';
import ProjectActionsCell from './WorkInProgressTable/ProjectActionsCell';
import ProjectRemoveWindow from './Windows/ProjectRemoveWindow';
import ResetProjectCheckInStatusWindow from './Windows/ResetProjectCheckInStatusWindow';
import ProjectPublishNewsletterWindow from './Windows/ProjectPublishNewsletterWindow';
import ProjectCancelPublishWindow from './Windows/ProjectCalcelPublishWindow';
import { useHandleProjectAction } from './WorkInProgressTable/useHandleProjectAction';
import ProjectShareWindow from './Windows/ProjectShareWindow';
import ProjectExpandableContent from './WorkInProgressTable/ProjectExpandableContent';
import PreviewEmailWindow from './Windows/PreviewEmailWindow';
import GenerateLinkWindow from './Windows/GenerateLinkWindow';

type EmailProjectsPageProps = RouteComponentProps<{}> & {};

const EmailProjectsPage: FunctionComponent<EmailProjectsPageProps> = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [initialLoading, setInitialLoading] = useState(true);
  const user = useTypedSelector((state) => state.auth.user);
  const role = useTypedSelector((state) => state.auth.role);
  const projects = useTypedSelector((state) => state.emailProjects.projects);

  const isFailed = useTypedSelector((state) => state.emailProjects.index.isFailed);
  const loading = useTypedSelector((state) => state.emailProjects.index.isLoading);
  const pagination = useTypedSelector((state) => state.emailProjects.pagination);
  const tableSearch = useTypedSelector((state) => state.emailProjects.tableSearch);
  const {
    handleAction,
    activeProject,

    shareProjectWindowOpen,
    removeProjectWindowOpen,
    publishProjectWindowOpen,
    resetCheckInWindowOpen,
    cancelPublishProjectWindowOpen,
    previewEmailWindowOpen,
    generateLinkWindowOpen,

    onShareProjectWindowClose,
    onRemoveProjectWindowClose,
    onPublishProjectWindowClose,
    onResetCheckInWindowClose,
    onCancelPublishProjectWindowClose,
    onPreviewEmailWindowClose,
    onGenerateLinkWindowClose
  } = useHandleProjectAction();

  useEffect(() => {
    if (!loading) {
      setInitialLoading(false);
    }
  }, [loading]);

  usePaginationWatch(pagination, [emailProjectsOperations.getUserEmailProjects]);
  useTableSearchWatch<EmailProject>(tableSearch, [emailProjectsOperations.getUserEmailProjects]);

  const getActionButtons = () => {
    if (hasPermission(role, ['projectsEmailAccess'])) {
      return [
        {
          onClick: () => dispatch(push(linksConstants.DASHBOARD.NEW_PROJECT)),
          label: t('pages.dashboard.createProjectButton')
        }
      ];
    }

    return [];
  };

  const getUserFullName = (user: User) => {
    return `${user.firstName} ${user.lastName}`.trim() || user.email;
  };

  const title = (
    <div>
      <WelcomeBackText>{t('pages.dashboard.emailProjects')},</WelcomeBackText>
      <div>{getUserFullName(user)}!</div>
    </div>
  );

  return (
    <PageContainer>
      <PageHeader title={title} rightActionButtons={getActionButtons()} />
      <PageContent>
        {initialLoading && <Loader />}
        {!initialLoading && !projects.result.length && !pagination.q && (
          <>
            <NoResultsFound>
              <div style={{ marginBottom: '2rem' }}>{t('pages.dashboard.noProjectsInProgress')}</div>

              {hasPermissionToAnyChannel(role) && (
                <div>
                  <Button
                    color={'secondary'}
                    variant={'contained'}
                    size="large"
                    onClick={() => dispatch(push(linksConstants.DASHBOARD.NEW_PROJECT))}
                  >
                    {t('pages.dashboard.createProjectButton')}
                  </Button>
                </div>
              )}
            </NoResultsFound>
          </>
        )}
        {((!initialLoading && projects.result.length > 0) || pagination.q) && (
          <DataTable<EmailProject, 'actions' | 'latestNote'>
            enableSearch={true}
            wrapperStyle={{
              padding: '0.5rem 2rem 0'
            }}
            headingStyle={{
              fontSize: '1.4rem',
              color: '#014b72',
              textAlign: 'left',
              padding: '0.5rem',
              fontWeight: 'normal'
            }}
            columnDefinitions={[
              {
                name: 'name',
                sortable: true,
                render: (project) => <ProjectNameCell project={project} user={user} role={role} />
              },
              {
                name: 'latestNote',
                sortable: false,
                render: (project) => <LatestNoteCell project={project} />
              },
              {
                name: 'updatedBy',
                sortable: false,
                render: (project) => <LatestActivityCell project={project} user={user} />
              },
              {
                name: 'publishDate',
                sortable: true,
                render: (project) => <PublicationDateCell project={project} />
              },
              {
                name: 'status',
                sortable: true,
                render: (project) => <ProjectStatusCell project={project} />
              },
              {
                name: 'actions',
                sortable: false,
                render: (project) => (
                  <ProjectActionsCell project={project} user={user} role={role} handleEvent={handleAction} />
                )
              }
            ]}
            tPath={'pages.dashboard.table'}
            data={projects}
            tableSearchProps={{
              tableSearch,
              module: TableSearchStoreModule.EMAIL_PROJECTS
            }}
            paginationProps={{
              pagination,
              module: PaginationStoreModule.EMAIL_PROJECTS
              // onPageChange: () => {},
              // onPerChange: () => {}
            }}
            isFailed={isFailed}
            isLoading={loading}
            expandableKey={'isShared'}
            expandableContent={(entity, open) => <ProjectExpandableContent entity={entity} open={open} />}
          />
        )}
      </PageContent>
      <ProjectShareWindow
        context="email"
        open={shareProjectWindowOpen}
        project={activeProject}
        onCloseClick={onShareProjectWindowClose}
        fullScreenOnMobile={true}
      />
      <ProjectRemoveWindow
        context="email"
        open={removeProjectWindowOpen}
        project={activeProject}
        onCloseClick={onRemoveProjectWindowClose}
        fullScreenOnMobile={true}
      />
      <ResetProjectCheckInStatusWindow
        open={resetCheckInWindowOpen}
        project={activeProject}
        onCloseClick={onResetCheckInWindowClose}
        fullScreenOnMobile={true}
      />
      {publishProjectWindowOpen && (
        <ProjectPublishNewsletterWindow
          open={publishProjectWindowOpen}
          project={activeProject as EmailProject}
          onCloseClick={onPublishProjectWindowClose}
          fullScreenOnMobile={true}
        />
      )}
      <ProjectCancelPublishWindow
        open={cancelPublishProjectWindowOpen}
        project={activeProject as EmailProject}
        onCloseClick={onCancelPublishProjectWindowClose}
        fullScreenOnMobile={true}
      />
      {previewEmailWindowOpen && (
        <PreviewEmailWindow
          open={previewEmailWindowOpen}
          project={activeProject as EmailProject}
          onCloseClick={onPreviewEmailWindowClose}
        />
      )}
      {generateLinkWindowOpen && (
        <GenerateLinkWindow
          open={generateLinkWindowOpen}
          project={activeProject as EmailProject}
          onCloseClick={onGenerateLinkWindowClose}
        />
      )}
    </PageContainer>
  );
};

const WelcomeBackText = styled.div`
  font-size: 1.4rem;
  font-weight: normal;
`;

export default EmailProjectsPage;
