export type AppState = {
  versions: {
    loading: boolean;
    error: NetworkError | null;
    versions: {
      api: string;
      web: string;
      designer: string;
    };
    designerVersionFromIframe: string;
  };
  videoPreviewWindow: {
    open: boolean;
    src: string;
  };
};

export type GetVersionsActionBEGIN = {
  type: AppActionsConstants.GET_VERSIONS_BEGIN;
};
export type GetVersionsActionSUCCESS = {
  type: AppActionsConstants.GET_VERSIONS_SUCCESS;
  payload: GetVersionsResponse;
};
export type GetVersionsActionFAILED = {
  type: AppActionsConstants.GET_VERSIONS_FAILED;
  payload: {
    error: any;
  };
};
export type SetDesignerVersionFromIframeAction = {
  type: AppActionsConstants.SET_DESIGNER_VERSION_FROM_IFRAME;
  payload: string;
};

export type GetVersionsResponse = {
  api: string;
  web: string;
  designer: string;
};
export type OpenVideoPreviewModalAction = {
  type: AppActionsConstants.OPEN_VIDEO_MODAL;
  payload: {
    src: string;
  };
};
export type CloseVideoPreviewModalAction = {
  type: AppActionsConstants.CLOSE_VIDEO_MODAL;
};

export enum AppActionsConstants {
  SET_DESIGNER_VERSION_FROM_IFRAME = 'APP/SET_DESIGNER_VERSION_FROM_IFRAME',
  GET_VERSIONS_BEGIN = 'APP/GET_VERSIONS_BEGIN',
  GET_VERSIONS_SUCCESS = 'APP/GET_VERSIONS_SUCCESS',
  GET_VERSIONS_FAILED = 'APP/GET_VERSIONS_FAILED',
  OPEN_VIDEO_MODAL = 'APP/OPEN_VIDEO_MODAL',
  CLOSE_VIDEO_MODAL = 'APP/CLOSE_VIDEO_MODAL'
}

export type AppActionType =
  | GetVersionsActionBEGIN
  | GetVersionsActionSUCCESS
  | GetVersionsActionFAILED
  | OpenVideoPreviewModalAction
  | CloseVideoPreviewModalAction
  | SetDesignerVersionFromIframeAction;
