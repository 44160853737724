import React, { FunctionComponent, useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import DatePicker from 'react-datepicker';
import { Box, Button, TextField } from '@material-ui/core';
import CalendarTodayIcon from '@material-ui/icons/CalendarToday';
import { ButtonsContainer } from '../ProjectDetailsPage';
import { PublishDatePicker } from '../../Dashboard/Creators/Partials/SelectPublishDate';
import { AnyProject } from '../../../../store/Dashboard/types';
import { formatPublishDate, getUtcDate, isEmailProject } from '../../../../utils';
import { emailProjectsOperations } from '../../../../store/EmailProjects';
import Toast from '../../../Shared/Toast/Toast';
import { EmailProject, EmailProjectStatus } from '../../../../store/EmailProjects/types';
import styled from 'styled-components';
import { useDispatch } from 'react-redux';
import moment from 'moment';

type PublicationDateDetailsProps = {
  disabled: boolean;
  project: AnyProject;
  onPublishDateUpdate: (date: Date) => void;
};

const PublicationDateDetails: FunctionComponent<PublicationDateDetailsProps> = ({
  project,
  onPublishDateUpdate,
  disabled
}) => {
  const projectPublishDate = moment(project.publishDate).isValid() ? getUtcDate(project.publishDate) : new Date();

  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [showDatePicker, setShowDatePicker] = useState<boolean>(false);
  const [activeChosenPublishDate, setActiveChosenPublishDate] = useState<Date>(projectPublishDate);
  const [subject, setSubject] = useState<string>(project.subject || '');

  const saveProjectSubject = useCallback(async () => {
    try {
      if (project && subject && subject !== project.subject) {
        await dispatch(emailProjectsOperations.saveProjectSubject(project as EmailProject, subject));
        Toast.success(t('notifications.projectSubjectChange.success'));
      }
    } catch (e) {
      Toast.error(t('notifications.projectSubjectChange.error'));
    }
  }, [subject]);

  return (
    <>
      <Box display="flex" alignItems="center">
        {t('pages.projectDetails.publishDate')}: {formatPublishDate(project.publishDate)}
        {!showDatePicker && (
          <Button
            disabled={disabled}
            variant="contained"
            color="secondary"
            onClick={() => setShowDatePicker(true)}
            style={{ marginLeft: '1rem' }}
          >
            <CalendarTodayIcon style={{ marginRight: 10 }} /> {t('common.change')}
          </Button>
        )}
      </Box>
      {showDatePicker && (
        <>
          <Box display="flex" justifyContent="center">
            <PublishDatePicker elevation={0}>
              <DatePicker
                disabledKeyboardNavigation={true}
                nextMonthButtonLabel="Next >"
                previousMonthButtonLabel="< Previous"
                selected={activeChosenPublishDate}
                onChange={(date) => {
                  if (date) {
                    setActiveChosenPublishDate(getUtcDate(date));
                  }
                }}
                inline
              />
            </PublishDatePicker>
          </Box>
          <Box display="flex" justifyContent="flex-end">
            <ButtonsContainer>
              <Button variant="outlined" color="primary" onClick={() => setShowDatePicker(false)}>
                {t('common.cancel')}
              </Button>
              <Button
                variant="contained"
                color="secondary"
                onClick={() => onPublishDateUpdate(activeChosenPublishDate)}
              >
                {t('common.update')}
              </Button>
            </ButtonsContainer>
          </Box>
        </>
      )}
      {isEmailProject(project) && (
        <ProjectSubjectWrapper>
          <TextField
            label={t('common.emailSubject')}
            variant="outlined"
            size="small"
            placeholder={`${t('common.emailSubject')}...`}
            value={subject}
            onBlur={saveProjectSubject}
            onChange={(e) => setSubject(e.target.value)}
            disabled={disabled || project.status !== EmailProjectStatus.IN_PROGRESS}
            fullWidth
          />
        </ProjectSubjectWrapper>
      )}
    </>
  );
};

const ProjectSubjectWrapper = styled.div`
  width: 100%;
  margin-top: 1rem;
`;

export default PublicationDateDetails;
