import React, { FunctionComponent, useState } from 'react';
import { useTranslation } from 'react-i18next';
import DeleteIcon from '@material-ui/icons/Delete';
import AddIcon from '@material-ui/icons/Add';
import EditIcon from '@material-ui/icons/Edit';
import { PageContainer, PageContent } from '../../PageStyledComponents';
import { formatTableTimestamp, useTypedSelector } from '../../../../utils';
import { usePaginationWatch } from '../../../Shared/DataTable/Pagination/usePaginationWatch';
import { useTableSearchWatch } from '../../../Shared/DataTable/useTableSearchWatch';
import { PaginationStoreModule } from '../../../../store/Pagination/types';
import DataTable from '../../../Shared/DataTable/DataTable';
import TableActionButtonsContainer from '../../../Shared/DataTable/TableActionButton/TableActionButtonsContainer';
import TableActionButton from '../../../Shared/DataTable/TableActionButton/TableActionButton';
import { TableSearchStoreModule } from '../../../../store/TableSearch/types';
import useOpenHandler from '../../../../hooks/useOpenHandler';
import AdminCreateOrganizationUserWindow from './Windows/Users/AdminCreateOrganizationUserWindow';
import AdminUpdateOrganizationUserWindow from './Windows/Users/AdminUpdateOrganizationUserWindow';
import AdminDeleteOrganizationUserWindow from './Windows/Users/AdminDeleteOrganizationUserWindow';
import PageHeader from '../../../Shared/Layout/PageHeader';
import { adminOrganizationUsersOperations } from '../../../../store/AdminOrganizationUsers';
import { AdminOrganizationUser } from '../../../../store/AdminOrganizationUsers/types';
import { RouteComponentProps } from 'react-router';
import { hasPermission } from '../../../../utils/permissions';
import { push } from 'connected-react-router';
import linksConstants from '../../../../config/app/linksConstants';
import KeyboardArrowLeftIcon from '@material-ui/icons/KeyboardArrowLeft';
import { useDispatch } from 'react-redux';
import { Strong } from '../../../Shared/StyledComponents';

type AdminOrganizationUsersPageProps = RouteComponentProps<{ organizationId: string }> & {};

const AdminOrganizationUsersEditPage: FunctionComponent<AdminOrganizationUsersPageProps> = ({ match }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const role = useTypedSelector((state) => state.auth.role);
  const organization = useTypedSelector((state) => state.adminOrganizationUsers.organization);
  const organizationUsers = useTypedSelector((state) => state.adminOrganizationUsers.users);
  const isFailed = useTypedSelector((state) => state.adminOrganizationUsers.index.isFailed);
  const isLoading = useTypedSelector((state) => state.adminOrganizationUsers.index.isLoading);
  const pagination = useTypedSelector((state) => state.adminOrganizationUsers.pagination);
  const tableSearch = useTypedSelector((state) => state.adminOrganizationUsers.tableSearch);

  const organizationId = match.params.organizationId;

  usePaginationWatch(pagination, [adminOrganizationUsersOperations.index.bind(null, organizationId)]);
  useTableSearchWatch<AdminOrganizationUser>(tableSearch, [
    adminOrganizationUsersOperations.index.bind(null, organizationId)
  ]);

  const [activeOrganizationUser, setActiveOrganizationUser] = useState<AdminOrganizationUser | null>(null);
  const [
    createOrganizationUserWindowOpen,
    onCreateOrganizationUserWindowOpen,
    onCreateOrganizationUserWindowClose
  ] = useOpenHandler();
  const [
    updateOrganizationUserWindowOpen,
    onUpdateOrganizationUserWindowOpen,
    onUpdateOrganizationUserWindowClose
  ] = useOpenHandler();
  const [
    deleteOrganizationUserWindowOpen,
    onDeleteOrganizationUserWindowOpen,
    onDeleteOrganizationUserWindowClose
  ] = useOpenHandler();

  const getActionButtons = () => {
    if (hasPermission(role, ['organizationsEdit'])) {
      return [
        {
          label: t('pages.adminOrganizationUsers.createOrganizationUser'),
          icon: <AddIcon />,
          onClick: onCreateOrganizationUserWindowOpen
        }
      ];
    }

    return [];
  };

  const backButton = {
    onClick: () => dispatch(push(linksConstants.ADMINISTRATION.ORGANIZATIONS.INDEX)),
    label: t('common.back'),
    icon: <KeyboardArrowLeftIcon />
  };

  const organizationOwnersIds = organizationUsers.result.filter((userId) => organizationUsers.entities[userId].isOwner);

  return (
    <PageContainer>
      <PageHeader
        title={`${organization ? `${organization.name} -` : ''} ${t('pages.adminOrganizationUsers.title')}`}
        leftActionButtons={[backButton]}
        rightActionButtons={getActionButtons()}
      />
      <PageContent>
        <DataTable<AdminOrganizationUser, 'actions'>
          enableSearch={true}
          columnDefinitions={[
            {
              name: 'email',
              sortable: true,
              render: (organizationUser) => (
                <>
                  <div>{organizationUser.email}</div>{' '}
                  {organizationUser.isOwner && <Strong>({t('common.owner')})</Strong>}
                </>
              )
            },
            {
              name: 'actions',
              render: (organizationUser) => (
                <TableActionButtonsContainer>
                  <TableActionButton
                    icon={<EditIcon />}
                    tooltip={t('pages.adminOrganizationUsers.table.buttons.edit')}
                    ButtonProps={{
                      disabled: !hasPermission(role, ['organizationsEdit']),
                      onClick: () => {
                        setActiveOrganizationUser(organizationUser);
                        onUpdateOrganizationUserWindowOpen();
                      }
                    }}
                  />
                  <TableActionButton
                    icon={<DeleteIcon />}
                    tooltip={t('pages.adminOrganizationUsers.table.buttons.delete')}
                    ButtonProps={{
                      disabled:
                        !hasPermission(role, ['organizationsEdit']) ||
                        (organizationUser.isOwner && organizationOwnersIds.length <= 1),
                      onClick: () => {
                        setActiveOrganizationUser(organizationUser);
                        onDeleteOrganizationUserWindowOpen();
                      }
                    }}
                  />
                </TableActionButtonsContainer>
              )
            },
            {
              name: 'createdAt',
              render: (organizationUser) => <>{formatTableTimestamp(organizationUser.createdAt)}</>,
              sortable: true
            }
          ]}
          tPath={'pages.adminOrganizationUsers.table'}
          data={organizationUsers}
          tableSearchProps={{
            tableSearch,
            module: TableSearchStoreModule.ADMIN_ORGANIZATION_USERS
          }}
          paginationProps={{
            pagination,
            module: PaginationStoreModule.ADMIN_ORGANIZATION_USERS
            // onPageChange: () => {},
            // onPerChange: () => {}
          }}
          isFailed={isFailed}
          isLoading={isLoading}
        />
      </PageContent>

      <AdminCreateOrganizationUserWindow
        organizationId={organizationId}
        open={createOrganizationUserWindowOpen}
        onCloseClick={onCreateOrganizationUserWindowClose}
        fullScreenOnMobile
      />
      <AdminUpdateOrganizationUserWindow
        organizationId={organizationId}
        disableSetAsOwnerCheckbox={Boolean(
          activeOrganizationUser &&
            organizationOwnersIds.length === 1 &&
            organizationOwnersIds.includes(activeOrganizationUser.id)
        )}
        organizationUser={activeOrganizationUser}
        open={updateOrganizationUserWindowOpen}
        onCloseClick={onUpdateOrganizationUserWindowClose}
        fullScreenOnMobile
      />
      <AdminDeleteOrganizationUserWindow
        organizationId={organizationId}
        organizationUser={activeOrganizationUser}
        open={deleteOrganizationUserWindowOpen}
        onCloseClick={onDeleteOrganizationUserWindowClose}
        fullScreenOnMobile
      />
    </PageContainer>
  );
};

export default AdminOrganizationUsersEditPage;
