import { BaseSettings, RequestSettings } from '../../utils/httpRequest';
import { DamSystemForm, PasswordPolicySettings, SystemSetting } from './types';

export const getSystemSettings = (): BaseSettings => ({
  query: {
    method: 'get',
    url: '/api/v1/admin/system_settings'
  }
});

export const setSystemSettings = (
  settings: RequestSettings<{}, { data: Pick<SystemSetting, 'type' | 'value'>[] }>
): BaseSettings => ({
  query: {
    method: 'put',
    url: '/api/v1/admin/system_settings'
  },
  payload: settings.requestPayload
});

export const getGlobalPasswordPolicy = (): BaseSettings => ({
  query: {
    method: 'get',
    url: '/api/v1/admin/system_settings/password_policy'
  }
});

export const setGlobalPasswordPolicy = (settings: RequestSettings<{}, PasswordPolicySettings>): BaseSettings => ({
  query: {
    method: 'put',
    url: '/api/v1/admin/system_settings/password_policy'
  },
  payload: settings.requestPayload
});

export const getDamSystems = (): BaseSettings => ({
  query: {
    method: 'get',
    url: '/api/v1/admin/dam'
  }
});

export const deleteDamSystem = (settings: RequestSettings<{ id: string }, {}>): BaseSettings => ({
  query: {
    method: 'delete',
    url: '/api/v1/admin/dam/:id'
  },
  params: settings.requestParams
});

export const createDamSystem = (settings: RequestSettings<{}, DamSystemForm>): BaseSettings => ({
  query: {
    method: 'post',
    url: '/api/v1/admin/dam'
  },
  payload: settings.requestPayload
});

export const updateDamSystem = (settings: RequestSettings<{ id: string }, DamSystemForm>): BaseSettings => ({
  query: {
    method: 'put',
    url: '/api/v1/admin/dam/:id'
  },
  payload: settings.requestPayload,
  params: settings.requestParams
});
