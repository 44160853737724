import React, { FunctionComponent, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Box, Button, Grid, MenuItem, Typography } from '@material-ui/core';
import { PageContentPaper, PageContentPaperHeading } from '../../../PageStyledComponents';
import { Form, Formik, FormikHelpers, FormikProps } from 'formik';
import { useTypedSelector } from '../../../../../utils';
import * as Yup from 'yup';
import app from '../../../../../config/app/app';
import { AdminCheckboxInput, AdminSelectInput, AdminTextInput } from '../../Shared/AdminFormInputs';
import { AdminReportsGenerateCsvValues, AdPlacementReportFormValues } from '../../../../../store/AdminReports/types';
import { FilterHeader } from '../../AdminStyledComponents';
import styled from 'styled-components';
import SaveIcon from '@material-ui/icons/Save';
import { adminReportsOperations } from '../../../../../store/AdminReports';
import Toast from '../../../../Shared/Toast/Toast';
import useOpenHandler from '../../../../../hooks/useOpenHandler';
import AdminReportsGenerateCsvWindow from '../Windows/AdminReportsGenerateCsvWindow';

type AdPlacementReportProps = {};

const AdPlacementReport: FunctionComponent<AdPlacementReportProps> = () => {
  const { t } = useTranslation();
  const [loading, setLoading] = useState<boolean>(false);
  const [formFilters, setFormFilters] = useState<AdPlacementReportFormValues>({
    organizationNumber: '',
    dataCenter: 0,
    size: '',
    available: true,
    sold: true,
    oversold: true
  });
  const authState = useTypedSelector((state) => state.auth);
  const [csvDownloadWindowOpen, onCsvDownloadWindowOpen, onCsvDownloadWindowClose] = useOpenHandler();

  const showDateRangeModal = async (values: AdPlacementReportFormValues) => {
    setFormFilters(values);
    onCsvDownloadWindowOpen();
  };

  const downloadReport = async (range: AdminReportsGenerateCsvValues) => {
    try {
      await adminReportsOperations.adPlacementReport(formFilters, range);
      Toast.success(t('notifications.adminReports.success'));

      setTimeout(() => {
        setLoading(false);
      }, 2000);
    } catch (e) {
      Toast.error(t('notifications.adminReports.error'));
      setLoading(false);
    }
  };

  const renderForm = (props: FormikProps<AdPlacementReportFormValues>) => (
    <Form>
      <Grid container spacing={3}>
        <Grid item xs={12} md={6}>
          <AdminTextInput t={t} name="organizationNumber" section="adminReports" />
          <AdminSelectInput t={t} name="dataCenter" section="commonInputs">
            {Object.keys({ notSet: -1, ...authState.dataCenters }).map((dataCenter, i) => {
              if (i === 0) {
                return (
                  <MenuItem key={0} value={0}>
                    {t(`common.all`)}
                  </MenuItem>
                );
              }
              return (
                <MenuItem key={i + 1} value={i.toString()}>
                  {t(`common.dataCenters.${dataCenter}`)}
                </MenuItem>
              );
            })}
          </AdminSelectInput>
          <AdminTextInput t={t} name="size" section="adminReports" />
        </Grid>
        <Grid item xs={12} md={6}>
          <FilterHeader style={{ marginTop: '1em' }}>{t('pages.adminReports.inputs.statuses')}</FilterHeader>

          <StatusesWrapper>
            <AdminCheckboxInput t={t} name="available" section="adminReports" />
            <AdminCheckboxInput t={t} name="sold" section="adminReports" />
            <AdminCheckboxInput t={t} name="oversold" section="adminReports" />
          </StatusesWrapper>
        </Grid>
      </Grid>
      <Box display="flex" justifyContent="flex-end">
        <Button
          style={{ marginTop: '1rem' }}
          color="secondary"
          variant="contained"
          size="medium"
          type="submit"
          disabled={loading || props.isSubmitting || !props.isValid}
        >
          <SaveIcon /> {t('pages.adminReports.generateCsvReport')}
        </Button>
      </Box>
    </Form>
  );

  return (
    <PageContentPaper>
      <PageContentPaperHeading>
        <Typography variant="h6" gutterBottom>
          {t('pages.adminReports.adPlacementReport.title')}
        </Typography>
      </PageContentPaperHeading>
      <hr style={{ marginBottom: '1.5rem', opacity: 0.1 }} />
      <div>
        <Formik
          initialValues={{
            organizationNumber: '',
            dataCenter: 0,
            size: '',
            available: true,
            sold: true,
            oversold: true
          }}
          validationSchema={Yup.object().shape({
            organizationNumber: Yup.string().max(app.maxInputLength),
            dataCenter: Yup.number(),
            size: Yup.string(),
            available: Yup.boolean(),
            sold: Yup.boolean(),
            oversold: Yup.boolean()
          })}
          onSubmit={showDateRangeModal}
        >
          {renderForm}
        </Formik>
      </div>
      <AdminReportsGenerateCsvWindow
        open={csvDownloadWindowOpen}
        onCloseClick={onCsvDownloadWindowClose}
        onSubmit={async (range) => await downloadReport(range)}
      />
    </PageContentPaper>
  );
};

const StatusesWrapper = styled.div`
  display: flex;
  flex-direction: column;
  padding-left: 1em;
  padding-top: 1em;
`;

export default AdPlacementReport;
