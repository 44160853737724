export type ReportFormat = 'json' | 'csv';

export type GetReportFormatParameter = {
  format: ReportFormat;
  items?: number;
  range?: ReportDateRange;
  from?: Date;
  to?: Date;
};

export enum ReportDateRange {
  TODAY = 'today',
  LAST_DAY = 'lastDay',
  LAST_WEEK = 'lastWeek',
  THIS_WEEK = 'thisWeek',
  LAST_MONTH = 'lastMonth',
  THIS_MONTH = 'thisMonth',
  THIS_QUARTER = 'thisQuarter',
  LAST_QUARTER = 'lastQuarter',
  LAST_YEAR = 'lastYear',
  THIS_YEAR = 'thisYear',
  CUSTOM = 'custom'
}

export type AdminReportsGenerateCsvValues = {
  dateRange: ReportDateRange;
  startDate?: Date;
  endDate?: Date;
};

export type EmailCampaignsReportChartData = {
  date: string;
  campaigns: number;
  recipients: number;
};

export type EmailCampaignsReportResponse = {
  data: EmailCampaignsReportChartData[];
};

export type OrganizationMailingListSizeReportData = {
  organization: string;
  accountNumber: string;
  subscribers: number;
};

export type OrganizationMailingListSizeReportResponse = {
  data: OrganizationMailingListSizeReportData[];
};

export type DailyOrganizationEmailActivityReporData = {
  date: string;
  totalOrganizations: number;
  organizations: number;
  campaigns: number;
  uniqueOpens: number;
};

export type DailyOrganizationEmailActivityReportResponse = {
  data: DailyOrganizationEmailActivityReporData[];
};

export type DailyOrganizationPrintActivityReportData = {
  date: string;
  created: number;
  submitted: number;
};

export type DailyOrganizationPrintActivityReportResponse = {
  data: DailyOrganizationPrintActivityReportData[];
};

export type SubmittedPrintProjectsReportResponse = {
  data: SubmittedPrintProjectsReportData[];
};

export type SubmittedPrintProjectsReportData = {
  id: string;
  name: string;
  organizationNumber: string;
  organizationName: string;
  organizationId: string;
  submittedBy: string;
  publishDate: string;
  submittedAt: string;
  status: string;
};

export type DetailedEmailCampaignsReportResponse = {
  data: DetailedEmailCampaignsReportData[];
};

export type DetailedEmailCampaignsReportData = {
  id: string;
  name: string;
  organizationNumber: string;
  organizationName: string;
  organizationId: string;
  scheduledBy: string;
  deliveredAt: string | null;
  uniqueOpens: number;
  recipients: number;
};

export type DailyAdsReportData = {
  date: string;
  impressions: number;
  clicks: number;
};

export type DailyAdsReportResponse = {
  data: DailyAdsReportData[];
};

export type AdPlacementReportFormValues = {
  organizationNumber: string;
  dataCenter: number;
  size: string;
  available: boolean;
  sold: boolean;
  oversold: boolean;
};

export type AdPlacementReportParameters = {
  organizationNumber?: string;
  dataCenter?: number;
  size?: string;
  statuses?: string;
  range?: ReportDateRange;
  from?: Date;
  to?: Date;
};
