import React from 'react';
import { useDrag, useDrop } from 'react-dnd';
import { IconButton, useTheme } from '@material-ui/core';
import DeleteForeverIcon from '@material-ui/icons/DeleteForever';
import OpenWithIcon from '@material-ui/icons/OpenWith';
import { StoryChannelAttachment } from '../../../../../store/Stories/types';
import InputChangeOnBlur from '../../../../Shared/Forms/InputChangeOnBlur';
import { useTranslation } from 'react-i18next';
import { AttachmentThumbnail } from './AttachmentThumbnail';

const style = {
  display: 'flex',
  alignItems: 'center',
  marginTop: '15px'
};

export interface AttachmentProps {
  id: string;
  attachment: StoryChannelAttachment;
  moveAttachment: (id: string, to: number) => void;
  findAttachment: (id: string) => { index: number };
  onRemoveAttachment: (attachment: StoryChannelAttachment) => void;
  onChangeAttachmentCaption: (attachment: StoryChannelAttachment, caption: string) => Promise<void>;
  attachmentsLength: number;
  disabled: boolean;
}

interface Item {
  type: string;
  id: string;
  originalIndex: string;
}

export const Attachment: React.FC<AttachmentProps> = ({
  id,
  attachment,
  moveAttachment,
  findAttachment,
  onRemoveAttachment,
  onChangeAttachmentCaption,
  attachmentsLength,
  disabled
}) => {
  const { t } = useTranslation();
  const [removingFile, setRemovingFile] = React.useState<boolean>(false);
  const originalIndex = findAttachment(id).index;
  const [{ isDragging }, drag, preview] = useDrag({
    item: { type: 'attachment', id, originalIndex },
    collect: (monitor) => ({
      isDragging: monitor.isDragging()
    }),
    end: (dropResult, monitor) => {
      const { id: droppedId, originalIndex } = monitor.getItem();
      const didDrop = monitor.didDrop();
      if (!didDrop) {
        moveAttachment(droppedId, originalIndex);
      }
    }
  });

  const [, drop] = useDrop({
    accept: 'attachment',
    canDrop: () => false,
    hover({ id: draggedId }: Item) {
      if (draggedId !== id) {
        const { index: overIndex } = findAttachment(id);
        moveAttachment(draggedId, overIndex);
      }
    }
  });

  const theme = useTheme();
  const handleStyle = {
    width: '24px',
    height: '24px',
    display: 'inline-block',
    marginRight: '0.75rem',
    cursor: 'move',
    color: theme.palette.primary.main
  };

  const opacity = isDragging ? 0 : 1;
  return preview(
    <div ref={(node) => drop(node)} style={{ ...style, opacity }}>
      {attachmentsLength > 1 && !disabled && (
        <div ref={drag} style={handleStyle}>
          <OpenWithIcon />
        </div>
      )}
      <AttachmentThumbnail attachment={attachment} />
      <div style={{ width: '100%' }}>
        <InputChangeOnBlur
          fullWidth
          label={t('common.caption')}
          initialValue={attachment.filename}
          onSave={(caption) => onChangeAttachmentCaption(attachment, caption)}
          disabled={disabled}
        />
      </div>
      {!disabled && (
        <IconButton
          size="small"
          disabled={removingFile}
          onClick={async () => {
            setRemovingFile(false);
            await onRemoveAttachment(attachment);
          }}
        >
          <DeleteForeverIcon />
        </IconButton>
      )}
    </div>
  );
};
