import React, { FunctionComponent, useState } from 'react';
import { PageContainer, PageContent } from '../../PageStyledComponents';
import PageHeader from '../../../Shared/Layout/PageHeader';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { push } from 'connected-react-router';
import linksConstants from '../../../../config/app/linksConstants';
import KeyboardArrowLeftIcon from '@material-ui/icons/KeyboardArrowLeft';
import SelectPublishDate from './Partials/SelectPublishDate';
import SelectTemplate from './Partials/SelectTemplate';
import { PrintProjectTemplateTypeName, Template, TemplateTypeName } from '../../../../store/Templates/types';
import { BulletinProjectCreatorState } from '../types';
import Toast from '../../../Shared/Toast/Toast';
import LoadingModal from '../../../Shared/Loading/LoadingModal';
import CreateProjectStepper from './Partials/CreateProjectStepper';
import { createProjectErrorHandler } from './Utils';
import { printProjectsOperations } from '../../../../store/PrintProjects';

type BulletinProjectCreatorProps = {};

const BulletinProjectCreator: FunctionComponent<BulletinProjectCreatorProps> = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const steps: BulletinProjectCreatorState[] = ['publishDate', 'template', 'finish'];

  const [publishDate, setPublishDate] = useState<Date>(new Date());
  const [selectedTemplate, setSelectedTemplate] = useState<Template | null>(null);
  const [currentState, setCurrentState] = useState<BulletinProjectCreatorState>('publishDate');
  const [creatingProject, setCreatingProject] = useState(false);
  const [testProject, setTestProject] = useState(false);

  const backButton = {
    onClick: () => {
      switch (currentState) {
        case 'publishDate':
          return dispatch(push(linksConstants.DASHBOARD.NEW_PROJECT));

        case 'template':
          return setCurrentState('publishDate');

        case 'finish':
          return setCurrentState('template');
      }
    },
    label: t('common.back'),
    icon: <KeyboardArrowLeftIcon />
  };

  const onPublishDateSelected = (date: Date) => setPublishDate(date);

  const onTemplateSelected = async (template: Template) => {
    setSelectedTemplate(template);
    setCurrentState('finish');

    setCreatingProject(true);

    await createProject(template);
  };

  const createProject = async (template: Template) => {
    if (!template) {
      return Toast.info(t('notifications.projectCreate.templateNotSelected'));
    }

    setCreatingProject(true);
    try {
      const newProject = await printProjectsOperations.create(
        template,
        publishDate,
        PrintProjectTemplateTypeName.BULLETIN,
        testProject
      );
      Toast.success(t('notifications.projectCreate.success'));

      dispatch(push(linksConstants.PROJECTS.BULLETIN.EDIT(newProject.id)));
    } catch (e) {
      createProjectErrorHandler(e, t);

      setCreatingProject(false);
      setCurrentState('template');
    }
  };

  const getCurrentSection = () => {
    switch (currentState) {
      case 'publishDate':
        return (
          <SelectPublishDate
            onSubmit={() => setCurrentState('template')}
            onSelect={onPublishDateSelected}
            testProject={testProject}
            onTestProjectChecked={() => setTestProject(!testProject)}
            date={publishDate}
            checkReadMoreLinks={false}
            infoText="pages.dashboard.createWizard.publishDateSelector.selectDateInfoBulletin"
          />
        );

      case 'template':
      case 'finish':
        return (
          <SelectTemplate
            showSourceProject={false}
            showTopBar
            onSubmit={onTemplateSelected}
            templateTypeName={TemplateTypeName.BULLETIN}
            publishDate={publishDate}
            sourceProject={null}
            infoText={t('pages.dashboard.createWizard.templateSelector.selectBulletinTemplate')}
            setCurrentState={() => {
              console.log('aa');
            }}
          />
        );
    }
  };

  const HeaderTitle = (
    <>
      <div>{t('pages.dashboard.createWizard.bulletinCreatorTitle')}</div>
      <div>
        <CreateProjectStepper currentState={currentState} steps={steps} />
      </div>
    </>
  );

  return (
    <PageContainer>
      {creatingProject && <LoadingModal message={t('pages.dashboard.creatingYourProject')} />}
      <PageHeader title={HeaderTitle} leftActionButtons={[backButton]} noBottomPadding />
      <PageContent>{getCurrentSection()}</PageContent>
    </PageContainer>
  );
};

export default BulletinProjectCreator;
