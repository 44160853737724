import { BaseSettings, RequestSettings } from '../../utils/httpRequest';
import {
  AdminCreateEmailTemplateValues,
  AdminUpdateEmailTemplateValues,
  IndexAdminEmailTemplatesRequestParams
} from './types';
import { ProjectModelV2 } from '../../components/Pages/Editors/EmailDndEditor/types';

export const index = (settings: RequestSettings<IndexAdminEmailTemplatesRequestParams, {}>): BaseSettings => ({
  query: {
    method: 'get',
    url: `/api/v1/admin/templates/email?per=:per&page=:page`
  },
  params: settings.requestParams
});

export const create = (settings: RequestSettings<{}, AdminCreateEmailTemplateValues>): BaseSettings => ({
  query: {
    method: 'post',
    url: '/api/v1/admin/templates/email'
  },
  payload: settings.requestPayload
});

export const update = (settings: RequestSettings<{}, AdminUpdateEmailTemplateValues>): BaseSettings => ({
  query: {
    method: 'put',
    url: '/api/v1/admin/templates/email/:id'
  },
  payload: settings.requestPayload,
  params: settings.requestParams
});

export const deleteEmailTemplate = (settings: RequestSettings<{ id: string }>): BaseSettings => ({
  query: {
    method: 'delete',
    url: '/api/v1/admin/templates/email/:id'
  },
  params: settings.requestParams
});

export const downloadEmailTemplate = (settings: RequestSettings<{ id: string }>): BaseSettings => ({
  query: {
    method: 'get',
    url: '/api/v1/admin/templates/email/:id/download'
  },
  params: settings.requestParams
});

export const uploadEmailTemplateZip = (settings: {
  requestPayload: FormData;
  requestParams: { id: string };
}): BaseSettings => ({
  query: {
    method: 'post',
    url: '/api/v1/admin/templates/email/:id/upload'
  },
  payload: settings.requestPayload,
  params: settings.requestParams
});

export const getEmailTemplateThumbnail = (settings: RequestSettings<{ id: string }>): BaseSettings => ({
  query: {
    method: 'get',
    url: '/api/v1/admin/templates/email/:id/thumbnail'
  },
  params: settings.requestParams
});

export const uploadEmailTemplateThumbnail = (settings: {
  requestPayload: FormData;
  requestParams: { id: string };
}): BaseSettings => ({
  query: {
    method: 'post',
    url: '/api/v1/admin/templates/email/:id/upload_thumbnail'
  },
  payload: settings.requestPayload,
  params: settings.requestParams
});

export const addKeywordToEmailTemplate = (
  settings: RequestSettings<{ templateId: string }, { keywordId: string }>
): BaseSettings => ({
  query: {
    method: 'post',
    url: '/api/v1/admin/templates/email/:templateId/keywords'
  },
  payload: settings.requestPayload,
  params: settings.requestParams
});

export const addKeywordGroupToEmailTemplate = (
  settings: RequestSettings<{ templateId: string }, { keywordGroupId: string }>
): BaseSettings => ({
  query: {
    method: 'post',
    url: '/api/v1/admin/templates/email/:templateId/keyword_groups'
  },
  payload: settings.requestPayload,
  params: settings.requestParams
});

export const removeKeywordFromEmailTemplate = (
  settings: RequestSettings<{ templateId: string }, { keywordId: string }>
): BaseSettings => ({
  query: {
    method: 'delete',
    url: '/api/v1/admin/templates/email/:templateId/keywords'
  },
  payload: settings.requestPayload,
  params: settings.requestParams
});

export const removeKeywordGroupFromEmailTemplate = (
  settings: RequestSettings<{ templateId: string }, { keywordGroupId: string }>
): BaseSettings => ({
  query: {
    method: 'delete',
    url: '/api/v1/admin/templates/email/:templateId/keyword_groups'
  },
  payload: settings.requestPayload,
  params: settings.requestParams
});

export const getTemplateModel = (settings: RequestSettings<{ templateId: string }, {}>): BaseSettings => ({
  query: {
    method: 'get',
    url: '/api/v1/admin/templates/email/:templateId/model'
  },
  params: settings.requestParams
});

export const getAll = (): BaseSettings => ({
  query: {
    method: 'get',
    url: '/api/v1/admin/templates/email/all'
  }
});

export const show = (settings: RequestSettings<{ templateId: string }, {}>): BaseSettings => ({
  query: {
    method: 'get',
    url: '/api/v1/admin/templates/email/:templateId'
  },
  params: settings.requestParams
});

export const saveModel = (
  settings: RequestSettings<{ templateId: string }, { projectModel: ProjectModelV2 }>
): BaseSettings => ({
  query: {
    method: 'post',
    url: '/api/v1/admin/templates/email/:templateId/model'
  },
  payload: settings.requestPayload,
  params: settings.requestParams
});

export const setAutomatedDefaultTemplate = (settings: RequestSettings<{ templateId: string }, {}>): BaseSettings => ({
  query: {
    method: 'put',
    url: '/api/v1/admin/templates/email/:templateId/set_automated_default_template'
  },
  params: settings.requestParams
});
