import {
  ArticleHorizontal,
  ArticleVertical,
  CtaButton,
  DownloadAttachment,
  EmailProjectRow,
  ProjectModalColumnSize,
  ProjectModelColumn,
  ProjectModelV1,
  ProjectModelV2
} from '../types';
import { ColorSwatchType, MyOrganizationSwatchColor } from '../../../../../store/MyOrganization/types';

export const getEmptyColumnsArray = (sizes: ProjectModalColumnSize[]): ProjectModelColumn[] => {
  return sizes.map((size) => ({
    size,
    components: []
  }));
};

export const calculateColumnWidth = (size: number): number => {
  switch (size) {
    case 1:
      return 8.33;
    case 2:
      return 16.66;
    case 3:
      return 25;
    case 4:
      return 33.33;
    case 5:
      return 41.66;
    case 6:
      return 50;
    case 7:
      return 58.33;
    case 8:
      return 66.66;
    case 9:
      return 75;
    case 10:
      return 83.33;
    case 11:
      return 91.66;
    default:
      return 100;
  }
};

export const sanitizeToV2Model = (model: ProjectModelV1): ProjectModelV2 => {
  let columnSize = 0;
  let rowIterator = 0;
  let currentRow: EmailProjectRow = {
    id: rowIterator,
    cols: []
  };

  const v2Model: ProjectModelV2 = {
    colorSwatches: model.colorSwatches,
    version: 2,
    sizes: {
      large: [currentRow]
    }
  };

  for (const col of model.sizes.large) {
    /** Another row should be created */
    if (columnSize >= 12) {
      columnSize = 0;
      /** Increase row iterator to correctly set unique ID */
      rowIterator = rowIterator + 1;
      /** current row object re-init */
      currentRow = {
        id: rowIterator,
        cols: []
      };
      v2Model.sizes.large.push(currentRow);
    }

    columnSize += col.size;
    currentRow.cols.push(col);
  }

  return v2Model;
};

export const extractColorsFromSwatches = (
  selectedColorSwatches: { [key: string]: MyOrganizationSwatchColor },
  types: ColorSwatchType[],
  colorSwatchIgnore: string[] = [],
  defaultColor = '',
  overrideCss?: { [key: string]: string }
) => {
  const colors: { [key: string]: string } = {};

  for (const type of types) {
    if (selectedColorSwatches && selectedColorSwatches[type]) {
      const hex = selectedColorSwatches[type].hex;

      switch (type) {
        case ColorSwatchType.paragraph:
        case ColorSwatchType.link:
        case ColorSwatchType.headerFooterText:
        case ColorSwatchType.heading:
          colors['color'] = colorSwatchIgnore.includes('color') ? '' : hex;
          break;
        case ColorSwatchType.button:
        case ColorSwatchType.headerFooterBackground:
        case ColorSwatchType.accent1:
        case ColorSwatchType.accent2:
          colors['backgroundColor'] = colorSwatchIgnore.includes('background-color') ? '' : hex;
          break;
        case ColorSwatchType.divider1:
        case ColorSwatchType.divider2:
          colors['borderColor'] = colorSwatchIgnore.includes('border-color') ? defaultColor : hex;
          break;
      }
    }
  }

  if (overrideCss) {
    for (const [k, v] of Object.entries(overrideCss)) {
      if (v !== 'inherit') {
        colors[k] = v;
      }
    }
  }

  return colors;
};

export const getArticleColorSwatchTypes = (data: ArticleHorizontal | ArticleVertical) => {
  const elementTypes: ColorSwatchType[] = [];
  if (data.role === 'accent1') {
    elementTypes.push(ColorSwatchType.accent1);
  }
  if (data.role === 'accent2') {
    elementTypes.push(ColorSwatchType.accent2);
  }
  return elementTypes;
};

export const isHTML = (text: string) => {
  const a = document.createElement('div');
  a.innerHTML = text;

  for (let c = a.childNodes, i = c.length; i--; ) {
    if (c[i].nodeType == 1) return true;
  }

  return false;
};

export const getDefaultButtonBorderRadius = (data: CtaButton | DownloadAttachment) => {
  if (data.params.style && data.params.style.borderRadius) {
    if (data.params.style.borderRadius === 'inherit' && data.style && data.style['borderRadius']) {
      return data.style['borderRadius'].toString();
    }
    return data.params.style.borderRadius;
  } else if (data.style && data.style['borderRadius']) {
    return data.style['borderRadius'].toString();
  } else {
    return 'inherit';
  }
};

export const getDefaultButtonBackgroundColor = (data: CtaButton | DownloadAttachment) => {
  if (data.params.style && data.params.style.backgroundColor) {
    if (data.params.style.backgroundColor === 'inherit' && data.style && data.style['backgroundColor']) {
      return data.style['backgroundColor'].toString();
    }
    return data.params.style.backgroundColor;
  } else if (data.style && data.style['backgroundColor']) {
    return data.style['backgroundColor'].toString();
  } else {
    return 'inherit';
  }
};

export const getDefaultButtonBorderWidth = (data: CtaButton | DownloadAttachment) => {
  let defaultBorderWidth: string | null = null;
  if (data.style && data.style.border && typeof data.style.border === 'string') {
    const [borderWidth, borderStyle, ...borderColor] = data.style.border.split(' ');
    defaultBorderWidth = borderWidth;
  }

  if (data.params.style && data.params.style.borderWidth) {
    if (data.params.style.borderWidth === 'inherit' && data.style && data.style['borderWidth']) {
      return data.style['borderWidth'].toString();
    } else if (data.params.style.borderWidth === 'inherit' && defaultBorderWidth) {
      return defaultBorderWidth;
    }
    return data.params.style.borderWidth;
  } else if (data.style && data.style['borderWidth']) {
    return data.style['borderWidth'].toString();
  } else if (defaultBorderWidth) {
    return defaultBorderWidth;
  } else {
    return 'inherit';
  }
};

export const getDefaultButtonBorderColor = (data: CtaButton | DownloadAttachment) => {
  let defaultBorderColor: string | null = null;
  if (data.style && data.style.border && typeof data.style.border === 'string') {
    const [borderWidth, borderStyle, ...borderColor] = data.style.border.split(' ');
    defaultBorderColor = borderColor.join(' ');
  }

  if (data.params.style && data.params.style.borderColor) {
    if (data.params.style.borderColor === 'inherit' && data.style && data.style['borderColor']) {
      return data.style['borderColor'].toString();
    } else if (data.params.style.borderColor === 'inherit' && defaultBorderColor) {
      return defaultBorderColor;
    }
    return data.params.style.borderColor;
  } else if (data.style && data.style['borderColor']) {
    return data.style['borderColor'].toString();
  } else if (defaultBorderColor) {
    return defaultBorderColor;
  } else {
    return 'inherit';
  }
};
