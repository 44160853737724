import { Dictionary } from 'lodash';
import { StateWithPagination, StateWithTableSearch } from '../../components/Shared/DataTable/types';
import { MailingList, MailingListsUpdateAction } from '../MailingLists/types';

export type SubscribersState = StateWithPagination &
  StateWithTableSearch<Subscriber> & {
    data: NormalizedSubscribers;

    mailingList: MailingList | null;

    index: {
      isLoading: boolean;
      isFailed: boolean;
      error: NetworkError | null;
    };
  };

export type NormalizedSubscribers = {
  entities: Dictionary<Subscriber>;
  result: string[];
};

export type SubscribersIndexResponse = {
  data: Subscriber[];
  mailingList: MailingList;
  pagination: Pagination;
};

export enum SubscriberStatus {
  SUBSCRIBED = 1,
  UNSUBSCRIBED = 2,
  BOUNCED = 3,
  REMOVED = 4
}

export enum SubscribedWith {
  FORM,
  IMPORT,
  MANUAL
}

export type SubscribersIndexActionBEGIN = {
  type: SubscribersActionsConstants.INDEX_BEGIN;
};

export type SubscribersIndexActionSUCCESS = {
  type: SubscribersActionsConstants.INDEX_SUCCESS;
  payload: SubscribersIndexResponse;
};

export type IndexSubscribersRequestParams = {
  per: number;
  page: number;
  mailingListId: string;
  status: SubscriberStatus;
  q: string | null;
  order?: string[];
};
export type CreateSubscriberValues = Pick<Subscriber, 'firstName' | 'lastName' | 'email'>;
export type UpdateSubscriberValues = Pick<Subscriber, 'firstName' | 'lastName' | 'email'>;

export type SubscribersIndexActionFAILED = {
  type: SubscribersActionsConstants.INDEX_FAILED;
  payload: {
    error: any;
  };
};

export type Subscriber = {
  id: string;
  firstName: string;
  lastName: string;
  name: string;
  email: string;
  unsubscribedAt: Date | null;
};

export type SubscribersResponse = {
  data: Subscriber[];
};

export enum SubscribersActionsConstants {
  INDEX_BEGIN = 'SUBSCRIBERS/INDEX/BEGIN',
  INDEX_SUCCESS = 'SUBSCRIBERS/INDEX/SUCCESS',
  INDEX_FAILED = 'SUBSCRIBERS/INDEX/FAILED'
}

export type SubscribersActionType =
  | MailingListsUpdateAction
  | SubscribersIndexActionBEGIN
  | SubscribersIndexActionSUCCESS
  | SubscribersIndexActionFAILED;
