import React, { useState } from 'react';
import * as Yup from 'yup';
import { Form, Formik, FormikProps } from 'formik';
import {
  StoryChannel,
  StoryChannelStatus,
  StoryChannelType,
  StoryEventChannelValues,
  StoryFieldName,
  StoryFieldType
} from '../../../../store/Stories/types';
import { getChannelFileMimeTypes, getFieldValue } from '../Utils/storyUtils';
import { storiesOperations } from '../../../../store/Stories';
import Toast from '../../../Shared/Toast/Toast';
import { useTranslation } from 'react-i18next';
import { PageContentPaper } from '../../PageStyledComponents';
import { Box, Button, Checkbox, FormControlLabel, Grid } from '@material-ui/core';
import DateRangePickerComponent from './DateRangePickerComponent';
import { AdminTextInput } from '../../Admin/Shared/AdminFormInputs';
import ChannelAttachments from './Attachments/ChannelAttachments';
import SaveFormButton from './SaveFormButton';
import { hasPermission } from '../../../../utils/permissions';
import { useTypedSelector } from '../../../../utils';
import { Range } from 'react-date-range';
import moment from 'moment';

type CalendarEventProps = {
  storyId: string;
  calendarEvent: boolean;
  calendarEventData: StoryChannel;
  calendarEventPeriod: Range[];
  handleCalendarEventChange: (event: React.ChangeEvent<HTMLInputElement>) => Promise<void>;
  setCalendarEventPeriod: React.Dispatch<React.SetStateAction<Range[]>>;
  setChannelStatus: (
    channel: StoryChannel,
    status: StoryChannelStatus,
    prevStatus?: StoryChannelStatus
  ) => Promise<void>;
  defaultEventName: string;
  isOwnerOfStory: boolean;
};

const CalendarEvent: React.FC<CalendarEventProps> = ({
  storyId,
  calendarEvent,
  calendarEventData,
  calendarEventPeriod,
  handleCalendarEventChange,
  setCalendarEventPeriod,
  setChannelStatus,
  defaultEventName,
  isOwnerOfStory
}) => {
  const { t } = useTranslation();
  const role = useTypedSelector((state) => state.auth.role);
  const [showToast, setShowToast] = useState<boolean>(false);

  const updateCalendarEvent = async (values: StoryEventChannelValues, showToast: boolean) => {
    try {
      const data = Object.entries(values).map(([name, value]) => {
        if (parseInt(name) === StoryFieldName.START_DATE && calendarEventPeriod && calendarEventPeriod[0].startDate) {
          return {
            type: StoryFieldType.DATE,
            name: parseInt(name),
            value: moment(calendarEventPeriod[0].startDate).format('YYYY-MM-DD')
          };
        } else if (
          parseInt(name) === StoryFieldName.END_DATE &&
          calendarEventPeriod &&
          calendarEventPeriod[0].endDate
        ) {
          return {
            type: StoryFieldType.DATE,
            name: parseInt(name),
            value: moment(calendarEventPeriod[0].endDate).format('YYYY-MM-DD')
          };
        } else {
          return {
            type: StoryFieldType.STRING,
            name: parseInt(name),
            value: value
          };
        }
      });

      await storiesOperations.updateChannel(calendarEventData.id, data);
      if (showToast) {
        Toast.success(t('notifications.story.calendarEventSaved'));
      }
    } catch (e) {
      Toast.error(t('notifications.story.errorSave'));
    }
  };

  const renderCalendarEventDataForm = (props: FormikProps<StoryEventChannelValues>) => (
    <Form>
      <PageContentPaper>
        <FormControlLabel
          control={<Checkbox checked={calendarEvent} onChange={handleCalendarEventChange} name="calendarEvent" />}
          label={t('pages.story.calendarEvent.calendarEvent')}
          disabled={
            !isOwnerOfStory ||
            !hasPermission(role, ['storiesEdit']) ||
            calendarEventData.status === StoryChannelStatus.APPROVED
          }
        />
        <Grid container spacing={2}>
          <Grid item xs={4}>
            <DateRangePickerComponent
              range={calendarEventPeriod}
              setRange={(period) => {
                setCalendarEventPeriod(period);
                props.submitForm();
              }}
              disabled={
                !isOwnerOfStory ||
                !hasPermission(role, ['storiesEdit']) ||
                calendarEventData.status === StoryChannelStatus.APPROVED
              }
              label={t('pages.story.calendarEvent.eventDates')}
            />
          </Grid>
          <Grid item xs={8}>
            <Box>
              <AdminTextInput
                t={t}
                name={`${[StoryFieldName.NAME]}`}
                section="story"
                autoFocus
                disabled={
                  !isOwnerOfStory ||
                  !hasPermission(role, ['storiesEdit']) ||
                  calendarEventData.status === StoryChannelStatus.APPROVED
                }
              />
              <AdminTextInput
                t={t}
                multiline
                name={`${[StoryFieldName.DESCRIPTION]}`}
                section="story"
                autoFocus
                disabled={
                  !isOwnerOfStory ||
                  !hasPermission(role, ['storiesEdit']) ||
                  calendarEventData.status === StoryChannelStatus.APPROVED
                }
              />
              <AdminTextInput
                t={t}
                name={`${[StoryFieldName.URL]}`}
                section="story"
                autoFocus
                disabled={
                  !isOwnerOfStory ||
                  !hasPermission(role, ['storiesEdit']) ||
                  calendarEventData.status === StoryChannelStatus.APPROVED
                }
              />
            </Box>
          </Grid>
        </Grid>
        <ChannelAttachments
          storyId={storyId}
          initialAttachments={calendarEventData.attachments}
          mimeTypes={getChannelFileMimeTypes(StoryChannelType.CALENDAR_EVENT)}
          disabled={
            !isOwnerOfStory ||
            !hasPermission(role, ['storiesEdit']) ||
            calendarEventData.status === StoryChannelStatus.APPROVED
          }
          channelId={calendarEventData.id}
          channelType={StoryChannelType.CALENDAR_EVENT}
        />
        <Box display="flex" justifyContent="flex-end" marginTop={2}>
          <Button
            color="secondary"
            variant="contained"
            onClick={() => {
              setChannelStatus(
                calendarEventData,
                calendarEventData.status === StoryChannelStatus.APPROVED
                  ? StoryChannelStatus.IDLE
                  : StoryChannelStatus.APPROVED
              );
            }}
            size="medium"
            type="submit"
            disabled={!isOwnerOfStory || !hasPermission(role, ['storiesEdit'])}
          >
            {calendarEventData.status === StoryChannelStatus.APPROVED
              ? t('pages.story.calendarEvent.unpublish')
              : t('pages.story.calendarEvent.publish')}
          </Button>
          <SaveFormButton
            props={props}
            saveForm={async () => {
              setShowToast(true);
              await props.submitForm();
              setShowToast(false);
            }}
            disabled={
              !isOwnerOfStory ||
              !hasPermission(role, ['storiesEdit']) ||
              calendarEventData.status === StoryChannelStatus.APPROVED
            }
            style={{ display: 'none' }}
          />
        </Box>
      </PageContentPaper>
    </Form>
  );

  return (
    <Formik
      initialValues={{
        [StoryFieldName.NAME]: getFieldValue(calendarEventData, StoryFieldName.NAME).toString() || defaultEventName,
        [StoryFieldName.DESCRIPTION]: getFieldValue(calendarEventData, StoryFieldName.DESCRIPTION).toString(),
        [StoryFieldName.URL]: getFieldValue(calendarEventData, StoryFieldName.URL).toString(),
        [StoryFieldName.START_DATE]: new Date(getFieldValue(calendarEventData, StoryFieldName.START_DATE)),
        [StoryFieldName.END_DATE]: new Date(getFieldValue(calendarEventData, StoryFieldName.END_DATE))
      }}
      validationSchema={Yup.object().shape({
        [StoryFieldName.NAME]: Yup.string().required(),
        [StoryFieldName.DESCRIPTION]: Yup.string().required(),
        [StoryFieldName.URL]: Yup.string()
      })}
      onSubmit={(data) => updateCalendarEvent(data, showToast)}
    >
      {renderCalendarEventDataForm}
    </Formik>
  );
};

export default CalendarEvent;
