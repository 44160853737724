import React, { FunctionComponent } from 'react';
import OpenWithIcon from '@material-ui/icons/OpenWith';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import FileCopyOutlinedIcon from '@material-ui/icons/FileCopyOutlined';
import { IconButton } from '@material-ui/core';
import Toast from '../../../../../../Shared/Toast/Toast';
import { ItemTypes } from '../../../types';
import { useDrag } from 'react-dnd';
import { StoryChannel, StoryFieldName } from '../../../../../../../store/Stories/types';

type StoryProps = {
  story: StoryChannel;
};

const Story: FunctionComponent<StoryProps> = ({ story }) => {
  const body = story.fields.find((field) => field.name === StoryFieldName.BODY)?.value || '';
  const title = story.fields.find((field) => field.name === StoryFieldName.SUBJECT)?.value || '';

  const { t } = useTranslation();
  const [{ isDragging }, drag] = useDrag({
    item: { type: ItemTypes.STORY, story },
    collect: (monitor) => ({
      isDragging: monitor.isDragging()
    })
  });

  const opacity = isDragging ? 0.4 : 1;

  const stripHtml = (text: string): string => {
    const tmp = document.createElement('div');
    tmp.innerHTML = text;
    return tmp.textContent || tmp.innerText || '';
  };

  const copyToClipboard = async () => {
    try {
      await navigator.clipboard.writeText(stripHtml(body.toString()));
      Toast.success(t('notifications.copiedToClipboard.success'));
    } catch (e) {
      Toast.error(t('notifications.copiedToClipboard.error'));
    }
  };

  return (
    <Wrapper title={stripHtml(body.toString())} ref={drag} style={{ opacity }}>
      <Actions>
        <IconButton style={{ cursor: 'move' }}>
          <OpenWithIcon />
        </IconButton>
        <IconButton onClick={copyToClipboard}>
          <FileCopyOutlinedIcon />
        </IconButton>
      </Actions>
      <Content>
        {story.attachments.length > 0 && (
          <StoryImageWrapper>
            <StoryImage src={story.attachments[0].url} />
          </StoryImageWrapper>
        )}
        <StoryTextWrapper>
          {title && <StoryHeadline dangerouslySetInnerHTML={{ __html: title.toString() }} />}
          {body && <StoryBody dangerouslySetInnerHTML={{ __html: body.toString() }} />}
        </StoryTextWrapper>
      </Content>
    </Wrapper>
  );
};

const StoryImageWrapper = styled.div``;
const StoryTextWrapper = styled.div`
  padding: 0 10px;
`;

const StoryImage = styled.div<{ src: string }>`
  width: 50px;
  height: 50px;
  background-image: url('${({ src }) => src}');
  background-repeat: no-repeat;
  background-size: cover;
  background-position: 50% 50%;
`;
const StoryHeadline = styled.div`
  font-weight: bold;
  font-size: 0.9em;

  a {
    pointer-events: none;
  }
`;
const StoryBody = styled.div`
  padding-top: 6px;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 4;
  -webkit-box-orient: vertical;
  font-size: 0.8em;

  a {
    pointer-events: none;
  }
`;

const Wrapper = styled.div`
  cursor: move;
  padding: 1em 0.5em 0 0.5em;
  display: flex;
  align-items: center;
  width: 100%;
  word-break: break-word;
`;

const Actions = styled.div``;

const Content = styled.div`
  display: flex;
  align-items: center;
`;

export default Story;
