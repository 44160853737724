import { AnyProjectAdmin } from '../../../../../store/AdminProjects/types';
import { isEmailProject, isPrintProject } from '../../../../../utils';
import { PrintProjectStatus } from '../../../../../store/PrintProjects/types';
import { printProjectsOperations } from '../../../../../store/PrintProjects';
import Toast from '../../../../Shared/Toast/Toast';
import { push } from 'connected-react-router';
import linksConstants from '../../../../../config/app/linksConstants';
import { useState } from 'react';
import useOpenHandler from '../../../../../hooks/useOpenHandler';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { dashboardOperations } from '../../../../../store/Dashboard';

export type ProjectActionType =
  | 'remove'
  | 'edit'
  | 'statistics'
  | 'clone'
  | 'publishNewsletter'
  | 'publishPrint'
  | 'rePublishPrint'
  | 'cancelPublish'
  | 'preview'
  | 'generateFlipBook'
  | 'toggleTestFlag'
  | 'resetCheckIn'
  | 'share'
  | 'generateLink';

export const useHandleProjectAction = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const [activeProject, setActiveProject] = useState<AnyProjectAdmin | null>(null);
  const [shareProjectWindowOpen, onShareProjectWindowOpen, onShareProjectWindowClose] = useOpenHandler();
  const [removeProjectWindowOpen, onRemoveProjectWindowOpen, onRemoveProjectWindowClose] = useOpenHandler();
  const [publishProjectWindowOpen, onPublishProjectWindowOpen, onPublishProjectWindowClose] = useOpenHandler();
  const [publishPrintWindowOpen, onPublishPrintWindowOpen, onPublishPrintWindowClose] = useOpenHandler();
  const [rePublishPrintWindowOpen, onRePublishPrintWindowOpen, onRePublishPrintWindowClose] = useOpenHandler();
  const [previewPrintWindowOpen, onPreviewPrintWindowOpen, onPreviewPrintWindowClose] = useOpenHandler();
  const [previewEmailWindowOpen, onPreviewEmailWindowOpen, onPreviewEmailWindowClose] = useOpenHandler();
  const [generateLinkWindowOpen, onGenerateLinkWindowOpen, onGenerateLinkWindowClose] = useOpenHandler();
  const [resetCheckInWindowOpen, onResetCheckInWindowOpen, onResetCheckInWindowClose] = useOpenHandler();
  const [
    cancelPublishProjectWindowOpen,
    onCancelPublishProjectWindowOpen,
    onCancelPublishProjectWindowClose
  ] = useOpenHandler();

  const handleAction = async (project: AnyProjectAdmin, type: ProjectActionType) => {
    setActiveProject(project);

    switch (type) {
      case 'generateLink':
        onGenerateLinkWindowOpen();
        break;

      case 'share':
        onShareProjectWindowOpen();
        break;

      case 'statistics':
        dispatch(push(linksConstants.PROJECTS.EMAIL.STATISTICS(project.id)));
        break;

      case 'remove':
        onRemoveProjectWindowOpen();
        break;

      case 'publishNewsletter':
        onPublishProjectWindowOpen();
        break;

      case 'publishPrint':
        onPublishPrintWindowOpen();
        break;

      case 'rePublishPrint':
        onRePublishPrintWindowOpen();
        break;

      case 'clone':
        if (isPrintProject(project)) {
          dispatch(push(linksConstants.DASHBOARD.CLONE_PROJECT(project.id)));
        }
        break;

      case 'generateFlipBook':
        dispatch(push(linksConstants.PLAYGROUND.FLIP_BOOK(project.id) + `?pid=${project.id}`));
        break;

      case 'cancelPublish':
        onCancelPublishProjectWindowOpen();
        break;

      case 'resetCheckIn':
        onResetCheckInWindowOpen();
        break;

      case 'toggleTestFlag':
        await dispatch(dashboardOperations.toggleTestFlag(project));
        break;

      case 'preview': {
        if (isEmailProject(project)) {
          onPreviewEmailWindowOpen();
        }
        if (isPrintProject(project)) {
          if (project.status === PrintProjectStatus.DELIVERED) {
            const data = await printProjectsOperations.getProjectPreviewUrl(project);
            window.open(data.url);
          } else {
            try {
              onPreviewPrintWindowOpen();
            } catch (e) {
              Toast.error(t('notifications.common.error'));
            }
          }
        }
        break;
      }

      case 'edit':
        if (isPrintProject(project)) {
          dispatch(push(linksConstants.PROJECTS.BULLETIN.EDIT(project.id)));
        }
        if (isEmailProject(project)) {
          dispatch(push(linksConstants.PROJECTS.EMAIL.EDIT(project.id)));
        }
        break;
    }
  };

  return {
    handleAction,
    activeProject,

    generateLinkWindowOpen,
    shareProjectWindowOpen,
    removeProjectWindowOpen,
    publishProjectWindowOpen,
    publishPrintWindowOpen,
    rePublishPrintWindowOpen,
    previewPrintWindowOpen,
    previewEmailWindowOpen,
    resetCheckInWindowOpen,
    cancelPublishProjectWindowOpen,

    onGenerateLinkWindowClose,
    onShareProjectWindowClose,
    onRemoveProjectWindowClose,
    onPublishProjectWindowClose,
    onPublishPrintWindowClose,
    onRePublishPrintWindowClose,
    onPreviewPrintWindowClose,
    onPreviewEmailWindowClose,
    onResetCheckInWindowClose,
    onCancelPublishProjectWindowClose
  };
};
