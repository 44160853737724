import React, { FunctionComponent, useState } from 'react';
import { useTranslation } from 'react-i18next';
import DeleteIcon from '@material-ui/icons/Delete';
import AddIcon from '@material-ui/icons/Add';
import SyncIcon from '@material-ui/icons/Sync';
import EditIcon from '@material-ui/icons/Edit';
import CloudUpload from '@material-ui/icons/CloudUpload';
import { subscribersOperations } from '../../../store/Subscribers';
import { formatNoteDate, formatTableTimestamp, useTypedSelector } from '../../../utils';
import { usePaginationWatch } from '../../Shared/DataTable/Pagination/usePaginationWatch';
import { useTableSearchWatch } from '../../Shared/DataTable/useTableSearchWatch';
import { Subscriber, SubscriberStatus } from '../../../store/Subscribers/types';
import useOpenHandler from '../../../hooks/useOpenHandler';
import { PageContainer, PageContent } from '../PageStyledComponents';
import PageHeader from '../../Shared/Layout/PageHeader';
import DataTable from '../../Shared/DataTable/DataTable';
import TableActionButtonsContainer from '../../Shared/DataTable/TableActionButton/TableActionButtonsContainer';
import TableActionButton from '../../Shared/DataTable/TableActionButton/TableActionButton';
import { TableSearchStoreModule } from '../../../store/TableSearch/types';
import { PaginationStoreModule } from '../../../store/Pagination/types';
import CreateSubscriberWindow from './Windows/Subscribers/CreateSubscriberWindow';
import UpdateSubscriberWindow from './Windows/Subscribers/UpdateSubscriberWindow';
import DeleteSubscriberWindow from './Windows/Subscribers/DeleteSubscriberWindow';
import ImportFromCsvWindow from './Windows/Subscribers/ImportFromCsvWindow';
import { RouteComponentProps } from 'react-router';
import { hasPermission, isSystemOwner } from '../../../utils/permissions';
import { push } from 'connected-react-router';
import linksConstants from '../../../config/app/linksConstants';
import KeyboardArrowLeftIcon from '@material-ui/icons/KeyboardArrowLeft';
import { useDispatch } from 'react-redux';
import { AppBar, Tab, Tabs, withStyles } from '@material-ui/core';
import Toast from '../../Shared/Toast/Toast';
import { mailingListsOperations } from '../../../store/MailingLists';
import { ColumnDefinition } from '../../Shared/DataTable/types';

type MailingListsEditPageProps = RouteComponentProps<{ mailingListId: string; mailingListType: string }> & {};
const subscriberStatus = [
  SubscriberStatus.SUBSCRIBED,
  SubscriberStatus.BOUNCED,
  SubscriberStatus.UNSUBSCRIBED,
  SubscriberStatus.REMOVED
];

enum ViewContext {
  ORGANIZATION,
  ADMINISTRATION
}

const MailingListsEditPage: FunctionComponent<MailingListsEditPageProps> = ({ match }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const authState = useTypedSelector((state) => state.auth);
  const [tabValue, setTabValue] = useState(0);
  const [syncing, setSyncing] = useState(false);
  const subscribers = useTypedSelector((state) => state.subscribers.data);
  const mailingList = useTypedSelector((state) => state.subscribers.mailingList);
  const isFailed = useTypedSelector((state) => state.subscribers.index.isFailed);
  const isLoading = useTypedSelector((state) => state.subscribers.index.isLoading);
  const pagination = useTypedSelector((state) => state.subscribers.pagination);
  const tableSearch = useTypedSelector((state) => state.subscribers.tableSearch);

  const mailingListId = match.params.mailingListId;
  const mailingListType = match.params.mailingListType;

  const context: ViewContext = match.path.includes('mailing_lists_dashboard')
    ? ViewContext.ADMINISTRATION
    : ViewContext.ORGANIZATION;

  usePaginationWatch(pagination, [subscribersOperations.index.bind(null, mailingListId, subscriberStatus[tabValue])]);
  useTableSearchWatch<Subscriber>(tableSearch, [
    subscribersOperations.index.bind(null, mailingListId, subscriberStatus[tabValue])
  ]);

  const [activeSubscriber, setActiveSubscriber] = useState<Subscriber | null>(null);
  const [createSubscriberWindowOpen, onCreateSubscriberWindowOpen, onCreateSubscriberWindowClose] = useOpenHandler();
  const [updateSubscriberWindowOpen, onUpdateSubscriberWindowOpen, onUpdateSubscriberWindowClose] = useOpenHandler();
  const [deleteSubscriberWindowOpen, onDeleteSubscriberWindowOpen, onDeleteSubscriberWindowClose] = useOpenHandler();
  const [importCsvWindowOpen, onImportCsvWindowOpen, onImportCsvWindowClose] = useOpenHandler();

  const getActionButtons = () => {
    const buttons = [];

    if (isSystemOwner(authState)) {
      buttons.push({
        label: t('pages.subscribers.reSynchronize'),
        variant: 'outlined' as const,
        disabled: syncing,
        icon: <SyncIcon />,
        onClick: async () => {
          try {
            setSyncing(true);
            await mailingListsOperations.reSynchronize(mailingListId);
            await dispatch(subscribersOperations.index(mailingListId, subscriberStatus[tabValue]));
            Toast.success(t('notifications.subscribers.successOperation'));
            setSyncing(false);
          } catch (e) {
            Toast.error(t('notifications.subscribers.errorOperation'));
            setSyncing(false);
          }
        }
      });
    }

    if (hasPermission(authState.role, ['mailingListsEdit'])) {
      buttons.push(
        {
          label: t('pages.subscribers.importFromCsv'),
          variant: 'outlined' as const,
          icon: <CloudUpload />,
          onClick: onImportCsvWindowOpen
        },
        {
          label: t('pages.subscribers.create'),
          icon: <AddIcon />,
          onClick: onCreateSubscriberWindowOpen
        }
      );
    }

    return buttons;
  };

  const backButton = {
    onClick: () =>
      context === ViewContext.ORGANIZATION
        ? dispatch(push(linksConstants.MAILING_LISTS.INDEX))
        : dispatch(push(linksConstants.ADMINISTRATION.MAILING_LISTS.INDEX)),
    label: t('common.back'),
    icon: <KeyboardArrowLeftIcon />
  };

  const fetchMailingListTabData = async (tabValue: number) => {
    try {
      await dispatch(subscribersOperations.index(mailingListId, subscriberStatus[tabValue]));
    } catch (e) {
      Toast.error(t('notifications.subscribers.errorOperation'));
    }
  };

  const handleChange = async (event: React.ChangeEvent<{}>, newValue: number) => {
    setTabValue(newValue);
    fetchMailingListTabData(newValue);
  };

  if (!mailingList || mailingListId !== mailingList.id) {
    return null;
  }

  const columnsDefinitions: ColumnDefinition<Subscriber, 'actions'>[] = [
    {
      name: 'email',
      sortable: true,
      render: (subscriber) => <>{subscriber.email}</>
    },
    {
      name: 'firstName',
      sortable: true,
      render: (subscriber) => <>{subscriber.firstName || '-'}</>
    },
    {
      name: 'lastName',
      sortable: true,
      render: (subscriber) => <>{subscriber.lastName || '-'}</>
    }
  ];

  if (tabValue === 2) {
    columnsDefinitions.push({
      name: 'unsubscribedAt',
      sortable: true,
      render: (subscriber) => <>{subscriber.unsubscribedAt ? formatNoteDate(subscriber.unsubscribedAt) : '-'}</>
    });
  }

  if (tabValue === 0) {
    columnsDefinitions.push({
      name: 'actions',
      render: (subscriber) => (
        <TableActionButtonsContainer>
          <TableActionButton
            icon={<EditIcon />}
            tooltip={t('pages.subscribers.table.buttons.edit')}
            ButtonProps={{
              disabled: !hasPermission(authState.role, ['mailingListsEdit']),
              onClick: () => {
                setActiveSubscriber(subscriber);
                onUpdateSubscriberWindowOpen();
              }
            }}
          />
          <TableActionButton
            icon={<DeleteIcon />}
            tooltip={t('pages.subscribers.table.buttons.delete')}
            ButtonProps={{
              disabled: !hasPermission(authState.role, ['mailingListsEdit']),
              onClick: () => {
                setActiveSubscriber(subscriber);
                onDeleteSubscriberWindowOpen();
              }
            }}
          />
        </TableActionButtonsContainer>
      )
    });
  }

  return (
    <PageContainer>
      <PageHeader
        title={`${mailingList.name} (${mailingListType.toUpperCase()})`}
        leftActionButtons={[backButton]}
        rightActionButtons={getActionButtons()}
      />
      <PageContent>
        <AppBar position="static" color="default">
          <StyledTabs value={tabValue} onChange={handleChange} textColor="primary">
            {subscriberStatus.map((status, index) => (
              <StyledTab
                key={index}
                label={
                  <>
                    {t(`common.subscriberStatus.${index + 1}`)}
                    <br />
                    <span style={{ fontSize: 'smaller', fontWeight: 'bold' }}>
                      {index === 0 && mailingList?.activeMemberCount}
                      {index === 1 && mailingList?.bouncedMemberCount}
                      {index === 2 && mailingList?.unsubscribedMemberCount}
                      {index === 3 && mailingList?.removedMemberCount}
                    </span>
                  </>
                }
              />
            ))}
          </StyledTabs>
        </AppBar>
        <DataTable<Subscriber, 'actions'>
          enableSearch={true}
          columnDefinitions={columnsDefinitions}
          tPath={'pages.subscribers.table'}
          data={subscribers}
          tableSearchProps={{
            tableSearch,
            module: TableSearchStoreModule.SUBSCRIBERS
          }}
          paginationProps={{
            pagination,
            module: PaginationStoreModule.SUBSCRIBERS
          }}
          isFailed={isFailed}
          isLoading={isLoading}
        />
      </PageContent>

      <CreateSubscriberWindow
        open={createSubscriberWindowOpen}
        mailingListId={mailingListId}
        onCloseClick={(isFormSubmitted) => {
          onCreateSubscriberWindowClose();
          if (isFormSubmitted) {
            setTabValue(0);
            fetchMailingListTabData(0);
          }
        }}
        fullScreenOnMobile
      />
      <ImportFromCsvWindow
        open={importCsvWindowOpen}
        mailingListId={mailingListId}
        onCloseClick={(isFormSubmitted) => {
          onImportCsvWindowClose();
          if (isFormSubmitted) {
            setTabValue(0);
            fetchMailingListTabData(0);
          }
        }}
        fullScreenOnMobile
      />
      <UpdateSubscriberWindow
        subscriber={activeSubscriber}
        mailingListId={mailingListId}
        open={updateSubscriberWindowOpen}
        onCloseClick={(isFormSubmitted) => {
          onUpdateSubscriberWindowClose();
          if (isFormSubmitted) {
            fetchMailingListTabData(tabValue);
          }
        }}
        fullScreenOnMobile
      />
      <DeleteSubscriberWindow
        subscriber={activeSubscriber}
        mailingListId={mailingListId}
        open={deleteSubscriberWindowOpen}
        onCloseClick={(isFormSubmitted) => {
          onDeleteSubscriberWindowClose();
          if (isFormSubmitted) {
            fetchMailingListTabData(tabValue);
          }
        }}
        fullScreenOnMobile
      />
    </PageContainer>
  );
};

const StyledTabs = withStyles({
  root: {
    backgroundColor: '#fff'
  }
})(Tabs);

const StyledTab = withStyles({
  root: {
    textTransform: 'none'
  }
})(Tab);

export default MailingListsEditPage;
