import { createOrderArray, httpRequest } from '../../utils';
import { adminUsersActions, adminUsersRequests } from './index';
import { Dispatch } from 'redux';
import { ApplicationState } from '../rootReducer';
import {
  AdminCreateUserValues,
  AdminUpdateUserValues,
  AdminUser,
  AdminUsersIndexResponse,
  AdminUsersShowResponse,
  AdminVerifyUserPayload,
  UserType
} from './types';
import { authActions } from '../Auth';
import { routerActions } from 'connected-react-router';
import { getRedirectByRole } from '../../utils/getRedirectByRole';
import { SetPasswordFormValues, ValidateTokenRequestResponse } from '../Auth/types';
import { AdminKeyword, AdminKeywordGroup } from '../AdminKeywords/types';
import { PasswordPolicy } from '../SystemSettings/types';

export const index = () => async (dispatch: Dispatch, getState: () => ApplicationState) => {
  const { adminUsers } = getState();
  try {
    dispatch(adminUsersActions.usersIndexBegin());

    const response = await httpRequest.runRequest<AdminUsersIndexResponse>(
      adminUsersRequests.index({
        requestParams: {
          page: adminUsers.pagination.page,
          per: adminUsers.pagination.per,
          q: adminUsers.pagination.q,
          order: createOrderArray(adminUsers.tableSearch.order)
        }
      })
    );

    dispatch(adminUsersActions.usersIndexSuccess(response));
  } catch (e) {
    dispatch(adminUsersActions.usersIndexFailed(e));
  }
};

export const create = (values: AdminCreateUserValues) => async () => {
  await httpRequest.runRequest<void>(
    adminUsersRequests.create({
      requestPayload: values
    })
  );
};

export const update = (id: string, values: AdminUpdateUserValues) => async () => {
  await httpRequest.runRequest<void>(
    adminUsersRequests.update({
      requestPayload: values,
      requestParams: { id }
    })
  );
};

export const show = (id: string) => async (dispatch: Dispatch) => {
  try {
    dispatch(adminUsersActions.usersShowBegin());

    const response = await httpRequest.runRequest<AdminUsersShowResponse>(
      adminUsersRequests.show({
        requestParams: {
          id
        }
      })
    );

    dispatch(adminUsersActions.usersShowSuccess(response));
  } catch (e) {
    dispatch(adminUsersActions.usersShowFailed(e));
  }
};

export const blockUser = (userId: string) => async () => {
  await httpRequest.runRequest<void>(
    adminUsersRequests.blockUser({
      requestParams: { id: userId }
    })
  );
};

export const unblockUser = (userId: string) => async () => {
  await httpRequest.runRequest<void>(
    adminUsersRequests.unblockUser({
      requestParams: { id: userId }
    })
  );
};

export const deleteUser = (userId: string) => async () => {
  await httpRequest.runRequest<void>(
    adminUsersRequests.deleteUser({
      requestParams: { id: userId }
    })
  );
};

export const loginAsUser = (userId: string) => async (dispatch: Dispatch) => {
  const switchedAccount = await httpRequest.runRequest<ValidateTokenRequestResponse>(
    adminUsersRequests.loginAsUser({
      requestParams: { id: userId }
    })
  );
  dispatch(authActions.validateToken(switchedAccount));
  dispatch(routerActions.push(getRedirectByRole(switchedAccount.role)));
};

export const verify = (userId: string, setPasswordFormValues: AdminVerifyUserPayload) => async () => {
  await httpRequest.runRequest<void>(
    adminUsersRequests.verify({
      requestParams: { id: userId },
      requestPayload: {
        password: setPasswordFormValues.password,
        confirmPassword: setPasswordFormValues.confirmPassword
      }
    })
  );
};

export const changePassword = (userId: string, setPasswordFormValues: SetPasswordFormValues) => async () => {
  await httpRequest.runRequest<void>(
    adminUsersRequests.changePassword({
      requestParams: { id: userId },
      requestPayload: {
        password: setPasswordFormValues.password,
        confirmPassword: setPasswordFormValues.confirmPassword
      }
    })
  );
};

export const addKeywordToUser = (user: AdminUser, keyword: AdminKeyword) => async (dispatch: Dispatch) => {
  const updatedUser = await httpRequest.runRequest<AdminUser>(
    adminUsersRequests.addKeywordToUser({
      requestParams: { userId: user.id },
      requestPayload: { keywordId: keyword.id }
    })
  );
  dispatch(adminUsersActions.keywordAdded(updatedUser));
};

export const addKeywordGroupToUser = (user: AdminUser, group: AdminKeywordGroup) => async (dispatch: Dispatch) => {
  const updatedUser = await httpRequest.runRequest<AdminUser>(
    adminUsersRequests.addKeywordGroupToUser({
      requestParams: { userId: user.id },
      requestPayload: { keywordGroupId: group.id }
    })
  );
  dispatch(adminUsersActions.keywordGroupAdded(updatedUser));
};

export const removeKeywordFromUser = (user: AdminUser, keyword: AdminKeyword) => async (dispatch: Dispatch) => {
  const updatedUser = await httpRequest.runRequest<AdminUser>(
    adminUsersRequests.removeKeywordFromUser({
      requestParams: { userId: user.id },
      requestPayload: { keywordId: keyword.id }
    })
  );
  dispatch(adminUsersActions.keywordRemoved(updatedUser));
};

export const removeKeywordGroupFromUser = (user: AdminUser, group: AdminKeywordGroup) => async (dispatch: Dispatch) => {
  const updatedUser = await httpRequest.runRequest<AdminUser>(
    adminUsersRequests.removeKeywordGroupFromUser({
      requestParams: { userId: user.id },
      requestPayload: { keywordGroupId: group.id }
    })
  );
  dispatch(adminUsersActions.keywordGroupRemoved(updatedUser));
};

export const getUserTypes = async () => {
  return httpRequest.runRequest<{ data: UserType[] }>(adminUsersRequests.getUserTypes());
};

export const getUserPasswordPolicy = async (userId: string) => {
  return httpRequest.runRequest<PasswordPolicy>(
    adminUsersRequests.getUserPasswordPolicy({
      requestParams: { userId }
    })
  );
};
