import React, { FunctionComponent, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Box, FormControl, Radio, RadioGroup } from '@material-ui/core';
import ModalConfirmationWindow from '../../../../../Shared/Window/ModalConfirmationWindow';
import { ReactComponent as ImageTopIcon } from '../../../../../../assets/icons/image_top.svg';
import { ReactComponent as ImageMiddleIcon } from '../../../../../../assets/icons/image_middle.svg';
import { ReactComponent as ImageBottomIcon } from '../../../../../../assets/icons/image_bottom.svg';
import styled from 'styled-components';

type ArticleVerticalSettingsProps = {
  open: boolean;
  onCloseClick: () => void;
  onFormSubmit: (imagePosition: 'top' | 'middle' | 'bottom') => void;
  imagePosition: 'top' | 'middle' | 'bottom';
};

const ArticleVerticalSettingsWindow: FunctionComponent<ArticleVerticalSettingsProps> = ({
  open,
  onCloseClick,
  onFormSubmit,
  imagePosition
}) => {
  const { t } = useTranslation();

  const [imagePositionValue, setImagePositionValue] = useState<'top' | 'middle' | 'bottom'>(imagePosition);
  const handleImagePositionChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setImagePositionValue((event.target as HTMLInputElement).value as 'top' | 'middle' | 'bottom');
  };

  return (
    <ModalConfirmationWindow
      open={open}
      header={t('pages.emailEditor.articleStructure')}
      onCloseClick={onCloseClick}
      onSubmit={() => onFormSubmit(imagePositionValue)}
    >
      <Box display="flex" alignItems="center" justifyContent="center">
        <FormControl component="fieldset">
          <RadioGroup
            aria-label="headline-position"
            name="headline-position"
            value={imagePositionValue}
            onChange={handleImagePositionChange}
            row
          >
            <RadioContainer>
              <Radio value="top" />
              <ImageMiddleIcon onClick={() => setImagePositionValue('top')} />
            </RadioContainer>
            <RadioContainer>
              <Radio value="middle" />
              <ImageTopIcon onClick={() => setImagePositionValue('middle')} />
            </RadioContainer>
            <RadioContainer>
              <Radio value="bottom" />
              <ImageBottomIcon onClick={() => setImagePositionValue('bottom')} />
            </RadioContainer>
          </RadioGroup>
        </FormControl>
      </Box>
    </ModalConfirmationWindow>
  );
};

const RadioContainer = styled.div`
  width: 100px;
  text-align: center;
  margin: 0 10px;
  cursor: pointer;
`;

export default ArticleVerticalSettingsWindow;
