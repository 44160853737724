import React, { FunctionComponent } from 'react';
import { useTranslation } from 'react-i18next';
import { Grid, Typography } from '@material-ui/core';
import SocialAccounts from './Integrations/SocialAccounts';
import WordpressIntegrations from './Integrations/WordpressIntegrations';

type OrganizationSocialAccountsProps = {};

const OrganizationIntegrations: FunctionComponent<OrganizationSocialAccountsProps> = () => {
  const { t } = useTranslation();

  return (
    <>
      <Grid container justify="space-between" alignItems="center">
        <Typography variant="h6">{t('pages.myOrganization.integrations.title')}</Typography>
      </Grid>
      <hr style={{ marginBottom: '1.0rem', opacity: 0.1 }} />
      <SocialAccounts />
      <WordpressIntegrations />
    </>
  );
};

export default OrganizationIntegrations;
