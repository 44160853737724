import React, { useEffect, useState } from 'react';
import { StoryChannelAttachment } from '../../../../../store/Stories/types';
import { useDrop } from 'react-dnd';
import { Attachment } from './Attachment';
import update from 'immutability-helper';

type AttachmentsListProps = {
  attachments: StoryChannelAttachment[];
  onReorderAttachments: (attachments: StoryChannelAttachment[]) => Promise<void>;
  onRemoveAttachment: (attachment: StoryChannelAttachment) => void;
  onChangeAttachmentCaption: (attachment: StoryChannelAttachment, caption: string) => Promise<void>;
  disabled?: boolean;
};

const AttachmentsList: React.FC<AttachmentsListProps> = ({
  attachments,
  onReorderAttachments,
  onRemoveAttachment,
  onChangeAttachmentCaption,
  disabled = false
}) => {
  const [orderedAttachments, setOrderedAttachments] = useState<StoryChannelAttachment[]>(attachments);
  const [{ didDrop }, drop] = useDrop({
    accept: 'attachment',
    collect: (monitor) => ({
      didDrop: monitor.didDrop()
    })
  });

  const moveAttachment = (id: string, atIndex: number) => {
    const { attachment, index } = findAttachment(id);
    setOrderedAttachments(
      update(attachments, {
        $splice: [
          [index, 1],
          [atIndex, 0, attachment]
        ]
      })
    );
  };

  const findAttachment = (id: string) => {
    const attachment = attachments.filter((a) => `${a.id}` === id)[0];
    return {
      attachment,
      index: attachments.indexOf(attachment)
    };
  };

  useEffect(() => {
    if (didDrop && attachments.length > 1) {
      onReorderAttachments(orderedAttachments.map((attachment, i) => ({ ...attachment, order: i })));
    }
  }, [didDrop, attachments]);

  if (attachments.length === 0) {
    return <></>;
  }

  return (
    <div ref={drop}>
      {attachments.map((attachment, i) => {
        return (
          <Attachment
            key={`attachment-${attachment.id}`}
            id={`${attachment.id}`}
            attachment={attachment}
            moveAttachment={moveAttachment}
            findAttachment={findAttachment}
            onRemoveAttachment={onRemoveAttachment}
            onChangeAttachmentCaption={onChangeAttachmentCaption}
            attachmentsLength={attachments.length}
            disabled={disabled}
          />
        );
      })}
    </div>
  );
};

export default AttachmentsList;
