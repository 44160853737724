import React, { useCallback, useRef, useState } from 'react';
import RowWithColumns from './Mechanics/RowWithColumns';
import { useDrop } from 'react-dnd';
import { AddRowDropzone } from './Mechanics/Dropzone/AddRowDropzone';
import { AddColumnDropzone } from './Mechanics/Dropzone/AddColumnDropzone';
import { PageContentTextEditor } from '../../../PageStyledComponents';
import EditorSidebar from './Sidebar/EditorSidebar';
import { ColumnInlineSelector } from './Mechanics/ColumnInlineSelector';
import Image from '../Partials/Components/Image';
import Text from '../Partials/Components/Text';
import SocialLinks from '../Partials/Components/SocialLinks';
import ArticleHorizontalComponent from '../Partials/Components/ArticleHorizontal';
import ArticleVerticalComponent from '../Partials/Components/ArticleVertical';
import SeparatorComponent from '../Partials/Components/Separator';
import AdSpaceComponent from '../Partials/Components/AdSpace';
import CtaButtonComponent from '../Partials/Components/CtaButton';
import PlaceholderComponent from '../Partials/Components/Placeholder';
import styled from 'styled-components';
import { calculateColumnWidth, extractColorsFromSwatches } from '../Utils';
import {
  ComponentToUpdate,
  onAddColumnsToRow,
  onAddComponent,
  onAddRow,
  onComponentsUpdate,
  onDeleteComponent,
  onDeleteRow,
  onDuplicateRow,
  onMoveRow,
  RowToUpdateAlongWithComponents
} from '../Utils/modelTransformator';
import { initialComponentFactory } from './Components/Factories/initialComponentFactory';
import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';
import DeleteForeverIcon from '@material-ui/icons/DeleteForever';
import {
  AdPlacementStatus,
  AdsConstraint,
  EmailProjectRow,
  ImageData,
  ImageDropObjectData,
  ItemTypes,
  NewsletterSidebarItemType,
  ProjectModelColumn,
  ProjectModelComponent,
  ProjectModelV2
} from '../types';
import DeleteComponentWindow from './Windows/DeleteComponentWindow';
import DeleteRowWindow from './Windows/DeleteRowWindow';
import useOpenHandler from '../../../../../hooks/useOpenHandler';
import { useTranslation } from 'react-i18next';
import {
  ColorSwatchType,
  MyOrganizationSwatchColor,
  MyOrganizationSwatchGroup
} from '../../../../../store/MyOrganization/types';
import { EmailProject } from '../../../../../store/EmailProjects/types';
import { dragAndDropEventHandler } from '../Utils/Uploaders';
import { DesignerStory } from '../../../../../store/PrintProjects/types';
import { AdminEmailTemplate } from '../../../../../store/AdminEmailTemplates/types';
import { hasPermission } from '../../../../../utils/permissions';
import { useTypedSelector } from '../../../../../utils';
import { ComponentActionIcons, StyledEditIcon } from '../../../../Shared/StyledComponents';
import { Story } from '../../../../../store/Stories/types';
import DownloadAttachmentComponent from './Components/DownloadAttachment';
import UpcomingEventsComponent from './Components/UpcomingEvents';
import MonthCalendarComponent from './Components/MonthCalendar';
import { DamSystemName } from '../../../../../store/SystemSettings/types';

type EditorProps = {
  model: ProjectModelV2;
  project: EmailProject | AdminEmailTemplate;
  projectId: string;
  setModel: (model: ProjectModelV2) => void;
  swatchesGroups?: MyOrganizationSwatchGroup[];
  colors?: MyOrganizationSwatchColor[];
  designerStories?: DesignerStory[];
  storyList?: Story[];
  selectedColorSwatches: { [key: string]: MyOrganizationSwatchColor };
  applyColor?: (swatchGroup: MyOrganizationSwatchGroup, color: MyOrganizationSwatchColor | null) => void;
  availableAdSpaces: AdPlacementStatus[];
  inProjectEditor: boolean;
  sourceProjectId: string | null;
  damStatus?: { damActive: boolean; system: DamSystemName | null };
};

export const Editor: React.FC<EditorProps> = ({
  model,
  setModel,
  project,
  projectId,
  colors,
  selectedColorSwatches,
  swatchesGroups,
  applyColor,
  designerStories,
  storyList,
  availableAdSpaces,
  inProjectEditor,
  sourceProjectId,
  damStatus
}) => {
  {
    const { t } = useTranslation();
    const role = useTypedSelector((state) => state.auth.role);
    const organization = useTypedSelector((state) => state.auth.organization);
    const sidebarWidth = 400;
    const editorEndRef = useRef<HTMLDivElement>(null);

    const [sidebarExpanded, setSidebarExpanded] = useState(true);
    const [sidebarActiveTab, setSidebarActiveTab] = useState<NewsletterSidebarItemType>(
      hasPermission(role, ['projectsEmailAdvancedEditor']) ? 'content' : 'stories'
    );
    const [activeComponent, setActiveComponent] = useState<ProjectModelComponent | null>(null);
    const [activeComponentCoords, setActiveComponentCoords] = useState<{
      componentIterator: number;
      rowIndex: number;
      columnIndex: number;
    } | null>(null);
    const [activeRowIndex, setActiveRowIndex] = useState<number | null>(null);
    const [rowHasContent, setRowHasContent] = useState<boolean>(false);
    const [deleteComponentWindowOpen, onDeleteComponentWindowOpen, onDeleteComponentWindowClose] = useOpenHandler();
    const [deleteRowWindowOpen, onDeleteRowWindowOpen, onDeleteRowWindowClose] = useOpenHandler();

    const moveRow = (dragIndex: number, hoverIndex: number) => setModel(onMoveRow(model, dragIndex, hoverIndex));
    const addRow = (cols: ProjectModelColumn[]) => setModel(onAddRow(model, cols));
    const duplicateRow = (row: EmailProjectRow) => setModel(onDuplicateRow(model, row));
    const addColumnsToRow = (id: number, cols: ProjectModelColumn[]) => setModel(onAddColumnsToRow(model, id, cols));
    const handleDropRow = (item: { cols: ProjectModelColumn[] }) => {
      const { cols } = item;
      addRow(cols);
    };
    const handleDropContent = (
      dropData: { componentType: ProjectModelComponent['type']; data?: AdsConstraint },
      columnRef: HTMLDivElement | null,
      rowIndex: number,
      colNum: number
    ) => {
      const componentData = initialComponentFactory(dropData.componentType, model, columnRef, organization);
      if (componentData.type === 'ad-space' && dropData.data) {
        componentData.params.width = dropData.data.width;
        componentData.params.height = dropData.data.height;
      }
      if (componentData) {
        setModel(onAddComponent(model, componentData, rowIndex, colNum));
      }
    };

    const updateComponentsAndRow = useCallback(
      (components: ComponentToUpdate[], rowData?: RowToUpdateAlongWithComponents, cols?: ProjectModelColumn[]) => {
        const updatedModel = rowData && cols ? onAddColumnsToRow(model, rowData.index, cols) : model;

        setModel(onComponentsUpdate(updatedModel, components, rowData));
      },
      [model, setModel]
    );

    const handleDeleteComponent = (
      event: React.MouseEvent<HTMLDivElement, MouseEvent>,
      component: ProjectModelComponent,
      componentIterator: number,
      rowIndex: number,
      columnIndex: number
    ) => {
      event.stopPropagation();
      setActiveComponent(component);
      setActiveComponentCoords({
        componentIterator,
        rowIndex,
        columnIndex
      });
      onDeleteComponentWindowOpen();
    };

    const onDeleteComponentConfirmed = () => {
      onDeleteComponentWindowClose();
      if (activeComponentCoords) {
        setModel(
          onDeleteComponent(
            model,
            activeComponentCoords.componentIterator,
            activeComponentCoords.rowIndex,
            activeComponentCoords.columnIndex
          )
        );
      }
    };

    const handleDeleteRow = (rowIndex: number, hasContent: boolean) => {
      setActiveRowIndex(rowIndex);
      setRowHasContent(hasContent);
      onDeleteRowWindowOpen();
    };

    const onDeleteRowConfirmed = () => {
      onDeleteRowWindowClose();
      if (typeof activeRowIndex === 'number') {
        setModel(onDeleteRow(model, activeRowIndex));
      }
    };

    const [{ canDrop, isOver }, drop] = useDrop({
      accept: [ItemTypes.STRUCTURE, ItemTypes.CONTENT],
      drop: () => ({ name: 'AddRow' }),
      collect: (monitor) => ({
        isOver: monitor.isOver(),
        canDrop: monitor.canDrop()
      })
    });

    const onImageDrop = async (e: ImageDropObjectData, imageData: ImageData) => {
      return await dragAndDropEventHandler(project, imageData, e, t);
    };

    const renderComponent = (
      component: ProjectModelComponent,
      componentIterator: number,
      rowIndex: number,
      columnIndex: number
    ) => {
      const key = `comp-${rowIndex}-${columnIndex}-${componentIterator}`;

      switch (component.type) {
        case 'logo-horizontal':
        case 'logo-vertical':
        case 'print-project-cover':
        case 'image':
          return (
            <Image
              key={key}
              data={component}
              onImageDrop={onImageDrop}
              onChange={(data: ProjectModelComponent) =>
                updateComponentsAndRow([{ component: data, componentIterator, rowIndex, columnIndex }])
              }
            />
          );
        case 'text':
          return (
            <Text
              key={key}
              data={component}
              onChange={(data: ProjectModelComponent) =>
                updateComponentsAndRow([{ component: data, componentIterator, rowIndex, columnIndex }])
              }
            />
          );
        case 'social-links':
          return <SocialLinks key={key} data={component} />;
        case 'article-horizontal':
          return (
            <ArticleHorizontalComponent
              key={key}
              data={component}
              onChange={(data: ProjectModelComponent) =>
                updateComponentsAndRow([{ component: data, componentIterator, rowIndex, columnIndex }])
              }
              onImageDrop={onImageDrop}
              sourceProjectId={sourceProjectId}
              organization={organization}
              selectedColorSwatches={selectedColorSwatches}
            />
          );
        case 'article-vertical':
          return (
            <ArticleVerticalComponent
              key={key}
              data={component}
              onChange={(data: ProjectModelComponent) =>
                updateComponentsAndRow([{ component: data, componentIterator, rowIndex, columnIndex }])
              }
              onImageDrop={onImageDrop}
              sourceProjectId={sourceProjectId}
              organization={organization}
              selectedColorSwatches={selectedColorSwatches}
            />
          );
        case 'separator':
          return <SeparatorComponent key={key} data={component} selectedColorSwatches={selectedColorSwatches} />;
        case 'ad-space':
          return <AdSpaceComponent key={key} data={component} />;
        case 'cta-button':
          return (
            <CtaButtonComponent
              key={key}
              data={component}
              onChange={(data: ProjectModelComponent) =>
                updateComponentsAndRow([{ component: data, componentIterator, rowIndex, columnIndex }])
              }
              selectedColorSwatches={selectedColorSwatches}
              inProjectEditor={inProjectEditor}
            />
          );
        case 'download-attachment':
          return (
            <DownloadAttachmentComponent
              key={key}
              projectId={projectId}
              data={component}
              onChange={(data: ProjectModelComponent) =>
                updateComponentsAndRow([{ component: data, componentIterator, rowIndex, columnIndex }])
              }
              selectedColorSwatches={selectedColorSwatches}
              inProjectEditor={inProjectEditor}
            />
          );
        case 'upcoming-events':
          return (
            <UpcomingEventsComponent
              key={key}
              data={component}
              onChange={(data: ProjectModelComponent) =>
                updateComponentsAndRow([{ component: data, componentIterator, rowIndex, columnIndex }])
              }
              inProjectEditor={inProjectEditor}
            />
          );
        case 'placeholder':
          return <PlaceholderComponent key={key} data={component} />;
        case 'month-calendar':
          return (
            <MonthCalendarComponent
              key={key}
              data={component}
              onChange={(data: ProjectModelComponent) =>
                updateComponentsAndRow([{ component: data, componentIterator, rowIndex, columnIndex }])
              }
              inProjectEditor={inProjectEditor}
            />
          );
      }

      return null;
    };

    const canAddColumnElement = true; // rows.find((row) => row.cols.length === 0) ? false : true;

    const generalBorderSettings = model.settings
      ? {
          border: `${model.settings.border.borderWidth}px ${model.settings.border.borderStyle} ${model.settings.border.borderColor}`,
          borderRadius: `${model.settings.border.borderRadius}px`,
          width: Number(model.settings.border.borderWidth) * 2 + 700 + 'px'
        }
      : {
          width: '700px'
        };

    return (
      <>
        <PageContentTextEditor
          className="page-content-editor"
          style={{ width: `calc(100% - ${sidebarExpanded ? sidebarWidth : 0}px)` }}
        >
          <MainWrapper className="main-article" style={generalBorderSettings}>
            {model.sizes.large.length === 0 && (
              <AddRowDropzone
                accept={[ItemTypes.STRUCTURE]}
                onDrop={(item) => handleDropRow(item)}
                onClick={() => addRow([])}
              />
            )}
            {model.sizes.large.map((row, rowIndex) => (
              <RowWithColumns
                key={`row-${rowIndex}`}
                index={rowIndex}
                id={row.id}
                moveRow={moveRow}
                duplicateRow={(row) => {
                  duplicateRow(row);
                  // scroll to the bottom of the editor when duplicating the row
                  if (editorEndRef.current) {
                    editorEndRef.current.scrollIntoView({ behavior: 'smooth' });
                  }
                }}
                deleteRow={(index) =>
                  handleDeleteRow(index, row.cols.filter((col) => col.components.length).length > 0)
                }
                row={row}
                updateComponentsAndRow={updateComponentsAndRow}
                style={{
                  ...(row.role === 'header' || row.role === 'footer'
                    ? extractColorsFromSwatches(selectedColorSwatches, [
                        ColorSwatchType.headerFooterBackground,
                        ColorSwatchType.headerFooterText
                      ])
                    : {})
                }}
                inProjectEditor={inProjectEditor}
              >
                {row.cols.length > 0 ? (
                  <RowWrapper>
                    {row.cols.map((col, colIndex) => (
                      <AddColumnDropzone
                        className="email-column"
                        accept={[ItemTypes.CONTENT]}
                        onDrop={(item, columnRef) => handleDropContent(item, columnRef, rowIndex, colIndex)}
                        key={`row-${row.id}-col-${colIndex}`}
                        style={{
                          width: `${calculateColumnWidth(col.size)}%`,
                          ...(col.style || {}),
                          ...(col.role === 'header' || col.role === 'footer'
                            ? extractColorsFromSwatches(selectedColorSwatches, [
                                ColorSwatchType.headerFooterBackground,
                                ColorSwatchType.headerFooterText
                              ])
                            : {})
                        }}
                      >
                        <>
                          {!col.components.length && hasPermission(role, ['projectsEmailAdvancedEditor']) && (
                            <AddColumnElement>
                              <AddCircleOutlineIcon fontSize="large" color="secondary" />
                            </AddColumnElement>
                          )}
                          {col.components.map((component, componentIterator) => (
                            <ComponentWrapper key={`delete-${rowIndex}-${colIndex}-${componentIterator}`}>
                              <ComponentActionIcons>
                                {hasPermission(role, ['projectsEmailAdvancedEditor']) && (
                                  <StyledDeleteIcon
                                    onClick={(e) =>
                                      handleDeleteComponent(e, component, componentIterator, rowIndex, colIndex)
                                    }
                                  >
                                    <DeleteForeverIcon />
                                  </StyledDeleteIcon>
                                )}
                              </ComponentActionIcons>
                              {renderComponent(component, componentIterator, rowIndex, colIndex)}
                            </ComponentWrapper>
                          ))}
                        </>
                      </AddColumnDropzone>
                    ))}
                  </RowWrapper>
                ) : hasPermission(role, ['projectsEmailAdvancedEditor']) ? (
                  <ColumnInlineSelector row={row} index={rowIndex} onColumnPlaceholderClick={addColumnsToRow} />
                ) : (
                  <></>
                )}
              </RowWithColumns>
            ))}
            <div ref={editorEndRef} />
            {model.sizes.large.length > 0 &&
              canAddColumnElement &&
              hasPermission(role, ['projectsEmailAdvancedEditor']) && (
                <AddRowDropzone
                  accept={[ItemTypes.STRUCTURE]}
                  onDrop={(item) => handleDropRow(item)}
                  onClick={() => addRow([])}
                />
              )}
            {/*<input*/}
            {/*  type="text"*/}
            {/*  id="model-code"*/}
            {/*  onClick={() => {*/}
            {/*    const copyText: HTMLInputElement | null = document.getElementById('model-code') as HTMLInputElement;*/}
            {/*    if (copyText) {*/}
            {/*      copyText.select();*/}
            {/*      copyText.setSelectionRange(0, 99999);*/}
            {/*      navigator.clipboard.writeText(copyText.value);*/}
            {/*    }*/}
            {/*  }}*/}
            {/*  value={JSON.stringify(model)}*/}
            {/*/>*/}
          </MainWrapper>
        </PageContentTextEditor>
        <EditorSidebar
          setActiveTab={(tab: NewsletterSidebarItemType) => setSidebarActiveTab(tab)}
          active={sidebarActiveTab}
          toggleExpand={() => setSidebarExpanded(!sidebarExpanded)}
          expanded={sidebarExpanded}
          colors={colors}
          model={model}
          setModel={setModel}
          swatchesGroups={swatchesGroups}
          applyColor={applyColor}
          selectedColorSwatches={selectedColorSwatches}
          project={project}
          designerStories={designerStories}
          showBrandfolder={true}
          storyList={storyList}
          showUserUploadedImages={inProjectEditor}
          showEmailAttachments={inProjectEditor}
          availableAdSpaces={availableAdSpaces}
          inProjectEditor={inProjectEditor}
          variables={!inProjectEditor}
          damStatus={damStatus}
        />
        {deleteComponentWindowOpen && (
          <DeleteComponentWindow
            component={activeComponent}
            open={deleteComponentWindowOpen}
            onCloseClick={onDeleteComponentWindowClose}
            fullScreenOnMobile={true}
            onFormSubmit={onDeleteComponentConfirmed}
          />
        )}
        {deleteRowWindowOpen && (
          <DeleteRowWindow
            open={deleteRowWindowOpen}
            onCloseClick={onDeleteRowWindowClose}
            fullScreenOnMobile={true}
            onFormSubmit={onDeleteRowConfirmed}
            rowHasContent={rowHasContent}
          />
        )}
      </>
    );
  }
};

const MainWrapper = styled.div`
  margin: 0 auto;
  width: 700px;
  background: #fff;

  * {
    -khtml-user-select: none;
    -o-user-select: none;
    -moz-user-select: none;
    -webkit-user-select: none;
    user-select: none;
  }

  a {
    text-decoration: inherit;
  }

  hr {
    margin: 0;
    line-height: 0;
  }
`;

const AddColumnElement = styled.div`
  width: calc(100% - 20px);
  border: 2px dashed #d5d5d5;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 20px;
  margin: 10px;
`;

const StyledDeleteIcon = styled.div`
  cursor: pointer;
  width: 30px;
  height: 30px;
  background-color: #ef5350;
  color: #fff;
  border-radius: 20px;
  display: none;
  align-items: center;
  justify-content: center;
  margin-right: -5px;
`;

const ComponentWrapper = styled.div`
  position: relative;

  &:hover {
    outline: 2px dashed #9391c4;
    outline-offset: -1px;
    //z-index: 2;
  }

  &:hover ${StyledDeleteIcon} {
    display: flex;
  }

  &:hover ${StyledEditIcon} {
    display: flex;
  }
`;

const RowWrapper = styled.div`
  display: flex;
  width: 100%;
  position: relative;
`;
