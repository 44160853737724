import React, { FunctionComponent } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import ModalConfirmationWindow from '../../../Shared/Window/ModalConfirmationWindow';
import { Row } from '../../../Shared/StyledComponents';
import Toast from '../../../Shared/Toast/Toast';
import { push } from 'connected-react-router';
import linksConstants from '../../../../config/app/linksConstants';

type SaveBeforeExitWindowProps = {
  open: boolean;
  onCloseClick: () => void;
  fullScreenOnMobile: boolean;
};

const SaveBeforeExitWindow: FunctionComponent<SaveBeforeExitWindowProps> = ({ onCloseClick, open }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const onSubmit = async () => {
    try {
      dispatch(push(linksConstants.DASHBOARD.INDEX));
    } catch (e) {
      Toast.error(t('notifications.adminKeywords.errorOperation'));
    }
  };

  return (
    <ModalConfirmationWindow
      open={open}
      header={t('windows.saveBeforeExit.header')}
      okButtonText={t('windows.saveBeforeExit.continue')}
      onCloseClick={onCloseClick}
      onSubmit={onSubmit}
    >
      <Row>{t('windows.saveBeforeExit.areYouSure')}</Row>
    </ModalConfirmationWindow>
  );
};

export default SaveBeforeExitWindow;
