import React, { FunctionComponent, useEffect, useState } from 'react';
import * as Yup from 'yup';
import ModalFormWindow from '../../../../Shared/Window/ModalFormWindow';
import { useTranslation } from 'react-i18next';
import { AdminOrganization, AdminUpdateOrganizationValues } from '../../../../../store/AdminOrganizations/types';
import { adminOrganizationsOperations } from '../../../../../store/AdminOrganizations';
import { useDispatch } from 'react-redux';
import { AdminCheckboxInput, AdminSelectInput, AdminTextInput } from '../../Shared/AdminFormInputs';
import Toast from '../../../../Shared/Toast/Toast';
import app from '../../../../../config/app/app';
import { FormikProps } from 'formik';
import { AdminPasswordPolicy } from '../../../../../store/AdminPasswordPolicies/types';
import { FormLabel, Grid, MenuItem, TextField } from '@material-ui/core';
import { timestampToTime, timeToTimestamp, useTypedSelector } from '../../../../../utils';
import { isSystemOwner } from '../../../../../utils/permissions';
import { AdminEmailTemplate } from '../../../../../store/AdminEmailTemplates/types';
import {
  AUTOMATED_EMAIL_DATE_SCHEDULE,
  automatedEmailDateSchedule,
  DamSystem,
  DamSystemName
} from '../../../../../store/SystemSettings/types';
import { MailingList } from '../../../../../store/MailingLists/types';
import Loader from '../../../../Shared/Loading/Loader';
import InfoBox from '../../../../Shared/InfoBox/InfoBox';
import { Row } from '../../../../Shared/StyledComponents';
import { adminMailingListsOperations } from '../../../../../store/AdminMailingLists';
import { emailProjectsOperations } from '../../../../../store/EmailProjects';
import { SenderData } from '../../../../../store/EmailProjects/types';
import { push } from 'connected-react-router';
import linksConstants from '../../../../../config/app/linksConstants';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { systemSettingsOperations } from '../../../../../store/SystemSettings';

type AdminUpdateOrganizationWindowProps = {
  open: boolean;
  organization: AdminOrganization | null;
  onCloseClick: () => void;
  fullScreenOnMobile: boolean;
  passwordPolicies: AdminPasswordPolicy[];
  emailTemplates: AdminEmailTemplate[];
};

const AdminUpdateOrganizationWindow: FunctionComponent<AdminUpdateOrganizationWindowProps> = ({
  organization,
  onCloseClick,
  open,
  passwordPolicies,
  emailTemplates
}) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const authState = useTypedSelector((state) => state.auth);
  const [mailingLists, setMailingLists] = useState<MailingList[]>([]);
  const [damSystemsLoading, setDamSystemsLoading] = useState<boolean>(true);
  const [damSystems, setDamSystems] = useState<DamSystem[]>([]);
  const [mailingListsLoading, setMailingListsLoading] = useState<boolean>(true);
  const [sendersLoading, setSendersLoading] = useState<boolean>(true);
  const [senders, setSenders] = useState<SenderData[]>([]);
  const [organizationsLoading, setOrganizationsLoading] = useState<boolean>(true);
  const [organizations, setOrganizations] = useState<AdminOrganization[]>([]);

  useEffect(() => {
    const getMailingLists = async () => {
      if (organization) {
        setMailingListsLoading(true);
        try {
          const mailingLists = await adminMailingListsOperations.getOrganizationMailingLists(organization.id);
          setMailingLists(mailingLists.data);
          setMailingListsLoading(false);
        } catch (e) {
          Toast.error(t('notifications.mailingLists.fetchingListsError'));
        }
      }
    };
    const getSenders = async () => {
      setSendersLoading(true);
      try {
        const sendersResponse = await emailProjectsOperations.getSenders();
        setSenders(sendersResponse.data);
      } catch (e) {
        Toast.error(t('notifications.getSenders.error'));
      } finally {
        setSendersLoading(false);
      }
    };
    const getDamSystems = async () => {
      setDamSystemsLoading(true);
      try {
        const dams = await systemSettingsOperations.getDamSystems();
        setDamSystems(dams.data);
      } catch (e) {
        Toast.error(t('notifications.dams.getDamsError'));
      } finally {
        setDamSystemsLoading(false);
      }
    };
    const getOrganizations = async () => {
      setOrganizationsLoading(true);
      try {
        const organizationsResponse = await adminOrganizationsOperations.getAll();
        setOrganizations(organizationsResponse.data);
      } catch (e) {
        Toast.error(t('notifications.getBusinessUserData.error'));
      } finally {
        setOrganizationsLoading(false);
      }
    };
    if (open) {
      getMailingLists();
      if (isSystemOwner(authState)) {
        getSenders();
        getDamSystems();
        getOrganizations();
      }
    }
  }, [open]);

  if (!organization) return null;

  const onSubmit = async (values: AdminUpdateOrganizationValues) => {
    try {
      await dispatch(
        adminOrganizationsOperations.update(organization.id, {
          name: values.name,
          accountNumber: values.accountNumber,
          injectLogoToPrintProjects: values.injectLogoToPrintProjects,
          stripProtocolFromPrintInjectedUrls: values.stripProtocolFromPrintInjectedUrls,
          socialPublishing: values.socialPublishing,
          bulletin: values.bulletin,
          email: values.email,
          flyer: values.flyer,
          newsletter: values.newsletter,
          directory: values.directory,
          colorSwatchesLimit: values.colorSwatchesLimit || null,
          readMoreLink: values.readMoreLink || null,
          passwordPolicyId: values.passwordPolicyId,
          externalDataCenter: values.externalDataCenter === -1 ? null : values.externalDataCenter,
          emailWizardEnabled: values.emailWizardEnabled,
          automatedEmailEnabled: values.automatedEmailEnabled,
          defaultAutomatedEmailTemplateId: values.defaultAutomatedEmailTemplateId,
          automatedEmailDateSchedule: values.automatedEmailDateSchedule,
          automatedEmailTime: timeToTimestamp(values.automatedEmailTime),
          automatedEmailSubject: values.automatedEmailSubject || null,
          defaultAutomatedEmailMailingListId: values.defaultAutomatedEmailMailingListId,
          senderEmail: values.senderEmail === '-1' ? null : values.senderEmail,
          parentId: values.parentId === '-1' ? null : values.parentId,
          dam: values.dam && values.dam.id && values.dam.id !== '-1' ? values.dam : null
        })
      );
      Toast.success(t('notifications.adminOrganizations.successOperation'));

      await dispatch(adminOrganizationsOperations.index());

      onCloseClick();
    } catch (e) {
      Toast.error(t('notifications.adminOrganizations.errorOperation'));
    }
  };

  const getChosenDamSystem = (props: FormikProps<any>): DamSystemName | null => {
    if (props.values.dam && props.values.dam.id) {
      const dam = damSystems.find((d) => d.id === props.values.dam.id);
      if (dam) {
        return dam.name;
      }
    }

    return null;
  };

  const formInputs = (props: FormikProps<any>) => (
    <>
      <AdminTextInput t={t} name="name" section="adminOrganizations" />
      <AdminTextInput t={t} name="accountNumber" section="adminOrganizations" />
      {isSystemOwner(authState) && (
        <>
          <AdminCheckboxInput t={t} name="injectLogoToPrintProjects" section="adminOrganizations" />
          <AdminCheckboxInput t={t} name="stripProtocolFromPrintInjectedUrls" section="adminOrganizations" />
          <AdminCheckboxInput t={t} name="socialPublishing" section="adminOrganizations" />
          <AdminTextInput t={t} type="number" name="colorSwatchesLimit" section="adminOrganizations" />
          <AdminTextInput t={t} name="readMoreLink" section="adminOrganizations" />
          <div style={{ margin: '1rem 0 0.3rem' }}>
            <FormLabel component="legend">{t('pages.adminOrganizations.availableProducts')}</FormLabel>
            <AdminCheckboxInput t={t} name="bulletin" section="adminOrganizations" />
            <AdminCheckboxInput t={t} name="email" section="adminOrganizations" />
            <AdminCheckboxInput t={t} name="flyer" section="adminOrganizations" />
            <AdminCheckboxInput t={t} name="newsletter" section="adminOrganizations" />
            <AdminCheckboxInput t={t} name="directory" section="adminOrganizations" />
          </div>
          <FormLabel component="legend">{t('pages.adminOrganizations.parentOrg')}</FormLabel>
          {organizationsLoading && <Loader />}
          {!organizationsLoading && (
            <Autocomplete
              onChange={(event, value) => {
                if (value?.id) {
                  props.setFieldValue('parentId', value?.id);
                }
              }}
              value={{
                id: props.values.parentId,
                label: organizations.find((org) => org.id === props.values.parentId)?.name || ''
              }}
              size="small"
              getOptionSelected={(option, value) => {
                return option.id === value.id;
              }}
              renderInput={(params) => <TextField {...params} autoComplete="off" variant="outlined" />}
              options={[{ id: '-1', label: 'Unset' }, ...organizations.map((org) => ({ label: org.name, id: org.id }))]}
              getOptionLabel={(option) => option.label}
            />
          )}
        </>
      )}
      <AdminSelectInput t={t} name="passwordPolicyId" section="adminOrganizations">
        {passwordPolicies.map((passwordPolicy) => (
          <MenuItem key={passwordPolicy.id} value={passwordPolicy.id}>
            {passwordPolicy.isGlobal ? t('pages.systemSettings.passwordPolicyTitle') : passwordPolicy.name}
          </MenuItem>
        ))}
      </AdminSelectInput>
      <AdminSelectInput t={t} name="externalDataCenter" section="commonInputs">
        {Object.keys({ notSet: -1, ...authState.dataCenters }).map((dataCenter, i) => {
          if (i === 0) {
            return (
              <MenuItem key={-1} value={-1}>
                {t(`common.notSet`)}
              </MenuItem>
            );
          }
          return (
            <MenuItem key={i} value={i.toString()}>
              {t(`common.dataCenters.${dataCenter}`)}
            </MenuItem>
          );
        })}
      </AdminSelectInput>
      {isSystemOwner(authState) && (
        <>
          <AdminCheckboxInput t={t} name="emailWizardEnabled" section="adminOrganizations" />
          <AdminSelectInput t={t} name="defaultAutomatedEmailTemplateId" section="adminOrganizations">
            {[null, ...emailTemplates].map((template) => {
              if (template === null) {
                return (
                  <MenuItem key="template-not-set" value={undefined}>
                    {t(`common.notSet`)}
                  </MenuItem>
                );
              }
              return (
                <MenuItem key={template.id} value={template.id}>
                  {template.name}
                </MenuItem>
              );
            })}
          </AdminSelectInput>
          <AdminCheckboxInput t={t} name="automatedEmailEnabled" section="adminOrganizations" />
          {props.values.automatedEmailEnabled && (
            <>
              <Row>
                {mailingListsLoading && <Loader />}
                {!mailingListsLoading && mailingLists.length > 0 && (
                  <AdminSelectInput t={t} name="defaultAutomatedEmailMailingListId" section="adminOrganizations">
                    {mailingLists.map((mailingList) => (
                      <MenuItem key={mailingList.id} value={mailingList.id}>
                        {mailingList.name} ({mailingList.activeMemberCount} {t('common.activeMembers')})
                      </MenuItem>
                    ))}
                  </AdminSelectInput>
                )}
                {!mailingListsLoading && mailingLists.length <= 0 && (
                  <InfoBox type="warning">{t('pages.adminOrganizations.noMailingList')}</InfoBox>
                )}
              </Row>

              <Grid container spacing={2}>
                <Grid xs={6} item>
                  <AdminSelectInput t={t} name="automatedEmailDateSchedule" section="adminOrganizations">
                    {[null, ...automatedEmailDateSchedule].map((name) => {
                      if (name === null) {
                        return (
                          <MenuItem key="schedule-not-set" value={undefined}>
                            {t(`common.notSet`)}
                          </MenuItem>
                        );
                      }
                      return (
                        <MenuItem key={name} value={name}>
                          {t(`common.automatedEmailDateSchedule.${name}`)}
                        </MenuItem>
                      );
                    })}
                  </AdminSelectInput>
                </Grid>
                <Grid xs={6} item>
                  {props.values.automatedEmailDateSchedule !== AUTOMATED_EMAIL_DATE_SCHEDULE.IMMEDIATELY && (
                    <AdminTextInput t={t} name="automatedEmailTime" section="adminOrganizations" type="time" />
                  )}
                </Grid>
                <em style={{ textAlign: 'center', fontStyle: 'italic', width: '100%' }}>
                  {t('pages.adminOrganizations.automatedEmailSettingsHint')}
                </em>
              </Grid>

              <Row>
                <AdminTextInput t={t} name="automatedEmailSubject" section="adminOrganizations" />
                <em style={{ textAlign: 'center', fontStyle: 'italic', width: '100%' }}>
                  {t('pages.adminOrganizations.automatedEmailSubjectHint')}
                </em>
              </Row>
            </>
          )}
          {sendersLoading && <Loader />}
          {!sendersLoading && senders.length > 0 && (
            <AdminSelectInput t={t} name="senderEmail" section="adminOrganizations">
              {[null, ...senders].map((sender) => {
                if (sender === null) {
                  return (
                    <MenuItem key="sender-not-set" value="-1">
                      {t(`pages.adminOrganizations.senderEmailDefault`)}
                    </MenuItem>
                  );
                }
                return (
                  <MenuItem key={sender.ID} value={sender.Email}>
                    {sender.Name} ({sender.Email})
                  </MenuItem>
                );
              })}
            </AdminSelectInput>
          )}
          {damSystemsLoading && <Loader />}
          {!damSystemsLoading && damSystems.length > 0 && (
            <AdminSelectInput t={t} name="dam.id" section="adminOrganizations">
              {[null, ...damSystems].map((dam) => {
                if (dam === null) {
                  return (
                    <MenuItem key="dam-not-set" value="-1">
                      {t(`common.notSet`)}
                    </MenuItem>
                  );
                }
                return (
                  <MenuItem key={dam.id} value={dam.id}>
                    <div>
                      <p>{t(`common.damSystem.${dam.name}`)}</p>
                      <p style={{ fontSize: '0.8rem', fontStyle: 'italic' }}>{dam.url}</p>
                    </div>
                  </MenuItem>
                );
              })}
            </AdminSelectInput>
          )}
          {!damSystemsLoading && getChosenDamSystem(props) === DamSystemName.ARK && (
            <AdminTextInput t={t} name="dam.apiKey" section="adminOrganizations" />
          )}
        </>
      )}
    </>
  );

  return (
    <ModalFormWindow
      open={open}
      header={t('pages.adminOrganizations.windows.updateOrganization')}
      okButtonText={t('common.update')}
      onCloseClick={onCloseClick}
      onSubmit={onSubmit}
      formInputs={formInputs}
      initialValues={{
        name: organization.name,
        accountNumber: organization.accountNumber,
        passwordPolicyId: organization.passwordPolicyId,
        injectLogoToPrintProjects: organization.injectLogoToPrintProjects,
        colorSwatchesLimit: organization.colorSwatchesLimit,
        readMoreLink: organization.readMoreLink,
        stripProtocolFromPrintInjectedUrls: organization.stripProtocolFromPrintInjectedUrls,
        socialPublishing: organization.socialPublishing,
        bulletin: organization.bulletin,
        email: organization.email,
        flyer: organization.flyer,
        newsletter: organization.newsletter,
        directory: organization.directory,
        externalDataCenter: organization.externalDataCenter,
        defaultAutomatedEmailTemplateId: organization.defaultAutomatedEmailTemplateId,
        emailWizardEnabled: organization.emailWizardEnabled,
        automatedEmailEnabled: organization.automatedEmailEnabled,
        automatedEmailDateSchedule: organization.automatedEmailDateSchedule,
        automatedEmailTime: timestampToTime(organization.automatedEmailTime),
        automatedEmailSubject: organization.automatedEmailSubject,
        defaultAutomatedEmailMailingListId: organization.defaultAutomatedEmailMailingListId,
        senderEmail: organization.senderEmail === null ? '-1' : organization.senderEmail,
        parentId: organization.parentId === null ? '-1' : organization.parentId,
        dam:
          organization.damIntegrations && organization.damIntegrations[0]
            ? {
                id: organization.damIntegrations[0].systemId,
                apiKey: organization.damIntegrations[0].apiKey
              }
            : null
      }}
      validationSchema={Yup.object().shape({
        name: Yup.string().max(app.maxInputLength).required(),
        accountNumber: Yup.string().max(app.maxInputLength).required(),
        injectLogoToPrintProjects: Yup.boolean(),
        stripProtocolFromPrintInjectedUrls: Yup.boolean(),
        socialPublishing: Yup.boolean(),
        bulletin: Yup.boolean(),
        email: Yup.boolean(),
        flyer: Yup.boolean(),
        newsletter: Yup.boolean(),
        directory: Yup.boolean(),
        emailWizardEnabled: Yup.boolean(),
        automatedEmailEnabled: Yup.boolean(),
        defaultAutomatedEmailTemplateId: Yup.string().nullable(),
        colorSwatchesLimit: Yup.number().min(0).max(200).nullable(),
        readMoreLink: Yup.string().nullable(),
        externalDataCenter: Yup.number().nullable(),
        passwordPolicyId: Yup.string().required(),
        automatedEmailTime: Yup.string().nullable(),
        automatedEmailDateSchedule: Yup.string().nullable(),
        automatedEmailSubject: Yup.string().nullable(),
        defaultAutomatedEmailMailingListId: Yup.string().nullable(),
        senderEmail: Yup.string().nullable(),
        parentId: Yup.string().nullable(),
        dam: Yup.object()
          .shape({
            id: Yup.string(),
            apiKey: Yup.string()
          })
          .nullable()
      })}
    />
  );
};

export default AdminUpdateOrganizationWindow;
