import React from 'react';
import { useDrag } from 'react-dnd';
import { ItemTypes } from '../../../types';
import { UserImage } from '../../../../../../../store/EmailProjects/types';
import { Tooltip } from '@material-ui/core';
import NotPinnedIcon from '@material-ui/icons/Room';
import PinnedIcon from '@material-ui/icons/PinDrop';
import DeleteIcon from '@material-ui/icons/Delete';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';

interface BrandfolderAssetProps {
  image: UserImage;
  toggleSticky: (image: UserImage) => void;
  removeImage: (image: UserImage) => void;
}

export const UserUploadedAsset: React.FC<BrandfolderAssetProps> = ({ image, removeImage, toggleSticky }) => {
  const { t } = useTranslation();
  const [{ isDragging }, drag] = useDrag({
    item: { type: ItemTypes.USER_UPLOADED_ASSET, id: image.id || '' },
    collect: (monitor) => ({
      isDragging: monitor.isDragging()
    })
  });

  const opacity = isDragging ? 0.4 : 1;

  return (
    <ImageItem ref={drag} style={{ opacity }} src={image.thumbnailUrl} title={image.filename}>
      <Tooltip
        title={(image.sticky ? t('imagesPalette.clickToNotBeSticky') : t('imagesPalette.clickToMakeSticky')) as string}
      >
        <StickyIcon pinned={image.sticky} onClick={() => toggleSticky(image)}>
          {!image.sticky && <NotPinnedIcon />}
          {image.sticky && <PinnedIcon />}
        </StickyIcon>
      </Tooltip>

      <Tooltip title={t('imagesPalette.removeImage') as string}>
        <RemoveIcon onClick={() => removeImage(image)}>
          <DeleteIcon />
        </RemoveIcon>
      </Tooltip>
    </ImageItem>
  );
};

const ImageItem = styled.div<{ src: string }>`
  width: 100px;
  height: 80px;
  cursor: pointer;
  background-image: url('${({ src }) => src}');
  background-size: contain;
  border: 1px solid #f3f3f3;
  background-repeat: no-repeat;
  background-position: 50% 50%;
  border-radius: 4px;
  position: relative;
`;

const StickyIcon = styled.div<{ pinned: boolean }>`
  background: #f8fafc;
  border-radius: 10px;
  position: absolute;
  width: 34px;
  bottom: -7px;
  left: calc(50% - 17px);
  text-align: center;
  font-size: 75%;
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  padding: 2px;

  svg {
    opacity: ${({ pinned }) => (pinned ? 1 : 0.2)};
    padding: 3px;
  }
`;

const RemoveIcon = styled.div`
  background: #f8fafc;
  border-radius: 0 0 0 10px;
  position: absolute;
  width: 20px;
  top: 0;
  right: 0;
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  padding: 1px;
  opacity: 0.9;

  svg {
    width: 15px;
    color: #ef6666;
  }
`;
