import React, { FunctionComponent } from 'react';
import * as Yup from 'yup';
import ModalFormWindow from '../../../../Shared/Window/ModalFormWindow';
import { useTranslation } from 'react-i18next';
import { AdminPrintTemplate, AdminUpdatePrintTemplateValues } from '../../../../../store/AdminPrintTemplates/types';
import { adminPrintTemplatesOperations } from '../../../../../store/AdminPrintTemplates';
import { useDispatch } from 'react-redux';
import { AdminSelectInput, AdminTextInput } from '../../Shared/AdminFormInputs';
import { MenuItem } from '@material-ui/core';
import Toast from '../../../../Shared/Toast/Toast';
import app from '../../../../../config/app/app';
import { printTemplateTypes } from '../../../../../utils/projectTypeUtils';
import { FormikProps } from 'formik';

type AdminUpdatePrintTemplateWindowProps = {
  open: boolean;
  template: AdminPrintTemplate | null;
  onCloseClick: () => void;
  fullScreenOnMobile: boolean;
};

const AdminUpdatePrintTemplateWindow: FunctionComponent<AdminUpdatePrintTemplateWindowProps> = ({
  template,
  onCloseClick,
  open
}) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  if (!template) return null;

  const onSubmit = async (values: AdminUpdatePrintTemplateValues) => {
    try {
      await dispatch(
        adminPrintTemplatesOperations.update(template.id, {
          name: values.name,
          description: values.description,
          type: values.type
        })
      );
      Toast.success(t('notifications.adminPrintTemplates.successOperation'));

      await dispatch(adminPrintTemplatesOperations.index());

      onCloseClick();
    } catch (e) {
      Toast.error(t('notifications.adminPrintTemplates.errorOperation'));
    }
  };

  const formInputs = (props: FormikProps<any>) => (
    <>
      <AdminTextInput t={t} name="name" section="adminPrintTemplates" />
      <AdminTextInput t={t} name="description" section="adminPrintTemplates" />
      <AdminSelectInput t={t} name="type" section="adminPrintTemplates">
        {printTemplateTypes.map((name) => (
          <MenuItem key={name} value={name}>
            {t(`common.projectType.${name}`)}
          </MenuItem>
        ))}
      </AdminSelectInput>
    </>
  );

  return (
    <ModalFormWindow
      open={open}
      header={t('pages.adminPrintTemplates.windows.updatePrintTemplate')}
      okButtonText={t('common.update')}
      onCloseClick={onCloseClick}
      onSubmit={onSubmit}
      formInputs={formInputs}
      initialValues={{ name: template.name, description: template.description, type: template.type }}
      validationSchema={Yup.object().shape({
        name: Yup.string().max(app.maxInputLength).required(),
        description: Yup.string().max(app.maxInputLength).required(),
        type: Yup.string().required()
      })}
    />
  );
};

export default AdminUpdatePrintTemplateWindow;
