import { httpRequest } from '../../utils';
import { imagesRequests } from './index';
import { ImageProcessingResponse } from './types';

export const uploadImage = async (
  file: File,
  projectId: string,
  type: 'project' | 'template',
  targetImageWidth: number,
  targetImageHeight: number,
  onProgress: (progress: number) => void
) => {
  const formData = new FormData();

  formData.append('file', file);
  formData.append('filename', file.name);
  formData.append('type', type);
  formData.append('targetImageWidth', targetImageWidth.toString());
  formData.append('targetImageHeight', targetImageHeight.toString());

  return await httpRequest.runRequest<ImageProcessingResponse>({
    ...imagesRequests.uploadImage({
      // @ts-ignore
      requestPayload: formData,
      requestParams: { projectId }
    }),
    onUploadProgress: (progressEvent) => {
      const progress = Math.floor((progressEvent.loaded / progressEvent.total) * 100);
      onProgress(progress);
    }
  });
};

export const rotateImage = async (mode: RotateImageMode, fileKey: string, projectId: string) => {
  return await httpRequest.runRequest<ImageProcessingResponse>(
    imagesRequests.rotateImage({
      requestPayload: {
        mode,
        fileKey: fileKey
      },
      requestParams: { projectId }
    })
  );
};

export const cropImage = async (cropRegion: number[], fileKey: string, projectId: string) => {
  return await httpRequest.runRequest<ImageProcessingResponse>(
    imagesRequests.cropImage({
      requestPayload: {
        cropRegion,
        fileKey
      },
      requestParams: { projectId }
    })
  );
};

export const uploadBrandFolderImage = async (
  projectId: string,
  brandFolderImageId: string,
  type: 'project' | 'template',
  targetImageWidth: number,
  targetImageHeight: number
) => {
  return await httpRequest.runRequest<ImageProcessingResponse>(
    imagesRequests.uploadBrandFolderImage({
      requestPayload: { imageId: brandFolderImageId, targetImageWidth, targetImageHeight, type },
      requestParams: { projectId }
    })
  );
};

export const uploadStoryImage = async (
  projectId: string,
  imageUrl: string,
  targetImageWidth: number,
  targetImageHeight: number
) => {
  return await httpRequest.runRequest<ImageProcessingResponse>(
    imagesRequests.uploadStoryImage({
      requestPayload: { imageUrl, targetImageWidth, targetImageHeight },
      requestParams: { projectId }
    })
  );
};

export const uploadUserImage = async (
  projectId: string,
  imageId: string,
  targetImageWidth: number,
  targetImageHeight: number
) => {
  return await httpRequest.runRequest<ImageProcessingResponse>(
    imagesRequests.uploadUserImage({
      requestPayload: { imageId, targetImageWidth, targetImageHeight },
      requestParams: { projectId }
    })
  );
};
