import { createOrderArray, httpRequest } from '../../utils';
import { adminProjectsActions, adminProjectsRequests } from './index';
import {
  AdminProjectsIndexResponse,
  AdminProjectsState,
  FilterCheckboxWithStringValue,
  IndexAdminProjectsProjectsRequestParamsFilters,
  PublicationDateFilterChangePayload,
  TestProjectFilterOptions
} from './types';
import { Dispatch } from 'redux';
import { ApplicationState } from '../rootReducer';
import { TemplateTypeName } from '../Templates/types';
import { AnyProjectStatus } from '../Dashboard/types';
import moment from 'moment';
import { createUrl } from '../../utils/httpRequest';
import { saveAs } from 'file-saver';
import { changePagination } from '../Pagination/actions';
import { PaginationStoreModule } from '../Pagination/types';
import { replace } from 'connected-react-router';

const goToFirstPage = (dispatch: Dispatch, getState: () => ApplicationState): boolean => {
  const {
    adminProjects: { pagination }
  } = getState();

  if (pagination.page !== 1) {
    dispatch(changePagination(PaginationStoreModule.ADMIN_PROJECTS, { page: 1, per: pagination.per, q: pagination.q }));
    dispatch(
      replace({
        search: `?page=${1}&per=${pagination.per}${pagination.q ? `&q=${encodeURIComponent(pagination.q)}` : ''}`
      })
    );
    return true;
  }

  return false;
};

export const index = (resetPagination?: boolean) => async (dispatch: Dispatch, getState: () => ApplicationState) => {
  if (resetPagination) {
    const paginationChanged = goToFirstPage(dispatch, getState);
    if (paginationChanged) {
      return;
    }
  }

  const { adminProjects } = getState();

  try {
    dispatch(adminProjectsActions.projectsIndexBegin());

    const filters = extractFiltersData(adminProjects);

    const response = await httpRequest.runRequest<AdminProjectsIndexResponse>(
      adminProjectsRequests.index({
        requestParams: {
          page: adminProjects.pagination.page,
          per: adminProjects.pagination.per,
          q: adminProjects.pagination.q,
          format: 'json',
          order: createOrderArray(adminProjects.tableSearch.order),
          ...filters
        }
      })
    );

    dispatch(adminProjectsActions.projectsIndexSuccess(response));
  } catch (e) {
    dispatch(adminProjectsActions.projectsIndexFailed(e));
  }
};

export const downloadCsv = () => async (dispatch: Dispatch, getState: () => ApplicationState) => {
  const { adminProjects } = getState();
  const filters = extractFiltersData(adminProjects);

  const requestParams = {
    page: adminProjects.pagination.page,
    per: adminProjects.pagination.per,
    q: adminProjects.pagination.q,
    format: 'csv',
    order: createOrderArray(adminProjects.tableSearch.order),
    ...filters
  };

  const url = createUrl(
    adminProjectsRequests.index({
      requestParams
    }).query,
    requestParams
  );
  await saveAs(url);
  return null;
};

let debounceTimeout: number | null = null;

const debouncedIndexCall = (dispatch: Dispatch, getState: () => ApplicationState) => {
  if (debounceTimeout) {
    clearTimeout(debounceTimeout);
  }

  debounceTimeout = setTimeout(() => index(true)(dispatch, getState), 2000);
};

export const setAutomatedEmailFilter = (payload: boolean) => async (
  dispatch: Dispatch,
  getState: () => ApplicationState
) => {
  dispatch(adminProjectsActions.setAutomatedEmailFilter(payload));
  await index(true)(dispatch, getState);
};

export const setTestProjectsFilter = (payload: TestProjectFilterOptions) => async (
  dispatch: Dispatch,
  getState: () => ApplicationState
) => {
  dispatch(adminProjectsActions.setTestProjectsFilter(payload));
  await index(true)(dispatch, getState);
};

export const setPublicationTypeFilter = (payload: TemplateTypeName | 'orphan') => async (
  dispatch: Dispatch,
  getState: () => ApplicationState
) => {
  dispatch(adminProjectsActions.setPublicationTypeFilter(payload));
  await index(true)(dispatch, getState);
};

export const setProjectStatusFilter = (status: AnyProjectStatus | 'locked') => async (
  dispatch: Dispatch,
  getState: () => ApplicationState
) => {
  dispatch(adminProjectsActions.setProjectStatusFilter(status));
  await index(true)(dispatch, getState);
};

export const setPublicationDateFilter = (payload: PublicationDateFilterChangePayload) => async (
  dispatch: Dispatch,
  getState: () => ApplicationState
) => {
  dispatch(adminProjectsActions.setPublicationDateFilter(payload));
  await index(true)(dispatch, getState);
};

export const setOrganizationExternalDataCenterFilter = (dataCenter: number) => async (
  dispatch: Dispatch,
  getState: () => ApplicationState
) => {
  dispatch(adminProjectsActions.setOrganizationExternalDataCenterFilter(dataCenter));
  await index(true)(dispatch, getState);
};

export const setOrganizationNameFilter = (payload: FilterCheckboxWithStringValue) => async (
  dispatch: Dispatch,
  getState: () => ApplicationState
) => {
  dispatch(adminProjectsActions.setOrganizationNameFilter(payload));
  debouncedIndexCall(dispatch, getState);
};

export const setOrganizationNumberFilter = (payload: FilterCheckboxWithStringValue) => async (
  dispatch: Dispatch,
  getState: () => ApplicationState
) => {
  dispatch(adminProjectsActions.setOrganizationNumberFilter(payload));
  debouncedIndexCall(dispatch, getState);
};

export const setProjectNameFilter = (payload: FilterCheckboxWithStringValue) => async (
  dispatch: Dispatch,
  getState: () => ApplicationState
) => {
  dispatch(adminProjectsActions.setProjectNameFilter(payload));
  debouncedIndexCall(dispatch, getState);
};

export const setProjectExternalIdFilter = (payload: FilterCheckboxWithStringValue) => async (
  dispatch: Dispatch,
  getState: () => ApplicationState
) => {
  dispatch(adminProjectsActions.setProjectExternalIdFilter(payload));
  debouncedIndexCall(dispatch, getState);
};

const extractFiltersData = (adminProjects: AdminProjectsState): IndexAdminProjectsProjectsRequestParamsFilters => {
  const filters: IndexAdminProjectsProjectsRequestParamsFilters = {};

  const {
    publicationType,
    organizationName,
    organizationAccountNumber,
    projectName,
    status,
    organizationExternalDataCenter,
    publicationDateSelectedOption,
    publicationDateExact,
    publicationDateRange,
    testProjects,
    automatedEmail,
    externalId
  } = adminProjects.filters;

  filters.testProjects = `${testProjects.include}`;
  if (testProjects.only) {
    filters.onlyTestProjects = `${testProjects.only}`;
  }
  if (automatedEmail) {
    filters.automatedEmail = `${automatedEmail}`;
  }
  if (publicationType.length) {
    filters.publicationType = publicationType.join(',');
  }
  if (status.length) {
    filters.status = status.join(',');
  }
  if (organizationExternalDataCenter.length) {
    filters.dataCenter = organizationExternalDataCenter.join(',');
  }
  if (organizationName.enabled && organizationName.value) {
    filters.organizationName = organizationName.value.trim();
  }
  if (organizationAccountNumber.enabled && organizationAccountNumber.value) {
    filters.organizationNumber = organizationAccountNumber.value.trim();
  }
  if (externalId.enabled && externalId.value) {
    filters.externalId = externalId.value.trim();
  }
  if (projectName.enabled && projectName.value) {
    filters.projectName = projectName.value.trim();
  }
  if (publicationDateSelectedOption === 'exact' && publicationDateExact) {
    filters.publicationDate = moment(publicationDateExact).format('YYYY-MM-DD');
  }
  if (publicationDateSelectedOption === 'range' && (publicationDateRange.from || publicationDateRange.to)) {
    const start = publicationDateRange.from ? moment(publicationDateRange.from).format('YYYY-MM-DD') : 'none';
    const end = publicationDateRange.to ? moment(publicationDateRange.to).format('YYYY-MM-DD') : 'none';

    filters.publicationDateRange = `${start}|${end}`;
  }

  return filters;
};

export const syncMailStatistics = async () => {
  return await httpRequest.runRequest<void>(adminProjectsRequests.syncMailStatistics());
};
