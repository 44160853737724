import React, { FunctionComponent, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { push } from 'connected-react-router';
import { useDispatch } from 'react-redux';
import linksConstants from '../../../config/app/linksConstants';
import LockOpenIcon from '@material-ui/icons/LockOpen';
import { PageContentPaper } from '../PageStyledComponents';
import { Box, Button, Grid, Typography } from '@material-ui/core';
import Toast from '../../Shared/Toast/Toast';
import { Strong } from '../../Shared/StyledComponents';
import useOpenHandler from '../../../hooks/useOpenHandler';
import ProjectAvatar from '../../Shared/Avatar/ProjectAvatar';
import moment from 'moment';
import TelegramIcon from '@material-ui/icons/Telegram';
import ScheduleIcon from '@material-ui/icons/Schedule';
import CachedIcon from '@material-ui/icons/Cached';
import EditOutlinedIcon from '@material-ui/icons/EditOutlined';
import { emailProjectsOperations } from '../../../store/EmailProjects';
import { printProjectsOperations } from '../../../store/PrintProjects';
import { EmailProject, EmailProjectStatus } from '../../../store/EmailProjects/types';
import { PrintProject } from '../../../store/PrintProjects/types';
import { ButtonsContainer, TableCellStatus } from './ProjectDetailsPage';
import { User } from '../../../store/Auth/types';
import SourceProjectDetails from './Shared/SourceProjectDetails';
import PublicationDateDetails from './Shared/PublicationDateDetails';
import ProjectPublishNewsletterWindow from '../Dashboard/Windows/ProjectPublishNewsletterWindow';
import ProjectCancelPublishWindow from '../Dashboard/Windows/ProjectCalcelPublishWindow';
import { hasPermission, projectBelongsToOrganization } from '../../../utils/permissions';
import { formatTableTimestamp, useTypedSelector } from '../../../utils';
import ProjectNotes from '../Editors/Common/ProjectNotes';
import ResetProjectCheckInStatusWindow from '../Dashboard/Windows/ResetProjectCheckInStatusWindow';
import { ProjectActionType } from '../Dashboard/WorkInProgressTable/useHandleProjectAction';
import { AnyProject } from '../../../store/Dashboard/types';
import EqualizerIcon from '@material-ui/icons/Equalizer';
import InputChangeOnBlur from '../../Shared/Forms/InputChangeOnBlur';
import EmailAttachmentList from './Shared/EmailAttachmentList';
import ProjectClonesAccordion from './Shared/ProjectClonesAccordion';
import PreviewEmailWindow from '../Dashboard/Windows/PreviewEmailWindow';
import LinkIcon from '@material-ui/icons/Link';
import GenerateLinkWindow from '../Dashboard/Windows/GenerateLinkWindow';
import DevicesIcon from '@material-ui/icons/Devices';

type EmailProjectDetailsPageProps = {
  project: EmailProject;
  user: User;
  reFetchData: () => void;
  forceCheckOut: (project: AnyProject) => void;
};

const EmailProjectDetailsPage: FunctionComponent<EmailProjectDetailsPageProps> = ({
  project,
  reFetchData,
  forceCheckOut,
  user
}) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const role = useTypedSelector((state) => state.auth.role);
  const organization = useTypedSelector((state) => state.auth.organization);
  const [sourceProjectDetails, setSourceProjectDetails] = useState<PrintProject | null>(null);
  const [publishProjectWindowOpen, onPublishProjectWindowOpen, onPublishProjectWindowClose] = useOpenHandler();
  const [resetCheckInWindowOpen, onResetCheckInWindowOpen, onResetCheckInWindowClose] = useOpenHandler();
  const [previewEmailWindowOpen, onPreviewEmailWindowOpen, onPreviewEmailWindowClose] = useOpenHandler();
  const [generateLinkWindowOpen, onGenerateLinkWindowOpen, onGenerateLinkWindowClose] = useOpenHandler();
  const [
    cancelPublishProjectWindowOpen,
    onCancelPublishProjectWindowOpen,
    onCancelPublishProjectWindowClose
  ] = useOpenHandler();

  useEffect(() => {
    const fetchSourceProject = async () => {
      try {
        if (project.source) {
          const data = await printProjectsOperations.show(project.source);
          setSourceProjectDetails(data);
        }
      } catch (e) {
        console.warn('errorFetchingSource', e);
      }
    };

    fetchSourceProject();
  }, []);

  const getStatusIcon = (status: EmailProjectStatus) => {
    switch (status) {
      case EmailProjectStatus.IN_PROGRESS:
        return <CachedIcon />;

      case EmailProjectStatus.SCHEDULED:
        return <ScheduleIcon />;

      case EmailProjectStatus.PUBLISHED:
        return <TelegramIcon />;
    }
  };

  const saveProjectPublishDate = async (date: Date) => {
    try {
      await emailProjectsOperations.saveProjectPublishDate(project, date);
      await reFetchData();
      Toast.success(t('notifications.projectPublishDateChange.success'));
    } catch (e) {
      Toast.error(t('notifications.projectPublishDateChange.error'));
    }
  };

  const handleProjectAction = async (project: EmailProject, type: ProjectActionType) => {
    switch (type) {
      case 'publishNewsletter':
        onPublishProjectWindowOpen();
        break;

      case 'cancelPublish':
        onCancelPublishProjectWindowOpen();
        break;

      case 'preview': {
        onPreviewEmailWindowOpen();
        break;
      }

      case 'generateLink': {
        onGenerateLinkWindowOpen();
        break;
      }

      case 'resetCheckIn':
        onResetCheckInWindowOpen();
        break;

      case 'edit':
        dispatch(push(linksConstants.PROJECTS.EMAIL.EDIT(project.id)));
        break;

      case 'statistics':
        dispatch(push(linksConstants.PROJECTS.EMAIL.STATISTICS(project.id)));
        break;
    }
  };

  const saveProjectName = async (name: string) => {
    if (name) {
      try {
        await emailProjectsOperations.saveProjectName(project, name);
        Toast.success(t('notifications.projectNameChange.success'));
      } catch (e) {
        Toast.error(t('notifications.projectNameChange.error'));
      }
    }
  };

  const projectLocked = !!project.checkedInBy && user.id !== project.checkedInBy.id;

  return (
    <>
      <Grid item xs={12} sm={12} md={12} lg={6}>
        <PageContentPaper>
          <Box display="flex" alignItems="center">
            <ProjectAvatar type={project.type} locked={projectLocked} shared={project.isShared} />
            <div>
              <InputChangeOnBlur
                initialValue={project.name}
                onSave={saveProjectName}
                disabled={projectLocked || !projectBelongsToOrganization(organization, project)}
              />
              <Box fontSize={14} mt={1} ml={1}>
                {project.templateDescription}
              </Box>
            </div>
          </Box>

          <Typography component="div">
            <Box mt={2}>
              <TableCellStatus status={project.status}>
                {getStatusIcon(project.status)} {t(`common.projectStatus.${project.kind}.${project.status}`)}
              </TableCellStatus>
            </Box>
            <Box fontSize="fontSize" mt={1}>
              {t('pages.projectDetails.createdAt')}: {formatTableTimestamp(project.createdAt)}
            </Box>
            {!project.updatedBy && <>N/A</>}
            {project.updatedBy && (
              <Box fontSize={16} mb={5}>
                <Strong>{project.updatedBy.id === user.id ? t('common.you') : project.updatedBy.fullName}</Strong>{' '}
                {t('pages.dashboard.editedTheProject')} {moment(project.updatedByAt).fromNow()}
              </Box>
            )}
            {project.checkedInBy && (
              <Box fontSize={16} mb={5}>
                {t('common.projectCheckedOutBy')}{' '}
                <Strong>{project.checkedInBy.id === user.id ? t('common.you') : project.checkedInBy.fullName}</Strong>{' '}
                {project.checkedInBy.id === user.id && (
                  <Button onClick={() => forceCheckOut(project)} variant="outlined" color="secondary">
                    {t('pages.dashboard.imNotEditingAnymore')}
                  </Button>
                )}
              </Box>
            )}
          </Typography>
          {sourceProjectDetails && <SourceProjectDetails sourceProject={sourceProjectDetails} />}
          <EmailAttachmentList projectId={project.id} />
          {project.isShared && <ProjectClonesAccordion project={project} />}
          <Box display="flex" justifyContent="flex-end" mt={5}>
            <ButtonsContainer>
              <Button
                disabled={!hasPermission(role, ['projectsEmailAccess']) || projectLocked}
                onClick={() => handleProjectAction(project, 'preview')}
                variant="outlined"
                color="secondary"
              >
                <DevicesIcon fontSize="small" style={{ marginRight: 5 }} /> {t('pages.projectDetails.emulate')}
              </Button>
              {project.status === EmailProjectStatus.IN_PROGRESS && (
                <Button
                  disabled={
                    !hasPermission(role, ['projectsEmailEdition']) ||
                    projectLocked ||
                    !projectBelongsToOrganization(organization, project)
                  }
                  onClick={() => handleProjectAction(project, 'edit')}
                  variant="contained"
                  color="secondary"
                >
                  <EditOutlinedIcon /> {t('common.edit')}
                </Button>
              )}
              <br />
              <Button
                disabled={!hasPermission(role, ['projectsEmailAccess']) || projectLocked}
                onClick={() => handleProjectAction(project, 'generateLink')}
                variant="outlined"
                color="secondary"
              >
                <LinkIcon fontSize="small" style={{ marginRight: 5 }} /> {t('pages.projectDetails.generateLink')}
              </Button>
              {project.status === EmailProjectStatus.PUBLISHED && (
                <Button
                  disabled={!hasPermission(role, ['projectsEmailAccess'])}
                  onClick={() => handleProjectAction(project, 'statistics')}
                  variant="outlined"
                  color="secondary"
                >
                  <EqualizerIcon /> {t('common.statistics')}
                </Button>
              )}
              {project.status === EmailProjectStatus.IN_PROGRESS && (
                <Button
                  disabled={
                    !hasPermission(role, ['projectsEmailPublishing']) ||
                    projectLocked ||
                    !projectBelongsToOrganization(organization, project)
                  }
                  onClick={() => handleProjectAction(project, 'publishNewsletter')}
                  variant="outlined"
                  color="secondary"
                >
                  <TelegramIcon /> {t('common.scheduleForPublish')}
                </Button>
              )}
              {project.status === EmailProjectStatus.SCHEDULED && (
                <Button
                  disabled={
                    !hasPermission(role, ['projectsEmailPublishing']) ||
                    projectLocked ||
                    !projectBelongsToOrganization(organization, project)
                  }
                  onClick={() => handleProjectAction(project, 'cancelPublish')}
                  variant="outlined"
                  color="primary"
                >
                  <TelegramIcon /> {t('common.cancelPublish')}
                </Button>
              )}
              {projectLocked && hasPermission(role, ['projectsEmailResetCheckIn']) && (
                <Button
                  onClick={() => handleProjectAction(project, 'resetCheckIn')}
                  variant="contained"
                  color="secondary"
                >
                  <LockOpenIcon /> {t('common.resetCheckOut')}
                </Button>
              )}
            </ButtonsContainer>
          </Box>
        </PageContentPaper>
      </Grid>
      <Grid item xs={12} sm={12} md={12} lg={6}>
        <PageContentPaper>
          <PublicationDateDetails
            disabled={
              !hasPermission(role, ['projectsEmailPublishing']) ||
              projectLocked ||
              !projectBelongsToOrganization(organization, project)
            }
            project={project}
            onPublishDateUpdate={saveProjectPublishDate}
          />
        </PageContentPaper>
        <ProjectNotes project={project} type="email" role={role} user={user} isPopover={false} />
      </Grid>

      {publishProjectWindowOpen && (
        <ProjectPublishNewsletterWindow
          open={publishProjectWindowOpen}
          project={project}
          onCloseClick={onPublishProjectWindowClose}
          fullScreenOnMobile={true}
          onSuccessfulSubmit={reFetchData}
        />
      )}

      <ProjectCancelPublishWindow
        open={cancelPublishProjectWindowOpen}
        project={project}
        onCloseClick={onCancelPublishProjectWindowClose}
        fullScreenOnMobile={true}
        onSuccessfulSubmit={reFetchData}
      />
      <ResetProjectCheckInStatusWindow
        open={resetCheckInWindowOpen}
        project={project}
        onCloseClick={onResetCheckInWindowClose}
        fullScreenOnMobile={true}
      />

      {previewEmailWindowOpen && (
        <PreviewEmailWindow open={previewEmailWindowOpen} project={project} onCloseClick={onPreviewEmailWindowClose} />
      )}
      {generateLinkWindowOpen && (
        <GenerateLinkWindow open={generateLinkWindowOpen} project={project} onCloseClick={onGenerateLinkWindowClose} />
      )}
    </>
  );
};

export default EmailProjectDetailsPage;
