import React, { FunctionComponent, useState } from 'react';
import { PageContainer, PageContent } from '../../PageStyledComponents';
import PageHeader from '../../../Shared/Layout/PageHeader';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { push } from 'connected-react-router';
import linksConstants from '../../../../config/app/linksConstants';
import KeyboardArrowLeftIcon from '@material-ui/icons/KeyboardArrowLeft';
import SelectPrintProduct from './Partials/SelectPrintProduct';
import SelectPublishDate from './Partials/SelectPublishDate';
import SelectTemplate from './Partials/SelectTemplate';
import { Template, TemplateTypeName } from '../../../../store/Templates/types';
import { EmailProjectCreatorState } from '../types';
import Toast from '../../../Shared/Toast/Toast';
import LoadingModal from '../../../Shared/Loading/LoadingModal';
import { createProjectErrorHandler } from './Utils';
import CreateProjectStepper from './Partials/CreateProjectStepper';
import { PrintProject } from '../../../../store/PrintProjects/types';
import { emailProjectsOperations } from '../../../../store/EmailProjects';
import { OutlinedInfoBoxContent } from '../../../Shared/StyledComponents';
import InfoIcon from '@material-ui/icons/Info';
import InfoBox from '../../../Shared/InfoBox/InfoBox';

type EmailProjectCreatorProps = {};

const EmailProjectCreator: FunctionComponent<EmailProjectCreatorProps> = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const steps: EmailProjectCreatorState[] = ['publishDate', 'printProduct', 'template', 'finish'];

  const [publishDate, setPublishDate] = useState<Date>(new Date());
  const [selectedPrintProduct, setSelectedPrintProduct] = useState<PrintProject | null>(null);
  const [selectedTemplate, setSelectedTemplate] = useState<Template | null>(null);
  const [currentState, setCurrentState] = useState<EmailProjectCreatorState>('publishDate');
  const [creatingProject, setCreatingProject] = useState(false);
  const [testProject, setTestProject] = useState(false);

  const backButton = {
    onClick: () => {
      switch (currentState) {
        case 'publishDate':
          return dispatch(push(linksConstants.DASHBOARD.NEW_PROJECT));

        case 'printProduct':
          return setCurrentState('publishDate');

        case 'template':
          return setCurrentState('printProduct');

        case 'finish':
          return setCurrentState('template');
      }
    },
    label: t('common.back'),
    icon: <KeyboardArrowLeftIcon />
  };

  const onPublishDateSelected = (date: Date) => setPublishDate(date);

  const onPrintProductSelected = (project: PrintProject | null) => {
    setSelectedPrintProduct(project);
    setCurrentState('template');
  };

  const onTemplateSelected = async (template: Template) => {
    if (!template) {
      return Toast.info(t('notifications.projectCreate.templateNotSelected'));
    }

    setSelectedTemplate(template);
    setCurrentState('finish');
    setCreatingProject(true);

    try {
      const newProject = await emailProjectsOperations.create(
        template,
        publishDate,
        TemplateTypeName.EMAIL,
        testProject,
        selectedPrintProduct
      );
      Toast.success(t('notifications.projectCreate.success'));
      dispatch(push(linksConstants.PROJECTS.EMAIL.EDIT(newProject.id)));
    } catch (e) {
      createProjectErrorHandler(e, t);
      setCreatingProject(false);
      setCurrentState('template');
    }
  };

  const getCurrentSection = () => {
    switch (currentState) {
      case 'publishDate':
        return (
          <SelectPublishDate
            onSubmit={() => setCurrentState('printProduct')}
            onSelect={onPublishDateSelected}
            date={publishDate}
            testProject={testProject}
            onTestProjectChecked={() => setTestProject(!testProject)}
            checkReadMoreLinks={true}
            infoText="pages.dashboard.createWizard.publishDateSelector.selectDateInfoNewsletter"
          />
        );

      case 'printProduct':
        return (
          <SelectPrintProduct
            publishDate={publishDate}
            onSubmit={onPrintProductSelected}
            setCurrentState={(state) => setCurrentState(state)}
          />
        );

      case 'template':
      case 'finish':
        return (
          <SelectTemplate
            showSourceProject={true}
            showTopBar
            sourceProject={selectedPrintProduct}
            onSubmit={onTemplateSelected}
            templateTypeName={TemplateTypeName.EMAIL}
            setCurrentState={setCurrentState}
            publishDate={publishDate}
            infoText={t('pages.dashboard.createWizard.templateSelector.selectEmailTemplate')}
          />
        );
    }
  };

  const HeaderTitle = (
    <>
      <div>{t('pages.dashboard.createWizard.emailCreatorTitle')}</div>
      <div>
        <CreateProjectStepper steps={steps} currentState={currentState} />
      </div>
    </>
  );

  return (
    <PageContainer>
      {creatingProject && <LoadingModal message={t('pages.dashboard.creatingYourProject')} />}
      <PageHeader title={HeaderTitle} leftActionButtons={[backButton]} noBottomPadding />
      <PageContent>{getCurrentSection()}</PageContent>
    </PageContainer>
  );
};

export default EmailProjectCreator;
