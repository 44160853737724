import { createOrderArray, httpRequest } from '../../utils';
import {
  CreateMyOrganizationSwatchColorValues,
  CreateMyOrganizationUserValues,
  MyOrganizationSwatchColorResponse,
  MyOrganizationSwatchGroupResponse,
  MyOrganizationUsersIndexResponse,
  OrganizationCalendarStyleValues,
  OrganizationMonthCalendar,
  OrganizationTreeStructure,
  UpdateMyOrganizationUserValues,
  WordpressIntegration,
  WordpressIntegrationForm
} from './types';
import { Dispatch } from 'redux';
import { ApplicationState } from '../rootReducer';
import { myOrganizationActions, myOrganizationRequests } from './index';
import { DamSystemName, PasswordPolicy, PasswordPolicySettings } from '../SystemSettings/types';
import { StoryChannel, StoryContentContributor } from '../Stories/types';

export const index = (organizationId: string) => async (dispatch: Dispatch, getState: () => ApplicationState) => {
  const { myOrganization } = getState();

  try {
    dispatch(myOrganizationActions.organizationUsersIndexBegin());

    const response = await httpRequest.runRequest<MyOrganizationUsersIndexResponse>(
      myOrganizationRequests.index({
        requestParams: {
          organizationId,
          page: myOrganization.pagination.page,
          per: myOrganization.pagination.per,
          q: myOrganization.pagination.q,
          order: createOrderArray(myOrganization.tableSearch.order)
        }
      })
    );

    dispatch(myOrganizationActions.organizationUsersIndexSuccess(response));
  } catch (e) {
    dispatch(myOrganizationActions.organizationUsersIndexFailed(e));
  }
};

export const create = (organizationId: string, values: CreateMyOrganizationUserValues) => async () => {
  return await httpRequest.runRequest<void>(
    myOrganizationRequests.create({
      requestPayload: values,
      requestParams: { organizationId }
    })
  );
};

export const update = (
  organizationId: string,
  organizationUserId: string,
  values: UpdateMyOrganizationUserValues
) => async () => {
  return await httpRequest.runRequest<void>(
    myOrganizationRequests.update({
      requestPayload: values,
      requestParams: { organizationId, userId: organizationUserId }
    })
  );
};

export const deleteOrganizationUser = (organizationId: string, organizationUserId: string) => async () => {
  return await httpRequest.runRequest<void>(
    myOrganizationRequests.deleteOrganizationUser({
      requestParams: { organizationId, userId: organizationUserId }
    })
  );
};

export const getPasswordPolicy = async () => {
  return await httpRequest.runRequest<PasswordPolicy>(myOrganizationRequests.getPasswordPolicy());
};

export const getOrganizationPasswordPolicy = async (organizationId: string) => {
  return await httpRequest.runRequest<PasswordPolicy>(
    myOrganizationRequests.getOrganizationPasswordPolicy({
      requestParams: { organizationId }
    })
  );
};

export const removePasswordPolicy = async () => {
  return await httpRequest.runRequest<PasswordPolicy>(myOrganizationRequests.removePasswordPolicy());
};

export const setPasswordPolicy = async (payload: PasswordPolicySettings) => {
  return await httpRequest.runRequest<PasswordPolicy>(
    myOrganizationRequests.setPasswordPolicy({
      requestPayload: payload
    })
  );
};

export const indexOrganizationSwatches = () => async () => {
  return await httpRequest.runRequest<MyOrganizationSwatchGroupResponse>(
    myOrganizationRequests.getOrganizationSwatches()
  );
};

export const getOrganizationSwatches = async () => {
  return await httpRequest.runRequest<MyOrganizationSwatchGroupResponse>(
    myOrganizationRequests.getOrganizationSwatches()
  );
};

export const getOrganizationColors = async () => {
  return await httpRequest.runRequest<MyOrganizationSwatchColorResponse>(
    myOrganizationRequests.getOrganizationColors()
  );
};

export const createOrganizationSwatchColor = (values: CreateMyOrganizationSwatchColorValues) => async () => {
  return await httpRequest.runRequest<void>(
    myOrganizationRequests.createOrganizationSwatchColor({
      requestPayload: values
    })
  );
};

export const updateOrganizationSwatchColor = (
  swatchColorId: string,
  values: CreateMyOrganizationSwatchColorValues
) => async () => {
  return await httpRequest.runRequest<void>(
    myOrganizationRequests.updateOrganizationSwatchColor({
      requestPayload: values,
      requestParams: { swatchColorId }
    })
  );
};

export const deleteOrganizationSwatchColor = (swatchColorId: string) => async () => {
  return await httpRequest.runRequest<void>(
    myOrganizationRequests.deleteOrganizationSwatchColor({
      requestParams: { swatchColorId }
    })
  );
};

export const convertCmykToHex = async (cmyk: string) => {
  return await httpRequest.runRequest<{ hex: string | null }>(
    myOrganizationRequests.convertCmykToHex({
      requestParams: { cmyk }
    })
  );
};

export const indexOrganizationContributors = async (organizationId: string, page: number, search: string) => {
  return await httpRequest.runRequest<{ data: StoryContentContributor[]; pagination: Pagination }>(
    myOrganizationRequests.indexOrganizationContributors({
      requestParams: {
        organizationId,
        page,
        per: 10,
        q: search
      }
    })
  );
};

export const indexOrganizationContributorsForStory = async (
  organizationId: string,
  storyId: string,
  page: number,
  search: string
) => {
  return await httpRequest.runRequest<{ data: StoryContentContributor[]; pagination: Pagination }>(
    myOrganizationRequests.indexOrganizationContributorsForStory({
      requestParams: {
        organizationId,
        storyId,
        page,
        per: 10,
        q: search
      }
    })
  );
};

export const getSocialAccountsConnectorPage = async (organizationId: string) => {
  return await httpRequest.runRequest<{ url: string }>(
    myOrganizationRequests.getSocialAccountsConnectorPage({
      requestParams: {
        organizationId
      }
    })
  );
};

export const deleteOrganizationContributor = (organizationId: string, userId: string) => async () => {
  return await httpRequest.runRequest<void>(
    myOrganizationRequests.deleteOrganizationContributor({
      requestParams: { organizationId, userId }
    })
  );
};

export const getCalendarEvents = async (startDate: string, endDate: string) => {
  return await httpRequest.runRequest<{ data: StoryChannel[] }>(
    myOrganizationRequests.getCalendarEvents({
      requestParams: { startDate, endDate }
    })
  );
};

export const getOrganizationMonthCalendar = async (organizationId: string, date: string, type: 'month' | 'week') => {
  return await httpRequest.runRequest<OrganizationMonthCalendar>(
    myOrganizationRequests.getOrganizationMonthCalendar({
      requestParams: { organizationId, type, date }
    })
  );
};

export const setOrganizationCalendarStyle = async (payload: OrganizationCalendarStyleValues) => {
  return await httpRequest.runRequest<PasswordPolicy>(
    myOrganizationRequests.setOrganizationCalendarStyle({
      requestPayload: payload
    })
  );
};

export const getOrganizationTree = async () => {
  return await httpRequest.runRequest<{ data: OrganizationTreeStructure }>(
    myOrganizationRequests.getOrganizationTree()
  );
};

export const getWordpressIntegrations = async () => {
  return await httpRequest.runRequest<{ data: WordpressIntegration[] }>(
    myOrganizationRequests.getWordpressIntegrations()
  );
};

export const createWordpressIntegration = async (form: WordpressIntegrationForm) => {
  return await httpRequest.runRequest<WordpressIntegration>(
    myOrganizationRequests.createWordpressIntegration({
      requestPayload: form
    })
  );
};

export const deleteWordpressIntegration = async (id: string) => {
  return await httpRequest.runRequest<WordpressIntegration>(
    myOrganizationRequests.deleteWordpressIntegration({
      requestParams: { id }
    })
  );
};

export const updateWordpressIntegration = async (id: string, form: WordpressIntegrationForm) => {
  return await httpRequest.runRequest<WordpressIntegration>(
    myOrganizationRequests.updateWordpressIntegration({
      requestPayload: form,
      requestParams: { id }
    })
  );
};

export const getDamStatus = async () => {
  return await httpRequest.runRequest<{ damActive: boolean; system: DamSystemName }>(
    myOrganizationRequests.getDamStatus()
  );
};
