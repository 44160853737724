import { ApplicationRoute } from '../../../../typings';
import linksConstants from '../../../../config/app/linksConstants';
import EmailDndEditorPage from './EmailDndEditorPage';
import EmailPreviewPage from './EmailPreviewPage';

const EmailDndEditorRoutes: ApplicationRoute[] = [
  {
    path: linksConstants.PROJECTS.EMAIL.EDIT(':projectId'),
    exact: true,
    name: 'email_editor',
    public: false,
    show: true,
    component: EmailDndEditorPage,
    scope: []
  },
  {
    path: linksConstants.PROJECTS.EMAIL.PREVIEW(':previewUrl', ':contentWidth'),
    exact: true,
    name: 'email_preview',
    public: false,
    show: true,
    component: EmailPreviewPage,
    scope: []
  }
];

export default EmailDndEditorRoutes;
