import React, { FunctionComponent } from 'react';
import { AnyProject, AnyProjectStatus } from '../../../../store/Dashboard/types';
import { useTheme, withTheme } from '@material-ui/core';
import styled from 'styled-components';
import { EmailProjectStatus } from '../../../../store/EmailProjects/types';
import { PrintProjectStatus } from '../../../../store/PrintProjects/types';
import CachedIcon from '@material-ui/icons/Cached';
import ScheduleIcon from '@material-ui/icons/Schedule';
import TelegramIcon from '@material-ui/icons/Telegram';
import { useTranslation } from 'react-i18next';
import { ReactComponent as ProcessedIcon } from '../../../../assets/icons/processed.svg';

type ProjectStatusCellProps = {
  project: AnyProject;
};

const ProjectStatusCell: FunctionComponent<ProjectStatusCellProps> = ({ project }) => {
  const { t } = useTranslation();
  const theme = useTheme();

  const getStatusIcon = (status: AnyProjectStatus) => {
    switch (status) {
      case PrintProjectStatus.IN_PROGRESS:
      case EmailProjectStatus.IN_PROGRESS:
        return <CachedIcon />;

      case PrintProjectStatus.SCHEDULED:
      case EmailProjectStatus.SCHEDULED:
        return <ScheduleIcon />;

      case PrintProjectStatus.DELIVERED:
        return <ProcessedIcon width={24} fill={theme.palette.success.main} />;

      case EmailProjectStatus.PUBLISHED:
        return <TelegramIcon />;
    }
  };

  return (
    <Wrapper status={project.status}>
      {getStatusIcon(project.status)} {t(`common.projectStatus.${project.kind}.${project.status}`)}
    </Wrapper>
  );
};

const Wrapper = withTheme(styled.div<{ status: AnyProjectStatus }>`
  color: ${({ theme, status }) => {
    switch (status) {
      case EmailProjectStatus.IN_PROGRESS:
      case PrintProjectStatus.IN_PROGRESS:
        return theme.palette.warning.main;
      case PrintProjectStatus.SCHEDULED:
      case EmailProjectStatus.SCHEDULED:
        return theme.palette.primary.main;
      case PrintProjectStatus.DELIVERED:
      case EmailProjectStatus.PUBLISHED:
        return theme.palette.success.main;
    }
  }};

  svg {
    position: relative;
    top: -3px;
    vertical-align: middle;
  }
`);

export default ProjectStatusCell;
