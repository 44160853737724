import AdminOrganizationsPage from './AdminOrganizationsPage';
import AdminOrganizationsEditPage from './AdminOrganizationsEditPage';
import { ApplicationRoute } from '../../../../typings';
import linksConstants from '../../../../config/app/linksConstants';
import AdminOrganizationsDigitalAdsPage from './AdminOrganizationsDigitalAdsPage';
import AdminOrganizationsDigitalAdsAssignmentPreviewPage from './AdminOrganizationsDigitalAdsAssignmentPreviewPage';

const AdminOrganizationsRoutes: ApplicationRoute[] = [
  {
    path: linksConstants.ADMINISTRATION.ORGANIZATIONS.INDEX,
    exact: true,
    name: 'organizations',
    public: false,
    show: true,
    component: AdminOrganizationsPage,
    scope: []
  },
  {
    path: linksConstants.ADMINISTRATION.ORGANIZATIONS.EDIT(':organizationId'),
    exact: false,
    name: 'organizationEdit',
    public: false,
    show: true,
    component: AdminOrganizationsEditPage,
    scope: []
  },
  {
    path: linksConstants.ADMINISTRATION.ORGANIZATIONS.ADS(':organizationId'),
    exact: false,
    name: 'organizationAds',
    public: false,
    show: true,
    component: AdminOrganizationsDigitalAdsPage,
    scope: []
  },
  {
    path: linksConstants.ADMINISTRATION.ORGANIZATIONS.ADS_ASSIGNMENT(':organizationId'),
    exact: false,
    name: 'organizationAdsAssignment',
    public: false,
    show: true,
    component: AdminOrganizationsDigitalAdsAssignmentPreviewPage,
    scope: []
  }
];

export default AdminOrganizationsRoutes;
