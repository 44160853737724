import React, { FunctionComponent } from 'react';
import { Button } from '@material-ui/core';

type ChannelStatusButtonProps = {
  disabled?: boolean;
  variant: 'text' | 'outlined' | 'contained';
  isAllowed?: boolean;
  onClick?: () => void;
  color?: string;
  buttonVariantColor?: 'primary' | 'secondary';
  startIcon: JSX.Element;
  label: string;
  onMouseEnter?: () => void;
  onMouseLeave?: () => void;
};

const ChannelStatusButton: FunctionComponent<ChannelStatusButtonProps> = ({
  onClick,
  variant,
  color,
  disabled,
  label,
  startIcon,
  onMouseEnter,
  onMouseLeave,
  buttonVariantColor
}) => {
  return (
    <Button
      color={buttonVariantColor}
      disabled={disabled}
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
      variant={variant}
      size="small"
      onClick={(event) => {
        event.stopPropagation();
        if (onClick) {
          onClick();
        }
      }}
      style={{
        borderColor: color,
        color,
        marginRight: 5,
        opacity: disabled ? 0.4 : 1
      }}
      startIcon={startIcon}
    >
      {label}
    </Button>
  );
};

export default ChannelStatusButton;
