import { Dictionary } from 'lodash';
import { StateWithPagination, StateWithTableSearch } from '../../components/Shared/DataTable/types';
import { AdminKeyword } from '../AdminKeywords/types';
import { UserType } from '../AdminUsers/types';

export type AdminRolesState = StateWithPagination &
  StateWithTableSearch<AdminRole> & {
    roles: NormalizedAdminRoles;
    index: {
      isLoading: boolean;
      isFailed: boolean;
      error: NetworkError | null;
    };
  };

export type NormalizedAdminRoles = {
  entities: Dictionary<AdminRole>;
  result: string[];
};

export type AdminRolesIndexResponse = {
  data: AdminRole[];
  pagination: Pagination;
};

export type AdminUpdateRoleValues = Pick<AdminRole, 'name' | 'userTypeId'>;

export type AdminRolesIndexActionBEGIN = {
  type: AdminRolesActionsConstants.INDEX_BEGIN;
};

export type AdminRolesIndexActionSUCCESS = {
  type: AdminRolesActionsConstants.INDEX_SUCCESS;
  payload: AdminRolesIndexResponse;
};

export type IndexAdminRolesRequestParams = {
  per: number;
  page: number;
  order?: string[];
  q: string | null;
};

export type AdminRolesIndexActionFAILED = {
  type: AdminRolesActionsConstants.INDEX_FAILED;
  payload: {
    error: any;
  };
};

export type AdminRole = {
  id: string;
  name: string;
  description: string;
  isDefault: boolean;
  userTypeId: string;
  userType: UserType;
  createdAt: string;
  updatedAt: string;
  keywords: AdminKeyword[];
  permissions: RolePermissions;
};

export type RolePermissions = {
  /** Print Projects */
  projectsPrintAccess: boolean;
  projectsPrintEdition: boolean;
  projectsPrintPublishing: boolean;
  projectsPrintRemove: boolean;
  projectsPrintResetCheckIn: boolean;
  projectsPrintReSubmitJob: boolean;

  /** Email Projects */
  projectsEmailAccess: boolean;
  projectsEmailEdition: boolean;
  projectsEmailPublishing: boolean;
  projectsEmailRemove: boolean;
  projectsEmailResetCheckIn: boolean;
  projectsEmailAdvancedEditor: boolean;

  /** Project Notes */
  projectNotesView: boolean;
  projectNotesEdit: boolean;

  /** Mailing Lists */
  mailingListsView: boolean;
  mailingListsAdd: boolean;
  mailingListsEdit: boolean;
  mailingListsRemove: boolean;

  /** Admin Mailing Lists */
  adminMailingListsView: boolean;
  adminMailingListsAdd: boolean;
  adminMailingListsEdit: boolean;
  adminMailingListsRemove: boolean;

  /** Password Policies */
  passwordPoliciesView: boolean;
  passwordPoliciesAdd: boolean;
  passwordPoliciesEdit: boolean;
  passwordPoliciesRemove: boolean;

  /** Organizations Administration */
  organizationsView: boolean;
  organizationsAdd: boolean;
  organizationsEdit: boolean;
  organizationsRemove: boolean;

  /** Users Administration */
  usersView: boolean;
  usersAdd: boolean;
  usersRemove: boolean;
  usersLoginAs: boolean;
  usersBlock: boolean;
  usersChangePassword: boolean;
  usersChangeBrandfolderCollection: boolean;

  /** Roles Administration */
  rolesView: boolean;
  rolesAdd: boolean;
  rolesRemove: boolean;

  /** Keywords Administration */
  keywordsView: boolean;
  keywordsAdd: boolean;
  keywordsAssociate: boolean;
  keywordsRemove: boolean;

  /** Templates Administration */
  templatesView: boolean;
  templatesAdd: boolean;
  templatesDownload: boolean;
  templatesUpload: boolean;
  templatesRemove: boolean;

  /** System Settings */
  systemSettingsView: boolean;
  systemSettingsManage: boolean;

  /** Swatches */
  swatchesView: boolean;
  swatchesAdd: boolean;
  swatchesEdit: boolean;
  swatchesRemove: boolean;

  /**  Adserver administration */
  adsView: boolean;
  adsAdd: boolean;
  adsEdit: boolean;
  adsRemove: boolean;

  /** Debugging / Utils */
  debugJsonEmailTemplateEditor: boolean;
  debugFlipBookPreview: boolean;
  debugFlipBookRegeneration: boolean;
  debugTestProjectsCreation: boolean;
  debugExternalProjectIdDisplay: boolean;

  /** Reports */
  adminReportsDailyAdsReport: boolean;
  adminReportsDailyOrganizationEmailActivityReport: boolean;
  adminReportsDailyOrganizationPrintActivityReport: boolean;
  adminReportsEmailCampaignsReport: boolean;
  adminReportsOrganizationMailingListSizeReport: boolean;
  adminReportsSubmittedPrintProjectsReport: boolean;
  adminReportsDetailedEmailCampaignsReport: boolean;
  adminReportsAdPlacementReport: boolean;

  /** Backoffice */
  backofficeProjectsDashboard: boolean;

  /** Stories */
  storiesAccess: boolean;
  storiesEdit: boolean;
  storiesEditChannels: boolean;
  storiesAdd: boolean;
  storiesDelete: boolean;
  storiesContributors: boolean;
  storiesApproval: boolean;
  storiesPublish: boolean;
  storiesAiContent: boolean;
};

export enum DefaultRoleName {
  SYSTEM_OWNER = 'SYSTEM_OWNER',
  IT = 'IT',
  MANAGEMENT = 'MANAGEMENT',
  SUPPORT = 'SUPPORT',
  ORGANIZATION_OWNER = 'ORGANIZATION_OWNER',
  ORGANIZATION_CONTACT = 'ORGANIZATION_CONTACT',
  CONTENT_CONTRIBUTOR = 'CONTENT_CONTRIBUTOR'
}

export const defaultRoleNames: DefaultRoleName[] = [
  DefaultRoleName.SYSTEM_OWNER,
  DefaultRoleName.IT,
  DefaultRoleName.MANAGEMENT,
  DefaultRoleName.SUPPORT,
  DefaultRoleName.ORGANIZATION_OWNER,
  DefaultRoleName.ORGANIZATION_CONTACT,
  DefaultRoleName.CONTENT_CONTRIBUTOR
];

export const administrativeRoleNames: DefaultRoleName[] = [
  DefaultRoleName.SYSTEM_OWNER,
  DefaultRoleName.IT,
  DefaultRoleName.MANAGEMENT,
  DefaultRoleName.SUPPORT
];

export type AdminRolesResponse = {
  data: AdminRole[];
};

export type AdminRolesKeywordAddedAction = {
  type: AdminRolesActionsConstants.KEYWORD_ADDED;
  payload: AdminRole;
};

export type AdminRolesKeywordRemovedAction = {
  type: AdminRolesActionsConstants.KEYWORD_REMOVED;
  payload: AdminRole;
};

export enum AdminRolesActionsConstants {
  KEYWORD_ADDED = 'ADMIN_ROLES/KEYWORD_ADDED',
  KEYWORD_REMOVED = 'ADMIN_ROLES/KEYWORD_REMOVED',
  INDEX_BEGIN = 'ADMIN_ROLES/INDEX/BEGIN',
  INDEX_SUCCESS = 'ADMIN_ROLES/INDEX/SUCCESS',
  INDEX_FAILED = 'ADMIN_ROLES/INDEX/FAILED'
}

export type AdminRolesActionType =
  | AdminRolesKeywordAddedAction
  | AdminRolesKeywordRemovedAction
  | AdminRolesIndexActionBEGIN
  | AdminRolesIndexActionSUCCESS
  | AdminRolesIndexActionFAILED;
