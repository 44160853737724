import React, { FunctionComponent } from 'react';
import { useTypedSelector } from '../../../../../utils';
import { useTranslation } from 'react-i18next';
import { FormControlLabel, Radio, RadioGroup } from '@material-ui/core';
import { isSystemOwner } from '../../../../../utils/permissions';

type PrintPublishWindowLpiExpressDataCentersProps = {
  dataCenterUrl: string;
  setDataCenterUrl: (centerUrl: string) => void;
};

const PrintPublishWindowLpiExpressDataCenters: FunctionComponent<PrintPublishWindowLpiExpressDataCentersProps> = ({
  setDataCenterUrl,
  dataCenterUrl
}) => {
  const { t } = useTranslation();
  const authState = useTypedSelector((state) => state.auth);

  return (
    <>
      {isSystemOwner(authState) && (
        <RadioGroup
          row
          value={dataCenterUrl}
          onChange={(e) => setDataCenterUrl(e.target.value)}
          style={{
            display: 'inline-block',
            marginLeft: 20
          }}
        >
          <FormControlLabel value={authState.dataCenters.dc1} control={<Radio />} label={t('common.dataCenters.dc1')} />
          <FormControlLabel value={authState.dataCenters.dc2} control={<Radio />} label={t('common.dataCenters.dc2')} />
          <FormControlLabel value={authState.dataCenters.dc3} control={<Radio />} label={t('common.dataCenters.dc3')} />
        </RadioGroup>
      )}
    </>
  );
};

export default PrintPublishWindowLpiExpressDataCenters;
