import { Box, Typography, withTheme } from '@material-ui/core';
import React, { useCallback, useState } from 'react';
import { Accept, useDropzone } from 'react-dropzone';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import app from '../../../../../config/app/app';
import { StoryChannelAttachment, StoryChannelType } from '../../../../../store/Stories/types';
import InfoIcon from '@material-ui/icons/Info';
import AttachmentsGuidelinesWindow from '../../Windows/AttachmentsGuidelinesWindow';
import useOpenHandler from '../../../../../hooks/useOpenHandler';
import { toCamelCase } from '../../../../../utils';

type AttachmentsDropzoneProps = {
  label: string;
  buttonLabel: string;
  hintLabel: string;
  accept?: Accept;
  maxFiles?: number;
  maxSize?: number;
  handleAcceptedFiles: (files: File[] | Blob[] | MediaSource[]) => void;
  attachments: StoryChannelAttachment[];
  channelType: StoryChannelType;
};

const AttachmentsDropzone: React.FC<AttachmentsDropzoneProps> = ({
  label,
  buttonLabel,
  hintLabel,
  accept,
  maxFiles,
  maxSize,
  handleAcceptedFiles,
  attachments,
  channelType
}) => {
  const [error, setError] = useState<string | null>(null);
  const [
    attachmentsGuidelinesWindowOpen,
    onAttachmentsGuidelinesWindowOpen,
    onAttachmentsGuidelinesWindowClose
  ] = useOpenHandler();

  const onDrop = useCallback(
    (acceptedFiles, rejectedFiles) => {
      if (acceptedFiles.length > 0) {
        let error: string | null = null;

        error = validateVideos(acceptedFiles);
        if (!error) {
          error = validateImages(acceptedFiles);
        }

        if (error) {
          setError(error);
          setTimeout(() => {
            setError(null);
          }, 3000);
          return;
        }

        acceptedFiles.map((file: File | Blob | MediaSource) =>
          Object.assign(file, {
            preview: URL.createObjectURL(file)
          })
        );
        handleAcceptedFiles(acceptedFiles);
      }

      if (rejectedFiles.length > 0) {
        rejectedFiles.forEach((file: any) => {
          setError(file.errors[0].code);
          setTimeout(() => {
            setError(null);
          }, 3000);
        });
      }
    },
    [handleAcceptedFiles]
  );

  const { t } = useTranslation();
  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
    accept,
    maxFiles,
    maxSize
  });

  const fileTypesChecker = (fileType: string, types: string[]) => types.some((element) => fileType.includes(element));

  const validateVideos = (acceptedFiles: File[]) => {
    let error = null;
    const videoFormats = app.acceptedAttachments.videoFormats;

    const numOfVideoFiles = acceptedFiles.filter((file) => fileTypesChecker(file.type, videoFormats)).length;
    const numOfUploadedVideoFiles = attachments.filter((file) => fileTypesChecker(file.extension, videoFormats)).length;

    // check if there is only 1 vid uploaded
    if (numOfVideoFiles + numOfUploadedVideoFiles > 1) {
      error = 'too-many-videos';
    }
    return error;
  };

  const validateImages = (acceptedFiles: File[]) => {
    let error = null;
    const imageFormats = app.acceptedAttachments.imageFormats;
    const images = acceptedFiles.filter((file) => fileTypesChecker(file.type, imageFormats));

    for (const image of images) {
      if (!app.acceptedAttachments[channelType] || image.size > app.acceptedAttachments[channelType].maxImageSize) {
        error = 'file-too-large';
      }
    }

    return error;
  };

  const getErrorMessage = (errorCode: string) => {
    switch (errorCode) {
      case 'file-too-large':
        return t('attachments.errors.file-too-large');
      case 'file-invalid-type':
        return t('attachments.errors.file-invalid-type');
      case 'too-many-videos':
        return t('attachments.errors.too-many-videos');

      default:
        return t('attachments.errors.general');
    }
  };

  const renderHintAndGuidelinesButton = () => {
    return (
      <>
        <div className="drag-drop-hint">{hintLabel}</div>
        <Box
          display="flex"
          justifyContent="center"
          alignItems="center"
          mt={1}
          style={{ cursor: 'pointer' }}
          onClick={(e) => {
            e.stopPropagation();
            onAttachmentsGuidelinesWindowOpen();
          }}
        >
          <InfoIcon style={{ width: 14, height: 14, marginRight: 4 }} color="secondary" />
          <Typography variant="caption" color="secondary">
            {t('attachments.attachmentsGuidelines')}
          </Typography>
        </Box>
      </>
    );
  };

  return (
    <>
      <StyledDropzone className={error ? 'files-uploader error' : 'files-uploader'} {...getRootProps()}>
        <input {...getInputProps()} />
        {isDragActive ? (
          <div>
            <strong>{t('attachments.dropHere')}</strong>
          </div>
        ) : error ? (
          <Box display="flex" justifyContent="center" flexDirection="column" alignItems="center">
            <div>
              <strong>{getErrorMessage(error)}</strong>
            </div>
            {renderHintAndGuidelinesButton()}
          </Box>
        ) : (
          <Box display="flex" justifyContent="center" flexDirection="column" alignItems="center">
            <div>
              <strong>{label}</strong> {t('common.or')} <span className="drop-btn">{buttonLabel}</span>
            </div>
            {renderHintAndGuidelinesButton()}
          </Box>
        )}
      </StyledDropzone>
      {attachmentsGuidelinesWindowOpen && (
        <AttachmentsGuidelinesWindow
          channelType={channelType}
          open={attachmentsGuidelinesWindowOpen}
          fullScreenOnMobile={true}
          onCloseClick={onAttachmentsGuidelinesWindowClose}
        />
      )}
    </>
  );
};

export const StyledDropzone = withTheme(styled.div`
  transition: background-color 0.5s ease;
  margin-top: 15px;
  background-color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 100px;
  border-width: 1px;
  border-style: dashed;
  border-color: #ccc;
  border-radius: 5px;
  font-size: 14px;
  &.error {
    background-color: #cf0000;
    color: #fff;
    text-align: center;
    .drag-drop-hint: {
      color: #fff;
    }
  }
  .drop-btn {
    background-color: ${({ theme }) => theme.palette.primary.main};
    color: #fff;
    padding: 5px 10px 5px 10px;
    border-radius: 5px;
    font-weight: bolder;
    cursor: pointer;
  }
  .drag-drop-hint {
    font-size: 11px;
    margin-top: 10px;
    color: dark-gray;
    text-align: center;
  }
`);

export default AttachmentsDropzone;
