import { httpRequest } from '../../utils';
import { notesRequests } from './index';
import { NotesProjectType, ProjectNote } from './types';
import { AnyProject } from '../Dashboard/types';

export const getProjectNotes = async (projectType: NotesProjectType, project: AnyProject) => {
  const notes = await httpRequest.runRequest<{ data: ProjectNote[] }>(
    notesRequests.getProjectNotes({
      requestParams: { projectId: project.id, projectType }
    })
  );

  return notes.data;
};

export const addNewNote = async (projectType: NotesProjectType, project: AnyProject, content: string) => {
  return await httpRequest.runRequest<any>(
    notesRequests.addNoteToProject({
      requestParams: { projectId: project.id, projectType },
      requestPayload: { content }
    })
  );
};

export const removeNote = async (projectType: NotesProjectType, note: ProjectNote) => {
  return await httpRequest.runRequest<ProjectNote>(
    notesRequests.removeProjectNote({
      requestParams: { noteId: note.id, projectType }
    })
  );
};
