import React, { FunctionComponent, useState } from 'react';
import { ChromePicker } from 'react-color';
import { Popover } from '@material-ui/core';
import ColorLensIcon from '@material-ui/icons/ColorLens';
import styled from 'styled-components';
import { useTypedSelector } from '../../../utils';
import { rgbToHex } from '../../../utils/colors';
import { MyOrganizationSwatchColorSmall } from '../../../store/MyOrganization/types';

const shadesOfGray = ['#ECF0F1', '#CED4D9', '#95A5A6', '#7E8C8D', '#34495D'];
const lastLine = ['#000000', '#ffffff', 'transparent', 'empty', 'custom'];

type ColorPickerProps = {
  disabled?: boolean;
  color: string;
  onChange: (color: string) => void;
};

const ColorPicker: FunctionComponent<ColorPickerProps> = ({ color, onChange, disabled }) => {
  const [pickerType, setPickerType] = useState<'standard' | 'picker'>('standard');
  const [anchorEl, setAnchorEl] = React.useState<HTMLDivElement | null>(null);

  const organizationColors = useTypedSelector((state) => state.auth.colors);

  const handleClick = (event: React.MouseEvent<HTMLDivElement>) => {
    if (disabled) return;
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const selectColor = (color: string) => {
    onChange(color);
    handleClose();
  };

  const open = Boolean(anchorEl);

  const colorToDisplay = (color: string): MyOrganizationSwatchColorSmall | null => {
    const hexColor = color.startsWith('rgb')
      ? `#${rgbToHex(color)}`
      : color.length === 7 || color.length === 4 || color === 'transparent'
      ? color.toLocaleLowerCase()
      : '#';

    const organizationColor = organizationColors.find((c) => c.hex === hexColor);
    return organizationColor || null;
  };

  const selectedColor = colorToDisplay(color);

  return (
    <div>
      <PreviewWrapper disabled={disabled}>
        <Preview color={color} onClick={handleClick} />{' '}
        <PreviewText title={selectedColor ? selectedColor.name : ''}>
          {selectedColor ? selectedColor.name : ''}
        </PreviewText>
      </PreviewWrapper>

      <Popover
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center'
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center'
        }}
      >
        <PickerPopover>
          {pickerType === 'standard' && (
            <>
              <ColorsGrid>
                {organizationColors.map((color, i) => (
                  <Color
                    key={`org-color-${color}-${i}`}
                    title={color.name}
                    color={color.hex}
                    className={`${selectedColor && selectedColor.hex === color.hex ? 'active' : ''}`}
                    onClick={() => selectColor(color.hex)}
                  />
                ))}
              </ColorsGrid>
              <ColorsGrid>
                {shadesOfGray.map((color, i) => (
                  <Color
                    key={`shades-of-gray-${color}-${i}`}
                    className={`${selectedColor && selectedColor.hex === color ? 'active' : ''}`}
                    color={color}
                    onClick={() => selectColor(color)}
                  />
                ))}
                {lastLine.map((color, i) => (
                  <div key={i}>
                    {color === 'transparent' && (
                      <TransparentColor
                        color="transparent"
                        key={`last-line-${color}-${i}-transparent`}
                        onClick={() => selectColor(color)}
                      />
                    )}
                    {color === 'empty' && <EmptyColor color="#ffffff" key={`last-line-${color}-${i}-empty`} />}
                    {color !== 'custom' && color !== 'transparent' && color !== 'empty' && (
                      <Color key={`last-line-${color}-${i}`} color={color} onClick={() => selectColor(color)} />
                    )}
                    {color === 'custom' && (
                      <CustomColor
                        color="#fff"
                        key={`last-line-${color}-${i}-custom`}
                        onClick={() => setPickerType('picker')}
                      >
                        <ColorLensIcon />
                      </CustomColor>
                    )}
                  </div>
                ))}
              </ColorsGrid>
            </>
          )}

          {pickerType === 'picker' && (
            <CustomPickerWrapper>
              <BackButton onClick={() => setPickerType('standard')}>&lt; Back</BackButton>
              <ChromePicker color={color} onChange={(color) => onChange(color.hex)} />
            </CustomPickerWrapper>
          )}
        </PickerPopover>
      </Popover>
    </div>
  );
};

const BackButton = styled.button`
  border: none;
  background: #fff;
  width: 100%;
  cursor: pointer;
  padding: 8px;

  &:hover {
    background: #f8f8f8;
  }
`;

const PreviewWrapper = styled.div<{ disabled?: boolean }>`
  display: flex;
  align-items: center;
  opacity: ${({ disabled }) => (disabled ? '0.2' : '1')};
`;

const PreviewText = styled.div`
  margin-left: 10px;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  max-width: 300px;
`;

const Preview = styled.div<{ color: string; disabled?: boolean }>`
  min-width: 50px;
  height: 30px;
  border: 1px solid #ccc;
  cursor: pointer;
  border-radius: 6px;
  background: ${({ color }) =>
    color === 'transparent'
      ? `linear-gradient(
    135deg,
    rgba(0, 0, 0, 0) calc(50% - 2px),
    rgba(255, 0, 0, 1) calc(50%),
    rgba(0, 0, 0, 0) calc(50% + 2px)
  )`
      : color};
`;

const PickerPopover = styled.div`
  background-color: #ffffff;
  padding: 0;
  border-radius: 4px;
  overflow: hidden;
`;

const CustomPickerWrapper = styled.div``;

const ColorsGrid = styled.div`
  width: 150px;
  display: flex;
  flex-wrap: wrap;
`;

const Color = styled.div<{ color: string }>`
  width: 30px;
  height: 30px;
  background-color: ${({ color }) => color};
  transition: transform 0.15s, box-shadow 0.15s;

  &:hover {
    box-shadow: 0 0 0 1px rgb(127 127 127 / 30%) inset;
    transform: scale(0.8);
  }

  &.active {
    box-shadow: 0 0 0 1px rgb(127 127 127 / 30%) inset;
    transform: scale(0.8);
  }
`;

const TransparentColor = styled(Color)`
  background: linear-gradient(
    135deg,
    rgba(0, 0, 0, 0) calc(50% - 2px),
    rgba(255, 0, 0, 1) calc(50%),
    rgba(0, 0, 0, 0) calc(50% + 2px)
  );
`;

const CustomColor = styled(Color)`
  padding: 3px;
`;

const EmptyColor = styled(Color)`
  &:hover {
    box-shadow: none;
    transform: none;
  }
`;

export default ColorPicker;
