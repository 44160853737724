import { ApplicationRoute } from '../../../typings';
import linksConstants from '../../../config/app/linksConstants';
import StoriesPage from './StoriesPage';
import StoryContentContributors from './Contributors/StoryContentContributors';
import ManageStoryPage from './ManageStoryPage';
import PublicOpenAIPolicyPage from './PublicOpenAIPolicyPage';

export const StoriesRoutes: ApplicationRoute[] = [
  {
    path: linksConstants.STORY.INDEX,
    exact: true,
    name: 'stories',
    public: false,
    show: true,
    component: StoriesPage,
    scope: []
  },
  {
    path: linksConstants.STORY.EDIT(':id'),
    exact: false,
    name: 'edit',
    public: false,
    show: true,
    component: ManageStoryPage,
    scope: []
  },
  {
    path: linksConstants.STORY.CONTENT_CONTRIBUTORS(':storyId'),
    exact: false,
    name: 'story_content_contributors',
    public: false,
    show: true,
    component: StoryContentContributors,
    scope: []
  }
];

export const StoriesPublicRoutes: ApplicationRoute[] = [
  {
    path: linksConstants.PUBLIC_OPEN_AI_POLICY,
    exact: true,
    name: 'open_ai_policy',
    public: true,
    show: true,
    component: PublicOpenAIPolicyPage,
    scope: []
  }
];
