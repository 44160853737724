import React, { FunctionComponent, useEffect, useState } from 'react';
import * as Yup from 'yup';
import ModalFormWindow from '../../../../Shared/Window/ModalFormWindow';
import { useTranslation } from 'react-i18next';
import { AdminUpdateUserValues, AdminUser, UserType } from '../../../../../store/AdminUsers/types';
import { adminUsersOperations } from '../../../../../store/AdminUsers';
import { useDispatch } from 'react-redux';
import { AdminCheckboxInput, AdminSelectInput, AdminTextInput } from '../../Shared/AdminFormInputs';
import { FormLabel, MenuItem } from '@material-ui/core';
import Toast from '../../../../Shared/Toast/Toast';
import app from '../../../../../config/app/app';
import { adminRolesOperations } from '../../../../../store/AdminRoles';
import { administrativeRoleNames, AdminRole, DefaultRoleName } from '../../../../../store/AdminRoles/types';
import { FormikProps } from 'formik';
import { getRoleNameElement, useTypedSelector } from '../../../../../utils';
import InfoBox from '../../../../Shared/InfoBox/InfoBox';
import { hasPermission, isSystemOwner } from '../../../../../utils/permissions';

type AdminUpdateUserWindowProps = {
  open: boolean;
  user: AdminUser | null;
  userTypes: UserType[];
  onCloseClick: () => void;
  fullScreenOnMobile: boolean;
};

const AdminUpdateUserWindow: FunctionComponent<AdminUpdateUserWindowProps> = ({
  user,
  onCloseClick,
  open,
  userTypes
}) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [roles, setRoles] = useState<AdminRole[]>([]);
  const authState = useTypedSelector((state) => state.auth);

  useEffect(() => {
    const fetchData = async () => {
      if (user && open) {
        await onUserTypeChange({ target: { value: user.typeId } });
      }
    };
    fetchData();
  }, [open]);

  if (!user) return null;

  const isAdministrativeUserType = (userTypeId: string) => {
    const type = userTypes.find((type) => type.id === userTypeId);
    return type && administrativeRoleNames.includes(type.name as DefaultRoleName);
  };

  const onSubmit = async (values: AdminUpdateUserValues) => {
    try {
      await dispatch(
        adminUsersOperations.update(user.id, {
          lastName: values.lastName,
          firstName: values.firstName,
          roleId: values.roleId,
          typeId: values.typeId,
          brandfolderCollection: values.brandfolderCollection || null,
          systemMaintenanceAllowed: values.systemMaintenanceAllowed,
          externalDataCenter: values.externalDataCenter === -1 ? null : values.externalDataCenter
        })
      );
      Toast.success(t('notifications.adminUsers.successOperation'));

      await dispatch(adminUsersOperations.index());

      onCloseClick();
    } catch (e) {
      Toast.error(t('notifications.adminUsers.errorOperation'));
    }
  };

  const onUserTypeChange = async (e: any, props?: FormikProps<any>) => {
    try {
      const typeId = e.target.value;

      if (props) {
        props.setFieldValue('roleId', '');
      }

      const rolesOfType = await adminRolesOperations.getRolesOfUserType(typeId);

      setRoles(rolesOfType.data);

      if (props && !isAdministrativeUserType(typeId)) {
        const typeData = userTypes.find((type) => type.id === typeId);
        if (typeData) {
          const defaultRoleData = rolesOfType.data.find((role) => role.name === typeData.name);
          if (defaultRoleData) {
            props.setFieldValue('roleId', defaultRoleData.id);
          }
        }
      }
    } catch (e) {
      console.log('e', e);
    }
  };

  const formInputs = (props: FormikProps<any>) => (
    <>
      <AdminTextInput t={t} name="email" section="adminUsers" disabled />
      <AdminTextInput t={t} name="firstName" section="adminUsers" />
      <AdminTextInput t={t} name="lastName" section="adminUsers" />
      <AdminSelectInput t={t} name="externalDataCenter" section="commonInputs">
        {Object.keys({ notSet: -1, ...authState.dataCenters }).map((dataCenter, i) => {
          if (i === 0) {
            return (
              <MenuItem key={-1} value={-1}>
                {t(`common.notSet`)}
              </MenuItem>
            );
          }
          return (
            <MenuItem key={i} value={i.toString()}>
              {t(`common.dataCenters.${dataCenter}`)}
            </MenuItem>
          );
        })}
      </AdminSelectInput>
      <AdminSelectInput onChange={(e) => onUserTypeChange(e, props)} t={t} name="typeId" section="adminUsers">
        {userTypes.map((userType) => (
          <MenuItem key={userType.id} value={userType.id}>
            {t(`common.userTypes.${userType.name}`)}
          </MenuItem>
        ))}
      </AdminSelectInput>
      {isAdministrativeUserType(props.values.typeId) && (
        <AdminSelectInput t={t} name="roleId" section="adminUsers">
          {roles.map((role) => (
            <MenuItem key={role.id} value={role.id}>
              {getRoleNameElement(t, role)}
            </MenuItem>
          ))}
        </AdminSelectInput>
      )}
      {!isAdministrativeUserType(props.values.typeId) && props.values.roleId && (
        <div style={{ marginTop: 20 }}>
          <InfoBox type="info">{t('pages.adminUsers.windows.rolesAppliedInOrganizations')}</InfoBox>
        </div>
      )}

      {hasPermission(authState.role, ['usersChangeBrandfolderCollection']) && (
        <AdminSelectInput t={t} name="brandfolderCollection" section="adminUsers">
          {[null, ...app.brandfolderCollections].map((collectionName, i) => {
            if (i === 0) {
              return (
                <MenuItem key={'not-set'} value={undefined}>
                  {t(`common.default`)}
                </MenuItem>
              );
            }
            return (
              <MenuItem key={collectionName || 'not-set-2'} value={collectionName || undefined}>
                {collectionName}
              </MenuItem>
            );
          })}
        </AdminSelectInput>
      )}
      {isSystemOwner(authState) && <AdminCheckboxInput t={t} name="systemMaintenanceAllowed" section="adminUsers" />}
    </>
  );

  return (
    <ModalFormWindow
      open={open}
      header={t('pages.adminUsers.windows.updateUser')}
      okButtonText={t('common.update')}
      onCloseClick={onCloseClick}
      onSubmit={onSubmit}
      formInputs={formInputs}
      initialValues={{
        email: user.email,
        firstName: user.firstName,
        lastName: user.lastName,
        roleId: user.roleId,
        typeId: user.typeId,
        externalDataCenter: user.externalDataCenter,
        brandfolderCollection: user.brandfolderCollection,
        systemMaintenanceAllowed: user.systemMaintenanceAllowed
      }}
      validationSchema={Yup.object().shape({
        roleId: Yup.string().required(),
        typeId: Yup.string().required(),
        externalDataCenter: Yup.number().nullable(),
        email: Yup.string().max(app.maxInputLength).email().required(),
        firstName: Yup.string().max(app.maxInputLength).nullable(),
        lastName: Yup.string().max(app.maxInputLength).nullable(),
        brandfolderCollection: Yup.string().max(app.maxInputLength).nullable(),
        systemMaintenanceAllowed: Yup.boolean().nullable()
      })}
    />
  );
};

export default AdminUpdateUserWindow;
