import React, { FunctionComponent } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { Paper, Typography } from '@material-ui/core';
import Story from '../Helpers/DesignerStory';
import { EmailProject } from '../../../../../../../store/EmailProjects/types';
import { DesignerStory } from '../../../../../../../store/PrintProjects/types';

type DesignerStoriesProps = {
  project: EmailProject | null;
  stories: DesignerStory[];
};

const DesignerStories: FunctionComponent<DesignerStoriesProps> = ({ project, stories }) => {
  const { t } = useTranslation();

  if (!project) {
    return null;
  }

  return (
    <Paper>
      <Stories>
        {!project.source && <NoStories>{t('pages.dashboard.projectBuiltFromScratch')}</NoStories>}

        {project.source && stories.length === 0 && <NoStories>{t('pages.dashboard.noStories')}</NoStories>}
        {project.source && stories.length > 0 && (
          <>
            <SectionLabel variant="h6">{t('common.articles')}</SectionLabel>
            <StoriesList>
              {stories.map((story, i) => (
                <Story key={`${story.name}_${i}`} story={story} />
              ))}
            </StoriesList>
          </>
        )}
      </Stories>
    </Paper>
  );
};

const SectionLabel = styled(Typography)`
  padding: 0.5rem 0 0 1rem;
`;

const NoStories = styled.div`
  min-height: 300px;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 2rem;
  text-align: center;
`;

const Stories = styled.div`
  min-height: 300px;
`;

const StoriesList = styled.div``;

export default DesignerStories;
