import React from 'react';
import { StoryChannelAttachment, StoryChannelType } from '../../../../../store/Stories/types';
import { Tooltip } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { isVideo } from '../../Utils/storyUtils';
import { appActions } from '../../../../../store/App';
import { useDispatch } from 'react-redux';

export type AttachmentThumbnailProps = {
  attachment: StoryChannelAttachment;
  channelType?: StoryChannelType;
};

export const AttachmentThumbnail: React.FC<AttachmentThumbnailProps> = ({ attachment, channelType }) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  return (
    <Tooltip
      title={channelType !== undefined ? t(`pages.story.channels.${channelType}`).toString() : ''}
      placement="top"
      arrow
    >
      <img
        onClick={() => {
          if (isVideo(attachment.filename)) {
            dispatch(appActions.openVideoPreviewModal(attachment.url));
          } else {
            window.open(attachment.url);
          }
        }}
        style={{
          maxWidth: '50px',
          maxHeight: '50px',
          width: '50px',
          height: '50px',
          objectFit: 'contain',
          border: '1px solid #ccc',
          marginRight: '15px',
          cursor: 'pointer'
        }}
        alt={attachment.filename}
        src={attachment.thumbnailUrl}
      />
    </Tooltip>
  );
};
