import React, { FunctionComponent } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { Paper, Typography } from '@material-ui/core';
import { Story as StoryType } from '../../../../../../../store/Stories/types';
import Story from '../Helpers/Story';

type StoryListProps = {
  storyList: StoryType[];
};

const StoryList: FunctionComponent<StoryListProps> = ({ storyList }) => {
  const { t } = useTranslation();

  return (
    <Paper>
      <Stories>
        {storyList.length === 0 && <NoStories>{t('pages.story.noStories')}</NoStories>}
        {storyList.length > 0 && (
          <>
            <SectionLabel variant="h6">{t('pages.story.storyList')}</SectionLabel>
            <StoriesList>
              {storyList.map((story) => story.channels.map((channel) => <Story key={channel.id} story={channel} />))}
            </StoriesList>
          </>
        )}
      </Stories>
    </Paper>
  );
};

const SectionLabel = styled(Typography)`
  padding: 0.5rem 0 0 1rem;
`;

const NoStories = styled.div`
  min-height: 300px;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 2rem;
  text-align: center;
`;

const Stories = styled.div`
  min-height: 300px;
`;

const StoriesList = styled.div``;

export default StoryList;
