import React from 'react';
import { useDrop } from 'react-dnd';
import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';

const style: React.CSSProperties = {
  width: '700px',
  height: '150px',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  flexDirection: 'column',
  cursor: 'pointer',
  borderTop: '2px dashed #d5d5d5'
};

export interface AddRowProps {
  accept: string[];
  onDrop: (item: any) => void;
  onClick: () => void;
}

export const AddRowDropzone: React.FC<AddRowProps> = ({ accept, onDrop, onClick }) => {
  const [{ canDrop, isOver }, drop] = useDrop({
    accept,
    drop: onDrop,
    collect: (monitor) => ({
      isOver: monitor.isOver(),
      canDrop: monitor.canDrop()
    })
  });

  const isActive = canDrop && isOver;
  let backgroundColor = 'transparent';
  let border = 'none';
  if (isActive) {
    backgroundColor = '#edf2fe';
    border = '2px dashed #9391c4';
  } else if (canDrop) {
    backgroundColor = '#f8fbfc';
    border = '2px dashed #9391c4';
  }

  return (
    <div ref={drop} style={{ ...style, backgroundColor, border }} onClick={onClick}>
      {/* {isActive ? 'Release to drop' : 'Drag a box here'} */}
      <AddCircleOutlineIcon fontSize="large" color="secondary" />
      Click here or drag and drop structure element
    </div>
  );
};
