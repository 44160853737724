import React, { FunctionComponent, useCallback, useEffect, useRef, useState } from 'react';
import { FullSizePageContainer, ToolSpacer } from '../../PageStyledComponents';
import * as qs from 'query-string';
import { RouteComponentProps } from 'react-router';
import Toast from '../../../Shared/Toast/Toast';
import { useTranslation } from 'react-i18next';
import PageHeader from '../../../Shared/Layout/PageHeader';
import KeyboardArrowLeftIcon from '@material-ui/icons/KeyboardArrowLeft';
import { useDispatch } from 'react-redux';
import Loader from '../../../Shared/Loading/Loader';
import styled from 'styled-components';
import {
  closeFullscreen,
  createNetworkErrorObject,
  openFullscreen,
  userLatestActivity,
  useTypedSelector
} from '../../../../utils';
import IconButton from '@material-ui/core/IconButton';
import Tooltip from '@material-ui/core/Tooltip';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import UndoIcon from '@material-ui/icons/Undo';
import RedoIcon from '@material-ui/icons/Redo';
import FirstPageIcon from '@material-ui/icons/FirstPage';
import LastPageIcon from '@material-ui/icons/LastPage';
import NavigateBeforeIcon from '@material-ui/icons/NavigateBefore';
import NavigateNextIcon from '@material-ui/icons/NavigateNext';
import ZoomOutIcon from '@material-ui/icons/ZoomOut';
import ZoomInIcon from '@material-ui/icons/ZoomIn';
import YoutubeSearchedForIcon from '@material-ui/icons/YoutubeSearchedFor';
import SettingsIcon from '@material-ui/icons/Settings';
import VisibilityOutlinedIcon from '@material-ui/icons/VisibilityOutlined';
import SaveIcon from '@material-ui/icons/Save';
import FullscreenExitIcon from '@material-ui/icons/FullscreenExit';
import FullscreenIcon from '@material-ui/icons/Fullscreen';
import useOpenHandler from '../../../../hooks/useOpenHandler';
import SaveBeforeExitWindow from '../Windows/SaveBeforeExitWindow';
import CommentOutlinedIcon from '@material-ui/icons/CommentOutlined';
import { Fade, Popper, useMediaQuery, useTheme, withTheme } from '@material-ui/core';
import ProjectNotes from '../Common/ProjectNotes';
import DesignerSettingsMenu from './DesignerSettingsMenu';
import { DesignerSettings } from './types';
import { printProjectsOperations } from '../../../../store/PrintProjects';
import { PrintProject } from '../../../../store/PrintProjects/types';
import { hasPermission } from '../../../../utils/permissions';
import { connectToProjectChannel } from '../../../../utils/webSocket';
import nes from '@hapi/nes/lib/client';
import ProjectAlreadyCheckedIn from '../Windows/ProjectAlreadyCheckedIn';
import ForcingCheckOutNotificationWindow from '../Windows/ForcingCheckOutNotificationWindow';
import { push } from 'connected-react-router';
import linksConstants from '../../../../config/app/linksConstants';
import { v4 } from 'uuid';
import { appActions } from '../../../../store/App';
import InputChangeOnBlur from '../../../Shared/Forms/InputChangeOnBlur';
import { DamSystemName } from '../../../../store/SystemSettings/types';
import { myOrganizationOperations } from '../../../../store/MyOrganization';

type BulletinEditorPageProps = RouteComponentProps<{ projectId: string }> & {};

let generatedTime = new Date().getTime();

const BulletinEditorPage: FunctionComponent<BulletinEditorPageProps> = ({ match }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const designerAccessToken = useTypedSelector((state) => state.auth.designerAt);
  const webAppUrl = useTypedSelector((state) => state.auth.webAppUrl);
  const relativeNucleusPath = useTypedSelector((state) => state.auth.relativeNucleusPath);
  const designerUrl = useTypedSelector((state) => state.auth.designerUrl);
  const designerBackendUrl = useTypedSelector((state) => state.auth.designerBackendUrl);
  const [loading, setLoading] = useState(true);
  const damStatus = useRef<{ damActive: boolean; system: DamSystemName | null }>({
    damActive: false,
    system: null
  });
  const designerVersionFromIframeAlreadySaved = useRef<boolean>(false);
  const [designerProjectLoaded, setDesignerProjectLoaded] = useState(false);
  const [saving, setSaving] = useState(false);
  const [previewing, setPreviewing] = useState(false);
  const [previewUrl, setPreviewUrl] = useState<string | null>(null);
  const [fullscreen, setFullscreen] = useState(false);
  const [projectAlreadyCheckedInErrorMessage, setProjectAlreadyCheckedInErrorMessage] = useState('');
  const projectData = useRef<PrintProject | null>(null);
  const [pagination, setPagination] = useState<{ current: number; total: number }>({
    current: 0,
    total: 0
  });
  const [paginationInputState, setPaginationInputState] = useState<string>('');
  const paginationInput = useRef<HTMLInputElement | null>(null);
  const paginationFocused = useRef<boolean>(false);
  const [pageLoading, setPageLoading] = useState<boolean>(true);
  const savedString = useRef<string>('');
  const [saveBeforeExitWindowOpen, onSaveBeforeExitWindowOpen, onSaveBeforeExitWindowClose] = useOpenHandler();
  const [
    projectAlreadyCheckedInWindowOpen,
    onProjectAlreadyCheckedInWindowOpen,
    onProjectAlreadyCheckedInWindowClose
  ] = useOpenHandler();
  const [notesAnchorEl, setNotesAnchorEl] = useState(null);
  const [settingsAnchorEl, setSettingsAnchorEl] = useState(null);
  const [designerSettings, setDesignerSettings] = useState<DesignerSettings>({
    snapToGrid: false,
    snapToGuides: false,
    snapToMargins: false,
    snapToColumns: false,
    showGrid: false,
    showMargins: true,
    showColumns: false,
    showGuides: false
  });
  const authData = useTypedSelector((state) => state.auth);
  const { role, user } = authData;

  const projectId = match.params.projectId;

  let getDesignerAppStateInterval: any = null;

  const theme = useTheme();
  const isScreenLarge = useMediaQuery(theme.breakpoints.up('lg'));

  const onBeforeUnloadHandler = (e: any) => {
    if (savedString.current) {
      e.preventDefault();
      e.returnValue = '';
    }
  };
  const onKeyDownHandler = (event: KeyboardEvent) => {
    if ((event.ctrlKey || event.metaKey) && event.code == 'KeyV') {
      event.stopImmediatePropagation();
      event.stopPropagation();
      event.preventDefault();
      sendIframeMessage('paste');
    }
  };

  useEffect(() => {
    generatedTime = new Date().getTime();

    let webSocketClient: nes.Client | null = null;
    window.addEventListener('message', onIframeMessage, false);
    window.addEventListener('beforeunload', onBeforeUnloadHandler);
    window.addEventListener('keydown', onKeyDownHandler);

    getDesignerAppStateInterval = setInterval(() => sendIframeMessage('getState'), 2000);

    const fetchData = async () => {
      try {
        projectData.current = await printProjectsOperations.show(projectId);
      } catch (e) {
        Toast.error(
          t('notifications.newsletterProjectFetch.error', { rawError: `(${createNetworkErrorObject(e).message})` })
        );
      } finally {
        setLoading(false);
      }

      try {
        damStatus.current = await myOrganizationOperations.getDamStatus();
      } catch (e) {
        console.warn('Error occurred when trying to fetch DAM status', e);
      }

      try {
        await printProjectsOperations.checkIn(projectId, v4());
        webSocketClient = await connectToProjectChannel(projectId, authData.token, dispatch);
      } catch (e) {
        const error = createNetworkErrorObject(e);
        setProjectAlreadyCheckedInErrorMessage(error.message || '');
        onProjectAlreadyCheckedInWindowOpen();
        return;
      }
    };
    fetchData();

    return () => {
      dispatch(appActions.setDesignerVersionFromIframe(''));

      if (getDesignerAppStateInterval) {
        clearInterval(getDesignerAppStateInterval);
      }
      window.removeEventListener('message', onIframeMessage, false);
      window.removeEventListener('beforeunload', onBeforeUnloadHandler);
      window.removeEventListener('keydown', onKeyDownHandler);
      let unsubscribeTimeout: number | undefined;
      let runs = 0;
      const maxRuns = 10;

      const unsubscribeFromWebSocket = () => {
        clearTimeout(unsubscribeTimeout);

        unsubscribeTimeout = setTimeout(() => {
          if (webSocketClient) {
            webSocketClient.disconnect();
            return;
          }
          if (runs++ >= maxRuns) {
            return;
          }

          unsubscribeFromWebSocket();
        }, 2000);
      };

      if (webSocketClient) {
        webSocketClient.disconnect();
      } else {
        unsubscribeFromWebSocket();
      }
    };
  }, []);

  const onIframeMessage = (e: any) => {
    if (e && e.data && e.data.command) {
      switch (e.data.command) {
        case 'onChange': {
          userLatestActivity.set();
          break;
        }

        case 'projectLoaded': {
          setDesignerProjectLoaded(true);
          if (e.data.data && e.data.data.settings) {
            setDesignerSettings({
              snapToGrid: e.data.data.settings.snapToGrid,
              snapToGuides: e.data.data.settings.snapToGuides,
              snapToMargins: e.data.data.settings.snapToMargins,
              snapToColumns: e.data.data.settings.snapToColumns,
              showGrid: e.data.data.settings.showGrid,
              showMargins: e.data.data.settings.showMargins,
              showColumns: e.data.data.settings.showColumns,
              showGuides: e.data.data.settings.showGuides
            });
          }
          break;
        }

        case 'pageLoaded': {
          setTimeout(() => {
            setPageLoading(false);
          }, 800);
          break;
        }

        case 'designerPreviewError': {
          setPreviewing(false);
          setPreviewUrl(null);
          setSaving(false);
          break;
        }

        case 'getStateResponse': {
          if (!paginationFocused.current) {
            setPagination(e.data.data.pagination);
            setPaginationInputState(e.data.data.pagination.current.toString());
          }
          savedString.current = e.data.data.saved;
          if (!designerVersionFromIframeAlreadySaved.current && e.data.data.version) {
            dispatch(appActions.setDesignerVersionFromIframe(e.data.data.version || ''));
            designerVersionFromIframeAlreadySaved.current = true;
          }

          break;
        }

        case 'onSaved': {
          setSaving(false);
          break;
        }

        case 'onPreview': {
          setPreviewing(false);
          const jobPayload = e.data.data;

          if (projectData.current && jobPayload && jobPayload.id) {
            setPreviewUrl(
              `${designerBackendUrl}/api/sd/v1/jobs/${jobPayload.id}/raw?access_token=${projectData.current.hash}&spi-token=${designerAccessToken}`
            );
          }
          break;
        }
      }
    }
  };

  const backButton = {
    onClick: () => {
      if (savedString.current.length > 0) {
        onSaveBeforeExitWindowOpen();
      } else {
        dispatch(push(linksConstants.DASHBOARD.INDEX));
      }
    },
    label: t('navigation.dashboard'),
    icon: <KeyboardArrowLeftIcon />
  };

  const sendIframeMessage = (
    message:
      | 'openMarginsSettings'
      | 'changeSetting'
      | 'preview'
      | 'save'
      | 'undo'
      | 'redo'
      | 'paste'
      | 'zoomIn'
      | 'zoomOut'
      | 'zoomReset'
      | 'firstPage'
      | 'lastPage'
      | 'previousPage'
      | 'nextPage'
      | 'setCurrentPage'
      | 'getState',
    setting?: keyof DesignerSettings | number
  ) => {
    const iframe: HTMLIFrameElement | null = document.getElementById('designer-iframe') as HTMLIFrameElement;
    if (iframe && iframe.contentWindow) {
      iframe.contentWindow.postMessage({ command: message, setting }, '*');
    }
  };
  const openMarginsSettings = () => {
    sendIframeMessage('openMarginsSettings');
  };

  const changeSetting = (setting: keyof DesignerSettings) => {
    sendIframeMessage('changeSetting', setting);
  };

  const previewProject = () => {
    setSaving(true);
    setPreviewing(true);
    sendIframeMessage('preview');
  };

  const saveProject = () => {
    setSaving(true);
    printProjectsOperations.saveContent(projectId);
    sendIframeMessage('save');
  };

  const undoBtn = () => {
    sendIframeMessage('undo');
  };

  const redoBtn = () => {
    sendIframeMessage('redo');
  };

  const zoomInBtn = () => {
    sendIframeMessage('zoomIn');
  };

  const zoomOutBtn = () => {
    sendIframeMessage('zoomOut');
  };

  const zoomResetBtn = () => {
    sendIframeMessage('zoomReset');
  };

  const navigationButtonAction = (type: 'firstPage' | 'lastPage' | 'previousPage' | 'nextPage') => {
    setPageLoading(true);
    setTimeout(() => {
      sendIframeMessage(type);
    }, 50);
  };

  const toggleFullscreen = () => {
    if (fullscreen) {
      closeFullscreen();
      setFullscreen(false);
    } else {
      openFullscreen();
      setFullscreen(true);
    }
  };
  const openSettingMenu = (event: any) => {
    setSettingsAnchorEl(settingsAnchorEl ? null : event.currentTarget);
  };

  const openNotes = (event: any) => {
    setNotesAnchorEl(notesAnchorEl ? null : event.currentTarget);
  };

  const saveProjectName = async (name: string) => {
    if (name && projectData.current) {
      projectData.current = {
        ...projectData.current,
        name
      };
      try {
        await printProjectsOperations.savePrintProjectName(projectData.current, name);
        Toast.success(t('notifications.projectNameChange.success'));
      } catch (e) {
        Toast.error(t('notifications.projectNameChange.error'));
      }
    }
  };

  const jumpToSelectedPage = useCallback(() => {
    const val = parseInt(paginationInputState);

    if (val && !isNaN(val) && val <= pagination.total && val !== pagination.current) {
      sendIframeMessage('setCurrentPage', val);
      setPagination({
        total: pagination.total,
        current: val
      });
    } else {
      setPaginationInputState(pagination.current.toString());
    }
  }, [paginationInputState, pagination, paginationFocused]);

  const buttonSize = isScreenLarge ? ('medium' as const) : ('small' as const);
  const buttonStyle = isScreenLarge
    ? { marginTop: '0.25rem' }
    : { marginTop: '0.25rem', marginBottom: '0.25rem', marginLeft: '0.5rem' };

  const rightActionButtons = [
    {
      onClick: openNotes,
      label: t('common.notes'),
      icon: <CommentOutlinedIcon />,
      variant: 'outlined' as const,
      id: 'notes',
      disabled: !hasPermission(role, ['projectNotesView']),
      size: buttonSize,
      style: buttonStyle
    },
    {
      onClick: toggleFullscreen,
      label: t('common.fullscreen'),
      icon: fullscreen ? <FullscreenExitIcon /> : <FullscreenIcon />,
      variant: 'outlined' as const,
      id: 'fullscreen',
      size: buttonSize,
      style: buttonStyle
    },
    {
      onClick: previewProject,
      label: previewing ? t('common.generatingPreview') : t('common.preview'),
      icon: <VisibilityOutlinedIcon />,
      variant: 'outlined' as const,
      id: 'preview',
      visible: previewUrl === null,
      disabled:
        !hasPermission(role, ['projectsPrintEdition']) || previewing || !designerProjectLoaded || saving || pageLoading,
      size: buttonSize,
      style: buttonStyle
    },
    {
      onClick: () => {
        if (previewUrl) {
          window.open(previewUrl);
        }
        setPreviewUrl(null);
      },
      label: t('common.previewReady'),
      icon: <VisibilityOutlinedIcon />,
      id: 'preview-ready',
      visible: previewUrl !== null,
      disabled: !hasPermission(role, ['projectsPrintEdition']) || previewing,
      size: buttonSize,
      style: buttonStyle
    },
    {
      onClick: saveProject,
      label: t('common.save'),
      icon: <SaveIcon />,
      id: 'save',
      disabled: !hasPermission(role, ['projectsPrintEdition']) || saving || !designerProjectLoaded || pageLoading,
      size: buttonSize,
      style: buttonStyle
    }
  ];

  if (!projectData.current) return null;

  const queryParams = qs.stringify({
    instanceId: projectData.current.externalId,
    userAccessToken: projectData.current.hash,
    at: designerAccessToken,
    be: designerBackendUrl,
    wau: webAppUrl,
    rnp: relativeNucleusPath,
    t: generatedTime,
    publicationDate: projectData.current.publishDate,
    dam: damStatus.current.damActive ? 1 : 0
  });

  return (
    <FullSizePageContainer>
      <PageHeader
        style={{ height: '91px' }}
        variant="white"
        title={
          projectData.current ? (
            <Grid container alignItems="center">
              <InputChangeOnBlur initialValue={projectData.current.name} onSave={saveProjectName} />
              <ToolSpacer />
              <div style={{ display: 'flex', alignItems: 'center' }}>
                <Tooltip title={`${t('common.undo')}`}>
                  <span>
                    <IconButton size="small" onClick={undoBtn} disabled={saving || pageLoading}>
                      <UndoIcon fontSize="small" />
                    </IconButton>
                  </span>
                </Tooltip>
                <Tooltip title={`${t('common.redo')}`}>
                  <span>
                    <IconButton size="small" onClick={redoBtn} disabled={saving || pageLoading}>
                      <RedoIcon fontSize="small" />
                    </IconButton>
                  </span>
                </Tooltip>
                <ToolSpacer />
                <Tooltip title={`${t('common.firstPage')}`}>
                  <span>
                    <IconButton
                      size="small"
                      onClick={() => navigationButtonAction('firstPage')}
                      disabled={saving || pageLoading || pagination.current <= 1}
                    >
                      <FirstPageIcon fontSize="small" />
                    </IconButton>
                  </span>
                </Tooltip>
                <Tooltip title={`${t('common.previousPage')}`}>
                  <span>
                    <IconButton
                      size="small"
                      onClick={() => navigationButtonAction('previousPage')}
                      disabled={saving || pageLoading || pagination.current <= 1}
                    >
                      <NavigateBeforeIcon fontSize="small" />
                    </IconButton>
                  </span>
                </Tooltip>
                <Typography variant="subtitle2" style={{ marginTop: 3 }}>
                  <CurrentPageInput
                    type="text"
                    ref={paginationInput}
                    disabled={saving}
                    value={paginationInputState}
                    onBlur={() => {
                      jumpToSelectedPage();
                      setTimeout(() => {
                        paginationFocused.current = false;
                        setPageLoading(false);
                      }, 1000);
                    }}
                    onFocus={() => {
                      paginationFocused.current = true;
                      setPageLoading(true);
                    }}
                    onKeyDown={(e: React.KeyboardEvent<HTMLInputElement>) => {
                      if (e.key === 'Enter' && paginationInput.current) {
                        jumpToSelectedPage();
                      }
                    }}
                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => setPaginationInputState(e.target.value)}
                  />{' '}
                  of {pagination.total}
                </Typography>
                <Tooltip title={`${t('common.nextPage')}`}>
                  <span>
                    <IconButton
                      size="small"
                      onClick={() => navigationButtonAction('nextPage')}
                      disabled={saving || pageLoading || pagination.current >= pagination.total}
                    >
                      <NavigateNextIcon fontSize="small" />
                    </IconButton>
                  </span>
                </Tooltip>
                <Tooltip title={`${t('common.lastPage')}`}>
                  <span>
                    <IconButton
                      size="small"
                      onClick={() => navigationButtonAction('lastPage')}
                      disabled={saving || pageLoading || pagination.current >= pagination.total}
                    >
                      <LastPageIcon fontSize="small" />
                    </IconButton>
                  </span>
                </Tooltip>
                <ToolSpacer />
                <IconButton size="small" onClick={zoomOutBtn} disabled={saving || pageLoading}>
                  <ZoomOutIcon fontSize="small" />
                </IconButton>
                <IconButton size="small" onClick={zoomInBtn} disabled={saving || pageLoading}>
                  <ZoomInIcon fontSize="small" />
                </IconButton>
                <IconButton size="small" onClick={zoomResetBtn} disabled={saving || pageLoading}>
                  <YoutubeSearchedForIcon fontSize="small" />
                </IconButton>
                <ToolSpacer />
                <Tooltip title={`${t('designerSettings.openSettings')}`}>
                  <span>
                    <IconButton size="small" onClick={openSettingMenu} disabled={saving || pageLoading}>
                      <SettingsIcon fontSize="small" />
                    </IconButton>
                  </span>
                </Tooltip>
              </div>
              <Typography variant="subtitle2" style={{ marginLeft: 20 }}>
                {savedString.current}
              </Typography>
            </Grid>
          ) : null
        }
        leftActionButtons={[backButton]}
        rightActionButtons={rightActionButtons}
      />
      {loading && <Loader />}
      {!loading && !projectAlreadyCheckedInWindowOpen && (
        <Designer id="designer-iframe" allow="clipboard-write" src={`${designerUrl}?${queryParams}`} />
      )}
      <SaveBeforeExitWindow
        open={saveBeforeExitWindowOpen}
        onCloseClick={onSaveBeforeExitWindowClose}
        fullScreenOnMobile
      />
      <NotesPopper open={Boolean(notesAnchorEl)} anchorEl={notesAnchorEl} transition disablePortal={false}>
        {({ TransitionProps }) => (
          <Fade {...TransitionProps}>
            <ProjectNotes
              onClose={() => setNotesAnchorEl(null)}
              project={projectData.current}
              type="print"
              role={role}
              user={user}
            />
          </Fade>
        )}
      </NotesPopper>
      <SettingsPopper open={Boolean(settingsAnchorEl)} anchorEl={settingsAnchorEl} transition disablePortal={false}>
        {({ TransitionProps }) => (
          <Fade {...TransitionProps}>
            <DesignerSettingsMenu
              onOpenMarginsSettings={openMarginsSettings}
              onChange={(setting, value) => {
                setDesignerSettings({
                  ...designerSettings,
                  [setting]: value
                });

                changeSetting(setting);
              }}
              settings={designerSettings}
              onClose={() => setSettingsAnchorEl(null)}
            />
          </Fade>
        )}
      </SettingsPopper>
      <ProjectAlreadyCheckedIn
        open={projectAlreadyCheckedInWindowOpen}
        onCloseClick={onProjectAlreadyCheckedInWindowClose}
        errorMessage={projectAlreadyCheckedInErrorMessage}
        fullScreenOnMobile
      />
      <ForcingCheckOutNotificationWindow activeProject={projectData.current} />
    </FullSizePageContainer>
  );
};

const NotesPopper = styled(Popper)`
  z-index: 1290;
`;

const SettingsPopper = styled(Popper)`
  z-index: 1290;
`;

const Designer = styled.iframe`
  width: 100%;
  height: calc(100% - 91px);
`;

const CurrentPageInput = withTheme(styled.input`
  width: 35px;
  text-align: center;
  padding: 0;
  margin: 0;
  border-width: 0 0 1px 0;
  border-style: solid;
  border-color: ${({ theme }) => theme.palette.primary.main};
`);

export default BulletinEditorPage;
