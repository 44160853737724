import React, { FunctionComponent } from 'react';
import { ArticleHorizontal, ArticleVertical, ImageData, ImageDropObjectData } from '../../types';
import { ArticleMainWrapper, LoaderWrapper } from './ArticleHorizontal';
import ReadMoreConfigPopover from './Utils/ReadMoreConfigPopover';
import { useArticle } from './Utils/useArticle';
import { getBodyElement, getHeadlineElement, getImageElement, getReadMoreLinkElement } from './Utils/articleElements';
import { ImageProcessingResponse } from '../../../../../../store/Images/types';
import Loader from '../../../../../Shared/Loading/Loader';
import { MyOrganizationSwatchColor } from '../../../../../../store/MyOrganization/types';
import { extractColorsFromSwatches, getArticleColorSwatchTypes } from '../../Utils';
import { AdminOrganization } from '../../../../../../store/AdminOrganizations/types';
import { ReactComponent as SettingsIcon } from '../../../../../../assets/icons/settings.svg';
import { ComponentActionIcons, StyledEditIcon } from '../../../../../Shared/StyledComponents';
import useOpenHandler from '../../../../../../hooks/useOpenHandler';
import ArticleVerticalSettingsWindow from '../Windows/ArticleVerticalSettingsWindow';

type ArticleVerticalProps = {
  data: ArticleVertical;
  sourceProjectId: string | null;
  organization: AdminOrganization | null;
  onChange: (data: ArticleHorizontal | ArticleVertical) => void;
  onImageDrop: (data: ImageDropObjectData, imageData: ImageData) => Promise<ImageProcessingResponse | null>;
  selectedColorSwatches: { [key: string]: MyOrganizationSwatchColor };
};

const ArticleVerticalComponent: FunctionComponent<ArticleVerticalProps> = ({
  data,
  sourceProjectId,
  organization,
  onChange,
  onImageDrop,
  selectedColorSwatches
}) => {
  const {
    mainElement,
    showImage,
    setShowImage,

    showReadMore,
    setShowReadMore,

    customReadMore,
    setCustomReadMore,

    customReadMoreText,
    setCustomReadMoreText,

    customReadMoreLink,
    setCustomReadMoreLink,

    drop,
    isDraggingOver,
    applyingStory
  } = useArticle(data, onChange, onImageDrop, sourceProjectId, organization);
  const [
    articleVerticalSettingsWindowOpen,
    onArticleVerticalSettingsWindowOpen,
    onArticleVerticalSettingsWindowClose
  ] = useOpenHandler();

  return (
    <ArticleMainWrapper
      ref={(node) => {
        mainElement.current = node;
        drop(node);
      }}
      style={{
        ...data.wrapperStyle,
        ...extractColorsFromSwatches(selectedColorSwatches, getArticleColorSwatchTypes(data))
      }}
      className={isDraggingOver ? 'article-drag-over' : ''}
    >
      {data.params.imagePosition !== 'top' && data.params.imagePosition && (
        <div style={data.articleWrapperStyle}>{getHeadlineElement(data, onChange, selectedColorSwatches)}</div>
      )}
      {(!data.params.imagePosition || data.params.imagePosition !== 'bottom') && (
        <div
          style={{
            ...(data.imageWrapperStyle ? data.imageWrapperStyle : {}),
            flexShrink: 0
          }}
        >
          {getImageElement(data, onChange, onImageDrop, applyingStory)}
        </div>
      )}
      <div style={data.articleWrapperStyle}>
        {(data.params.imagePosition === 'top' || !data.params.imagePosition) &&
          getHeadlineElement(data, onChange, selectedColorSwatches)}
        {getBodyElement(data, onChange, selectedColorSwatches)}
        {getReadMoreLinkElement(data, selectedColorSwatches)}
      </div>
      {data.params.imagePosition === 'bottom' && (
        <div
          style={{
            ...(data.imageWrapperStyle ? data.imageWrapperStyle : {}),
            flexShrink: 0
          }}
        >
          {getImageElement(data, onChange, onImageDrop, applyingStory)}
        </div>
      )}
      <ReadMoreConfigPopover
        showImage={showImage}
        setShowImage={setShowImage}
        showReadMore={showReadMore}
        setShowReadMore={setShowReadMore}
        customReadMore={customReadMore}
        setCustomReadMore={setCustomReadMore}
        customReadMoreText={customReadMoreText}
        setCustomReadMoreText={setCustomReadMoreText}
        customReadMoreLink={customReadMoreLink}
        setCustomReadMoreLink={setCustomReadMoreLink}
      />
      {applyingStory && (
        <LoaderWrapper>
          <Loader />
        </LoaderWrapper>
      )}
      <ComponentActionIcons style={{ left: 'calc(100% - 45px)' }}>
        <StyledEditIcon onClick={() => onArticleVerticalSettingsWindowOpen()}>
          <SettingsIcon style={{ width: 22, fill: '#fff' }} />
        </StyledEditIcon>
      </ComponentActionIcons>

      {articleVerticalSettingsWindowOpen && (
        <ArticleVerticalSettingsWindow
          open={articleVerticalSettingsWindowOpen}
          onCloseClick={onArticleVerticalSettingsWindowClose}
          onFormSubmit={(imagePosition) => {
            onChange({
              ...data,
              params: {
                ...data.params,
                imagePosition
              }
            });
            onArticleVerticalSettingsWindowClose();
          }}
          imagePosition={data.params.imagePosition || 'top'}
        />
      )}
    </ArticleMainWrapper>
  );
};

export default ArticleVerticalComponent;
