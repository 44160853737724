import React, { FunctionComponent, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { myOrganizationOperations } from '../../../../store/MyOrganization';
import { useTypedSelector } from '../../../../utils';
import { Story, StoryContentContributor } from '../../../../store/Stories/types';
import {
  Avatar,
  Chip,
  Divider,
  Grid,
  List,
  ListItem,
  ListItemAvatar,
  ListItemSecondaryAction,
  ListItemText,
  TextField,
  Tooltip,
  Typography
} from '@material-ui/core';
import IconButton from '@material-ui/core/IconButton';
import ClearIcon from '@material-ui/icons/Clear';
import Loader from '../../../Shared/Loading/Loader';
import SearchIcon from '@material-ui/icons/Search';
import { Pagination } from '@material-ui/lab';
import QuickInviteContributorWindow from '../../Stories/Windows/QuickInviteContributorWindow';
import useOpenHandler from '../../../../hooks/useOpenHandler';
import { RegularTitle } from '../../PageStyledComponents';
import DeleteIcon from '@material-ui/icons/Delete';
import DeleteMyOrganizationUserWindow from '../Windows/DeleteMyOrganizationUserWindow';
import { MyOrganizationUser } from '../../../../store/MyOrganization/types';
import { hasPermission } from '../../../../utils/permissions';

type OrganizationContributorsListProps = {
  display: 'list' | 'grid';
  clickable?: boolean;
  reFetchRequired?: boolean;
  onReFetchCompleted?: () => void;
  story?: Story;
  subtitle?: JSX.Element;
  title: JSX.Element;
};

const OrganizationContributorsList: FunctionComponent<OrganizationContributorsListProps> = ({
  display,
  reFetchRequired,
  clickable,
  onReFetchCompleted,
  story,
  title,
  subtitle
}) => {
  const { t } = useTranslation();
  const currentOrganizationId = useTypedSelector((state) => state.auth.organizationId);
  const role = useTypedSelector((state) => state.auth.role);
  const [fetchError, setFetchError] = useState<boolean>(false);
  const [searchValue, setSearchValue] = useState<string>('');
  const [lastSearchValue, setLastSearchValue] = useState<string>('');
  const [loading, setLoading] = useState<boolean>(true);
  const [contributors, setContributors] = useState<StoryContentContributor[]>([]);
  const [activeContributor, setActiveContributor] = useState<StoryContentContributor | null>(null);
  const [pagination, setPagination] = useState<Pagination>({ page: 1, per: 10, q: '', total: 0, totalPages: 0 });
  const [
    quickInviteContributorWindowOpen,
    onQuickInviteContributorWindowOpen,
    onQuickInviteContributorWindowClose
  ] = useOpenHandler();

  const [
    deleteOrganizationContributorWindowOpen,
    onDeleteOrganizationContributorWindowOpen,
    onDeleteOrganizationContributorWindowClose
  ] = useOpenHandler();

  const fetchData = async (page: number, search: string) => {
    if (currentOrganizationId) {
      setFetchError(false);
      try {
        const contributors = story
          ? await myOrganizationOperations.indexOrganizationContributorsForStory(
              currentOrganizationId,
              story.id,
              page,
              search
            )
          : await myOrganizationOperations.indexOrganizationContributors(currentOrganizationId, page, search);
        setContributors(contributors.data);
        setPagination(contributors.pagination);
        setLastSearchValue(search);
      } catch (e) {
        setFetchError(true);
      } finally {
        setLoading(false);
      }
    }
  };

  useEffect(() => {
    const doReFetch = async () => {
      await fetchData(pagination.page, searchValue);
      if (onReFetchCompleted) {
        onReFetchCompleted();
      }
    };
    if (reFetchRequired) {
      doReFetch();
    }
  }, [reFetchRequired]);

  const handleSearch = (page: number, val: string) => {
    fetchData(page, val);
  };

  const handlePaginationChange = (event: React.ChangeEvent<unknown>, page: number, search: string) => {
    fetchData(page, search);
  };

  useEffect(() => {
    fetchData(1, '');
  }, []);

  useEffect(() => {
    const onEnter = (e: any) => {
      if (e.key === 'Enter') {
        handleSearch(1, searchValue);
      }
    };
    document.addEventListener('keydown', onEnter);
    return () => {
      document.removeEventListener('keydown', onEnter);
    };
  }, [searchValue]);

  const getInitials = (contributor: StoryContentContributor) => {
    return `${(contributor.firstName || '').charAt(0)}${(contributor.lastName || '').charAt(0)}`.toUpperCase();
  };

  const getAvatar = (contributor: StoryContentContributor) => {
    return <Avatar alt={`${contributor.firstName} ${contributor.lastName}`}>{getInitials(contributor)}</Avatar>;
  };

  const getFetchError = () => {
    return (
      <Typography variant="h6" style={{ textAlign: 'center', marginTop: 20 }}>
        {t('pages.myOrganization.contributors.fetchError')}
      </Typography>
    );
  };

  const onContributorClick = (contributor: StoryContentContributor) => {
    setActiveContributor(contributor);
    onQuickInviteContributorWindowOpen();
  };

  const getSearchField = () => {
    return (
      <div style={{ display: 'flex' }}>
        <TextField
          variant="outlined"
          size="small"
          fullWidth
          placeholder={`${t('common.search')}...`}
          value={searchValue}
          onChange={(e) => setSearchValue(e.target.value)}
          InputProps={{
            endAdornment: searchValue !== '' && (
              <IconButton
                color={'primary'}
                size="small"
                onClick={() => {
                  setSearchValue('');
                  handleSearch(1, '');
                }}
              >
                <ClearIcon />
              </IconButton>
            )
          }}
        />
        <IconButton color={'secondary'} size="medium" onClick={() => handleSearch(1, searchValue)}>
          <SearchIcon />
        </IconButton>
      </div>
    );
  };

  const getTitle = () => {
    return (
      <>
        <Typography variant="h6">{title}</Typography>
        <Typography variant="caption" gutterBottom style={{ fontStyle: 'italic' }}>
          {subtitle}
        </Typography>
      </>
    );
  };

  const getPagination = () => {
    return (
      <Pagination
        count={pagination.totalPages}
        page={pagination.page}
        shape="rounded"
        onChange={(e, val) => handlePaginationChange(e, val, searchValue)}
        size="small"
        style={{ marginTop: 'auto' }}
      />
    );
  };

  const renderList = () => {
    return (
      <>
        {getTitle()}
        <hr style={{ marginBottom: '1.0rem', opacity: 0.1 }} />
        {loading && <Loader />}
        {getSearchField()}

        {fetchError && getFetchError()}
        <List>
          {contributors.map((contributor) => (
            <React.Fragment key={contributor.id}>
              <ListItem
                alignItems="flex-start"
                button={!!clickable as true}
                onClick={() => clickable && onContributorClick(contributor)}
              >
                <ListItemAvatar>{getAvatar(contributor)}</ListItemAvatar>
                <ListItemText
                  primary={contributor.email}
                  secondary={`${contributor.firstName} ${contributor.lastName}`}
                />
                {hasPermission(role, ['organizationsEdit']) && (
                  <ListItemSecondaryAction>
                    <IconButton
                      edge="end"
                      aria-label="delete"
                      onClick={() => {
                        setActiveContributor(contributor);
                        onDeleteOrganizationContributorWindowOpen();
                      }}
                    >
                      <DeleteIcon />
                    </IconButton>
                  </ListItemSecondaryAction>
                )}
              </ListItem>
              <Divider variant="inset" component="li" />
            </React.Fragment>
          ))}
        </List>
        <div style={{ display: 'flex', justifyContent: 'center' }}>{pagination.total > 0 && getPagination()}</div>
        {getQuickInviteWindow()}
        {deleteOrganizationContributorWindowOpen && currentOrganizationId && (
          <DeleteMyOrganizationUserWindow
            type="contributor"
            organizationId={currentOrganizationId}
            organizationUser={activeContributor}
            open={deleteOrganizationContributorWindowOpen}
            onCloseClick={onDeleteOrganizationContributorWindowClose}
            fullScreenOnMobile
            onSuccessfulSubmit={() => fetchData(pagination.page, searchValue)}
          />
        )}
      </>
    );
  };

  const getQuickInviteWindow = () => {
    if (quickInviteContributorWindowOpen && story) {
      return (
        <QuickInviteContributorWindow
          open={quickInviteContributorWindowOpen}
          story={story}
          contributor={activeContributor}
          onCloseClick={onQuickInviteContributorWindowClose}
          fullScreenOnMobile
          onSuccessfulSubmit={() => fetchData(pagination.page, searchValue)}
        />
      );
    }

    return null;
  };

  const renderGrid = () => {
    return (
      <>
        <Grid container>
          <Grid item xs={8} style={{ marginBottom: 10 }}>
            {getTitle()}
          </Grid>
          <Grid item xs={4}>
            {getSearchField()}
          </Grid>
        </Grid>
        {loading && <Loader />}
        {fetchError && getFetchError()}
        {contributors.length === 0 && (
          <RegularTitle style={{ textAlign: 'center' }}>
            {t('pages.myOrganization.contributors.noResults')}
          </RegularTitle>
        )}
        {contributors.map((contributor) => (
          <Tooltip
            key={contributor.id}
            placement="top"
            title={
              <div style={{ textAlign: 'center' }}>
                <p>
                  {contributor.firstName} {contributor.lastName}
                </p>
                <p>{contributor.email}</p>
              </div>
            }
          >
            <Chip
              avatar={getAvatar(contributor)}
              label={contributor.email}
              onClick={() => clickable && onContributorClick(contributor)}
            />
          </Tooltip>
        ))}
        <div style={{ display: 'flex', justifyContent: 'center', marginTop: 10 }}>
          {pagination.total > 0 && getPagination()}
        </div>
        {getQuickInviteWindow()}
      </>
    );
  };

  if (display === 'list') {
    return renderList();
  } else {
    if (!contributors.length && !lastSearchValue) return null;

    return renderGrid();
  }
};

export default OrganizationContributorsList;
