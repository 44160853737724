import { BaseSettings, RequestSettings } from '../../utils/httpRequest';
import { DesignerStory } from '../PrintProjects/types';
import {
  IndexStoriesRequestParams,
  IndexStoryContentContributorsRequestParams,
  InviteContributorValues,
  PublishSocialChannelValues,
  StoryChannelAttachment,
  StoryChannelStatus,
  StoryChannelType,
  StoryFieldName,
  StoryFormData,
  UpdateContributorValues
} from './types';

export const index = (settings: RequestSettings<IndexStoriesRequestParams, {}>): BaseSettings => {
  return {
    query: {
      method: 'get',
      url: `/api/v1/story?per=:per&page=:page`
    },
    params: settings.requestParams
  };
};

export const create = (
  settings: RequestSettings<{}, { name: string; publicationDateFrom: Date; publicationDateTo: Date }>
): BaseSettings => ({
  query: {
    method: 'post',
    url: '/api/v1/story'
  },
  payload: settings.requestPayload
});

export const update = (
  settings: RequestSettings<
    { storyId: string },
    { name: string; publicationDateFrom: string; publicationDateTo: string }
  >
): BaseSettings => ({
  query: {
    method: 'put',
    url: '/api/v1/story/:storyId'
  },
  payload: settings.requestPayload,
  params: settings.requestParams
});

export const show = (settings: RequestSettings<{ storyId: string }, {}>): BaseSettings => ({
  query: {
    method: 'get',
    url: '/api/v1/story/:storyId'
  },
  params: settings.requestParams
});

export const remove = (settings: RequestSettings<{ storyId: string }, {}>): BaseSettings => ({
  query: {
    method: 'delete',
    url: '/api/v1/story/:storyId'
  },
  params: settings.requestParams
});

export const indexStoryContributors = (
  settings: RequestSettings<IndexStoryContentContributorsRequestParams, {}>
): BaseSettings => {
  return {
    query: {
      method: 'get',
      url: `/api/v1/story/:storyId/contributors?per=:per&page=:page`
    },
    params: settings.requestParams
  };
};

export const showStoryContributor = (
  settings: RequestSettings<{ storyId: string; userId: string }, {}>
): BaseSettings => ({
  query: {
    method: 'get',
    url: '/api/v1/story/:storyId/contributors/:userId'
  },
  params: settings.requestParams
});

export const inviteContentContributor = (
  settings: RequestSettings<{ storyId: string }, InviteContributorValues>
): BaseSettings => ({
  query: {
    method: 'post',
    url: '/api/v1/story/:storyId/contributors'
  },
  params: settings.requestParams,
  payload: settings.requestPayload
});

export const updateStoryContributorPermission = (
  settings: RequestSettings<{ storyId: string; contributorId: string }, UpdateContributorValues>
): BaseSettings => ({
  query: {
    method: 'put',
    url: '/api/v1/story/:storyId/contributors/:contributorId'
  },
  params: settings.requestParams,
  payload: settings.requestPayload
});

export const removeStoryContributor = (
  settings: RequestSettings<{ storyId: string; contributorId: string }, {}>
): BaseSettings => ({
  query: {
    method: 'delete',
    url: '/api/v1/story/:storyId/contributors/:contributorId'
  },
  params: settings.requestParams
});

export const createChannel = (
  settings: RequestSettings<{ storyId: string }, { type: StoryChannelType; formData: StoryFormData[] }>
): BaseSettings => ({
  query: {
    method: 'post',
    url: '/api/v1/story/:storyId/channel'
  },
  payload: settings.requestPayload,
  params: settings.requestParams
});

export const updateChannel = (
  settings: RequestSettings<{ channelId: string }, { formData: StoryFormData[] }>
): BaseSettings => ({
  query: {
    method: 'put',
    url: '/api/v1/story/channel/:channelId'
  },
  payload: settings.requestPayload,
  params: settings.requestParams
});

export const updateChannelStatus = (
  settings: RequestSettings<{ channelId: string; storyId: string }, { status: StoryChannelStatus }>
): BaseSettings => ({
  query: {
    method: 'put',
    url: '/api/v1/story/:storyId/channel/:channelId/status'
  },
  payload: settings.requestPayload,
  params: settings.requestParams
});

export const removeChannel = (settings: RequestSettings<{ storyId: string; channelId: string }, {}>): BaseSettings => ({
  query: {
    method: 'delete',
    url: '/api/v1/story/:storyId/channel/:channelId'
  },
  params: settings.requestParams
});

export const copyChannel = (
  settings: RequestSettings<
    { targetChannelId: string; sourceChannelId: string },
    { targetChannelFields: StoryFieldName[] }
  >
): BaseSettings => ({
  query: {
    method: 'post',
    url: '/api/v1/story/channel/:targetChannelId/copy/:sourceChannelId'
  },
  payload: settings.requestPayload,
  params: settings.requestParams
});

export const copyPrintArticle = (
  settings: RequestSettings<
    { targetChannelId: string },
    { article: DesignerStory; targetChannelFields: StoryFieldName[] }
  >
): BaseSettings => ({
  query: {
    method: 'post',
    url: '/api/v1/story/channel/:targetChannelId/copy'
  },
  payload: settings.requestPayload,
  params: settings.requestParams
});

export const uploadAttachment = (
  settings: RequestSettings<{ storyId: string; channelId: string }, {}>
): BaseSettings => ({
  query: {
    method: 'post',
    url: '/api/v1/story/:storyId/channel/:channelId/attachment'
  },
  payload: settings.requestPayload,
  params: settings.requestParams
});

export const reorderAttachments = (
  settings: RequestSettings<{ storyId: string; channelId: string }, { attachments: StoryChannelAttachment[] }>
): BaseSettings => ({
  query: {
    method: 'put',
    url: '/api/v1/story/:storyId/channel/:channelId/attachments'
  },
  payload: settings.requestPayload,
  params: settings.requestParams
});

export const removeAttachment = (
  settings: RequestSettings<{ storyId: string; channelId: string; attachmentId: string }, {}>
): BaseSettings => ({
  query: {
    method: 'delete',
    url: '/api/v1/story/:storyId/channel/:channelId/attachment/:attachmentId'
  },
  params: settings.requestParams
});

export const changeAttachmentCaption = (
  settings: RequestSettings<{ storyId: string; channelId: string; attachmentId: string }, { caption: string }>
): BaseSettings => ({
  query: {
    method: 'put',
    url: '/api/v1/story/:storyId/channel/:channelId/attachment/:attachmentId'
  },
  payload: settings.requestPayload,
  params: settings.requestParams
});

export const publishChannel = (
  settings: RequestSettings<{ storyId: string; channelId: string }, PublishSocialChannelValues>
): BaseSettings => ({
  query: {
    method: 'post',
    url: '/api/v1/story/:storyId/channel/:channelId/publish'
  },
  payload: settings.requestPayload,
  params: settings.requestParams
});

export const generateSocialPreview = (
  settings: RequestSettings<{ storyId: string; channelId: string }, { type: StoryChannelType }>
): BaseSettings => ({
  query: {
    method: 'post',
    url: '/api/v1/story/:storyId/channel/:channelId/preview'
  },
  payload: settings.requestPayload,
  params: settings.requestParams
});

export const getStories = (
  settings: RequestSettings<{ publicationDate: string; channelType: StoryChannelType }>
): BaseSettings => ({
  query: {
    method: 'get',
    url: '/api/v1/story/get_stories?publicationDate=:publicationDate&channelType=:channelType'
  },
  params: settings.requestParams
});

export const getChannelPublishErrors = (
  settings: RequestSettings<{ storyId: string; channelId: string }>
): BaseSettings => ({
  query: {
    method: 'get',
    url: '/api/v1/story/:storyId/channel/:channelId/publish_date_errors'
  },
  params: settings.requestParams
});

export const generateAiContent = (
  settings: RequestSettings<{}, { content: string; type: StoryChannelType }>
): BaseSettings => ({
  query: {
    method: 'post',
    url: '/api/v1/story/generate_ai_content'
  },
  payload: settings.requestPayload
});

export const generateAiContentForChannels = (
  settings: RequestSettings<{ storyId: string }, { content: string; types: StoryChannelType[] }>
): BaseSettings => ({
  query: {
    method: 'post',
    url: '/api/v1/story/:storyId/generate_ai_content_for_channels'
  },
  params: settings.requestParams,
  payload: settings.requestPayload
});

export const generateAiImage = (
  settings: RequestSettings<{ storyId: string; channelId: string }, { prompt: string }>
): BaseSettings => ({
  query: {
    method: 'post',
    url: '/api/v1/story/:storyId/:channelId/generate_ai_image'
  },
  params: settings.requestParams,
  payload: settings.requestPayload
});

export const shareStory = (
  settings: RequestSettings<{ storyId: string }, { organizations: string[] }>
): BaseSettings => ({
  query: {
    method: 'post',
    url: '/api/v1/story/:storyId/share'
  },
  params: settings.requestParams,
  payload: settings.requestPayload
});

export const cloneShared = (settings: RequestSettings<{ storyId: string }, { name: string }>): BaseSettings => ({
  query: {
    method: 'post',
    url: `/api/v1/story/:storyId/clone`
  },
  params: settings.requestParams,
  payload: settings.requestPayload
});

export const getStoryClones = (settings: RequestSettings<{ storyId: string }, {}>): BaseSettings => ({
  query: {
    method: 'get',
    url: '/api/v1/story/:storyId/clones'
  },
  params: settings.requestParams
});
