import React, { FunctionComponent, useEffect, useState } from 'react';
import * as Yup from 'yup';
import ModalFormWindow from '../../../../Shared/Window/ModalFormWindow';
import { useTranslation } from 'react-i18next';
import { AdminCreateOrganizationValues } from '../../../../../store/AdminOrganizations/types';
import { adminOrganizationsOperations } from '../../../../../store/AdminOrganizations';
import { useDispatch } from 'react-redux';
import { AdminCheckboxInput, AdminSelectInput, AdminTextInput } from '../../Shared/AdminFormInputs';
import Toast from '../../../../Shared/Toast/Toast';
import app from '../../../../../config/app/app';
import { FormikProps } from 'formik';
import { FormLabel, MenuItem } from '@material-ui/core';
import { AdminPasswordPolicy } from '../../../../../store/AdminPasswordPolicies/types';
import { createNetworkErrorObject, timeToTimestamp, useTypedSelector } from '../../../../../utils';
import { isSystemOwner } from '../../../../../utils/permissions';
import { AdminEmailTemplate } from '../../../../../store/AdminEmailTemplates/types';
import { automatedEmailDateSchedule, DamSystem, DamSystemName } from '../../../../../store/SystemSettings/types';
import { Row } from '../../../../Shared/StyledComponents';
import { SenderData } from '../../../../../store/EmailProjects/types';
import { emailProjectsOperations } from '../../../../../store/EmailProjects';
import Loader from '../../../../Shared/Loading/Loader';
import { systemSettingsOperations } from '../../../../../store/SystemSettings';

type AdminCreateOrganizationWindowProps = {
  open: boolean;
  onCloseClick: () => void;
  fullScreenOnMobile: boolean;
  passwordPolicies: AdminPasswordPolicy[];
  emailTemplates: AdminEmailTemplate[];
};

const AdminCreateOrganizationWindow: FunctionComponent<AdminCreateOrganizationWindowProps> = ({
  onCloseClick,
  open,
  passwordPolicies,
  emailTemplates
}) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const authState = useTypedSelector((state) => state.auth);
  const [globalPasswordPolicyId, setGlobalPasswordPolicyId] = useState<string>('');
  const [senders, setSenders] = useState<SenderData[]>([]);
  const [damSystemsLoading, setDamSystemsLoading] = useState<boolean>(true);
  const [damSystems, setDamSystems] = useState<DamSystem[]>([]);
  const [sendersLoading, setSendersLoading] = useState<boolean>(true);

  useEffect(() => {
    const globalPasswordPolicies = passwordPolicies.find((passwordPolicy) => passwordPolicy.isGlobal);
    if (globalPasswordPolicies) {
      setGlobalPasswordPolicyId(globalPasswordPolicies.id);
    }
  }, [passwordPolicies]);

  useEffect(() => {
    const getSenders = async () => {
      setSendersLoading(true);
      try {
        const sendersResponse = await emailProjectsOperations.getSenders();
        setSenders(sendersResponse.data);
      } catch (e) {
        Toast.error(t('notifications.getSenders.error'));
      } finally {
        setSendersLoading(false);
      }
    };
    if (open && isSystemOwner(authState)) {
      getSenders();
      getDamSystems();
    }
  }, [open]);

  const getDamSystems = async () => {
    setDamSystemsLoading(true);
    try {
      const dams = await systemSettingsOperations.getDamSystems();
      setDamSystems(dams.data);
    } catch (e) {
      Toast.error(t('notifications.dams.getDamsError'));
    } finally {
      setDamSystemsLoading(false);
    }
  };

  const getChosenDamSystem = (props: FormikProps<any>): DamSystemName | null => {
    if (props.values.dam && props.values.dam.id) {
      const dam = damSystems.find((d) => d.id === props.values.dam.id);
      if (dam) {
        return dam.name;
      }
    }

    return null;
  };

  const onSubmit = async (values: AdminCreateOrganizationValues) => {
    try {
      await dispatch(
        adminOrganizationsOperations.create({
          ...values,
          externalDataCenter: values.externalDataCenter === -1 ? null : values.externalDataCenter,
          automatedEmailTime: timeToTimestamp(values.automatedEmailTime),
          senderEmail: values.senderEmail === '-1' ? null : values.senderEmail,
          dam: values.dam && values.dam.id && values.dam.id !== '-1' ? values.dam : null
        })
      );
      await dispatch(adminOrganizationsOperations.index());
      onCloseClick();
      Toast.success(t('notifications.adminOrganizations.successOperation'));
    } catch (e) {
      const error = createNetworkErrorObject(e);

      switch (error.message) {
        case 'account_number_already_taken':
          return Toast.error(t('notifications.adminOrganizations.accountNumberTaken'));
        default:
          return Toast.error(t('notifications.adminOrganizations.errorOperation'));
      }
    }
  };

  const formInputs = (props: FormikProps<any>) => (
    <>
      <AdminTextInput t={t} name="name" section="adminOrganizations" />
      <AdminTextInput t={t} name="accountNumber" section="adminOrganizations" />
      {isSystemOwner(authState) && (
        <>
          <AdminCheckboxInput t={t} name="injectLogoToPrintProjects" section="adminOrganizations" />
          <AdminCheckboxInput t={t} name="stripProtocolFromPrintInjectedUrls" section="adminOrganizations" />
          <AdminCheckboxInput t={t} name="socialPublishing" section="adminOrganizations" />
          <AdminTextInput t={t} type="number" name="colorSwatchesLimit" section="adminOrganizations" />
          <AdminTextInput t={t} name="readMoreLink" section="adminOrganizations" />
          <div style={{ margin: '1rem 0 0.3rem' }}>
            <FormLabel component="legend">{t('pages.adminOrganizations.availableProducts')}</FormLabel>
            <AdminCheckboxInput t={t} name="bulletin" section="adminOrganizations" />
            <AdminCheckboxInput t={t} name="email" section="adminOrganizations" />
            <AdminCheckboxInput t={t} name="flyer" section="adminOrganizations" />
            <AdminCheckboxInput t={t} name="newsletter" section="adminOrganizations" />
            <AdminCheckboxInput t={t} name="directory" section="adminOrganizations" />
          </div>
        </>
      )}
      <AdminSelectInput t={t} name="passwordPolicyId" section="adminOrganizations">
        {passwordPolicies.map((passwordPolicy) => (
          <MenuItem key={passwordPolicy.id} value={passwordPolicy.id}>
            {passwordPolicy.isGlobal ? t('pages.systemSettings.passwordPolicyTitle') : passwordPolicy.name}
          </MenuItem>
        ))}
      </AdminSelectInput>
      <AdminSelectInput t={t} name="externalDataCenter" section="commonInputs">
        {Object.keys({ notSet: -1, ...authState.dataCenters }).map((dataCenter, i) => {
          if (i === 0) {
            return (
              <MenuItem key={-1} value={-1}>
                {t(`common.notSet`)}
              </MenuItem>
            );
          }
          return (
            <MenuItem key={i} value={i.toString()}>
              {t(`common.dataCenters.${dataCenter}`)}
            </MenuItem>
          );
        })}
      </AdminSelectInput>
      {isSystemOwner(authState) && (
        <>
          <AdminCheckboxInput t={t} name="emailWizardEnabled" section="adminOrganizations" />
          <AdminSelectInput t={t} name="defaultAutomatedEmailTemplateId" section="adminOrganizations">
            {[null, ...emailTemplates].map((template) => {
              if (template === null) {
                return (
                  <MenuItem key="template-not-set" value={undefined}>
                    {t(`common.notSet`)}
                  </MenuItem>
                );
              }
              return (
                <MenuItem key={template.id} value={template.id}>
                  {template.name}
                </MenuItem>
              );
            })}
          </AdminSelectInput>
          <AdminCheckboxInput t={t} name="automatedEmailEnabled" section="adminOrganizations" />
          {props.values.automatedEmailEnabled && (
            <>
              <AdminSelectInput t={t} name="automatedEmailDateSchedule" section="adminOrganizations">
                {[null, ...automatedEmailDateSchedule].map((name) => {
                  if (name === null) {
                    return (
                      <MenuItem key="schedule-not-set" value={undefined}>
                        {t(`common.notSet`)}
                      </MenuItem>
                    );
                  }
                  return (
                    <MenuItem key={name} value={name}>
                      {t(`common.automatedEmailDateSchedule.${name}`)}
                    </MenuItem>
                  );
                })}
              </AdminSelectInput>
              <AdminTextInput t={t} name="automatedEmailTime" section="adminOrganizations" type="time" />
              <Row>
                <AdminTextInput t={t} name="automatedEmailSubject" section="adminOrganizations" />
                <em style={{ textAlign: 'center', fontStyle: 'italic', width: '100%' }}>
                  {t('pages.adminOrganizations.automatedEmailSubjectHint')}
                </em>
              </Row>
            </>
          )}
          {sendersLoading && <Loader />}
          {!sendersLoading && senders.length > 0 && (
            <AdminSelectInput t={t} name="senderEmail" section="adminOrganizations">
              {[null, ...senders].map((sender) => {
                if (sender === null) {
                  return (
                    <MenuItem key="sender-not-set" value="-1">
                      {t(`pages.adminOrganizations.senderEmailDefault`)}
                    </MenuItem>
                  );
                }
                return (
                  <MenuItem key={sender.ID} value={sender.Email}>
                    {sender.Name} ({sender.Email})
                  </MenuItem>
                );
              })}
            </AdminSelectInput>
          )}
          {damSystemsLoading && <Loader />}
          {!damSystemsLoading && damSystems.length > 0 && (
            <AdminSelectInput t={t} name="dam.id" section="adminOrganizations">
              {[null, ...damSystems].map((dam) => {
                if (dam === null) {
                  return (
                    <MenuItem key="dam-not-set" value="-1">
                      {t(`common.notSet`)}
                    </MenuItem>
                  );
                }
                return (
                  <MenuItem key={dam.id} value={dam.id}>
                    <div>
                      <p>{t(`common.damSystem.${dam.name}`)}</p>
                      <p style={{ fontSize: '0.8rem', fontStyle: 'italic' }}>{dam.url}</p>
                    </div>
                  </MenuItem>
                );
              })}
            </AdminSelectInput>
          )}
          {!damSystemsLoading && getChosenDamSystem(props) === DamSystemName.ARK && (
            <AdminTextInput t={t} name="dam.apiKey" section="adminOrganizations" />
          )}
        </>
      )}
    </>
  );

  return (
    <ModalFormWindow
      open={open}
      okButtonText={t('common.create')}
      header={t('pages.adminOrganizations.windows.createOrganization')}
      onCloseClick={onCloseClick}
      onSubmit={onSubmit}
      formInputs={formInputs}
      initialValues={{
        name: '',
        accountNumber: '',
        readMoreLink: 'https://parishesonline.com/flipbook',
        passwordPolicyId: globalPasswordPolicyId,
        stripProtocolFromPrintInjectedUrls: true,
        bulletin: true,
        email: true,
        flyer: true,
        newsletter: true,
        directory: true,
        colorSwatchesLimit: null,
        injectLogoToPrintProjects: true,
        externalDataCenter: null,
        emailWizardEnabled: false,
        automatedEmailEnabled: false,
        defaultAutomatedEmailTemplateId: null,
        automatedEmailDateSchedule: null,
        automatedEmailTime: null,
        automatedEmailSubject: null,
        senderEmail: '-1',
        socialPublishing: false,
        dam: null
      }}
      validationSchema={Yup.object().shape({
        name: Yup.string().max(app.maxInputLength).required(),
        accountNumber: Yup.string().max(app.maxInputLength).required(),
        colorSwatchesLimit: Yup.number().min(0).max(200).nullable(),
        injectLogoToPrintProjects: Yup.boolean(),
        stripProtocolFromPrintInjectedUrls: Yup.boolean(),
        socialPublishing: Yup.boolean(),
        bulletin: Yup.boolean(),
        email: Yup.boolean(),
        flyer: Yup.boolean(),
        newsletter: Yup.boolean(),
        directory: Yup.boolean(),
        readMoreLink: Yup.string().nullable(),
        externalDataCenter: Yup.number().nullable(),
        passwordPolicyId: Yup.string().required(),
        emailWizardEnabled: Yup.boolean(),
        automatedEmailEnabled: Yup.boolean(),
        defaultAutomatedEmailTemplateId: Yup.string().nullable(),
        automatedEmailTime: Yup.string().nullable(),
        automatedEmailDateSchedule: Yup.string().nullable(),
        automatedEmailSubject: Yup.string().nullable(),
        senderEmail: Yup.string().nullable(),
        dam: Yup.object()
          .shape({
            id: Yup.string(),
            apiKey: Yup.string()
          })
          .nullable()
      })}
    />
  );
};

export default AdminCreateOrganizationWindow;
