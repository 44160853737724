import { CSSProperties } from '@material-ui/core/styles/withStyles';
import {
  MonthCalendarStyle,
  MonthCalendarStyles,
  MyOrganizationSwatchColor,
  OrganizationMonthCalendarDay
} from '../../../../store/MyOrganization/types';
import { DesignerStory } from '../../../../store/PrintProjects/types';
import { StoryChannel } from '../../../../store/Stories/types';

export type NewsletterSidebarItemType =
  | 'settings'
  | 'structure'
  | 'content'
  | 'stories'
  | 'storyList'
  | 'uploadedImages'
  | 'emailAttachments'
  | 'bandFolder'
  | 'dam'
  | 'colorSwatches'
  | 'userImage'
  | 'variables'
  | 'native';

export type DragDataTransfer = {
  type: NewsletterSidebarItemType;
  data: any;
};

export type ProjectModel = ProjectModelV1 | ProjectModelV2;

export type ProjectModelV1 = {
  version?: number;
  colorSwatches?: {
    [key: string]: MyOrganizationSwatchColor;
  };
  sizes: {
    large: ProjectModelColumn[];
  };
};
export type ProjectModelColumn = {
  size: ProjectModalColumnSize;
  style?: CSSProperties;
  role?: 'header' | 'footer';
  components: ProjectModelComponent[];
};

export type ProjectModelV2 = {
  version: 2;
  constraints?: ProjectModelConstraints;
  // TODO: check if any[] can be replaced
  defaultComponentStyle?: any[];
  colorSwatches?: {
    [key: string]: MyOrganizationSwatchColor;
  };
  settings?: {
    border: BorderSettings;
  };
  sizes: {
    large: EmailProjectRow[];
  };
};

export type ProjectModelConstraints = {
  ads?: AdsConstraint[];
};

export type AdsConstraint = {
  width: number;
  height: number;
  maxRow: number;
};

export type EmailProjectRow = {
  id: number;
  cols: ProjectModelColumn[];
  style?: CSSProperties;
  role?: 'header' | 'footer' | 'not-set';
  border?: {
    top: boolean;
    right: boolean;
    bottom: boolean;
    left: boolean;
  };
};

export type ProjectModalColumnSize = 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9 | 10 | 11 | 12;

export type ProjectModelComponent =
  | EmailImage
  | EmailText
  | EmailSocialLinks
  | ArticleHorizontal
  | ArticleVertical
  | Separator
  | CtaButton
  | LogoHorizontal
  | LogoVertical
  | PrintProjectCover
  | Placeholder
  | AdSpace
  | DownloadAttachment
  | UpcomingEvents
  | MonthCalendar
  | DownloadAttachment;

export type ProjectModelComponentBase = {
  style?: CSSProperties;
  colorSwatchIgnore?: string[];
  wrapperStyle?: CSSProperties;
};

export type ImageData = {
  url: string;
  containerWidth: number;
  containerHeight: number;
  width?: number;
  height?: number;
  positionX: number;
  positionY: number;
  linkUrl?: string;
  borderTopLeftRadius?: string;
  borderTopRightRadius?: string;
  borderBottomLeftRadius?: string;
  borderBottomRightRadius?: string;
  hasCaption: boolean;
  caption: string;
};

export type EmailImage = ProjectModelComponentBase & {
  type: 'image';
  params: ImageData;
};

export type LogoHorizontal = ProjectModelComponentBase & {
  type: 'logo-horizontal';
  params: ImageData;
};

export type LogoVertical = ProjectModelComponentBase & {
  type: 'logo-vertical';
  params: ImageData;
};

export type PrintProjectCover = ProjectModelComponentBase & {
  type: 'print-project-cover';
  params: ImageData;
};

export type EmailText = ProjectModelComponentBase & {
  type: 'text';
  role: 'common' | 'organization-address' | 'organization-contact-information' | 'organization-name';
  params: {
    content: string;
  };
};

export type Separator = ProjectModelComponentBase & {
  type: 'separator';
  params: {};
};

export type Placeholder = ProjectModelComponentBase & {
  type: 'placeholder';
  params: {};
};

export type EmailSocialLinks = ProjectModelComponentBase & {
  type: 'social-links';
  params: {
    images: {
      facebook: ImageData;
      twitter: ImageData;
      linkedIn: ImageData;
      instagram: ImageData;
      youtube: ImageData;
    };
    urls: {
      facebook: string;
      twitter: string;
      linkedIn: string;
      instagram: string;
      youtube: string;
    };
    color: string;
  };
};

export type ArticleBase = {
  role: 'accent1' | 'accent2';
  imageWrapperStyle?: CSSProperties;
  articleWrapperStyle?: CSSProperties;
  articleHeadlineStyle?: CSSProperties;
  articleBodyStyle?: CSSProperties;
  articleReadMoreStyle?: CSSProperties;
  articleHeadlineClasses?: string;
  articleBodyClasses?: string;
  params: {
    showImage?: boolean;
    readMoreText?: string;
    showReadMore: boolean;
    customReadMore?: boolean;
    customReadMoreLink?: string;
    customReadMoreText?: string;
    readMoreLinkUrl: string;
    image: ImageData | null;
    headline: string | null;
    imagePosition?: 'top' | 'middle' | 'bottom';
    body: string | null;
  };
};

export type ArticleHorizontal = ProjectModelComponentBase &
  ArticleBase & {
    type: 'article-horizontal';
  };

export type ArticleVertical = ProjectModelComponentBase &
  ArticleBase & {
    type: 'article-vertical';
  };

export type AdSpace = ProjectModelComponentBase & {
  type: 'ad-space';
  params: {
    width: number;
    height: number;
  };
};

export type CtaButton = ProjectModelComponentBase & {
  type: 'cta-button';
  role: 'common' | 'view-sponsors-link' | 'view-print-product' | 'donate-online' | 'view-email-project';
  params: {
    text: string;
    link: string;
    style: {
      backgroundColor: string;
      borderRadius: string;
      borderStyle: string;
      borderWidth: string;
      borderColor: string;
    };
  };
};

export enum DownloadAttachmentType {
  ALL,
  SINGLE
}

export type DownloadAttachment = ProjectModelComponentBase & {
  type: 'download-attachment';
  role: 'common';
  params: {
    type: DownloadAttachmentType;
    text: string;
    attachmentId: string | null;
    style: {
      backgroundColor: string;
      borderRadius: string;
      borderStyle: string;
      borderWidth: string;
      borderColor: string;
    };
  };
};

export type CalendarEvent = {
  name: string;
  description: string;
  url: string | null;
  startDay: number;
  endDay: number;
  startMonth: string;
  endMonth: string;
  thumbnailUrl: string | null;
  isSingleDayEvent: boolean;
};

export type BorderSettings = {
  borderRadius: string;
  borderStyle: string;
  borderWidth: string;
  borderColor: string;
};

export type UpcomingEvents = ProjectModelComponentBase & {
  type: 'upcoming-events';
  role: 'common';
  params: {
    header: string;
    startDate: string;
    endDate: string;
    events: CalendarEvent[];
  };
};

export type MonthCalendar = ProjectModelComponentBase & {
  type: 'month-calendar';
  role: 'common';
  params: {
    date: string;
    calendar: OrganizationMonthCalendarDay[][];
    selectedStyle: MonthCalendarStyles | null;
    styles: MonthCalendarStyle;
    viewType: 'month' | 'week';
    year: number;
    month: number;
    week: number;
    labelPosition: 'left' | 'center' | 'right';
  };
};

export const ItemTypes = {
  ROW: 'row',
  STRUCTURE: 'structure',
  CONTENT: 'content',
  BRANDFOLDER: 'brandfolder-asset',
  USER_UPLOADED_ASSET: 'user-uploaded-asset',
  DESIGNER_STORY: 'designer-story',
  STORY: 'story'
};

export type ImageDropObjectData = {
  files?: File[];
  id?: string;
  type?: string;
  designerStory?: DesignerStory;
  story?: StoryChannel;
};

export interface RowDragObject {
  id: string;
  index: number;
}

export const structureElements: { name: string; sizes: ProjectModalColumnSize[] }[] = [
  {
    name: 'Full Width',
    sizes: [12]
  },
  {
    name: '1/2 - 1/2',
    sizes: [6, 6]
  },
  {
    name: '1/3 - 1/3 - 1/3',
    sizes: [4, 4, 4]
  },
  {
    name: '1/4 - 1/4 - 1/4 - 1/4',
    sizes: [3, 3, 3, 3]
  },
  {
    name: '1/3 - 2/3',
    sizes: [4, 8]
  },
  {
    name: '2/3 - 1/3',
    sizes: [8, 4]
  },
  {
    name: '1/3 - 3/4',
    sizes: [3, 9]
  },
  {
    name: '3/4 - 1/4',
    sizes: [9, 3]
  }
];

export const emailTemplateVariables = [
  '<org-name>',
  '<org-contact-person>',
  '<org-contact-email>',
  '<org-address-line-1>',
  '<org-address-line-2>',
  '<org-city>',
  '<org-state>',
  '<org-zip-code>',
  '<org-telephone>',
  '<org-second-telephone>',
  '<org-website>',
  '<read-more-link>',
  '<org-sponsors-url>',
  '<org-donate-url>',
  '<unsubscribe-link>'
];

export type PlacedAd = {
  width: number;
  height: number;
  rowIndex: number;
  counted: boolean;
};

export type AdPlacementStatus = {
  width: number;
  height: number;
  requiredRowIndex: number;
  currentRowIndex: number | null;
  isValid: boolean;
  placed: boolean;
};

export type EditRowFormWindowData = EmailProjectRow & {
  [key: string]: ProjectModelComponent;
};
