import { ApplicationRoute } from '../../../typings';
import linksConstants from '../../../config/app/linksConstants';
import DashboardPage from './DashboardPage';
import CreateProjectPage from './CreateProjectPage';
import EmailProjectCreator from './Creators/EmailProjectCreator';
import BulletinProjectCreator from './Creators/BulletinProjectCreator';
import FlyerProjectCreator from './Creators/FlyerProjectCreator';
import DirectoryProjectCreator from './Creators/DirectoryProjectCreator';
import NewsletterProjectCreator from './Creators/NewsletterProjectCreator';
import PrintProjectsPage from './PrintProjectsPage';
import EmailProjectsPage from './EmailProjectsPage';
import CloneProjectPage from './Cloner/CloneProjectPage';

const DashboardRoutes: ApplicationRoute[] = [
  {
    path: linksConstants.DASHBOARD.INDEX,
    exact: true,
    name: 'dashboard',
    public: false,
    show: true,
    component: DashboardPage,
    scope: []
  },
  {
    path: linksConstants.DASHBOARD.PRINT_PROJECTS,
    exact: true,
    name: 'dashboard',
    public: false,
    show: true,
    component: PrintProjectsPage,
    scope: []
  },
  {
    path: linksConstants.DASHBOARD.EMAIL_PROJECTS,
    exact: true,
    name: 'dashboard',
    public: false,
    show: true,
    component: EmailProjectsPage,
    scope: []
  },
  {
    path: linksConstants.DASHBOARD.NEW_PROJECT,
    exact: true,
    name: 'new',
    public: false,
    show: true,
    component: CreateProjectPage,
    scope: []
  },
  {
    path: linksConstants.DASHBOARD.PROJECT_CREATOR.email,
    exact: true,
    name: 'emailCreator',
    public: false,
    show: true,
    component: EmailProjectCreator,
    scope: []
  },
  {
    path: linksConstants.DASHBOARD.PROJECT_CREATOR.bulletin,
    exact: true,
    name: 'bulletinCreator',
    public: false,
    show: true,
    component: BulletinProjectCreator,
    scope: []
  },
  {
    path: linksConstants.DASHBOARD.PROJECT_CREATOR.flyer,
    exact: true,
    name: 'flyerCreator',
    public: false,
    show: true,
    component: FlyerProjectCreator,
    scope: []
  },
  {
    path: linksConstants.DASHBOARD.PROJECT_CREATOR.directory,
    exact: true,
    name: 'directoryCreator',
    public: false,
    show: true,
    component: DirectoryProjectCreator,
    scope: []
  },
  {
    path: linksConstants.DASHBOARD.PROJECT_CREATOR.newsletter,
    exact: true,
    name: 'newsletterCreator',
    public: false,
    show: true,
    component: NewsletterProjectCreator,
    scope: []
  },
  {
    path: linksConstants.DASHBOARD.CLONE_PROJECT(':projectId'),
    exact: false,
    name: 'projectCloner',
    public: false,
    show: true,
    component: CloneProjectPage,
    scope: []
  }
];

export default DashboardRoutes;
