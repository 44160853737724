import { createOrderArray, httpRequest } from '../../utils';
import { CreateMailingListValues, MailingList, MailingListsIndexResponse, UpdateMailingListValues } from './types';
import { Dispatch } from 'redux';
import { ApplicationState } from '../rootReducer';
import { mailingListsActions, mailingListsRequests } from './index';

export const index = () => async (dispatch: Dispatch, getState: () => ApplicationState) => {
  const { mailingLists } = getState();

  try {
    dispatch(mailingListsActions.mailingListsIndexBegin());

    const response = await httpRequest.runRequest<MailingListsIndexResponse>(
      mailingListsRequests.index({
        requestParams: {
          page: mailingLists.pagination.page,
          per: mailingLists.pagination.per,
          q: mailingLists.pagination.q,
          order: createOrderArray(mailingLists.tableSearch.order, ['order=name,asc'])
        }
      })
    );

    dispatch(mailingListsActions.mailingListsIndexSuccess(response));
  } catch (e) {
    dispatch(mailingListsActions.mailingListsIndexFailed(e));
  }
};

export const getAllUserMailingLists = async () => {
  return await httpRequest.runRequest<{ data: MailingList[] }>(mailingListsRequests.getAll());
};

export const create = (values: CreateMailingListValues) => async () => {
  await httpRequest.runRequest<void>(
    mailingListsRequests.create({
      requestPayload: values
    })
  );
};

export const getTestMailingList = async (mailingListId: string) => {
  return await httpRequest.runRequest<MailingList>(
    mailingListsRequests.getTestMailingList({
      requestParams: { mailingListId }
    })
  );
};

export const update = (id: string, values: UpdateMailingListValues) => async () => {
  await httpRequest.runRequest<void>(
    mailingListsRequests.update({
      requestPayload: values,
      requestParams: { id }
    })
  );
};

export const deleteMailingList = (id: string) => async () => {
  await httpRequest.runRequest<void>(
    mailingListsRequests.deleteMailingList({
      requestParams: { id }
    })
  );
};

export const setWebsiteFormDefault = async (id: string) => {
  await httpRequest.runRequest<void>(
    mailingListsRequests.setWebsiteFormDefault({
      requestParams: { id }
    })
  );
};

export const reSynchronize = async (mailingListId: string) => {
  await httpRequest.runRequest<void>(
    mailingListsRequests.reSynchronize({
      requestParams: { mailingListId }
    })
  );
};
