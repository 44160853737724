import React, { FunctionComponent, useEffect, useState } from 'react';
import styled from 'styled-components';
import { GridList, GridListTile, Paper, Typography } from '@material-ui/core';
import { emailProjectsOperations } from '../../../../../../../store/EmailProjects';
import { EmailProject, UserImage } from '../../../../../../../store/EmailProjects/types';
import { Alert, Pagination } from '@material-ui/lab';
import Loader from '../../../../../../Shared/Loading/Loader';
import { useTranslation } from 'react-i18next';
import app from '../../../../../../../config/app/app';
import Toast from '../../../../../../Shared/Toast/Toast';
import { getDroppedNativeFile } from '../../../Utils/Uploaders';
import { UserUploadedAsset } from '../Helpers/UserUploadedAsset';

type UploadedImagesProps = {
  project: EmailProject | null;
};

const UploadedImages: FunctionComponent<UploadedImagesProps> = ({ project }) => {
  const { t } = useTranslation();
  const [loading, setLoading] = useState<boolean>(false);
  const [progress, setProgress] = useState<number>(0);
  const [uploading, setUploading] = useState<boolean>(false);
  const [dragOver, setDragOver] = useState<boolean>(false);
  const [images, setImages] = useState<UserImage[]>([]);
  const [pagination, setPagination] = useState<Pagination>({ page: 1, per: 12, total: 0, totalPages: 0, q: null });

  const fetchData = async (page: number) => {
    if (project) {
      setLoading(true);
      try {
        const data = await emailProjectsOperations.getUserImages(project.id, 12, page);
        setImages(data.data);
        setPagination(data.pagination);
        setLoading(false);
      } catch (e) {
        setLoading(false);
      }
    }
  };

  useEffect(() => {
    fetchData(1);
  }, []);

  if (!project) {
    return null;
  }

  const handleChange = (event: React.ChangeEvent<unknown>, page: number) => {
    fetchData(page);
  };

  const toggleSticky = async (image: UserImage) => {
    await emailProjectsOperations.toggleStickyImage(project.id, image.id);
    await fetchData(pagination.page);
  };

  const removeImage = async (image: UserImage) => {
    await emailProjectsOperations.removeImage(project.id, image.id);
    await fetchData(pagination.page);
  };

  const CenteredLoader = () => (
    <LoaderWrapper>
      <Loader />
    </LoaderWrapper>
  );

  const containsFiles = (e: any) => {
    if (e && e.dataTransfer && e.dataTransfer.types) {
      for (let i = 0; i < e.dataTransfer.types.length; i++) {
        if (e.dataTransfer.types[i] == 'Files') {
          return true;
        }
      }
    }

    return false;
  };

  const onFileDrop = async (e: any) => {
    try {
      setProgress(0);
      if (containsFiles(e)) {
        let file: File | null = null;
        if (e && e.dataTransfer && e.dataTransfer.files && e.dataTransfer.files[0]) {
          file = e.dataTransfer.files[0];
        }
        if (file) {
          const extension = file.name.split('.').pop();

          if (!app.validLogoExtensions.includes((extension || '').toLowerCase())) {
            Toast.error(t('notifications.uploadEmailFile.invalidExtension'));
            setUploading(false);
            setDragOver(false);
            return;
          }

          if (file.size > app.maxLogoSize) {
            Toast.error(t('notifications.uploadEmailFile.fileTooBig'));
            setUploading(false);
            setDragOver(false);
            return;
          }

          setUploading(true);
          await emailProjectsOperations.uploadImage(file, project.id, (progress) => {
            setProgress(progress);
          });
          setUploading(false);
          await fetchData(pagination.page);
        }
      }
    } catch (e) {
      setUploading(false);
      console.warn('Error occurred while uploading file', e);
    }

    setDragOver(false);
  };

  const AssetsContent = () => {
    if (loading) {
      return <>{loading && <CenteredLoader />}</>;
    }

    return (
      <>
        {loading && <CenteredLoader />}
        {!loading && (
          <>
            <Typography
              variant="subtitle1"
              gutterBottom
              style={{ textAlign: 'left', padding: '1rem 1rem 0 1rem', width: '100%' }}
            >
              {t('imagesPalette.imageListTitle')}
            </Typography>
            <GridList
              cellHeight={87}
              cols={3}
              spacing={10}
              style={{ padding: '10px', margin: '0 10px 10px 10px', width: '400px' }}
            >
              {images.map((image) => (
                <GridListTile key={image.id}>
                  <UserUploadedAsset image={image} toggleSticky={toggleSticky} removeImage={removeImage} />
                </GridListTile>
              ))}
            </GridList>
            {images.length === 0 && (
              <Alert severity="info" style={{ marginTop: 'auto', marginBottom: 'auto' }}>
                {t('imagesPalette.noAssetsFound')}
              </Alert>
            )}
          </>
        )}
        {pagination.total > 0 && (
          <Pagination
            count={pagination.totalPages}
            page={pagination.page}
            shape="rounded"
            onChange={handleChange}
            size="small"
            style={{ marginTop: 'auto' }}
          />
        )}
      </>
    );
  };

  return (
    <Paper
      style={{ position: 'relative' }}
      onDragEnter={(e) => {
        if (containsFiles(e)) {
          setDragOver(true);
        }
      }}
    >
      <StyledPaper>
        <AssetsContent />
      </StyledPaper>
      {(dragOver || uploading) && (
        <DropHereInfo onDrop={(e) => onFileDrop(e)} onDragLeave={() => setDragOver(false)}>
          {uploading
            ? progress === 100
              ? t('imagesPalette.processing')
              : `${t('imagesPalette.uploading')} (${progress}%)`
            : t('imagesPalette.dropHere')}
        </DropHereInfo>
      )}
    </Paper>
  );
};

const DropHereInfo = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(0, 0, 0, 0.6);
  font-size: 1.5rem;
  font-style: italic;
  color: #fff;
  border-radius: 4px;
`;

const StyledPaper = styled(Paper)`
  min-height: 480px;
  display: flex;
  flex-direction: column;
  padding-bottom: 20px;
  align-items: center;
`;

const LoaderWrapper = styled.div`
  display: flex;
  height: 20rem;
  width: 100%;
  align-items: center;
  justify-content: center;
`;

export default UploadedImages;
