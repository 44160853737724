import React, { FunctionComponent, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { CampaignSummary, EmailProject } from '../../../../store/EmailProjects/types';
import { emailProjectsOperations } from '../../../../store/EmailProjects';
import { Doughnut } from 'react-chartjs-2';
import { Grid, Paper, Button, Typography } from '@material-ui/core';
import { ChartData } from 'chart.js';
import GroupIcon from '@material-ui/icons/Group';
import ImportContactsIcon from '@material-ui/icons/ImportContacts';
import MouseIcon from '@material-ui/icons/Mouse';
import UnsubscribeIcon from '@material-ui/icons/Unsubscribe';
import ListAltIcon from '@material-ui/icons/ListAlt';
import styled from 'styled-components';
import EmailStatsSubscribers from './EmailStatsSubscribers';
import EmailStatsLinksActivity from './EmailStatsLinksActivity';
import Loader from '../../../Shared/Loading/Loader';
import { useDispatch } from 'react-redux';
import { push } from 'connected-react-router';
import linksConstants from '../../../../config/app/linksConstants';

type EmailStatsSummaryProps = {
  project: EmailProject;
};

const EmailStatsSummary: FunctionComponent<EmailStatsSummaryProps> = ({ project }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [stats, setStats] = useState<CampaignSummary | null>(null);
  const [chartData, setChartData] = useState<ChartData | null>(null);
  const [loading, setLoading] = useState<boolean>(true);

  const chartOptions = {
    plugins: {
      legend: {
        position: 'bottom',
        labels: {
          usePointStyle: true,
          padding: 20
        }
      }
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      if (project) {
        setLoading(true);
        const response = await emailProjectsOperations.emailCampaignStatsSummary(project.id);
        const opened = response.data.UniqueOpens;
        const notOpened = response.data.Sent - opened;
        setStats(response.data);
        setChartData({
          labels: [`${t('pages.emailStatistics.opened')}`, `${t('pages.emailStatistics.notOpened')}`],
          datasets: [
            {
              data: [opened, notOpened],
              backgroundColor: ['rgba(92, 205, 222, 1)', 'rgba(225, 96, 83, 1)'],
              borderColor: ['rgba(92, 205, 222, 1)', 'rgba(225, 96, 83, 1)'],
              borderWidth: 0,
              // @ts-ignore
              cutout: '95%'
            }
          ]
        });
        setLoading(false);
      }
    };

    fetchData();
  }, []);

  const openMailingList = () => {
    if (project.publishDateExpanded?.mailingListId) {
      dispatch(push(linksConstants.MAILING_LISTS.EDIT(project.publishDateExpanded.mailingListId, 'main')));
    }
  };

  return (
    <>
      {loading && <Loader />}
      {!loading && (
        <>
          <EmailSummaryHeader>
            <div>
              <EmailStatisticsTitle>{t('pages.emailStatistics.subject')}</EmailStatisticsTitle>
              <Typography variant="h6">{stats?.CampaignSubject}</Typography>
            </div>
            <div style={{ marginLeft: '32px' }}>
              <EmailStatisticsTitle>{t('pages.emailStatistics.delivered')}</EmailStatisticsTitle>
              <Typography variant="h6">
                {stats &&
                  new Date(
                    parseInt(
                      (stats.CampaignDeliveredOn || '').substring(6, (stats.CampaignDeliveredOn || '').length - 7)
                    )
                  ).toLocaleString()}
              </Typography>
            </div>
            {project.publishDateExpanded?.mailingListId && (
              <div style={{ marginLeft: 'auto' }}>
                <EmailStatisticsTitle>{project.publishDateExpanded?.mailingList?.name}</EmailStatisticsTitle>
                <Button
                  variant="contained"
                  size="medium"
                  color="primary"
                  startIcon={<ListAltIcon />}
                  disabled={!project.publishDateExpanded?.mailingListId}
                  onClick={() => openMailingList()}
                >
                  {t('pages.emailStatistics.openMailingList')}
                </Button>
              </div>
            )}
          </EmailSummaryHeader>
          <Grid container spacing={4} style={{ marginTop: '15px', marginBottom: '15px' }}>
            <Grid item xs={3}>
              <Paper style={{ padding: '15px' }}>
                <EmailStatisticsTitle>{t('pages.emailStatistics.recipients')}</EmailStatisticsTitle>
                <EmailStatisticsItemContainer>
                  <EmailStatisticsIconContainer style={{ backgroundColor: '#2D2D2D' }}>
                    <GroupIcon style={{ color: '#FFFFFF' }} />
                  </EmailStatisticsIconContainer>
                  <div>
                    <EmailStatisticsTextBig>{stats?.Sent}</EmailStatisticsTextBig>
                  </div>
                </EmailStatisticsItemContainer>
              </Paper>
            </Grid>
            <Grid item xs={3}>
              <Paper style={{ padding: '15px' }}>
                <EmailStatisticsTitle>{t('pages.emailStatistics.opens')}</EmailStatisticsTitle>
                <EmailStatisticsItemContainer>
                  <EmailStatisticsIconContainer style={{ backgroundColor: '#5CCDDE' }}>
                    <ImportContactsIcon style={{ color: '#FFFFFF' }} />
                  </EmailStatisticsIconContainer>
                  <div>
                    <EmailStatisticsTextBig>
                      {stats && Math.round(((stats.UniqueOpens * 100) / stats.Sent + Number.EPSILON) * 100) / 100}%
                    </EmailStatisticsTextBig>
                    <EmailStatisticsTextSmall>
                      <span style={{ color: '#5CCDDE' }}>{stats?.UniqueOpens}</span>{' '}
                      {t('pages.emailStatistics.uniqueOpens')}
                      <br />
                      <span style={{ color: '#5CCDDE' }}>{stats?.TotalOpens}</span>{' '}
                      {t('pages.emailStatistics.totalOpens')}
                    </EmailStatisticsTextSmall>
                  </div>
                </EmailStatisticsItemContainer>
              </Paper>
            </Grid>
            <Grid item xs={3}>
              <Paper style={{ padding: '15px' }}>
                <EmailStatisticsTitle>{t('pages.emailStatistics.clicks')}</EmailStatisticsTitle>
                <EmailStatisticsItemContainer>
                  <EmailStatisticsIconContainer style={{ backgroundColor: '#F2C14A' }}>
                    <MouseIcon style={{ color: '#FFFFFF' }} />
                  </EmailStatisticsIconContainer>
                  <div>
                    <EmailStatisticsTextBig>
                      {stats && stats.TotalLinkClicks
                        ? Math.round(((stats.UniqueLinkClicks * 100) / stats.TotalLinkClicks + Number.EPSILON) * 100) /
                          100
                        : 0}
                      %
                    </EmailStatisticsTextBig>
                    <EmailStatisticsTextSmall>
                      <span style={{ color: '#F2C14A' }}>{stats?.UniqueLinkClicks}</span>{' '}
                      {t('pages.emailStatistics.uniqueClicks')}
                      <br />
                      <span style={{ color: '#F2C14A' }}>{stats?.TotalLinkClicks}</span>{' '}
                      {t('pages.emailStatistics.totalClicks')}
                    </EmailStatisticsTextSmall>
                  </div>
                </EmailStatisticsItemContainer>
              </Paper>
            </Grid>
            <Grid item xs={3}>
              <Paper style={{ padding: '15px' }}>
                <EmailStatisticsTitle>{t('pages.emailStatistics.unsubscribers')}</EmailStatisticsTitle>
                <EmailStatisticsItemContainer>
                  <EmailStatisticsIconContainer style={{ backgroundColor: '#E16053' }}>
                    <UnsubscribeIcon style={{ color: '#FFFFFF' }} />
                  </EmailStatisticsIconContainer>
                  <div>
                    <EmailStatisticsTextBig>
                      {stats && Math.round(((stats.TotalUnsubscribes * 100) / stats.Sent + Number.EPSILON) * 100) / 100}
                      %
                    </EmailStatisticsTextBig>
                    <EmailStatisticsTextSmall>
                      <span style={{ color: '#E16053' }}>{stats?.TotalUnsubscribes}</span>{' '}
                      {t('pages.emailStatistics.totalUnsubscribers')}
                    </EmailStatisticsTextSmall>
                  </div>
                </EmailStatisticsItemContainer>
              </Paper>
            </Grid>
          </Grid>
          <Grid container spacing={4}>
            <Grid item xs={4}>
              <Paper style={{ padding: '20px' }}>
                <EmailStatisticsTitle>Campaign Status</EmailStatisticsTitle>
                <Doughnut
                  type="doughnut"
                  data={chartData}
                  options={chartOptions}
                  style={{ margin: '20px 30px 0 30px' }}
                />
              </Paper>
            </Grid>
            <Grid item xs={8}>
              <EmailStatsSubscribers project={project} />
              <EmailStatsLinksActivity project={project} />
            </Grid>
          </Grid>
        </>
      )}
    </>
  );
};

const EmailSummaryHeader = styled.div`
  display: flex;
  align-items: top;
`;

const EmailStatisticsTitle = styled.div`
  font-weight: bold;
  color: #545871;
`;

const EmailStatisticsItemContainer = styled.div`
  display: flex;
  align-items: center;
  margin-top: 10px;
`;

const EmailStatisticsIconContainer = styled.div`
  width: 60px;
  height: 60px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 15px;
`;

const EmailStatisticsTextBig = styled.div`
  font-size: 24px;
  font-weight: bold;
  color: #545871;
`;

const EmailStatisticsTextSmall = styled.div`
  font-size: 11px;
  line-height: 11px;
  color: #545871;
`;

export default EmailStatsSummary;
