import React, { FunctionComponent } from 'react';
import { useTranslation } from 'react-i18next';
import { PageContainer, PageContent } from '../../PageStyledComponents';
import { formatNoteDate, useTypedSelector } from '../../../../utils';
import { usePaginationWatch } from '../../../Shared/DataTable/Pagination/usePaginationWatch';
import { useTableSearchWatch } from '../../../Shared/DataTable/useTableSearchWatch';
import { PaginationStoreModule } from '../../../../store/Pagination/types';
import DataTable from '../../../Shared/DataTable/DataTable';
import { TableSearchStoreModule } from '../../../../store/TableSearch/types';
import PageHeader from '../../../Shared/Layout/PageHeader';
import { push } from 'connected-react-router';
import linksConstants from '../../../../config/app/linksConstants';
import KeyboardArrowLeftIcon from '@material-ui/icons/KeyboardArrowLeft';
import { useDispatch } from 'react-redux';
import { adminOnlineUsersOperations } from '../../../../store/AdminOnlineUsers';
import { AdminOnlineUser } from '../../../../store/AdminOnlineUsers/types';
import UserOrganizationsWithKeywords from '../Users/Partials/UserOrganizationWithKeywords';
import UserDataCenterWithOrganizationInheritance from '../Users/Partials/UserDataCenterWithOrganizationInheritance';

type AdminOnlineUsersPageProps = {};

const AdminOnlineUsersPage: FunctionComponent<AdminOnlineUsersPageProps> = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const users = useTypedSelector((state) => state.adminOnlineUsers.users);
  const isFailed = useTypedSelector((state) => state.adminOnlineUsers.index.isFailed);
  const isLoading = useTypedSelector((state) => state.adminOnlineUsers.index.isLoading);
  const pagination = useTypedSelector((state) => state.adminOnlineUsers.pagination);
  const tableSearch = useTypedSelector((state) => state.adminOnlineUsers.tableSearch);

  usePaginationWatch(pagination, [adminOnlineUsersOperations.index]);
  useTableSearchWatch<AdminOnlineUser>(tableSearch, [adminOnlineUsersOperations.index]);

  const backButton = {
    onClick: () => dispatch(push(linksConstants.ADMIN_PROJECTS)),
    label: t('common.back'),
    icon: <KeyboardArrowLeftIcon />
  };

  return (
    <PageContainer>
      <PageHeader title={t('pages.adminOnlineUsers.title')} leftActionButtons={[backButton]} />
      <PageContent>
        <DataTable<AdminOnlineUser, 'fullName'>
          enableSearch={true}
          columnDefinitions={[
            {
              name: 'email',
              render: (connected) => <div title={connected.user.email}>{connected.user.email}</div>,
              sortable: true
            },
            {
              name: 'fullName',
              render: (connected) => (
                <>
                  {connected.user.firstName} {connected.user.lastName}
                </>
              ),
              isEmpty: (connected) => !connected.user.firstName && !connected.user.lastName,
              sortable: true
            },
            {
              name: 'organizations',
              render: (connected) => (
                <UserOrganizationsWithKeywords
                  key={`${connected.user.id}-organization-with-keyword`}
                  userId={connected.user.id}
                  organizations={connected.organizations}
                  showKeywords={false}
                />
              ),
              sortable: false
            },
            {
              name: 'externalDataCenter',
              label: t('pages.commonInputs.inputs.dataCenter'),
              render: (connected) => (
                <UserDataCenterWithOrganizationInheritance
                  key={`${connected.user.id}-data-centers`}
                  userDataCenter={connected.externalDataCenter}
                  organizations={connected.organizations}
                />
              ),
              sortable: false
            },
            {
              name: 'createdAt',
              render: (connected) => <>{formatNoteDate(connected.createdAt)}</>,
              sortable: true
            }
          ]}
          tPath={'pages.adminOnlineUsers.table'}
          data={users}
          tableSearchProps={{
            tableSearch,
            module: TableSearchStoreModule.ADMIN_ONLINE_USERS
          }}
          paginationProps={{
            pagination,
            module: PaginationStoreModule.ADMIN_ONLINE_USERS
          }}
          disablePagination={true}
          isFailed={isFailed}
          isLoading={isLoading}
        />
      </PageContent>
    </PageContainer>
  );
};

export default AdminOnlineUsersPage;
