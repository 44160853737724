import update from 'immutability-helper';
import {
  StoriesActionsConstants,
  StoriesActionType,
  StoriesState,
  Story,
  StoryContentContributor,
  StoryContentContributorsActionType,
  StoryContentContributorsState
} from './types';
import { normalizeStories } from '../helpers';
import { createNetworkErrorObject, getLocationPaginationState } from '../../utils';
import { AuthActionsConstants } from '../Auth/types';
import { createPaginationReducer } from '../Pagination/reducers';
import { PaginationStoreModule } from '../Pagination/types';
import { createTableSearchReducer } from '../TableSearch/reducers';
import { TableSearchStoreModule } from '../TableSearch/types';
import Toast from '../../components/Shared/Toast/Toast';

const initialState: StoriesState = {
  list: {
    entities: {},
    result: []
  },
  tableSearch: {
    order: {}
  },
  pagination: getLocationPaginationState(),
  index: {
    error: null,
    isFailed: false,
    isLoading: false
  },
  aiGeneration: {
    storyId: '',
    channelsDone: []
  }
};

const storiesPaginationReducer = createPaginationReducer<StoriesState, Story>(PaginationStoreModule.STORY);

const storiesTableSearchReducer = createTableSearchReducer<Story, StoriesState>(TableSearchStoreModule.STORY);

export function storiesReducer(state = initialState, action: WithLogoutAction<StoriesActionType>) {
  switch (action.type) {
    case StoriesActionsConstants.INDEX_BEGIN: {
      return update(state, {
        index: {
          isFailed: { $set: false },
          isLoading: { $set: true },
          error: { $set: null }
        }
      });
    }

    case StoriesActionsConstants.INDEX_SUCCESS: {
      const data = normalizeStories(action.payload.data);

      return update(state, {
        index: {
          isLoading: { $set: false },
          isFailed: { $set: false },
          error: { $set: null }
        },
        list: {
          entities: { $set: data.entities },
          result: { $set: data.result }
        },
        pagination: {
          $set: action.payload.pagination
        }
      });
    }

    case StoriesActionsConstants.INDEX_FAILED: {
      const networkError = createNetworkErrorObject(action.payload.error);
      return update(state, {
        index: {
          isLoading: { $set: false },
          isFailed: { $set: true },
          error: { $set: networkError }
        },
        list: {
          entities: { $set: {} },
          result: { $set: [] }
        }
      });
    }

    case StoriesActionsConstants.REMOVE_STORY:
      return update(state, {
        list: {
          result: { $set: state.list.result.filter((id) => id !== action.payload) }
        }
      });

    case StoriesActionsConstants.BULK_AI_CONTENT_GENERATION_STARTED: {
      return update(state, {
        aiGeneration: {
          storyId: {
            $set: action.payload
          }
        }
      });
    }

    case StoriesActionsConstants.BULK_AI_CONTENT_GENERATION_CHANNEL_DONE: {
      return update(state, {
        aiGeneration: {
          storyId: {
            $set: action.payload.storyId
          },
          channelsDone: {
            $push: [action.payload.channel.id]
          }
        }
      });
    }

    case StoriesActionsConstants.BULK_AI_CONTENT_GENERATION_DONE: {
      const { t } = action.payload;
      Toast.success(t('pages.story.contentCreationSuccess').toString());
      return update(state, {
        aiGeneration: {
          storyId: {
            $set: ''
          },
          channelsDone: {
            $set: []
          }
        }
      });
    }

    case StoriesActionsConstants.SET_AI_GENERATION:
      return update(state, {
        aiGeneration: {
          storyId: {
            $set: action.payload.storyId
          },
          channelsDone: {
            $apply: (current: string[]) => (action.payload.channelsDone ? action.payload.channelsDone : current)
          }
        }
      });

    case AuthActionsConstants.LOGOUT:
      return update(state, {
        $set: initialState
      });

    default: {
      return storiesTableSearchReducer(storiesPaginationReducer(state, action), action) as StoriesState;
    }
  }
}

const contributorsInitialState: StoryContentContributorsState = {
  list: {
    entities: {},
    result: []
  },
  tableSearch: {
    order: {}
  },
  pagination: getLocationPaginationState(),
  index: {
    error: null,
    isFailed: false,
    isLoading: false
  }
};

const storyContentContributorsPaginationReducer = createPaginationReducer<
  StoryContentContributorsState,
  StoryContentContributor
>(PaginationStoreModule.STORY_CONTENT_CONTRIBUTORS);

const storyContentContributorsTableSearchReducer = createTableSearchReducer<
  StoryContentContributor,
  StoryContentContributorsState
>(TableSearchStoreModule.STORY_CONTENT_CONTRIBUTORS);

export function storyContentContributorsReducer(
  state = contributorsInitialState,
  action: WithLogoutAction<StoryContentContributorsActionType>
) {
  switch (action.type) {
    case StoriesActionsConstants.INDEX_CONTRIBUTORS_BEGIN: {
      return update(state, {
        index: {
          isFailed: { $set: false },
          isLoading: { $set: true },
          error: { $set: null }
        }
      });
    }

    case StoriesActionsConstants.INDEX_CONTRIBUTORS_SUCCESS: {
      const data = normalizeStories(action.payload.data);

      return update(state, {
        index: {
          isLoading: { $set: false },
          isFailed: { $set: false },
          error: { $set: null }
        },
        list: {
          entities: { $set: data.entities },
          result: { $set: data.result }
        },
        pagination: {
          $set: action.payload.pagination
        }
      });
    }

    case StoriesActionsConstants.INDEX_CONTRIBUTORS_FAILED: {
      const networkError = createNetworkErrorObject(action.payload.error);
      return update(state, {
        index: {
          isLoading: { $set: false },
          isFailed: { $set: true },
          error: { $set: networkError }
        },
        list: {
          entities: { $set: {} },
          result: { $set: [] }
        }
      });
    }

    case AuthActionsConstants.LOGOUT:
      return update(state, {
        $set: contributorsInitialState
      });

    default: {
      return storyContentContributorsTableSearchReducer(
        storyContentContributorsPaginationReducer(state, action),
        action
      ) as StoryContentContributorsState;
    }
  }
}
