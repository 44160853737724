import { AppConfig } from '../../typings';
import { StoryChannelType } from '../../store/Stories/types';

const app: AppConfig = {
  enableApplicationGuide: false,
  storyFieldToolbar: [
    `undo redo | fontfamily fontsize | forecolor backcolor | link | 
    bold italic underline | alignleft aligncenter alignright alignjustify | emoticons`
  ],
  acceptedAttachments: {
    videoFormats: ['mp4', 'mov'],
    imageFormats: ['png', 'jpg', 'jpeg'],
    [StoryChannelType.PRINT]: {
      accept: {
        'image/png': [],
        'image/jpeg': [],
        'image/jpg': []
      },
      extensions: 'png, jpg, jpeg',
      maxImageSize: 10000000
    },
    [StoryChannelType.EMAIL]: {
      accept: {
        'image/png': [],
        'image/jpeg': [],
        'image/jpg': []
      },
      extensions: 'png, jpg, jpeg',
      maxImageSize: 10000000
    },
    [StoryChannelType.FACEBOOK]: {
      accept: {
        'image/png': [],
        'image/jpeg': [],
        'image/jpg': [],
        'video/mp4': [],
        'video/quicktime': []
      },
      extensions: 'png, jpg, jpeg, mp4, mov',
      maxImageSize: 10000000
    },
    [StoryChannelType.TWITTER]: {
      accept: {
        'image/png': [],
        'image/jpeg': [],
        'image/jpg': [],
        'video/mp4': [],
        'video/quicktime': []
      },
      extensions: 'png, jpg, jpeg, mp4, mov',
      maxImageSize: 5000000
    },
    [StoryChannelType.INSTAGRAM]: {
      accept: {
        'image/png': [],
        'image/jpeg': [],
        'image/jpg': [],
        'video/mp4': [],
        'video/quicktime': []
      },
      extensions: 'png, jpg, jpeg, mp4, mov',
      maxImageSize: 8000000
    },
    [StoryChannelType.CALENDAR_EVENT]: {
      accept: {
        'image/png': [],
        'image/jpeg': [],
        'image/jpg': []
      },
      extensions: 'png, jpg, jpeg',
      maxImageSize: 10000000
    },
    [StoryChannelType.LINKEDIN]: {
      accept: {
        'image/png': [],
        'image/jpeg': [],
        'image/jpg': [],
        'video/mp4': []
      },
      extensions: 'png, jpg, jpeg, mp4',
      maxImageSize: 5000000
    },
    [StoryChannelType.BLOG]: {
      accept: {
        'image/png': [],
        'image/jpeg': [],
        'image/jpg': [],
        'video/mp4': []
      },
      extensions: 'png, jpg, jpeg, mp4',
      maxImageSize: 800000
    }
  },
  brandfolderCollections: ['Vibrant Churches', 'Jewish Traditions', 'Vibrant Communities'],
  secondsToWaitBeforeForceCheckOut: 30,
  emailProjectAutoSaveTimeout: 5000,
  publicPageLayoutRoutes: ['org_calendar'],
  authLayoutRoutes: ['login', 'sign_up', 'forgot_password', 'resend_verification', 'verify', 'reset', 'unsubscribed'],
  signedInLayoutRoutes: [
    'dashboard',
    'story',
    'users',
    'logout',
    'my_account',
    'project_details',
    'my_organization',
    'new',
    'mailing_lists',
    'email_projects',
    'print_projects',
    'system_settings',
    'support',
    'keywords_administration',
    'keyword_groups_administration',
    'password_policies_administration',
    'roles_administration',
    'organizations_administration',
    'print_templates_administration',
    'email_templates_administration',
    'house_ads',
    'ads',
    'statistics',
    'clone',
    'ads_statistics',
    'online_users',
    'mailing_lists_dashboard',
    'projects_administration',
    'system_reports',
    'flip_book',
    'calendar',
    'sharing_center'
  ],
  fullPageLayoutRoutes: [
    'newsletter',
    'bulletin',
    'playground',
    'email_editor',
    'email_templates_editor',
    'email_preview'
  ],

  colorPalette: ['#29586e', '#7295a9', '#a88f41', '#5f2664', '#b03454', '#004673', '#d8e5e6', '#f7d723'],

  colorSwatchElements: [
    { selector: 'p', name: 'paragraph', cssParam: 'color' },
    { selector: 'h1, h2', name: 'heading', cssParam: 'color' },
    { selector: '[data-element-type="header-and-footer"]', name: 'background', cssParam: 'background-color' },
    { selector: 'a', name: 'link', cssParam: 'color' },
    { selector: '[data-element-type="button"]', name: 'button', cssParam: 'background-color' }
  ],

  maxInputLength: 200,
  maxTableColumnWidth: 250,

  textLimits: {
    'main-headline-text': 400,
    'article-text': 200
  },

  dateFormats: {
    projectPublishDate: 'MM/DD/YYYY',
    noteDateAndTime: 'MM/DD/YYYY HH:mm',
    lpiExpressDate: 'DD-MM-YYYY',
    inputValueDate: 'YYYY-MM-DD'
  },

  validLogoExtensions: ['jpg', 'jpeg', 'png'],
  validEmailAttachmentExtensions: ['jpg', 'jpeg', 'png', 'pdf'],
  maxLogoSize: 1e7, // 10MB
  monthNames: [
    'January',
    'February',
    'March',
    'April',
    'May',
    'June',
    'July',
    'August',
    'September',
    'October',
    'November',
    'December'
  ],
  filesizeUnits: ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB']
};

export default app;
