import { useEffect, useRef, useState } from 'react';
import { ArticleHorizontal, ArticleVertical, ImageData, ImageDropObjectData, ItemTypes } from '../../../types';
import { useDrop } from 'react-dnd';
import Toast from '../../../../../../Shared/Toast/Toast';
import { ImageProcessingResponse } from '../../../../../../../store/Images/types';
import { useTranslation } from 'react-i18next';
import { getDefaultImage } from '../Factories/initialComponentFactory';
import { toCamelCase, trimHtml } from '../../../../../../../utils';
import { AdminOrganization } from '../../../../../../../store/AdminOrganizations/types';
import { StoryFieldName } from '../../../../../../../store/Stories/types';

export const useArticle = (
  data: ArticleHorizontal | ArticleVertical,
  onChange: (data: ArticleVertical | ArticleHorizontal) => void,
  onImageDrop: (data: ImageDropObjectData, imageData: ImageData) => Promise<ImageProcessingResponse | null>,
  sourceProjectId: string | null,
  organization: AdminOrganization | null
) => {
  const { t } = useTranslation();
  const mainElement = useRef<HTMLDivElement | null>(null);
  const [applyingStory, setApplyingStory] = useState<boolean>(false);
  const [showImage, setShowImage] = useState<boolean>(
    data.params.image ? data.params.showImage === true || data.params.showImage === undefined : false
  );
  const [showReadMore, setShowReadMore] = useState<boolean>(data.params.showReadMore);
  const [customReadMore, setCustomReadMore] = useState<boolean>(!!data.params.customReadMore);
  const [customReadMoreLink, setCustomReadMoreLink] = useState<string>(
    data.params.customReadMoreLink || data.params.readMoreLinkUrl || ''
  );
  const [customReadMoreText, setCustomReadMoreText] = useState<string>(
    data.params.customReadMoreText || (data.params.readMoreText || '').replace('&gt;', '>')
  );

  const [{ canDrop, isOver }, drop] = useDrop({
    accept: [ItemTypes.DESIGNER_STORY, ItemTypes.STORY],
    // @ts-ignore
    drop: (e: ImageDropObjectData) => onDrop(e),
    collect: (monitor) => ({
      isOver: monitor.isOver(),
      canDrop: monitor.canDrop()
    })
  });

  const onDrop = async (event: ImageDropObjectData) => {
    setApplyingStory(true);
    try {
      if (event.designerStory) {
        const updatedData: ArticleHorizontal | ArticleVertical = {
          ...data
        };
        if (event.designerStory.images && event.designerStory.images.length && updatedData.params.image) {
          const uploadResponse = await onImageDrop(event, updatedData.params.image);

          if (uploadResponse) {
            updatedData.params.image = {
              ...updatedData.params.image,
              linkUrl: event.designerStory.images[0].link || '',
              url: uploadResponse.url,
              width: uploadResponse.size[0],
              height: uploadResponse.size[1],
              positionX: 0,
              positionY: 0
            };
          }
        }

        if (event.designerStory.title && updatedData.params.headline) {
          updatedData.params.headline = keepArticleContentStyling(
            updatedData.params.headline,
            event.designerStory.title
          ).html;
        }

        if (event.designerStory.body && updatedData.params.body) {
          const articleBody = keepArticleContentStyling(updatedData.params.body, event.designerStory.body);
          updatedData.params.body = articleBody.html;
          /** Disabled due to #14849 */
          // updatedData.params.showReadMore = articleBody.more;
        }

        updatedData.params.readMoreLinkUrl = getReadMoreLinkUrl(
          organization,
          sourceProjectId,
          event.designerStory.name
        );

        onChange(updatedData);
      }

      if (event.story) {
        const body = event.story.fields.find((field) => field.name === StoryFieldName.BODY)?.value;
        const title = event.story.fields.find((field) => field.name === StoryFieldName.SUBJECT)?.value;
        const updatedData: ArticleHorizontal | ArticleVertical = {
          ...data
        };
        if (event.story.attachments && event.story.attachments.length && updatedData.params.image) {
          const uploadResponse = await onImageDrop(event, updatedData.params.image);

          if (uploadResponse) {
            updatedData.params.image = {
              ...updatedData.params.image,
              linkUrl: '',
              url: uploadResponse.url,
              width: uploadResponse.size[0],
              height: uploadResponse.size[1],
              positionX: 0,
              positionY: 0
            };
          }
        }

        if (title && updatedData.params.headline) {
          updatedData.params.headline = keepArticleContentStyling(updatedData.params.headline, title.toString()).html;
        }

        if (body && updatedData.params.body) {
          const articleBody = keepArticleContentStyling(updatedData.params.body, body.toString());
          updatedData.params.body = articleBody.html;
          /** Disabled due to #14849 */
          // updatedData.params.showReadMore = articleBody.more;
        }

        updatedData.params.readMoreLinkUrl = getReadMoreLinkUrl(organization, sourceProjectId);

        onChange(updatedData);
      }
    } catch (e) {
      /** Error on file upload */
      Toast.error(t('notifications.uploadEmailFile.storyApplyError'));
    } finally {
      setApplyingStory(false);
    }
  };

  const addStylesToEveryElement = (cssText: string, element: string, htmlString: string): string => {
    const parser = new DOMParser();
    const document = parser.parseFromString(htmlString, 'text/html');

    document.body.querySelectorAll(element).forEach((node) => {
      (node as HTMLElement).style.cssText += cssText;
    });

    return document.body.innerHTML;
  };

  const addTargetBlankToLinks = (htmlString: string): string => {
    const parser = new DOMParser();
    const document = parser.parseFromString(htmlString, 'text/html');

    document.body.querySelectorAll('a').forEach((node) => {
      node.setAttribute('target', '_blank');
      node.setAttribute('rel', 'noopener');
    });

    return document.body.innerHTML;
  };

  const keepArticleContentStyling = (currentContent: string, storyContent: string) => {
    const inlineEditorRegex = /<p\b[^>]*>(?:<em>)?(?:<strong>)?(?:<em>)?<span\b[^>]*>(.*?)<\/p>/g;
    if (inlineEditorRegex.test(currentContent)) {
      const parser = new DOMParser();
      const document = parser.parseFromString(currentContent, 'text/html');
      const p = document.querySelectorAll('p')[0];

      let spanStyle = '';
      const allowedInlineEditorStyles = ['color', 'background-color', 'font-size', 'font-family'];
      // there may be more than one <span> coming from inline editor, lets combine styles together
      // but allow only 'color', 'background-color', 'font-size' and 'font-family' props
      document.querySelectorAll('span').forEach((node: HTMLElement) => {
        if (node && node.style) {
          allowedInlineEditorStyles.forEach((prop) => {
            if ((node.style as any)[toCamelCase(prop)]) {
              spanStyle += `${prop}: ${(node.style as any)[toCamelCase(prop)]};`;
              console.log(prop, (node.style as any)[toCamelCase(prop)]);
            }
          });
        }
      });

      const em = document.querySelectorAll('em')[0];
      const strong = document.querySelectorAll('strong')[0];

      if (em) {
        spanStyle += 'font-style: italic;';
      }
      if (strong) {
        spanStyle += 'font-weight: bold;';
      }

      const trimmed = trimHtml(storyContent, { limit: 200, preserveTags: true });

      let htmlString = addStylesToEveryElement(p.style.cssText, 'p', trimmed.html);
      htmlString = addStylesToEveryElement(spanStyle, 'span', htmlString);
      htmlString = addStylesToEveryElement(spanStyle, 'a', htmlString);
      htmlString = addTargetBlankToLinks(htmlString);
      return {
        html: htmlString,
        more: trimmed.more
      };
    }

    const trimmed = trimHtml(storyContent, { limit: 200, preserveTags: true });
    return {
      html: addTargetBlankToLinks(trimmed.html),
      more: trimmed.more
    };
  };

  useEffect(() => {
    if (mainElement.current) {
      const readMoreLink: HTMLAnchorElement | null = mainElement.current.querySelector(
        'a[data-placeholder-name="read-more"]'
      );

      if (readMoreLink) {
        if (customReadMore) {
          readMoreLink.innerText = customReadMoreText;
          readMoreLink.setAttribute('href', customReadMoreLink);
        } else {
          readMoreLink.innerHTML = data.params.readMoreText || 'Read More >';
          readMoreLink.setAttribute('href', data.params.readMoreLinkUrl);
        }
      }
    }
  }, [customReadMore, customReadMoreText, customReadMoreLink]);

  return {
    mainElement,
    showImage,
    setShowImage: (val: boolean) => {
      setShowImage(val);
      onChange({
        ...data,
        params: {
          ...data.params,
          showImage: val,
          image: data.params.image || getDefaultImage(100, 100)
        }
      });
    },

    showReadMore,
    setShowReadMore: (val: boolean) => {
      setShowReadMore(val);
      onChange({
        ...data,
        params: {
          ...data.params,
          showReadMore: val
        }
      });
    },

    customReadMore,
    setCustomReadMore: (val: boolean) => {
      setCustomReadMore(val);
      onChange({
        ...data,
        params: {
          ...data.params,
          customReadMore: val
        }
      });
    },

    customReadMoreText,
    setCustomReadMoreText: (val: string) => {
      setCustomReadMoreText(val);
      onChange({
        ...data,
        params: {
          ...data.params,
          customReadMoreText: val
        }
      });
    },

    customReadMoreLink,
    setCustomReadMoreLink: (val: string) => {
      setCustomReadMoreLink(val);
      onChange({
        ...data,
        params: {
          ...data.params,
          customReadMoreLink: val
        }
      });
    },

    drop,
    isDraggingOver: canDrop && isOver,
    applyingStory
  };
};

export const getReadMoreLinkUrl = (
  organization: AdminOrganization | null,
  projectId: string | null,
  anchorId?: string
) => {
  if (!organization || !organization.readMoreLink) {
    return '';
  }
  if (!projectId) {
    return organization.readMoreLink;
  }

  return `${organization.readMoreLink}?pid=${projectId}${anchorId ? `&anchor=${anchorId}` : ''}`;
};
