import React, { useEffect, useState } from 'react';
import { StoryAttachmentFile, StoryChannelAttachment, StoryChannelType } from '../../../../../store/Stories/types';
import AttachmentsDropzone from './AttachmentsDropzone';
import AttachmentsList from './AttachmentsList';
import { storiesOperations } from '../../../../../store/Stories';
import { useTranslation } from 'react-i18next';
import LoadingModal from '../../../../Shared/Loading/LoadingModal';
import Toast from '../../../../Shared/Toast/Toast';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import DeleteStoryChannelAttachmentWindow from '../../Windows/DeleteStoryChannelAttachmentWindow';
import useOpenHandler from '../../../../../hooks/useOpenHandler';
import { Accept } from 'react-dropzone';
import { getChannelVideoLength, isVideo } from '../../Utils/storyUtils';
import { createNetworkErrorObject, secondsToHms } from '../../../../../utils';
import { Alert } from '@material-ui/lab';

type ChannelAttachmentsProps = {
  storyId: string;
  channelId: string;
  initialAttachments: StoryChannelAttachment[];
  disabled?: boolean;
  mimeTypes: {
    accept: Accept;
    extensions: string;
  } | null;
  channelType: StoryChannelType;
};

const ChannelAttachments: React.FC<ChannelAttachmentsProps> = ({
  initialAttachments,
  channelId,
  storyId,
  mimeTypes,
  disabled = false,
  channelType
}) => {
  const { t } = useTranslation();
  const [uploadingFiles, setUploadingFiles] = useState<boolean>(false);
  const [attachments, setAttachments] = useState<StoryChannelAttachment[]>([]);
  const [activeAttachment, setActiveAttachment] = useState<StoryChannelAttachment | null>(null);
  const [videoError, setVideoError] = useState<string | null>(null);

  const [deleteAttachmentWindowOpen, onDeleteAttachmentWindowOpen, onDeleteAttachmentWindowClose] = useOpenHandler();

  useEffect(() => {
    setAttachments(initialAttachments);
  }, [initialAttachments]);

  const handleAcceptedFiles = async (acceptedFiles: StoryAttachmentFile[]) => {
    setUploadingFiles(true);

    let uploadedCounter = 0;

    const videoAlreadyUploaded = attachments.find((att) => isVideo(att.filename));

    for (const acceptedFile of acceptedFiles) {
      if (videoAlreadyUploaded && isVideo((acceptedFile as File).name)) {
        Toast.info(t('notifications.story.onlyOneVideoAllowed'));
        continue;
      }
      try {
        const newAttachment = await storiesOperations.uploadAttachment(
          storyId,
          channelId,
          acceptedFile as File,
          (acceptedFile as File).name
        );
        setAttachments((prevState) => [...prevState, newAttachment]);
        uploadedCounter++;
      } catch (e) {
        const error = createNetworkErrorObject(e);

        if (error.message) {
          setVideoError(error.message);
        } else {
          console.log('Attachments upload error', e);
        }
      }
    }

    if (uploadedCounter === acceptedFiles.length) {
      Toast.success(t('notifications.story.attachmentsUploaded'));
    } else {
      if (uploadedCounter === 0) {
        Toast.error(t('notifications.story.attachmentsNotUploaded'));
      } else {
        Toast.info(t('notifications.story.notAllAttachmentsUploaded'));
      }
    }

    setUploadingFiles(false);
  };

  const onReorderAttachments = async (attachments: StoryChannelAttachment[]) => {
    try {
      await storiesOperations.reorderAttachments(storyId, channelId, attachments);
      setAttachments(attachments);
      Toast.success(t('notifications.story.attachmentsReordered'));
    } catch (e) {
      Toast.error(t('notifications.story.attachmentsNotReordered'));
    }
  };

  const onRemoveAttachment = async (attachment: StoryChannelAttachment) => {
    try {
      await storiesOperations.removeAttachment(storyId, channelId, attachment.id);
      setAttachments((prevState) => prevState.filter((att) => att.id !== attachment.id));
      onDeleteAttachmentWindowClose();
      Toast.success(t('notifications.story.attachmentRemoved'));
    } catch (e) {
      Toast.error(t('notifications.story.attachmentNotRemoved'));
    }
  };

  const onChangeAttachmentCaption = async (attachment: StoryChannelAttachment, caption: string) => {
    try {
      await storiesOperations.changeAttachmentCaption(storyId, channelId, attachment.id, caption);
      setAttachments((prevState) =>
        prevState.map((att) => {
          if (att.id === attachment.id) {
            att.filename = caption;
          }
          return att;
        })
      );
      Toast.success(t('notifications.story.attachmentCaptionChanged'));
    } catch (e) {
      Toast.error(t('notifications.story.attachmentCaptionCouldNotBeChanged'));
    }
  };

  return (
    <>
      {uploadingFiles && <LoadingModal message={t('pages.story.attachments.uploadingMessage')} />}
      {videoError && (
        <Alert variant="outlined" severity="error" style={{ marginTop: '5px' }} onClose={() => setVideoError(null)}>
          {t(`notifications.story.${videoError}`, {
            duration: secondsToHms(getChannelVideoLength(channelType)[videoError === 'videoTooShort' ? 0 : 1])
          })}
        </Alert>
      )}
      {mimeTypes && !disabled && (
        <AttachmentsDropzone
          label="Drag & drop some files here"
          buttonLabel="click to select files"
          hintLabel={`Allowed extensions: ${mimeTypes.extensions}`}
          accept={mimeTypes.accept}
          handleAcceptedFiles={handleAcceptedFiles}
          maxSize={50000000} // 50MB
          attachments={attachments}
          channelType={channelType}
        />
      )}
      <DndProvider backend={HTML5Backend}>
        <AttachmentsList
          attachments={attachments}
          onReorderAttachments={onReorderAttachments}
          onRemoveAttachment={(attachment) => {
            setActiveAttachment(attachment);
            onDeleteAttachmentWindowOpen();
          }}
          onChangeAttachmentCaption={onChangeAttachmentCaption}
          disabled={disabled}
        />
      </DndProvider>
      {activeAttachment && deleteAttachmentWindowOpen && (
        <DeleteStoryChannelAttachmentWindow
          fullScreenOnMobile
          attachment={activeAttachment}
          onCloseClick={onDeleteAttachmentWindowClose}
          open={deleteAttachmentWindowOpen}
          onSuccess={onRemoveAttachment}
        />
      )}
    </>
  );
};

export default ChannelAttachments;
