import { ProjectModelComponent, Separator } from '../../../types';
import { TFunction } from 'i18next';
import { AdminSelectInput, AdminTextInput } from '../../../../../Admin/Shared/AdminFormInputs';
import * as Yup from 'yup';
import update from 'immutability-helper';
import React from 'react';
import { ComponentEditFormReturnType } from '../index';
import { Grid, MenuItem } from '@material-ui/core';
import { CSSProperties } from '@material-ui/core/styles/withStyles';
import { FormikProps } from 'formik';
import { AdminInputLabel } from '../../../../../Admin/AdminStyledComponents';
import ColorPicker from '../../../../../../Shared/ColorPicker/ColorPicker';

type Values = {
  separator: {
    style: {
      thickness: number;
      typeStyle: string;
      color: string;
    };
    colorSwatchIgnore: boolean;
  };
};

export const separator = (component: Separator, t: TFunction): ComponentEditFormReturnType<Separator> => {
  const form = (props: FormikProps<Values>) => (
    <Grid container spacing={3}>
      <Grid item xs={5}>
        <AdminTextInput t={t} type="number" name="separator.style.thickness" section="emailEditor.separator" />
        <AdminSelectInput t={t} name="separator.style.typeStyle" section="emailEditor.separator">
          <MenuItem key="solid" value="solid">
            {t('pages.emailEditor.separator.solid')}
          </MenuItem>
          <MenuItem key="dashed" value="dashed">
            {t('pages.emailEditor.separator.dashed')}
          </MenuItem>
        </AdminSelectInput>
      </Grid>
      <Grid item xs={7}>
        <AdminInputLabel>{t('pages.emailEditor.separator.inputs.separator.style.color')}</AdminInputLabel>
        <ColorPicker
          color={props.values.separator.style.color}
          onChange={(color) => props.setFieldValue('separator.style.color', color)}
        />
      </Grid>
    </Grid>
  );

  const initialFields: Values = extractInitialFieldValues(component);

  const validationSchema = {
    separator: Yup.object().shape({
      style: Yup.object().shape({
        thickness: Yup.number().required(),
        typeStyle: Yup.string().required(),
        color: Yup.string().required()
      }),
      colorSwatchIgnore: Yup.boolean()
    })
  };

  return {
    type: component.type,
    form,
    initialFields,
    validationSchema,
    onSubmit: (params: Values, component: Separator) => {
      const colorSwatchIgnore = params.separator.colorSwatchIgnore ? ['border-color'] : [''];
      return update(component, {
        style: { $set: extractStylesForSeparator(params) },
        colorSwatchIgnore: { $set: colorSwatchIgnore }
      });
    }
  };
};

const extractStylesForSeparator = (params: Values): CSSProperties => {
  return {
    borderColor: params.separator.style.color,
    borderStyle: params.separator.style.typeStyle,
    borderWidth: `${params.separator.style.thickness}px`
  };
};

const extractInitialFieldValues = (component: Separator) => {
  const styles = component.style || {};

  const extractedStyles = {
    separator: {
      style: {
        thickness: 0,
        typeStyle: 'solid',
        color: '#000000'
      },
      colorSwatchIgnore: component.colorSwatchIgnore?.includes('border-color') || false
    }
  };

  const div = document.createElement('div');
  Object.assign(div.style, styles);
  div.style.display = 'none';
  document.body.appendChild(div);

  const computed = window.getComputedStyle(div, null);

  // @ts-ignore
  if (computed['border-top-color']) {
    // @ts-ignore
    extractedStyles.separator.style.color = computed['border-top-color'];
  }

  // @ts-ignore
  if (computed['border-top-width']) {
    // @ts-ignore
    extractedStyles.separator.style.thickness = parseInt(computed['border-top-width']);
  }

  // @ts-ignore
  if (computed['border-top-style']) {
    // @ts-ignore
    extractedStyles.separator.style.typeStyle = computed['border-top-style'];
  }

  document.body.removeChild(div);

  return extractedStyles;
};
