import { BaseSettings, RequestSettings } from '../../utils/httpRequest';

export const getTemplates = (settings: RequestSettings<{ type: string }, {}>): BaseSettings => ({
  query: {
    method: 'get',
    url: '/api/v1/template/:type'
  },
  params: settings.requestParams
});
export const getTemplate = (settings: RequestSettings<{ id: string }, {}>): BaseSettings => ({
  query: {
    method: 'get',
    url: '/api/v1/template/:id/show'
  },
  params: settings.requestParams
});
export const getTemplateHtml = (settings: RequestSettings<{ id: string }, {}>): BaseSettings => ({
  query: {
    method: 'get',
    url: '/api/v1/template/html/:id'
  },
  params: settings.requestParams
});
export const getTemplateTypes = (): BaseSettings => ({
  query: {
    method: 'get',
    url: '/api/v1/template_type'
  }
});
