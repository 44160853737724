import React, { FunctionComponent } from 'react';
import { Checkbox, FormControlLabel, FormGroup } from '@material-ui/core';
import { FilterHeader } from '../../AdminStyledComponents';
import { AdminProjectsFilters } from '../../../../../store/AdminProjects/types';
import { useTranslation } from 'react-i18next';

type DataCenterFiltersProps = {
  filters: AdminProjectsFilters['organizationExternalDataCenter'];
  onChange: (dataCenter: number) => void;
};

const DataCenterFilters: FunctionComponent<DataCenterFiltersProps> = ({ filters, onChange }) => {
  const { t } = useTranslation();

  return (
    <>
      <FilterHeader>{t('pages.commonInputs.inputs.dataCenter')}</FilterHeader>
      <FormGroup>
        {[1, 2, 3].map((dataCenterId) => (
          <FormControlLabel
            key={dataCenterId}
            control={<Checkbox checked={filters.includes(dataCenterId)} onChange={() => onChange(dataCenterId)} />}
            label={t(`common.dataCentersExtended.dc${dataCenterId}`)}
          />
        ))}
      </FormGroup>
    </>
  );
};

export default DataCenterFilters;
