import moment from 'moment';
import { httpRequest } from '../../utils';
import { systemSettingsRequests } from './index';
import {
  DamSystem,
  DamSystemForm,
  PasswordPolicy,
  PasswordPolicySettings,
  SystemSettingsNormalized,
  SystemSettingsResponse,
  SystemSettingType
} from './types';
import { normalizeSystemSettings } from '../helpers';
import { parseInt } from 'lodash';

export const getSystemSettings = async () => {
  const response = await httpRequest.runRequest<SystemSettingsResponse>(systemSettingsRequests.getSystemSettings());

  return normalizeSystemSettings(response.data);
};

export const setSystemSettings = async (values: SystemSettingsNormalized) => {
  const payload = (Object.keys(values) as Array<keyof typeof SystemSettingType>).map((key) => {
    let value = values[key];

    if (key === SystemSettingType.PUBLISH_HOUR) {
      value = Math.floor(parseInt(value) - moment().utcOffset() / 60).toString();
    }

    if (key === SystemSettingType.AUTOMATED_EMAIL_TIME) {
      const date = new Date();
      const [hours, minutes] = value.split(':');
      date.setHours(parseInt(hours || '0'));
      date.setMinutes(parseInt(minutes || '0'));
      date.setSeconds(0);
      value = date.getTime().toString();
    }

    return {
      type: key as SystemSettingType,
      value: value.toString()
    };
  });

  const response = await httpRequest.runRequest<SystemSettingsResponse>(
    systemSettingsRequests.setSystemSettings({
      requestPayload: { data: payload }
    })
  );

  return normalizeSystemSettings(response.data);
};

export const getGlobalPasswordPolicy = async () => {
  return await httpRequest.runRequest<PasswordPolicy>(systemSettingsRequests.getGlobalPasswordPolicy());
};

export const setGlobalPasswordPolicy = async (payload: PasswordPolicySettings) => {
  return await httpRequest.runRequest<PasswordPolicy>(
    systemSettingsRequests.setGlobalPasswordPolicy({
      requestPayload: payload
    })
  );
};

export const getDamSystems = async () => {
  return await httpRequest.runRequest<{ data: DamSystem[] }>(systemSettingsRequests.getDamSystems());
};

export const deleteDamSystem = async (id: string) => {
  return await httpRequest.runRequest<DamSystem>(systemSettingsRequests.deleteDamSystem({ requestParams: { id } }));
};

export const createDamSystem = async (payload: DamSystemForm) => {
  return await httpRequest.runRequest<DamSystem>(systemSettingsRequests.createDamSystem({ requestPayload: payload }));
};

export const updateDamSystem = async (id: string, payload: DamSystemForm) => {
  return await httpRequest.runRequest<DamSystem>(
    systemSettingsRequests.updateDamSystem({ requestParams: { id }, requestPayload: payload })
  );
};
