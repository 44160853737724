import React from 'react';
import styled from 'styled-components';
import { TableLinkExternal } from '../../../../Shared/DataTable/ColumnCell/UrlColumnCell';
import linksConstants from '../../../../../config/app/linksConstants';
import { useTranslation } from 'react-i18next';
import { AdminOrganization } from '../../../../../store/AdminOrganizations/types';

type UserDataCenterWithOrganizationInheritanceProps = {
  userDataCenter: number | null;
  organizations?: AdminOrganization[];
};

const UserDataCenterWithOrganizationInheritance: React.FC<UserDataCenterWithOrganizationInheritanceProps> = ({
  userDataCenter,
  organizations
}) => {
  const { t } = useTranslation();
  if (!userDataCenter && (!organizations || !organizations.length)) {
    return <NoOrganizations>N/A</NoOrganizations>;
  }

  return (
    <Wrapper>
      <OrganizationsList>
        {userDataCenter && <>{t(`common.dataCenters.dc${userDataCenter}`)}</>}

        {!userDataCenter &&
          organizations &&
          organizations.map((organization, i) => (
            <div key={`${organization.id}-${i}-data-center`}>
              <UserOrganizationItem>
                <TableLinkExternal
                  to={`${linksConstants.ADMINISTRATION.ORGANIZATIONS.EDIT(organization.id)}?page=1&per=10`}
                >
                  {organization.name}
                </TableLinkExternal>
                <KeywordsList>
                  {!organization.externalDataCenter && <>N/A</>}
                  {organization.externalDataCenter && (
                    <>{t(`common.dataCenters.dc${organization.externalDataCenter}`)}</>
                  )}
                </KeywordsList>
              </UserOrganizationItem>
              <Separator />
            </div>
          ))}
      </OrganizationsList>
    </Wrapper>
  );
};

const Separator = styled.hr`
  background: #e2e2e2;
  height: 1px;
  border: none;
`;

const Wrapper = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
`;

const OrganizationsList = styled.div`
  hr:last-child {
    display: none;
  }
`;
const UserOrganizationItem = styled.div``;

const KeywordsList = styled.div`
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  opacity: 0.6;
`;

const NoOrganizations = styled.div`
  padding: 7px 20px;
  font-style: italic;
`;

export default UserDataCenterWithOrganizationInheritance;
