import React, { FunctionComponent, useEffect, useState } from 'react';
import {
  AccordionDetails,
  AccordionSummary,
  Box,
  Button,
  Menu,
  MenuItem,
  Tooltip,
  Typography,
  useTheme
} from '@material-ui/core';
import { StyledAccordion } from '../../PageStyledComponents';
import { useTranslation } from 'react-i18next';
import {
  StoryChannel,
  StoryChannelStatus,
  StoryChannelType,
  storyChannelTypes,
  StoryEmailChannelValues,
  StoryFieldName,
  StoryFieldType,
  StoryPrintChannelValues,
  StorySimpleSocialChannelValues
} from '../../../../store/Stories/types';
import PrintChannelForm from './PrintChannelForm';
import EmailChannelForm from './EmailChannelForm';
import Toast from '../../../Shared/Toast/Toast';
import { storiesOperations } from '../../../../store/Stories';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import DeleteStoryChannelWindow from '../Windows/DeleteStoryChannelWindow';
import useOpenHandler from '../../../../hooks/useOpenHandler';
import { ReactComponent as PrintIcon } from '../../../../assets/icons/channels/print.svg';
import { ReactComponent as EmailIcon } from '../../../../assets/icons/channels/email.svg';
import { ReactComponent as FacebookIcon } from '../../../../assets/icons/channels/facebook.svg';
import { ReactComponent as TwitterIcon } from '../../../../assets/icons/channels/x.svg';
import { ReactComponent as InstagramIcon } from '../../../../assets/icons/channels/instagram.svg';
import { ReactComponent as LinkedinIcon } from '../../../../assets/icons/channels/linkedin.svg';
import { ReactComponent as BlogIcon } from '../../../../assets/icons/channels/wordpress.svg';
import ChannelStatusButtons from './ChannelStatusButtons';
import PublishSocialChannelWindow from '../Windows/PublishSocialChannelWindow';
import { useTypedSelector } from '../../../../utils';
import {
  getChannelBodyCharLimit,
  getChannelFields,
  getOrganizationChannelTypes,
  getStoryChannelStatus,
  isChannelDisabled,
  isHtmlFieldType,
  newLinesToParagraphs
} from '../Utils/storyUtils';
import SimpleSocialChannelForm from './SimpleSocialChannelForm';
import StoryChannelPublishFailedWindow from '../Windows/StoryChannelPublishFailedWindow';
import ChannelLatestChange from './ChannelLatestChange';
import { hasPermission } from '../../../../utils/permissions';
import PrintArticlesWindow from '../Windows/PrintArticlesWindow';
import { DesignerStory } from '../../../../store/PrintProjects/types';
import LoadingModal from '../../../Shared/Loading/LoadingModal';
import AssignmentIndIcon from '@material-ui/icons/AssignmentInd';
import GenerateAiContentWindow from '../Windows/GenerateAiContentWindow';
import GenerateAiImageWindow from '../Windows/GenerateAiImageWindow';
import BlogChannelForm from './BlogChannelForm';
import PublishBlogChannelWindow from '../Windows/PublishBlogChannelWindow';

type ChannelAccordionProps = {
  storyId: string;
  channelId: string;
  channel: StoryChannel;
  channelsData: StoryChannel[];
  channelType: StoryChannelType;
  expanded: StoryChannelType | false;
  handleChannelChange: (channel: StoryChannelType) => (event: React.ChangeEvent<{}>, isExpanded: boolean) => void;
  deleteChannel: () => void;
  setChannelStatus: (channel: StoryChannel, status: StoryChannelStatus) => void;
  isOwnerOfStory: boolean;
  disabled: boolean;
  fetchStoryData: () => Promise<React.ReactText | undefined>;
};

const ChannelAccordion: FunctionComponent<ChannelAccordionProps> = ({
  storyId,
  channelId,
  channelsData,
  channelType,
  expanded,
  handleChannelChange,
  channel,
  deleteChannel,
  setChannelStatus,
  isOwnerOfStory,
  disabled,
  fetchStoryData
}) => {
  const { t } = useTranslation();
  const theme = useTheme();
  const authState = useTypedSelector((state) => state.auth);
  const [
    deleteStoryChannelWindowOpen,
    onDeleteStoryChannelWindowOpen,
    onDeleteStoryChannelWindowClose
  ] = useOpenHandler();
  const [channelData, setChannelData] = useState<StoryChannel | undefined>(
    channelsData.find((channel) => channel.type === channelType)
  );

  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const [publishSocialPostWindowOpen, onPublishSocialPostWindowOpen, onPublishSocialPostWindowClose] = useOpenHandler();
  const [publishBlogPostWindowOpen, onPublishBlogPostWindowOpen, onPublishBlogPostWindowClose] = useOpenHandler();
  const [publishErrorWindowOpen, onPublishErrorWindowOpen, onPublishErrorWindowClose] = useOpenHandler();
  const [printArticlesWindowOpen, onPrintArticlesWindowOpen, onPrintArticlesWindowClose] = useOpenHandler();
  const [generateAiContentWindowOpen, onGenerateAiContentWindowOpen, onGenerateAiContentWindowClose] = useOpenHandler();
  const [generateAiImageWindowOpen, onGenerateAiImageWindowOpen, onGenerateAiImageWindowClose] = useOpenHandler();
  const [copyingPrintArticle, setCopyingPrintArticle] = useState<boolean>(false);
  const [isFormValid, setIsFormValid] = useState<boolean>(false);

  useEffect(() => {
    const newChannelData = channelsData.find((channel) => channel.type === channelType);
    setChannelData(newChannelData);
  }, [channelsData]);

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.stopPropagation();
    setAnchorEl(event.currentTarget);
  };

  const handleGenerateClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.stopPropagation();
    onGenerateAiContentWindowOpen();
  };

  const handleGenerateImageClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.stopPropagation();
    onGenerateAiImageWindowOpen();
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const createOrUpdateChannel = async (
    channelType: StoryChannelType,
    formData: StoryPrintChannelValues | StoryEmailChannelValues | StorySimpleSocialChannelValues,
    showToast?: boolean
  ) => {
    if (storyId) {
      try {
        const data = Object.entries(formData).map(([name, value]) => ({
          type: StoryFieldType.STRING,
          name: parseInt(name),
          value: value
        }));

        if (channelId) {
          await storiesOperations.updateChannel(channelId, data);
        } else {
          await storiesOperations.createChannel(storyId, channelType, data);
        }

        setChannelData((prevState) => {
          if (prevState) {
            return {
              ...prevState,
              fields: data
            };
          } else {
            return prevState;
          }
        });

        if (showToast) {
          Toast.success(t('notifications.story.storyChannelSaved'));
        }
      } catch (e) {
        Toast.error(t('notifications.story.errorSave'));
      }
    }
  };

  const getChannelIcon = (type: StoryChannelType) => {
    switch (type) {
      case StoryChannelType.PRINT:
        return <PrintIcon width={20} height={20} fill={theme.palette.primary.main} />;
      case StoryChannelType.EMAIL:
        return <EmailIcon width={20} height={20} fill={theme.palette.primary.main} />;
      case StoryChannelType.FACEBOOK:
        return <FacebookIcon width={20} height={20} fill={theme.palette.primary.main} />;
      case StoryChannelType.TWITTER:
        return <TwitterIcon width={20} height={20} fill={theme.palette.primary.main} />;
      case StoryChannelType.INSTAGRAM:
        return <InstagramIcon width={20} height={20} fill={theme.palette.primary.main} />;
      case StoryChannelType.LINKEDIN:
        return <LinkedinIcon width={20} height={20} fill={theme.palette.primary.main} />;
      case StoryChannelType.BLOG:
        return <BlogIcon width={20} height={20} fill={theme.palette.primary.main} />;

      default:
        return null;
    }
  };

  const handleCopyAllData = async (e: React.MouseEvent<HTMLLIElement, MouseEvent>, type: StoryChannelType) => {
    e.stopPropagation();
    const channelToCopy = channelsData.find((channel) => channel.type === type);

    if (!channelToCopy || !channelData) {
      setAnchorEl(null);
      return;
    }

    try {
      const copiedChannelData = await storiesOperations.copyChannel(
        channelData.id,
        channelToCopy.id,
        getChannelFields(channelData.type)
      );

      setChannelData(copiedChannelData);
      Toast.success(t('notifications.story.storyChannelCopied'));
    } catch (e) {
      Toast.error(t('notifications.story.errorCopy'));
    }
    setAnchorEl(null);
  };

  const handleCopyPrintArticle = async (article: DesignerStory) => {
    if (!channelData) {
      setAnchorEl(null);
      return;
    }

    setCopyingPrintArticle(true);
    try {
      const copiedChannelData = await storiesOperations.copyPrintArticle(
        channelData.id,
        article,
        getChannelFields(channelData.type)
      );

      setChannelData(copiedChannelData);
      Toast.success(t('notifications.story.printArticleCopied'));
    } catch (e) {
      Toast.error(t('notifications.story.printArticleErrorCopy'));
    } finally {
      setCopyingPrintArticle(false);
    }
  };

  const shouldDisplayCopyButton = (channelData?: StoryChannel) => {
    if (
      !channelData ||
      isChannelDisabled(channelData, isOwnerOfStory) ||
      !hasPermission(authState.role, ['storiesEditChannels'])
    ) {
      return false;
    }
    return true;
  };

  const shouldDisplayGenerateButton = (channelData?: StoryChannel) => {
    if (
      !channelData ||
      isChannelDisabled(channelData, isOwnerOfStory) ||
      !(
        hasPermission(authState.role, ['storiesEditChannels']) && hasPermission(authState.role, ['storiesAiContent'])
      ) ||
      !isFormValid
    ) {
      return false;
    }
    return true;
  };

  const getChannelForm = (channelData: StoryChannel | undefined, channelType: StoryChannelType) => {
    if (!channelData) return null;

    switch (channelType) {
      case StoryChannelType.PRINT:
        return (
          <PrintChannelForm
            storyId={storyId}
            channelId={channelId}
            isOwnerOfStory={isOwnerOfStory}
            expanded={expanded}
            channelData={channelData}
            submitForm={(data, showToast) => createOrUpdateChannel(channelType, data, showToast)}
            deleteChannel={onDeleteStoryChannelWindowOpen}
            setIsFormValid={setIsFormValid}
          />
        );

      case StoryChannelType.EMAIL:
        return (
          <EmailChannelForm
            storyId={storyId}
            isOwnerOfStory={isOwnerOfStory}
            channelId={channelId}
            expanded={expanded}
            channelData={channelData}
            submitForm={(data, showToast) => createOrUpdateChannel(channelType, data, showToast)}
            deleteChannel={onDeleteStoryChannelWindowOpen}
            setIsFormValid={setIsFormValid}
          />
        );

      case StoryChannelType.BLOG:
        return (
          <>
            <BlogChannelForm
              storyId={storyId}
              isOwnerOfStory={isOwnerOfStory}
              channelId={channelId}
              expanded={expanded}
              channelData={channelData}
              submitForm={(data, showToast) => createOrUpdateChannel(channelType, data, showToast)}
              deleteChannel={onDeleteStoryChannelWindowOpen}
              setIsFormValid={setIsFormValid}
            />
            <PublishBlogChannelWindow
              type={channelType}
              channel={channelData}
              open={publishBlogPostWindowOpen}
              onCloseClick={onPublishBlogPostWindowClose}
              onSubmit={() => setChannelStatus(channelData, StoryChannelStatus.PUBLISHED)}
              fullScreenOnMobile
            />
          </>
        );

      case StoryChannelType.FACEBOOK:
      case StoryChannelType.TWITTER:
      case StoryChannelType.INSTAGRAM:
      case StoryChannelType.LINKEDIN:
        return (
          <>
            <SimpleSocialChannelForm
              type={channelType}
              isOwnerOfStory={isOwnerOfStory}
              charLimit={getChannelBodyCharLimit(channelType)}
              storyId={storyId}
              channelId={channelId}
              expanded={expanded}
              channelData={channelData}
              submitForm={(data, showToast) => createOrUpdateChannel(channelType, data, showToast)}
              deleteChannel={onDeleteStoryChannelWindowOpen}
              setIsFormValid={setIsFormValid}
            />
            <PublishSocialChannelWindow
              type={channelType}
              channel={channelData}
              open={publishSocialPostWindowOpen}
              onCloseClick={onPublishSocialPostWindowClose}
              onSubmit={() => setChannelStatus(channelData, StoryChannelStatus.SCHEDULED)}
              fullScreenOnMobile
            />
            <StoryChannelPublishFailedWindow
              channel={channelData}
              storyId={storyId}
              open={publishErrorWindowOpen}
              onCloseClick={onPublishErrorWindowClose}
              onSuccessfulSubmit={() => setChannelStatus(channelData, StoryChannelStatus.IDLE)}
              fullScreenOnMobile
            />
          </>
        );
    }
  };

  return (
    <StyledAccordion
      expanded={expanded === channelType}
      onChange={handleChannelChange(channelType)}
      disabled={disabled}
    >
      {copyingPrintArticle && <LoadingModal message={t('pages.story.copyingPrintArticle')} />}
      <AccordionSummary
        expandIcon={<ExpandMoreIcon />}
        aria-controls={`${channelType}bh-content`}
        id={`${channelType}bh-header`}
      >
        <Box display="flex" alignItems="center">
          {getChannelIcon(channelType)}{' '}
          <Typography style={{ marginLeft: 7 }}>
            <Box display="flex">
              <strong>{t(`pages.story.channels.${channelType}`)}</strong>
              {channelData && channelData.createdBy && (
                <Box display="flex" alignItems="center" fontSize="12px" marginLeft={1}>
                  &nbsp;{t('pages.story.by')}&nbsp;
                  <AssignmentIndIcon
                    titleAccess={t('pages.story.author')}
                    style={{ width: '12px', height: '12px' }}
                    color="disabled"
                  />
                  <div style={{ fontSize: '13px', marginLeft: '3px' }}>
                    <Tooltip title={channelData.createdBy.email || ''} placement="bottom" arrow>
                      <div>{channelData.createdBy.fullName}</div>
                    </Tooltip>
                  </div>
                </Box>
              )}
            </Box>
            {channelData && getStoryChannelStatus(channelData.status, t, theme, channelData.type)}
          </Typography>
        </Box>
        {expanded === channelType && (
          <div>
            {shouldDisplayCopyButton(channelData) && (
              <>
                <Button variant="outlined" size="small" onClick={handleClick} style={{ marginLeft: 10 }}>
                  {t('pages.story.copyAllDataFrom')}
                </Button>
                <Menu anchorEl={anchorEl} keepMounted open={Boolean(anchorEl)} onClose={handleClose}>
                  <MenuItem
                    onClick={(e) => {
                      e.stopPropagation();
                      onPrintArticlesWindowOpen();
                      setAnchorEl(null);
                    }}
                  >
                    {t('pages.story.printArticle')}
                  </MenuItem>
                  {storyChannelTypes.map((storyChannelType) => {
                    if (
                      channelType !== storyChannelType &&
                      channelsData.find(
                        (channel) => channel.type === storyChannelType && channel.status !== StoryChannelStatus.REJECTED
                      )
                    ) {
                      return (
                        <MenuItem
                          onClick={(e) => handleCopyAllData(e, storyChannelType)}
                          key={`menu-item-${storyChannelType}`}
                        >
                          {storyChannelType === StoryChannelType.PRINT
                            ? t('pages.story.printStory')
                            : t(`pages.story.channels.${storyChannelType}`)}
                        </MenuItem>
                      );
                    }
                  })}
                </Menu>
              </>
            )}
            {shouldDisplayGenerateButton(channelData) && channelData && (
              <Tooltip title={`${t(`pages.story.generateAiContentTooltip.${channelData.type}`)}`} placement="top" arrow>
                <Button variant="outlined" size="small" onClick={handleGenerateClick} style={{ marginLeft: 10 }}>
                  {t('pages.story.generateAiContent')}
                </Button>
              </Tooltip>
            )}
            {shouldDisplayGenerateButton(channelData) && channelData && (
              <Tooltip title={`${t(`pages.story.generateAiImageTooltip.${channelData.type}`)}`} placement="top" arrow>
                <Button variant="outlined" size="small" onClick={handleGenerateImageClick} style={{ marginLeft: 10 }}>
                  {t('pages.story.generateAiImage')}
                </Button>
              </Tooltip>
            )}
          </div>
        )}
        {channelData && (
          <div style={{ marginLeft: 'auto', display: 'flex', alignItems: 'center' }}>
            <ChannelLatestChange channel={channelData} />
            <ChannelStatusButtons
              channel={channelData}
              isOwnerOfStory={isOwnerOfStory}
              setChannelStatus={setChannelStatus}
              onPublishWindowOpen={() => {
                if (channelType === StoryChannelType.BLOG) {
                  onPublishBlogPostWindowOpen();
                } else {
                  onPublishSocialPostWindowOpen();
                }
              }}
              onPublishErrorWindowOpen={onPublishErrorWindowOpen}
              isFormValid={isFormValid}
              expanded={expanded}
              disabled={!getOrganizationChannelTypes(authState.organization).includes(channelType)}
            />
          </div>
        )}
      </AccordionSummary>
      <AccordionDetails>{getChannelForm(channelData, channelType)}</AccordionDetails>
      {deleteStoryChannelWindowOpen && (
        <DeleteStoryChannelWindow
          channelType={channelType}
          storyId={storyId}
          channelId={channelId}
          open={deleteStoryChannelWindowOpen}
          fullScreenOnMobile={true}
          onCloseClick={onDeleteStoryChannelWindowClose}
          onSuccess={() => {
            onDeleteStoryChannelWindowClose();
            deleteChannel();
          }}
        />
      )}
      {printArticlesWindowOpen && (
        <PrintArticlesWindow
          open={printArticlesWindowOpen}
          fullScreenOnMobile={true}
          onCloseClick={onPrintArticlesWindowClose}
          onSuccessfulSubmit={handleCopyPrintArticle}
        />
      )}
      {generateAiContentWindowOpen && channelData && (
        <GenerateAiContentWindow
          channelType={channelType}
          channelData={channelData}
          open={generateAiContentWindowOpen}
          fullScreenOnMobile={true}
          onCloseClick={onGenerateAiContentWindowClose}
          onCopyToChannelClick={(generatedContent) => {
            if (channelData) {
              setChannelData((prevState) => {
                if (prevState) {
                  const updatedFields = prevState.fields.map((field) => {
                    const generatedField = generatedContent.find((genField) => genField.name === field.name);
                    return generatedField
                      ? {
                          ...field,
                          value:
                            field.name === StoryFieldName.BODY && isHtmlFieldType(channelType)
                              ? newLinesToParagraphs(generatedField.value)
                              : generatedField.value
                        }
                      : field;
                  });

                  return { ...prevState, fields: updatedFields };
                }
                return prevState;
              });
            }
            onGenerateAiContentWindowClose();
          }}
        />
      )}
      {generateAiImageWindowOpen && channelData && (
        <GenerateAiImageWindow
          channelType={channelType}
          channelData={channelData}
          open={generateAiImageWindowOpen}
          fullScreenOnMobile={true}
          onCloseClick={onGenerateAiImageWindowClose}
          onGenerationDone={() => {
            onGenerateAiImageWindowClose();
            fetchStoryData();
          }}
          userId={authState.user.id}
        />
      )}
    </StyledAccordion>
  );
};

export default ChannelAccordion;
