import React, { FunctionComponent, useCallback, useEffect, useState } from 'react';
import { Button, Paper, TextField, Typography, withTheme } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { useDispatch } from 'react-redux';
import Toast from '../../../Shared/Toast/Toast';
import Loader from '../../../Shared/Loading/Loader';
import ProjectNoteItem from './ProjectNoteItem';
import { AnyProject } from '../../../../store/Dashboard/types';
import { NotesProjectType, ProjectNote } from '../../../../store/Notes/types';
import { notesOperations } from '../../../../store/Notes';
import { hasPermission } from '../../../../utils/permissions';
import { AdminRole } from '../../../../store/AdminRoles/types';
import { User } from '../../../../store/Auth/types';

type ProjectNotesProps = {
  project: AnyProject | null;
  role: AdminRole;
  user: User;
  type: NotesProjectType;
  onClose?: () => void;
  isPopover?: boolean;
};

const ProjectNotes: FunctionComponent<ProjectNotesProps> = ({
  project,
  onClose,
  type,
  role,
  user,
  isPopover = true
}) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [newNoteContent, setNewNoteContent] = useState<string>('');
  const [notes, setNotes] = useState<ProjectNote[]>([]);
  const [loading, setLoading] = useState<boolean>(true);

  useEffect(() => {
    const fetchData = async () => {
      try {
        if (project) {
          const notes = await notesOperations.getProjectNotes(type, project);
          setNotes(notes);
        }
      } catch (e) {
        Toast.error(t('notifications.notesFetch.error'));
      } finally {
        setLoading(false);
      }
    };
    fetchData();
  }, []);

  useEffect(() => {
    document.addEventListener('keydown', onKeyDown);

    return () => {
      document.removeEventListener('keydown', onKeyDown);
    };
  }, [newNoteContent]);

  if (!project) {
    return null;
  }

  const sendNote = useCallback(async () => {
    if (hasPermission(role, ['projectNotesEdit'])) {
      const note = await notesOperations.addNewNote(type, project, newNoteContent);

      setNotes([note, ...notes]);
      setNewNoteContent('');
    }
  }, [newNoteContent]);

  const onKeyDown = useCallback(
    async (e: any) => {
      if (e && e.key === 'Enter' && !e.shiftKey) {
        e.preventDefault();
        e.stopPropagation();

        await sendNote();
      }
    },
    [newNoteContent]
  );

  const onDeleteNote = async (note: ProjectNote) => {
    if (hasPermission(role, ['projectNotesEdit'])) {
      await notesOperations.removeNote(type, note);
      setNotes(notes.filter((n) => n.id !== note.id));
    }
  };

  return (
    <NotesPaper style={!isPopover ? { width: '100%', maxHeight: 'initial' } : {}}>
      {!isPopover && (
        <Typography variant="h6" gutterBottom>
          {t('common.notes')}
        </Typography>
      )}
      <NotesList>
        {loading && <Loader />}

        {!loading && !notes.length && <NoNotes>{t('notes.noNotes')}</NoNotes>}

        {!loading &&
          notes.map((note) => (
            <ProjectNoteItem onDeleteNote={onDeleteNote} key={note.id} note={note} role={role} user={user} />
          ))}
      </NotesList>

      {hasPermission(role, ['projectNotesEdit']) && (
        <NewNoteWrapper>
          <NewNoteTextField
            variant="outlined"
            size="small"
            multiline={true}
            placeholder={t('common.enterNote')}
            value={newNoteContent}
            onChange={(e) => setNewNoteContent(e.target.value)}
            required
            disabled={!hasPermission(role, ['projectNotesEdit'])}
          />
          <NewNoteButton
            disabled={!hasPermission(role, ['projectNotesEdit'])}
            variant="contained"
            color="secondary"
            onClick={sendNote}
          >
            {t('common.ok')}
          </NewNoteButton>
        </NewNoteWrapper>
      )}
      {isPopover && <CloseButton onClick={onClose}>{t('common.close')}</CloseButton>}
    </NotesPaper>
  );
};
const NotesPaper = styled(Paper)`
  padding: 1rem;
  width: 500px;
  max-height: 70vh;
  overflow: hidden;

  .MuiPaper-root {
    width: 500px;
    max-height: 70vh;
  }
`;

const NotesList = styled.div`
  overflow: auto;
  max-height: calc(70vh - 110px);
`;

const CloseButton = withTheme(styled.div`
  cursor: pointer;
  color: ${({ theme }) => theme.palette.secondary.main};
  margin-top: 0.8rem;
  display: inline-block;
`);

const NoNotes = styled.div`
  text-align: center;
  margin: 1rem 0;
  font-size: 0.8rem;
  font-style: italic;
  opacity: 0.8;
`;
const NewNoteWrapper = styled.div`
  margin-top: 1rem;
  display: flex;
`;
const NewNoteTextField = styled(TextField)`
  flex-grow: 1;
`;
const NewNoteButton = styled(Button)`
  margin-left: 1rem;
`;

export default ProjectNotes;
