import { ApplicationRoute } from '../../../typings';
import linksConstants from '../../../config/app/linksConstants';
import FlipBookPage from './FlipBookPage';

const FlipBookRoutes: ApplicationRoute[] = [
  {
    path: linksConstants.PLAYGROUND.FLIP_BOOK(':projectId'),
    exact: false,
    name: 'flipBookPreview',
    public: false,
    show: true,
    component: FlipBookPage,
    scope: []
  }
];

export default FlipBookRoutes;
