import React, { FunctionComponent, useState } from 'react';
import styled from 'styled-components';
import { withTheme, Paper as PaperMUI } from '@material-ui/core';

type ChannelTileProps = {
  title: string | JSX.Element;
  icon?: JSX.Element;
  color: string;
  onClick: () => void;
  disabled: boolean;
};

const ChannelTile: FunctionComponent<ChannelTileProps> = ({ title, icon, color, onClick, disabled }) => {
  const [elevation, setElevation] = useState(2);

  return (
    <Paper
      elevation={elevation}
      onMouseEnter={() => setElevation(5)}
      onMouseLeave={() => setElevation(2)}
      onClick={onClick}
      style={{ opacity: disabled ? 0.3 : 1 }}
    >
      <Icon color={color}>{icon}</Icon>
      <Title>{title}</Title>
    </Paper>
  );
};

const Paper = styled(PaperMUI)`
  max-width: 10rem;
  width: 140px;
  cursor: pointer;
  margin-right: 2rem;
`;
const Icon = styled.div<{ color: string }>`
  background: ${({ color }) => color};
  color: #fff;
  padding: 1rem;
  display: flex;
  height: 160px;
  justify-content: center;
  align-items: center;

  img {
    max-width: 100%;
  }
`;
const Title = withTheme(styled.div`
  color: ${({ theme }) => theme.palette.primary.main};
  font-weight: bold;
  padding: 1rem;
  background: ${({ theme }) => theme.palette.common.white};
  letter-spacing: 0.08rem;
`);

export default ChannelTile;
