import React, { useEffect, useState } from 'react';
import { WordpressIntegration } from '../../../../../store/MyOrganization/types';
import {
  Avatar,
  Box,
  Button,
  Checkbox,
  Divider,
  IconButton,
  Link,
  List,
  ListItem,
  ListItemAvatar,
  ListItemIcon,
  ListItemSecondaryAction,
  ListItemText,
  Typography
} from '@material-ui/core';
import DeleteIcon from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Edit';
import LanguageIcon from '@material-ui/icons/Language';
import { useTranslation } from 'react-i18next';

type WordpressIntegrationsListProps = {
  wordpressIntegrations: WordpressIntegration[];
  checkboxes?: boolean;
  onUpdateIconClick?: (integration: WordpressIntegration) => void;
  onDeleteIconClick?: (integration: WordpressIntegration) => void;
  setSelectedWordpressIntegrations?: React.Dispatch<React.SetStateAction<string[]>>;
};

const WordpressIntegrationsList: React.FC<WordpressIntegrationsListProps> = ({
  wordpressIntegrations,
  checkboxes = false,
  onDeleteIconClick,
  onUpdateIconClick,
  setSelectedWordpressIntegrations
}) => {
  const { t } = useTranslation();
  const [checked, setChecked] = useState<string[]>(
    wordpressIntegrations.length === 1 ? [wordpressIntegrations[0].id] : []
  );
  const [selectAll, setSelectAll] = useState(false);

  useEffect(() => {
    // Handle the special case when there's only one integration
    if (wordpressIntegrations.length === 1 && checkboxes) {
      const singleIntegrationId = wordpressIntegrations[0].id;
      setChecked([singleIntegrationId]);
      setSelectedWordpressIntegrations && setSelectedWordpressIntegrations([singleIntegrationId]);
    }
  }, [checkboxes, wordpressIntegrations, setSelectedWordpressIntegrations]);

  const handleToggle = (integration: string) => () => {
    if (wordpressIntegrations.length === 1) {
      // If there's only one integration, keep it checked
      return;
    }

    const currentIndex = checked.indexOf(integration);
    const newChecked = [...checked];

    if (currentIndex === -1) {
      newChecked.push(integration);
    } else {
      newChecked.splice(currentIndex, 1);
    }

    setChecked(newChecked);
    setSelectedWordpressIntegrations && setSelectedWordpressIntegrations(newChecked);

    if (newChecked.length === wordpressIntegrations.length) {
      setSelectAll(true);
    } else {
      setSelectAll(false);
    }
  };

  const toggleSelectAll = () => {
    if (selectAll) {
      setChecked([]);
      setSelectedWordpressIntegrations && setSelectedWordpressIntegrations([]);
    } else {
      const allIntegrations = wordpressIntegrations.map((integration) => integration.id);
      setChecked(allIntegrations);
      setSelectedWordpressIntegrations && setSelectedWordpressIntegrations(allIntegrations);
    }
    setSelectAll(!selectAll);
  };

  return (
    <Box display="flex" flexDirection="column" justifyContent="flex-end">
      {checkboxes && wordpressIntegrations.length > 1 && (
        <Box display="flex" justifyContent="space-between" alignItems="center">
          <Typography variant="subtitle2">{t('pages.myOrganization.integrations.wordpress.chooseSites')}:</Typography>
          <Button size="small" color="primary" variant="contained" onClick={toggleSelectAll}>
            {selectAll ? t('common.deselectAll') : t('common.selectAll')}
          </Button>
        </Box>
      )}
      <List dense>
        {wordpressIntegrations.map((integration) => (
          <React.Fragment key={integration.id}>
            <ListItem button onClick={handleToggle(integration.id)}>
              <ListItemAvatar>
                <Avatar>
                  <LanguageIcon />
                </Avatar>
              </ListItemAvatar>
              <ListItemText
                primary={integration.name}
                secondary={
                  <Link href={integration.url} target="_blank">
                    {integration.url}
                  </Link>
                }
              />
              {onUpdateIconClick && onDeleteIconClick && (
                <ListItemSecondaryAction>
                  <IconButton aria-label="update" onClick={() => onUpdateIconClick(integration)}>
                    <EditIcon />
                  </IconButton>
                  <IconButton edge="end" aria-label="delete" onClick={() => onDeleteIconClick(integration)}>
                    <DeleteIcon />
                  </IconButton>
                </ListItemSecondaryAction>
              )}
              {checkboxes && wordpressIntegrations.length > 1 && (
                <ListItemIcon>
                  <Checkbox edge="start" checked={checked.indexOf(integration.id) !== -1} tabIndex={-1} disableRipple />
                </ListItemIcon>
              )}
            </ListItem>
            <Divider />
          </React.Fragment>
        ))}
      </List>
    </Box>
  );
};

export default WordpressIntegrationsList;
