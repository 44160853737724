import { createOrderArray, httpRequest } from '../../utils';
import { storiesActions, storiesRequests } from './index';
import { Dispatch } from 'redux';
import { ApplicationState } from '../rootReducer';
import {
  GeneratedAiContentResponse,
  GetStoriesResponse,
  GetStoryContentContributorsResponse,
  InviteContributorValues,
  PublishSocialChannelValues,
  Story,
  StoryChannel,
  StoryChannelAttachment,
  StoryChannelPublishError,
  StoryChannelStatus,
  StoryChannelType,
  StoryClonesResponse,
  StoryContentContributor,
  StoryContentContributorPermission,
  StoryFieldName,
  StoryFormData
} from './types';
import { DesignerStory } from '../PrintProjects/types';

export const index = () => async (dispatch: Dispatch, getState: () => ApplicationState) => {
  const { stories } = getState();

  try {
    dispatch(storiesActions.storiesIndexBegin());

    const response = await httpRequest.runRequest<GetStoriesResponse>(
      storiesRequests.index({
        requestParams: {
          page: stories.pagination.page,
          per: stories.pagination.per,
          q: stories.pagination.q,
          order: createOrderArray(stories.tableSearch.order)
        }
      })
    );

    dispatch(storiesActions.storiesIndexSuccess(response));
  } catch (e) {
    dispatch(storiesActions.storiesIndexFailed(e));
  }
};

export const create = async (name: string, publicationDateFrom: Date, publicationDateTo: Date) => {
  return httpRequest.runRequest<Story>(
    storiesRequests.create({
      requestPayload: {
        name,
        publicationDateFrom,
        publicationDateTo
      }
    })
  );
};

export const update = async (storyId: string, name: string, publicationDateFrom: string, publicationDateTo: string) => {
  return httpRequest.runRequest<Story>(
    storiesRequests.update({
      requestPayload: {
        name,
        publicationDateFrom,
        publicationDateTo
      },
      requestParams: {
        storyId
      }
    })
  );
};

export const remove = (storyId: string) => async () => {
  return httpRequest.runRequest<Story>(
    storiesRequests.remove({
      requestParams: {
        storyId
      }
    })
  );
};

export const show = async (storyId: string) => {
  return httpRequest.runRequest<Story>(
    storiesRequests.show({
      requestParams: {
        storyId
      }
    })
  );
};

export const indexStoryContributors = (storyId: string) => async (
  dispatch: Dispatch,
  getState: () => ApplicationState
) => {
  const { storyContributors } = getState();

  try {
    dispatch(storiesActions.storyContentContributorsIndexBegin());

    const response = await httpRequest.runRequest<GetStoryContentContributorsResponse>(
      storiesRequests.indexStoryContributors({
        requestParams: {
          storyId,
          page: storyContributors.pagination.page,
          per: storyContributors.pagination.per,
          q: storyContributors.pagination.q,
          order: createOrderArray(storyContributors.tableSearch.order)
        }
      })
    );

    dispatch(storiesActions.storyContentContributorsIndexSuccess(response));
  } catch (e) {
    dispatch(storiesActions.storyContentContributorsIndexFailed(e));
  }
};

export const inviteContentContributor = (storyId: string, values: InviteContributorValues) => async () => {
  return httpRequest.runRequest<StoryContentContributor>(
    storiesRequests.inviteContentContributor({
      requestParams: {
        storyId
      },
      requestPayload: values
    })
  );
};

export const createChannel = async (storyId: string, type: StoryChannelType, formData: StoryFormData[]) => {
  return httpRequest.runRequest<StoryChannel>(
    storiesRequests.createChannel({
      requestPayload: {
        type,
        formData
      },
      requestParams: {
        storyId
      }
    })
  );
};

export const updateStoryContributorPermission = (
  storyId: string,
  contributorId: string,
  permission: StoryContentContributorPermission
) => async () => {
  return httpRequest.runRequest<StoryContentContributor>(
    storiesRequests.updateStoryContributorPermission({
      requestParams: {
        storyId,
        contributorId
      },
      requestPayload: {
        permission
      }
    })
  );
};

export const removeStoryContributor = (storyId: string, contributorId: string) => async () => {
  return httpRequest.runRequest<StoryContentContributor>(
    storiesRequests.removeStoryContributor({
      requestParams: {
        storyId,
        contributorId
      }
    })
  );
};

export const updateChannel = async (channelId: string, formData: StoryFormData[]) => {
  return httpRequest.runRequest<StoryChannel>(
    storiesRequests.updateChannel({
      requestPayload: {
        formData
      },
      requestParams: {
        channelId
      }
    })
  );
};

export const updateChannelStatus = async (storyId: string, channelId: string, status: StoryChannelStatus) => {
  return httpRequest.runRequest<StoryChannel>(
    storiesRequests.updateChannelStatus({
      requestPayload: {
        status
      },
      requestParams: {
        channelId,
        storyId
      }
    })
  );
};

export const removeChannel = async (storyId: string, channelId: string) => {
  return httpRequest.runRequest<StoryChannel>(
    storiesRequests.removeChannel({
      requestParams: {
        storyId,
        channelId
      }
    })
  );
};

export const copyChannel = async (
  targetChannelId: string,
  sourceChannelId: string,
  targetChannelFields: StoryFieldName[]
) => {
  return httpRequest.runRequest<StoryChannel>(
    storiesRequests.copyChannel({
      requestPayload: {
        targetChannelFields
      },
      requestParams: {
        targetChannelId,
        sourceChannelId
      }
    })
  );
};

export const copyPrintArticle = async (
  targetChannelId: string,
  article: DesignerStory,
  targetChannelFields: StoryFieldName[]
) => {
  return httpRequest.runRequest<StoryChannel>(
    storiesRequests.copyPrintArticle({
      requestPayload: {
        article,
        targetChannelFields
      },
      requestParams: {
        targetChannelId
      }
    })
  );
};

export const uploadAttachment = async (storyId: string, channelId: string, attachment: File, filename: string) => {
  const formData = new FormData();

  formData.append('file', attachment);
  formData.append('filename', filename);

  return await httpRequest.runRequest<StoryChannelAttachment>(
    storiesRequests.uploadAttachment({
      requestPayload: formData,
      requestParams: { storyId, channelId }
    })
  );
};

export const reorderAttachments = async (storyId: string, channelId: string, attachments: StoryChannelAttachment[]) => {
  return httpRequest.runRequest<StoryChannelAttachment[]>(
    storiesRequests.reorderAttachments({
      requestParams: { storyId, channelId },
      requestPayload: { attachments }
    })
  );
};

export const removeAttachment = async (storyId: string, channelId: string, attachmentId: string) => {
  return httpRequest.runRequest<StoryChannelAttachment>(
    storiesRequests.removeAttachment({
      requestParams: { storyId, channelId, attachmentId }
    })
  );
};

export const changeAttachmentCaption = async (
  storyId: string,
  channelId: string,
  attachmentId: string,
  caption: string
) => {
  return httpRequest.runRequest<StoryChannelAttachment>(
    storiesRequests.changeAttachmentCaption({
      requestParams: { storyId, channelId, attachmentId },
      requestPayload: { caption }
    })
  );
};

export const publishChannel = async (storyId: string, channelId: string, payload: PublishSocialChannelValues) => {
  return httpRequest.runRequest<StoryChannel>(
    storiesRequests.publishChannel({
      requestParams: { storyId, channelId },
      requestPayload: payload
    })
  );
};

export const generateSocialPreview = async (storyId: string, channelId: string, type: StoryChannelType) => {
  return httpRequest.runRequest<{ url: string }>(
    storiesRequests.generateSocialPreview({
      requestParams: { storyId, channelId },
      requestPayload: { type }
    })
  );
};

export const getStories = async (publicationDate: string, channelType: StoryChannelType) => {
  return httpRequest.runRequest<{ stories: Story[] }>(
    storiesRequests.getStories({
      requestParams: { publicationDate, channelType }
    })
  );
};

export const getChannelPublishErrors = async (storyId: string, channelId: string) => {
  return httpRequest.runRequest<{ data: StoryChannelPublishError[] }>(
    storiesRequests.getChannelPublishErrors({
      requestParams: { storyId, channelId }
    })
  );
};

export const showStoryContributor = async (storyId: string, userId: string) => {
  return httpRequest.runRequest<StoryContentContributor>(
    storiesRequests.showStoryContributor({
      requestParams: {
        storyId,
        userId
      }
    })
  );
};

export const generateAiContent = async (content: string, type: StoryChannelType) => {
  return httpRequest.runRequest<GeneratedAiContentResponse>(
    storiesRequests.generateAiContent({
      requestPayload: {
        content,
        type
      }
    })
  );
};

export const generateAiContentForChannels = async (storyId: string, content: string, types: StoryChannelType[]) => {
  return httpRequest.runRequest<{
    status: string;
  }>(
    storiesRequests.generateAiContentForChannels({
      requestParams: { storyId },
      requestPayload: {
        content,
        types
      }
    })
  );
};

export const generateAiImage = async (storyId: string, channelId: string, prompt: string) => {
  return httpRequest.runRequest<StoryChannelAttachment>(
    storiesRequests.generateAiImage({
      requestParams: { storyId, channelId },
      requestPayload: {
        prompt
      }
    })
  );
};

export const shareStory = async (storyId: string, organizations: string[]) => {
  await httpRequest.runRequest<Story>(
    storiesRequests.shareStory({
      requestParams: { storyId },
      requestPayload: { organizations }
    })
  );
};

export const cloneShared = async (storyId: string, name: string) => {
  return await httpRequest.runRequest<Story>(
    storiesRequests.cloneShared({
      requestParams: { storyId },
      requestPayload: { name }
    })
  );
};

export const getStoryClones = async (storyId: string) => {
  return await httpRequest.runRequest<StoryClonesResponse>(
    storiesRequests.getStoryClones({
      requestParams: { storyId }
    })
  );
};
