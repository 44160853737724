import { createOrderArray, formatPublishDate, httpRequest } from '../../utils';
import { Template, TemplateTypeName } from '../Templates/types';
import { Dispatch } from 'redux';
import { emailProjectsActions, emailProjectsRequests } from './index';
import {
  BrandfolderAssetsResponse,
  BrandfolderAttachmentsResponse,
  BrandfolderPinsResponse,
  BrandfolderSectionsResponse,
  CampaignSummary,
  EmailCampaignStandardResponse,
  EmailProject,
  EmailProjectAttachment,
  EmailProjectClonesResponse,
  EmailProjectsIndexResponse,
  SendersResponse,
  UserImagesResponse
} from './types';
import { PrintProject } from '../PrintProjects/types';
import { ApplicationState } from '../rootReducer';
import { ProjectModelV2 } from '../../components/Pages/Editors/EmailDndEditor/types';

export const getUserEmailProjects = () => async (dispatch: Dispatch, getState: () => ApplicationState) => {
  const { emailProjects } = getState();

  try {
    dispatch(emailProjectsActions.projectsIndexBegin());

    const response = await httpRequest.runRequest<EmailProjectsIndexResponse>(
      emailProjectsRequests.getUserEmailProjects({
        requestParams: {
          page: emailProjects.pagination.page,
          per: emailProjects.pagination.per,
          q: emailProjects.pagination.q,
          order: createOrderArray(emailProjects.tableSearch.order)
        }
      })
    );

    dispatch(emailProjectsActions.projectsIndexSuccess(response));
  } catch (e) {
    dispatch(emailProjectsActions.projectsIndexFailed(e));
  }
};

export const create = async (
  template: Template,
  publishDate: Date,
  type: TemplateTypeName,
  testProject: boolean,
  sourceProject?: PrintProject | null
) => {
  return await httpRequest.runRequest<EmailProject>(
    emailProjectsRequests.create({
      requestParams: { templateId: template.id },
      requestPayload: {
        type,
        sourceProjectId: sourceProject ? sourceProject.id : null,
        publishDate: formatPublishDate(publishDate),
        test: testProject
      }
    })
  );
};

export const createAutomatedEmailContent = async (
  type: TemplateTypeName,
  sourceProject: PrintProject,
  payload?: {
    template?: Template | null;
    articles?: { name: string; selectedImageIndex: number }[];
    stripUnusedArticleSpots?: boolean;
  }
) => {
  const requestPayload: {
    type: TemplateTypeName;
    sourceProjectId: string;
    templateId?: string;
    articles?: { name: string; selectedImageIndex: number }[];
    stripUnusedArticleSpots?: boolean;
  } = {
    type,
    sourceProjectId: sourceProject.id
  };

  if (payload && payload.template) {
    requestPayload.templateId = payload.template.id;
  }

  if (payload && payload.articles) {
    requestPayload.articles = payload.articles;
  }

  if (payload && payload.stripUnusedArticleSpots) {
    requestPayload.stripUnusedArticleSpots = payload.stripUnusedArticleSpots;
  }

  return await httpRequest.runRequest<EmailProject>(
    emailProjectsRequests.createAutomatedEmailContent({ requestPayload })
  );
};

export const show = async (projectId: string) => {
  return await httpRequest.runRequest<EmailProject>(
    emailProjectsRequests.show({
      requestParams: { projectId }
    })
  );
};

export const getProjectPreviewUrl = async (project: EmailProject) => {
  return await httpRequest.runRequest<{ url: string; contentWidth: number }>(
    emailProjectsRequests.getEmailProjectPreviewUrl({
      requestParams: { projectId: project.id }
    })
  );
};

export const prepareFinalPreview = async (project: EmailProject) => {
  return await httpRequest.runRequest<{ url: string; contentWidth: number }>(
    emailProjectsRequests.prepareFinalPreview({
      requestParams: { projectId: project.id }
    })
  );
};

export const saveProjectName = async (project: EmailProject, name: string) => {
  return await httpRequest.runRequest<EmailProject>(
    emailProjectsRequests.saveEmailProjectData({
      requestParams: { projectId: project.id },
      requestPayload: { name }
    })
  );
};

export const saveProjectSubject = (project: EmailProject, subject: string) => async (
  dispatch: Dispatch,
  getState: () => ApplicationState
) => {
  const emailProject = await httpRequest.runRequest<EmailProject>(
    emailProjectsRequests.saveEmailProjectData({
      requestParams: { projectId: project.id },
      requestPayload: { subject }
    })
  );

  dispatch(emailProjectsActions.saveProjectSubject(emailProject));

  return emailProject;
};

export const setProjectModel = async (projectId: string, projectModel: ProjectModelV2) => {
  return await httpRequest.runRequest<EmailProject>(
    emailProjectsRequests.setProjectModel({
      requestParams: { projectId },
      requestPayload: { projectModel }
    })
  );
};

export const getProjectModel = async (projectId: string) => {
  return await httpRequest.runRequest<{ projectModel: ProjectModelV2 }>(
    emailProjectsRequests.getProjectModel({
      requestParams: { projectId }
    })
  );
};

export const removeProject = (projectId: string) => async (dispatch: Dispatch) => {
  await httpRequest.runRequest<EmailProject>(
    emailProjectsRequests.removeProject({
      requestParams: { projectId }
    })
  );

  dispatch(emailProjectsActions.removeProject(projectId));
};

export const shareProject = (projectId: string, organizations: string[]) => async (dispatch: Dispatch) => {
  await httpRequest.runRequest<EmailProject>(
    emailProjectsRequests.shareProject({
      requestParams: { projectId },
      requestPayload: { organizations }
    })
  );

  dispatch(emailProjectsActions.shareProject(projectId));
};

export const cloneShared = async (projectId: string, name: string) => {
  return await httpRequest.runRequest<EmailProject>(
    emailProjectsRequests.cloneShared({
      requestParams: { projectId },
      requestPayload: { name }
    })
  );
};

export const getProjectClones = async (projectId: string) => {
  return await httpRequest.runRequest<EmailProjectClonesResponse>(
    emailProjectsRequests.getProjectClones({
      requestParams: { projectId }
    })
  );
};

export const automatedEmailOptOut = (printProjectId: string) => async (dispatch: Dispatch) => {
  const emailProjectId = await httpRequest.runRequest<{ data: string | null }>(
    emailProjectsRequests.automatedEmailOptOut({
      requestParams: { printProjectId }
    })
  );

  if (emailProjectId.data) {
    dispatch(emailProjectsActions.removeProject(emailProjectId.data));
  }
};

export const publishProject = (
  projectId: string,
  mailingListId: string,
  publishTime: Date,
  subject: string,
  publishDate: string
) => async (dispatch: Dispatch) => {
  const project = await httpRequest.runRequest<EmailProject>(
    emailProjectsRequests.publishProject({
      requestParams: {
        projectId,
        mailingListId
      },
      requestPayload: {
        publishTime,
        subject,
        publishDate
      }
    })
  );

  dispatch(emailProjectsActions.publishProject(project));

  return project;
};

export const sendToTestMailingList = (projectId: string, mailingListId: string, subject: string) => async () => {
  await httpRequest.runRequest<EmailProject>(
    emailProjectsRequests.sendToTestMailingList({
      requestParams: { projectId, mailingListId },
      requestPayload: { subject }
    })
  );
};

export const cancelProjectPublication = (projectId: string) => async (dispatch: Dispatch) => {
  const project = await httpRequest.runRequest<EmailProject>(
    emailProjectsRequests.cancelProjectPublication({
      requestParams: { projectId }
    })
  );

  dispatch(emailProjectsActions.cancelProjectPublication(project));

  return project;
};

export const getBrandfolderAssets = async (per: number, page: number, search: string) => {
  return await httpRequest.runRequest<BrandfolderAssetsResponse>(
    emailProjectsRequests.getBrandfolderAssets({
      requestParams: { per, page, search }
    })
  );
};

export const getBrandfolderSectionAssets = async (sectionId: string, per: number, page: number, search: string) => {
  return await httpRequest.runRequest<BrandfolderAssetsResponse>(
    emailProjectsRequests.getBrandfolderSectionAssets({
      requestParams: { sectionId, per, page, search }
    })
  );
};

export const getBrandfolderSections = async () => {
  return await httpRequest.runRequest<BrandfolderSectionsResponse>(emailProjectsRequests.getBrandfolderSections());
};

export const getBrandfolderPins = async () => {
  return await httpRequest.runRequest<BrandfolderPinsResponse>(emailProjectsRequests.getBrandfolderPins());
};

export const getBrandfolderAttachments = async (assetId: string) => {
  return await httpRequest.runRequest<BrandfolderAttachmentsResponse>(
    emailProjectsRequests.getBrandfolderAttachments({
      requestParams: { assetId }
    })
  );
};

export const saveProjectPublishDate = async (project: EmailProject, newPublishDate: Date) => {
  return await httpRequest.runRequest<EmailProject>(
    emailProjectsRequests.saveProjectPublishDate({
      requestParams: { projectId: project.id },
      requestPayload: { name: project.name, publishDate: newPublishDate }
    })
  );
};

export const checkIn = async (projectId: string, checkInToken: string) => {
  return await httpRequest.runRequest<EmailProject>(
    emailProjectsRequests.checkIn({
      requestParams: { projectId },
      requestPayload: { checkInToken }
    })
  );
};

export const getUserImages = async (projectId: string, per: number, page: number) => {
  return await httpRequest.runRequest<UserImagesResponse>(
    emailProjectsRequests.getUserImages({
      requestParams: { projectId, per, page }
    })
  );
};

export const uploadImage = async (file: File, projectId: string, onProgress: (progress: number) => void) => {
  const formData = new FormData();

  formData.append('file', file);
  formData.append('filename', file.name);

  return await httpRequest.runRequest<void>({
    ...emailProjectsRequests.uploadImage({
      // @ts-ignore
      requestPayload: formData,
      requestParams: { projectId }
    }),
    onUploadProgress: (progressEvent) => {
      const progress = Math.floor((progressEvent.loaded / progressEvent.total) * 100);
      onProgress(progress);
    }
  });
};

export const uploadAttachment = async (file: File, projectId: string, onProgress: (progress: number) => void) => {
  const formData = new FormData();

  formData.append('file', file);
  formData.append('filename', file.name);

  return await httpRequest.runRequest<void>({
    ...emailProjectsRequests.uploadAttachment({
      // @ts-ignore
      requestPayload: formData,
      requestParams: { projectId }
    }),
    onUploadProgress: (progressEvent) => {
      const progress = Math.floor((progressEvent.loaded / progressEvent.total) * 100);
      onProgress(progress);
    }
  });
};

export const removeAttachment = async (projectId: string, attachmentId: string) => {
  return await httpRequest.runRequest<void>(
    emailProjectsRequests.removeAttachment({
      requestParams: { projectId, attachmentId }
    })
  );
};

export const toggleStickyImage = async (projectId: string, imageId: string) => {
  return await httpRequest.runRequest<void>(
    emailProjectsRequests.toggleStickyImage({
      requestParams: { projectId, imageId }
    })
  );
};

export const removeImage = async (projectId: string, imageId: string) => {
  return await httpRequest.runRequest<void>(
    emailProjectsRequests.removeImage({
      requestParams: { projectId, imageId }
    })
  );
};

export const emailCampaignStatsSubscribers = async (projectId: string) => {
  return await httpRequest.runRequest<EmailCampaignStandardResponse>(
    emailProjectsRequests.emailCampaignStatsSubscribers({
      requestParams: { projectId }
    })
  );
};

export const emailCampaignStatsLinksActivity = async (projectId: string) => {
  return await httpRequest.runRequest<EmailCampaignStandardResponse>(
    emailProjectsRequests.emailCampaignStatsLinksActivity({
      requestParams: { projectId }
    })
  );
};

export const emailCampaignStatsByLocation = async (projectId: string) => {
  return await httpRequest.runRequest<EmailCampaignStandardResponse>(
    emailProjectsRequests.emailCampaignStatsByLocation({
      requestParams: { projectId }
    })
  );
};

export const emailCampaignStatsSummary = async (projectId: string) => {
  return await httpRequest.runRequest<{ data: CampaignSummary }>(
    emailProjectsRequests.emailCampaignStatsSummary({
      requestParams: { projectId }
    })
  );
};

export const updatePublishDateEntity = async (
  projectId: string,
  mailingListId: string,
  publishTime: Date,
  subject: string,
  publishDate: string
) => {
  return httpRequest.runRequest<EmailProject>(
    emailProjectsRequests.updatePublishDateEntity({
      requestParams: {
        projectId
      },
      requestPayload: {
        publishTime,
        mailingListId,
        subject,
        publishDate
      }
    })
  );
};

export const getAutomatedEmailTemplate = async () => {
  return await httpRequest.runRequest<Template>(emailProjectsRequests.getAutomatedEmailTemplate());
};

export const getSenders = async () => {
  return await httpRequest.runRequest<SendersResponse>(emailProjectsRequests.getSenders());
};

export const getAttachments = async (projectId: string) => {
  return await httpRequest.runRequest<{ data: EmailProjectAttachment[] }>(
    emailProjectsRequests.getAttachments({ requestParams: { projectId } })
  );
};

export const cloneProject = async (projectId: string, name: string) => {
  return await httpRequest.runRequest<EmailProject>(
    emailProjectsRequests.cloneProject({ requestParams: { projectId }, requestPayload: { name } })
  );
};
