import update from 'immutability-helper';
import { normalizeProjects } from '../helpers';
import { createNetworkErrorObject, getLocationPaginationState } from '../../utils';
import { AuthActionsConstants } from '../Auth/types';
import { AnyProject, DashboardActionsConstants, DashboardActionType, DashboardState } from './types';
import { EmailProjectsActionsConstants, EmailProjectStatus } from '../EmailProjects/types';
import { PrintProjectsActionsConstants, PrintProjectStatus } from '../PrintProjects/types';
import { createPaginationReducer } from '../Pagination/reducers';
import { PaginationStoreModule } from '../Pagination/types';
import { createTableSearchReducer } from '../TableSearch/reducers';
import { TableSearchStoreModule } from '../TableSearch/types';

const initialState: DashboardState = {
  projects: {
    entities: {},
    result: []
  },
  index: {
    error: null,
    isFailed: false,
    isLoading: false
  },
  details: {
    error: null,
    isFailed: false,
    isLoading: false,
    project: null
  },
  tableSearch: {
    order: {}
  },
  pagination: getLocationPaginationState(),
  notificationToCheckOutWindows: []
};

const dashboardProjectsPaginationReducer = createPaginationReducer<DashboardState, AnyProject>(
  // @ts-ignore
  PaginationStoreModule.DASHBOARD_PROJECTS
);

const dashboardProjectsTableSearchReducer = createTableSearchReducer<AnyProject, DashboardState>(
  // @ts-ignore
  TableSearchStoreModule.DASHBOARD_PROJECTS
);

export default function dashboardReducer(state = initialState, action: WithLogoutAction<DashboardActionType>) {
  switch (action.type) {
    case DashboardActionsConstants.INDEX_BEGIN: {
      return update(state, {
        index: {
          isFailed: { $set: false },
          isLoading: { $set: true },
          error: { $set: null }
        }
      });
    }

    case DashboardActionsConstants.INDEX_SUCCESS: {
      const data = normalizeProjects(action.payload.data);

      return update(state, {
        index: {
          isLoading: { $set: false },
          isFailed: { $set: false },
          error: { $set: null }
        },
        projects: {
          entities: { $set: data.entities },
          result: { $set: data.result }
        },
        pagination: {
          $set: action.payload.pagination
        }
      });
    }

    case DashboardActionsConstants.INDEX_FAILED: {
      const networkError = createNetworkErrorObject(action.payload.error);
      return update(state, {
        index: {
          isLoading: { $set: false },
          isFailed: { $set: true },
          error: { $set: networkError }
        },
        projects: {
          entities: { $set: {} },
          result: { $set: [] }
        }
      });
    }

    case DashboardActionsConstants.GET_PROJECT_BY_ID_BEGIN: {
      return update(state, {
        details: {
          isFailed: { $set: false },
          isLoading: { $set: true },
          error: { $set: null },
          project: { $set: null }
        }
      });
    }

    case DashboardActionsConstants.GET_PROJECT_BY_ID_SUCCESS: {
      return update(state, {
        details: {
          isLoading: { $set: false },
          isFailed: { $set: false },
          error: { $set: null },
          project: { $set: action.payload }
        }
      });
    }

    case DashboardActionsConstants.GET_PROJECT_BY_ID_FAILED: {
      const networkError = createNetworkErrorObject(action.payload.error);
      return update(state, {
        details: {
          isLoading: { $set: false },
          isFailed: { $set: true },
          error: { $set: networkError },
          project: { $set: null }
        }
      });
    }

    case PrintProjectsActionsConstants.REMOVE_PROJECT:
    case EmailProjectsActionsConstants.REMOVE_PROJECT:
      return update(state, {
        projects: {
          result: { $set: state.projects.result.filter((projectId) => projectId !== action.payload) }
        }
      });

    case PrintProjectsActionsConstants.PUBLISH_PROJECT: {
      const project = state.projects.entities[action.payload.id];
      if (!project) {
        return state;
      }

      return update(state, {
        projects: {
          entities: {
            [action.payload.id]: {
              status: { $set: PrintProjectStatus.SCHEDULED }
            }
          }
        }
      });
    }

    case EmailProjectsActionsConstants.PUBLISH_PROJECT: {
      const project = state.projects.entities[action.payload.id];
      if (!project) {
        return state;
      }

      return update(state, {
        projects: {
          entities: {
            [action.payload.id]: {
              status: { $set: EmailProjectStatus.SCHEDULED },
              subject: { $set: action.payload.subject },
              exactTime: { $set: action.payload.exactTime },
              publishDate: { $set: action.payload.publishDate }
            }
          }
        }
      });
    }

    case EmailProjectsActionsConstants.SAVE_PROJECT_SUBJECT: {
      if (state.details.project && state.details.project.id === action.payload.id) {
        return update(state, {
          details: {
            project: {
              subject: { $set: action.payload.subject }
            }
          }
        });
      }
      return state;
    }

    case EmailProjectsActionsConstants.CANCEL_PUBLISH_PROJECT: {
      const project = state.projects.entities[action.payload.id];
      if (!project) {
        return state;
      }

      return update(state, {
        projects: {
          entities: {
            [action.payload.id]: {
              status: { $set: EmailProjectStatus.IN_PROGRESS },
              exactTime: { $set: null }
            }
          }
        }
      });
    }

    case DashboardActionsConstants.TEST_FLAG_TOGGLED: {
      const project = state.projects.entities[action.payload.id];
      if (!project) {
        return state;
      }

      return update(state, {
        projects: {
          entities: {
            [action.payload.id]: {
              test: { $set: action.payload.test }
            }
          }
        }
      });
    }

    case DashboardActionsConstants.NOTIFY_TO_CHECK_OUT: {
      const notification = state.notificationToCheckOutWindows.find(
        (not) => not.project && not.project.id === action.payload.project.id
      );

      if (notification) {
        return update(state, {
          notificationToCheckOutWindows: {
            $set: state.notificationToCheckOutWindows.map((notification) => {
              if (notification.project && notification.project.id === action.payload.project.id) {
                notification.open = true;
              }
              return notification;
            })
          }
        });
      }

      return update(state, {
        notificationToCheckOutWindows: {
          $set: [
            ...state.notificationToCheckOutWindows,
            {
              open: true,
              notifier: action.payload.notifier,
              project: action.payload.project
            }
          ]
        }
      });
    }

    case DashboardActionsConstants.NOTIFY_TO_CHECK_OUT_CLOSE:
      return update(state, {
        notificationToCheckOutWindows: {
          $set: state.notificationToCheckOutWindows.map((notification) => {
            if (notification.project && notification.project.id === action.payload.project.id) {
              notification.open = false;
            }
            return notification;
          })
        }
      });

    case DashboardActionsConstants.KICKED_OUT_FROM_PROJECT:
      return update(state, {
        notificationToCheckOutWindows: {
          $set: state.notificationToCheckOutWindows.filter(
            (notification) => notification.project && notification.project.id !== action.payload.project.id
          )
        }
      });

    case PrintProjectsActionsConstants.CHECK_IN_PROJECT:
    case EmailProjectsActionsConstants.CHECK_IN_PROJECT: {
      const project = state.projects.entities[action.payload.projectId];
      const isInDetails = state.details.project && state.details.project.id === action.payload.projectId;

      const projectsUpdater = project
        ? {
            projects: {
              entities: {
                [action.payload.projectId]: {
                  checkedInBy: { $set: action.payload.user }
                }
              }
            }
          }
        : {};

      const detailsUpdater = isInDetails
        ? {
            details: {
              project: {
                checkedInBy: { $set: action.payload.user }
              }
            }
          }
        : {};

      return update(state, {
        ...projectsUpdater,
        ...detailsUpdater
      });
    }

    case DashboardActionsConstants.PROJECT_CHECKED_OUT: {
      const project = state.projects.entities[action.payload.projectId];
      const isInDetails = state.details.project && state.details.project.id === action.payload.projectId;

      const projectsUpdater = project
        ? {
            projects: {
              entities: {
                [action.payload.projectId]: {
                  checkedInBy: { $set: undefined }
                }
              }
            }
          }
        : {};

      const detailsUpdater = isInDetails
        ? {
            details: {
              project: {
                checkedInBy: { $set: undefined }
              }
            }
          }
        : {};

      return update(state, {
        ...projectsUpdater,
        ...detailsUpdater
      });
    }

    case AuthActionsConstants.LOGOUT:
      return update(state, {
        $set: initialState
      });

    default: {
      return dashboardProjectsTableSearchReducer(
        dashboardProjectsPaginationReducer(state, action),
        action
      ) as DashboardState;
    }
  }
}
