import { BaseSettings, RequestSettings } from '../../utils/httpRequest';
import { RotateImageRequestPayload } from './types';

export const uploadImage = (settings: RequestSettings<{ projectId: string }, File>): BaseSettings => ({
  query: {
    method: 'post',
    url: '/api/v1/images/upload/:projectId'
  },
  payload: settings.requestPayload,
  params: settings.requestParams
});

export const uploadBrandFolderImage = (
  settings: RequestSettings<
    { projectId: string },
    { imageId: string; targetImageWidth: number; targetImageHeight: number; type: 'project' | 'template' }
  >
): BaseSettings => ({
  query: {
    method: 'post',
    url: '/api/v1/images/upload/:projectId/brandfolder'
  },
  payload: settings.requestPayload,
  params: settings.requestParams
});

export const uploadStoryImage = (
  settings: RequestSettings<
    { projectId: string },
    { imageUrl: string; targetImageWidth: number; targetImageHeight: number }
  >
): BaseSettings => ({
  query: {
    method: 'post',
    url: '/api/v1/images/upload/:projectId/story'
  },
  payload: settings.requestPayload,
  params: settings.requestParams
});

export const rotateImage = (
  settings: RequestSettings<{ projectId: string }, RotateImageRequestPayload>
): BaseSettings => ({
  query: {
    method: 'put',
    url: '/api/v1/images/rotate/:projectId'
  },
  payload: settings.requestPayload,
  params: settings.requestParams
});

export const cropImage = (
  settings: RequestSettings<{ projectId: string }, { cropRegion: number[]; fileKey: string }>
): BaseSettings => ({
  query: {
    method: 'put',
    url: '/api/v1/images/crop/:projectId'
  },
  payload: settings.requestPayload,
  params: settings.requestParams
});

export const uploadUserImage = (
  settings: RequestSettings<
    { projectId: string },
    { imageId: string; targetImageWidth: number; targetImageHeight: number }
  >
): BaseSettings => ({
  query: {
    method: 'post',
    url: '/api/v1/images/upload/:projectId/user_image'
  },
  payload: settings.requestPayload,
  params: settings.requestParams
});
