import nes from '@hapi/nes/lib/client';
import { AuthState } from '../store/Auth/types';
import { emailProjectsActions } from '../store/EmailProjects';
import { printProjectsActions } from '../store/PrintProjects';
import { dashboardActions, dashboardOperations } from '../store/Dashboard';
import { mailingListsActions } from '../store/MailingLists';
import Toast from '../components/Shared/Toast/Toast';
import { TFunction } from 'i18next';
import { authActions } from '../store/Auth';
import { storiesActions } from '../store/Stories';

export enum WsMessageType {
  PDF_GENERATION_ERROR = 'PDF_GENERATION_ERROR',
  PDF_GENERATION_DONE = 'PDF_GENERATION_DONE',
  UPDATE_MAILING_LIST = 'UPDATE_MAILING_LIST',
  PROJECT_CHECK_IN = 'PROJECT_CHECK_IN',
  PROJECT_CHECK_OUT = 'PROJECT_CHECK_OUT',
  NOTIFY_TO_CHECK_OUT = 'NOTIFY_TO_CHECK_OUT',
  KICKED_FROM_PROJECT = 'KICKED_FROM_PROJECT',
  FLIP_BOOK_STATE_CHANGED = 'FLIP_BOOK_STATE_CHANGED',
  SOCIAL_ACCOUNT_CONNECTION = 'SOCIAL_ACCOUNT_CONNECTION',
  BULK_AI_CONTENT_GENERATION_STARTED = 'BULK_AI_CONTENT_GENERATION_STARTED',
  BULK_AI_CONTENT_GENERATION_CHANNEL_DONE = 'BULK_AI_CONTENT_GENERATION_CHANNEL_DONE',
  BULK_AI_CONTENT_GENERATION_DONE = 'BULK_AI_CONTENT_GENERATION_DONE'
}

type WsData = {
  message: {
    type: WsMessageType;
    data: any;
  };
};

const getClient = () => {
  const secure = window.location.protocol === 'https:';
  const apiUrl = (process.env.REACT_APP_API_HOST || window.location.host).replace(/https?:\/\//, '');
  return new nes.Client(`${secure ? 'wss' : 'ws'}://${apiUrl}/api/ws`);
};

const connect = async (client: nes.Client, token: string) => {
  await client.connect({
    retries: 5,
    auth: {
      headers: { authorization: `Bearer ${token}` }
    }
  });
};

export const connectToUserChannel = async (
  user: AuthState['user'],
  token: string,
  dispatch: any,
  t: TFunction
): Promise<nes.Client> => {
  const client = getClient();

  await connect(client, token);

  await client.subscribe(`/user/${user.id}`, async (data: WsData) => {
    switch (data.message.type) {
      case WsMessageType.PROJECT_CHECK_IN:
        switch (data.message.data.channel) {
          case 'email':
            dispatch(emailProjectsActions.checkIn(data.message.data.projectId, data.message.data.user));
            break;
          case 'print':
            dispatch(printProjectsActions.checkIn(data.message.data.projectId, data.message.data.user));
            break;
        }
        break;

      case WsMessageType.UPDATE_MAILING_LIST:
        dispatch(mailingListsActions.updateMailingList(data.message.data.mailingList));
        break;

      case WsMessageType.FLIP_BOOK_STATE_CHANGED:
        dispatch(printProjectsActions.setFlipBookState(data.message.data.projectId, data.message.data.flipBookState));
        break;

      case WsMessageType.PROJECT_CHECK_OUT:
        dispatch(dashboardActions.projectCheckedOut(data.message.data.projectId, data.message.data.user));
        break;

      case WsMessageType.NOTIFY_TO_CHECK_OUT:
        dispatch(dashboardActions.notifyToCheckOut(data.message.data.project, data.message.data.notifier));
        break;

      case WsMessageType.KICKED_FROM_PROJECT:
        dispatch(dashboardOperations.kickOutFromProject(data.message.data.project));
        break;

      case WsMessageType.PDF_GENERATION_ERROR:
        Toast.error(t('notifications.publishPrintProject.error', { rawError: `(${data.message.data.error})` }));
        dispatch(printProjectsActions.pdfPublishRenderingJobError(data.message.data.projectId));
        break;

      case WsMessageType.PDF_GENERATION_DONE:
        dispatch(printProjectsActions.pdfPublishRenderingJobDone(data.message.data.projectId));
        break;

      case WsMessageType.SOCIAL_ACCOUNT_CONNECTION:
        dispatch(authActions.getOrganizationSocialAccountsData(data.message.data));
        break;

      case WsMessageType.BULK_AI_CONTENT_GENERATION_STARTED:
        dispatch(storiesActions.bulkAiContentGenerationStarted(data.message.data.storyId));
        break;

      case WsMessageType.BULK_AI_CONTENT_GENERATION_CHANNEL_DONE:
        dispatch(
          storiesActions.bulkAiContentGenerationChannelDone(data.message.data.storyId, data.message.data.channel)
        );
        break;

      case WsMessageType.BULK_AI_CONTENT_GENERATION_DONE:
        dispatch(storiesActions.bulkAiContentGenerationDone(data.message.data.storyId, t));
        break;
    }
  });

  return client;
};

export const connectToProjectChannel = async (projectId: string, token: string, dispatch: any): Promise<nes.Client> => {
  const client = getClient();

  await connect(client, token);

  await client.subscribe(`/project/${projectId}`, async () => {
    // connect to project channel
  });

  return client;
};

const webSocket = { connectToProjectChannel, connectToUserChannel };

export default webSocket;
