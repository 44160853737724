import React, { FunctionComponent } from 'react';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import EqualizerIcon from '@material-ui/icons/Equalizer';
import DeleteOutlineOutlinedIcon from '@material-ui/icons/DeleteOutlineOutlined';
import VisibilityOutlinedIcon from '@material-ui/icons/VisibilityOutlined';
import SettingsIcon from '@material-ui/icons/Settings';
import FileCopyIcon from '@material-ui/icons/FileCopy';
import LockOpenIcon from '@material-ui/icons/LockOpen';
import EditOutlinedIcon from '@material-ui/icons/EditOutlined';
import { useTranslation } from 'react-i18next';
import { Button, Menu, MenuItem, withTheme } from '@material-ui/core';
import styled from 'styled-components';
import { User } from '../../../../../store/Auth/types';
import { isEmailProject, isPrintProject } from '../../../../../utils';
import { AnyProjectAdmin } from '../../../../../store/AdminProjects/types';
import { EmailProjectStatus } from '../../../../../store/EmailProjects/types';
import { FlipBookState, PrintProjectStatus } from '../../../../../store/PrintProjects/types';
import { AdminRole } from '../../../../../store/AdminRoles/types';
import { hasAccessToAction, hasPermissionToAnyAction } from './hasAccessToAction';
import { ProjectActionType } from './useHandleProjectAction';
import { hasPermission } from '../../../../../utils/permissions';
import EmojiFlagsIcon from '@material-ui/icons/EmojiFlags';
import MenuBookIcon from '@material-ui/icons/MenuBook';
import TelegramIcon from '@material-ui/icons/Telegram';
import LinkIcon from '@material-ui/icons/Link';

type ProjectActionsCellProps = {
  project: AnyProjectAdmin;
  user: User;
  role: AdminRole;
  handleEvent: (project: AnyProjectAdmin, type: ProjectActionType) => void;
};

const ProjectActionsCell: FunctionComponent<ProjectActionsCellProps> = ({ project, user, role, handleEvent }) => {
  const { t } = useTranslation();
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);

  const projectLocked = !!project.checkedInBy && user.id !== project.checkedInBy.id;
  const hasAction = hasAccessToAction(project, role);

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const handleMenuItemClick = (project: AnyProjectAdmin, event: ProjectActionType) => {
    handleMenuClose();
    return handleEvent(project, event);
  };

  const handleMenuOpen = (event: React.MouseEvent<HTMLButtonElement>) => {
    if (hasPermissionToAnyAction(project, role, projectLocked)) {
      setAnchorEl(event.currentTarget);
    }
  };

  return (
    <>
      <ActionButton
        disabled={!hasPermissionToAnyAction(project, role, projectLocked)}
        variant="outlined"
        size="small"
        endIcon={<KeyboardArrowDownIcon />}
        onClick={handleMenuOpen}
      >
        <SettingsIcon />
      </ActionButton>
      <Menu anchorEl={anchorEl} keepMounted open={Boolean(anchorEl)} onClose={handleMenuClose}>
        {isPrintProject(project) &&
          hasAction('generateFlipBook') &&
          (project.flipBookState === FlipBookState.DONE || hasPermission(role, ['debugFlipBookRegeneration'])) && (
            <ActionsMenuItem onClick={() => handleMenuItemClick(project, 'generateFlipBook')}>
              <MenuBookIcon />{' '}
              {project.flipBookState === FlipBookState.DONE ? t('common.flipBook') : t('common.flipBookNotYetReady')}
            </ActionsMenuItem>
          )}
        {hasAction('access') && isEmailProject(project) && project.status === EmailProjectStatus.PUBLISHED && (
          <ActionsMenuItem onClick={() => handleMenuItemClick(project, 'statistics')}>
            <EqualizerIcon /> {t('common.statistics')}
          </ActionsMenuItem>
        )}
        {hasAction('resetCheckIn') && projectLocked && (
          <ActionsMenuItem onClick={() => handleMenuItemClick(project, 'resetCheckIn')}>
            <LockOpenIcon /> {t('common.resetCheckOut')}
          </ActionsMenuItem>
        )}
        {hasAction('access') && !projectLocked && (
          <ActionsMenuItem onClick={() => handleMenuItemClick(project, 'preview')}>
            <VisibilityOutlinedIcon /> {t('common.preview')}
          </ActionsMenuItem>
        )}
        {project.status === PrintProjectStatus.IN_PROGRESS && !projectLocked && hasAction('edit') && (
          <ActionsMenuItem onClick={() => handleMenuItemClick(project, 'edit')}>
            <EditOutlinedIcon /> {t('common.edit')}
          </ActionsMenuItem>
        )}
        {hasAction('access') && !projectLocked && isEmailProject(project) && (
          <ActionsMenuItem onClick={() => handleMenuItemClick(project, 'generateLink')}>
            <LinkIcon /> {t('pages.projectDetails.generateLink')}
          </ActionsMenuItem>
        )}
        {isPrintProject(project) &&
          !projectLocked &&
          project.status === PrintProjectStatus.IN_PROGRESS &&
          hasAction('publish') && (
            <ActionsMenuItem onClick={() => handleMenuItemClick(project, 'publishPrint')}>
              <TelegramIcon /> {t('common.scheduleForPublish')}
            </ActionsMenuItem>
          )}
        {isPrintProject(project) &&
          !projectLocked &&
          (project.status === PrintProjectStatus.SCHEDULED || project.status === PrintProjectStatus.DELIVERED) &&
          hasAction('publish') && (
            <ActionsMenuItem onClick={() => handleMenuItemClick(project, 'rePublishPrint')}>
              <TelegramIcon /> {t('common.reScheduleForPublish')}
            </ActionsMenuItem>
          )}
        {isPrintProject(project) && hasAction('edit') && (
          <ActionsMenuItem onClick={() => handleMenuItemClick(project, 'clone')}>
            <FileCopyIcon /> {t('common.copy')}
          </ActionsMenuItem>
        )}
        {hasPermission(role, ['debugTestProjectsCreation']) && (
          <ActionsMenuItem onClick={() => handleMenuItemClick(project, 'toggleTestFlag')}>
            <EmojiFlagsIcon /> {project.test ? t('common.removeTestFlag') : t('common.addTestFlag')}
          </ActionsMenuItem>
        )}
        {hasAction('remove') && !projectLocked && (
          <ActionsMenuDeleteItem onClick={() => handleMenuItemClick(project, 'remove')}>
            <DeleteOutlineOutlinedIcon /> {t('common.remove')}
          </ActionsMenuDeleteItem>
        )}
      </Menu>
    </>
  );
};

const ActionsMenuItem = styled(MenuItem)`
  display: flex;
  align-items: center;
  svg {
    margin-right: 0.6rem;
  }
`;

const ActionsMenuDeleteItem = styled(ActionsMenuItem)`
  color: #f00;
`;

const ActionButton = withTheme(styled(Button)`
  color: ${({ theme }) => theme.palette.secondary.main};
  .MuiButton-endIcon {
    color: ${({ theme }) => theme.palette.primary.main};
  }
`);

export default ProjectActionsCell;
