import React, { createContext, useEffect, useState } from 'react';
import { DataTableHeaderCell, TableContainer, TableHeadStyled, TableWrapper } from './DataTableStyled';
import { Collapse, Paper, TableBody, TableCell, TableRow } from '@material-ui/core';
import {
  ColumnName,
  DataTableProps,
  ExtendedColumnDefinition,
  TranslateColumnDefinition,
  UrlColumnDefinition
} from './types';
import { DataTableColumnCell } from './DataTableColumnCell';
import { LoadingData } from './Body/LoadingData';
import { FailedData } from './Body/FailedData';
import { NoData } from './Body/NoData';
import { DataTablePagination } from './Pagination/DataTablePagination';
import useViewportSize from '../../../hooks/useViewportSize';
import { useTranslation } from 'react-i18next';
import DataTableColumnOrderButtons from './ColumnOrderButtons/DataTableColumnOrderButtons';
import { useDispatch } from 'react-redux';
import { clearAllOrder, orderColumn } from '../../../store/TableSearch/actions';
import { clearPagination } from '../../../store/Pagination/actions';
import IconButton from '@material-ui/core/IconButton';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import KeyboardArrowRightIcon from '@material-ui/icons/KeyboardArrowRight';

function isUtilColumn<E = never, UCN extends string = never>(columnName: ColumnName<E, UCN>) {
  return (['actions'] as ColumnName<E, UCN>[]).includes(columnName);
}

export const DataTableContext = createContext({ tPath: '' });

function getName<E = never, UCN extends string = never>(def: ExtendedColumnDefinition<E, UCN>) {
  return typeof def === 'string' ? def : (def as ExtendedColumnDefinition<E, UCN>).name;
}

export function DataTable<E = never, UCN extends string = never>({
  columnDefinitions,
  tPath,
  data,
  isLoading,
  isFailed,
  paginationProps,
  disablePagination,
  enableSearch,
  headingStyle,
  wrapperStyle,
  tableSearchProps,
  expandableKey,
  expandableContent,
  tabs
}: DataTableProps<E, UCN>): JSX.Element {
  const columnNames: ColumnName<E, UCN>[] = columnDefinitions.map((def) =>
    typeof def === 'string' ? def : (def as ExtendedColumnDefinition<E, UCN>).name
  );

  const columnAmount = columnNames.length;
  const { smartphone } = useViewportSize();
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const hasExpandableRows =
    expandableKey && Object.values(data.entities).find((entity) => entity[expandableKey as keyof E]) ? true : false;

  const [expandedItemIndex, setExpandedItemIndex] = useState<number | null>(null);

  useEffect(() => {
    setExpandedItemIndex(null);
  }, [data.result]);

  useEffect(() => {
    return () => {
      if (paginationProps) {
        dispatch(clearPagination(paginationProps.module));
      }
      if (tableSearchProps) {
        dispatch(clearAllOrder(tableSearchProps.module));
      }
    };
  }, []);

  return (
    <DataTableContext.Provider value={{ tPath }}>
      <div style={{ width: '100%' }}>
        {paginationProps && (
          <DataTablePagination<E>
            {...paginationProps}
            disablePagination={disablePagination}
            enableSearch={enableSearch}
            columnAmount={columnAmount}
            isLoading={isLoading}
          />
        )}
        {tabs}
        <Paper>
          <TableWrapper style={{ ...(wrapperStyle || {}) }}>
            {isLoading && <LoadingData columnAmount={columnAmount} />}
            <TableContainer>
              <TableHeadStyled>
                <TableRow>
                  {hasExpandableRows && <TableCell />}
                  {columnDefinitions.map((def, idx) => {
                    const name = getName(def as ExtendedColumnDefinition<E, UCN>);
                    return (
                      <TableCell
                        key={idx}
                        style={{
                          padding: smartphone ? '6px 16px' : '',
                          cursor:
                            tableSearchProps && (def as ExtendedColumnDefinition<E, UCN>).sortable
                              ? 'pointer'
                              : 'default'
                        }}
                      >
                        <DataTableHeaderCell
                          onClick={() => {
                            if (tableSearchProps && (def as ExtendedColumnDefinition<E, UCN>).sortable) {
                              dispatch(
                                orderColumn(tableSearchProps.module, {
                                  order: {
                                    name
                                  }
                                })
                              );
                            }
                          }}
                        >
                          <div style={{ ...(headingStyle || {}) }}>
                            {(def as ExtendedColumnDefinition<E, UCN>).label ||
                              t(`${isUtilColumn(name) ? 'table' : tPath}.${String(name)}`)}
                          </div>
                          {tableSearchProps && (def as ExtendedColumnDefinition<E, UCN>).sortable && (
                            <DataTableColumnOrderButtons columnName={name} {...tableSearchProps} />
                          )}
                        </DataTableHeaderCell>
                      </TableCell>
                    );
                  })}
                </TableRow>
              </TableHeadStyled>
              <TableBody>
                {isFailed ? (
                  <FailedData columnAmount={columnAmount} />
                ) : data.result.length === 0 ? (
                  <NoData columnAmount={columnAmount} />
                ) : (
                  <>
                    {data.result.map((key, i) => {
                      const borderBottom: React.CSSProperties =
                        expandableKey && data.entities[key][expandableKey as keyof E] ? { borderBottom: 0 } : {};

                      return (
                        <React.Fragment key={key}>
                          <TableRow hover>
                            {expandableKey && data.entities[key][expandableKey as keyof E] ? (
                              <TableCell style={{ ...borderBottom, padding: 0 }}>
                                <IconButton
                                  aria-label="expand row"
                                  size="small"
                                  onClick={() => setExpandedItemIndex(expandedItemIndex === i ? null : i)}
                                >
                                  {expandedItemIndex === i ? <KeyboardArrowDownIcon /> : <KeyboardArrowRightIcon />}
                                </IconButton>
                              </TableCell>
                            ) : (
                              hasExpandableRows && <TableCell />
                            )}
                            {columnDefinitions.map((def, idx) => (
                              <DataTableColumnCell<E, UCN>
                                style={{
                                  ...(def as ExtendedColumnDefinition<E, UCN>).style,
                                  ...borderBottom
                                }}
                                key={idx}
                                definition={def}
                                value={
                                  (def as ExtendedColumnDefinition<E, UCN>).name &&
                                  !(def as TranslateColumnDefinition<E, UCN>).isTranslate &&
                                  !(typeof (def as UrlColumnDefinition<E, UCN>).url === 'string')
                                    ? data.entities[key]
                                    : data.entities[key][
                                        ((def as ExtendedColumnDefinition<E, UCN>).name || def) as keyof E
                                      ]
                                }
                              />
                            ))}
                          </TableRow>
                          {expandableContent && expandableKey && data.entities[key][expandableKey as keyof E] && (
                            <TableRow>
                              <TableCell
                                style={{ paddingBottom: 0, paddingTop: 0 }}
                                colSpan={columnDefinitions.length + 1}
                              >
                                <Collapse in={expandedItemIndex === i} timeout="auto" unmountOnExit>
                                  {expandableContent(data.entities[key], expandedItemIndex === i)}
                                </Collapse>
                              </TableCell>
                            </TableRow>
                          )}
                        </React.Fragment>
                      );
                    })}
                  </>
                )}
              </TableBody>
            </TableContainer>
          </TableWrapper>
        </Paper>
        {paginationProps && (
          <DataTablePagination<E>
            {...paginationProps}
            columnAmount={columnAmount}
            isLoading={isLoading}
            disablePagination={disablePagination}
          />
        )}
      </div>
    </DataTableContext.Provider>
  );
}

export default DataTable;
