import React from 'react';
import {
  nonSocialStoryChannelTypes,
  StoryChannel,
  StoryChannelAttachment,
  StoryChannelStatus,
  StoryChannelType,
  storyChannelTypes,
  StoryFieldName,
  StoryFormData
} from '../../../../store/Stories/types';
import ThumbUpIcon from '@material-ui/icons/ThumbUp';
import VisibilityIcon from '@material-ui/icons/Visibility';
import ThumbDownIcon from '@material-ui/icons/ThumbDown';
import EmojiEmotionsOutlinedIcon from '@material-ui/icons/EmojiEmotionsOutlined';
import ErrorIcon from '@material-ui/icons/Error';
import CachedIcon from '@material-ui/icons/Cached';
import ScheduleOutlinedIcon from '@material-ui/icons/ScheduleOutlined';
import { Theme, Typography } from '@material-ui/core';
import { AdminOrganization } from '../../../../store/AdminOrganizations/types';
import app from '../../../../config/app/app';
import styled, { keyframes } from 'styled-components';
import { TFunction } from 'i18next';
import { trimHtml } from '../../../../utils';

export const getFieldValue = (channel: StoryChannel, fieldName: StoryFieldName) => {
  return channel.fields.find((field) => field.name === fieldName)?.value || '';
};

export const isChannelDisabled = (channel: StoryChannel, isOwnerOfStory: boolean) => {
  if (!isOwnerOfStory) {
    return true;
  }

  return [
    StoryChannelStatus.READY_TO_REVIEW,
    StoryChannelStatus.APPROVED,
    StoryChannelStatus.REJECTED,
    StoryChannelStatus.SCHEDULED,
    StoryChannelStatus.PUBLISHED,
    StoryChannelStatus.PUBLISH_ERROR
  ].includes(channel.status);
};

export const getChannelFields = (channelType: StoryChannelType) => {
  switch (channelType) {
    case StoryChannelType.FACEBOOK:
    case StoryChannelType.TWITTER:
    case StoryChannelType.INSTAGRAM:
    case StoryChannelType.LINKEDIN:
      return [StoryFieldName.BODY];
    case StoryChannelType.PRINT:
      return [StoryFieldName.HEADLINE, StoryFieldName.LEAD, StoryFieldName.BODY];
    case StoryChannelType.CALENDAR_EVENT:
      return [StoryFieldName.BODY, StoryFieldName.URL, StoryFieldName.START_DATE, StoryFieldName.END_DATE];
    case StoryChannelType.EMAIL:
      return [StoryFieldName.SUBJECT, StoryFieldName.BODY];
    case StoryChannelType.BLOG:
      return [StoryFieldName.HEADLINE, StoryFieldName.BODY];
  }
};

export const getStoryAttachments = (channels: StoryChannel[]) =>
  channels.reduce((all, channel) => {
    if (channel.attachments.length) {
      all.push(...channel.attachments);
    }

    return all;
  }, [] as StoryChannelAttachment[]);

export const getStoryChannelStatus = (
  status: StoryChannelStatus,
  t: TFunction,
  theme: Theme,
  channelType?: StoryChannelType
) => {
  let icon: React.ReactNode;
  let color: string = theme.palette.secondary.main;
  const iconStyle: React.CSSProperties = {
    width: 12,
    height: 12
  };

  switch (status) {
    case StoryChannelStatus.IDLE:
      color = theme.palette.warning.main;
      icon = <CachedIcon style={iconStyle} />;
      break;
    case StoryChannelStatus.READY_TO_REVIEW:
      color = '#10adaf';
      icon = <VisibilityIcon style={iconStyle} />;
      break;
    case StoryChannelStatus.APPROVED:
      color = theme.palette.success.main;
      icon = <ThumbUpIcon style={iconStyle} />;
      break;
    case StoryChannelStatus.REJECTED:
      color = theme.palette.error.light;
      icon = <ThumbDownIcon style={iconStyle} />;
      break;
    case StoryChannelStatus.SCHEDULED:
      color = theme.palette.primary.main;
      icon = <ScheduleOutlinedIcon style={iconStyle} />;
      break;
    case StoryChannelStatus.PUBLISHED:
      color = '#9c27b0';
      icon = <EmojiEmotionsOutlinedIcon style={iconStyle} />;
      break;
    case StoryChannelStatus.PUBLISH_ERROR:
      color = theme.palette.error.main;
      icon = <BlinkingErrorIcon style={iconStyle} />;
      break;
  }

  return (
    <Typography variant="caption" style={{ color: color, display: 'flex', alignItems: 'center', lineHeight: 'normal' }}>
      {icon}&nbsp;
      {t(
        `pages.story.statuses.${
          StoryChannelType.BLOG === channelType && StoryChannelStatus.PUBLISHED === status ? 'sentToWordPress' : status
        }`
      )}
    </Typography>
  );
};

export const generateChannelChipContent = (channel: StoryChannel, t: TFunction, theme: Theme) => (
  <>
    {t(`pages.story.channels.${channel.type}`)}
    {getStoryChannelStatus(channel.status, t, theme, channel.type)}
  </>
);

export const getOrganizationChannelTypes = (organization: AdminOrganization | null) => {
  if (!organization) return [];
  return organization.socialPublishing ? storyChannelTypes : nonSocialStoryChannelTypes;
};

export const isVideo = (filename: string) => {
  try {
    const parts = filename.split('.');
    const ext = parts[parts.length - 1];

    return app.acceptedAttachments.videoFormats.includes(ext.toLowerCase());
  } catch (e) {
    return false;
  }
};

export const socialChannelHasPreview = (type: StoryChannelType) => {
  return [StoryChannelType.FACEBOOK, StoryChannelType.TWITTER].includes(type);
};

export const getChannelFileMimeTypes = (type: StoryChannelType) => {
  if (!app.acceptedAttachments[type]) {
    return null;
  }

  return app.acceptedAttachments[type];
};

export const getChannelBodyCharLimit = (type: StoryChannelType): number | null => {
  switch (type) {
    case StoryChannelType.INSTAGRAM:
      return 2200;
    case StoryChannelType.FACEBOOK:
      return 33000;
    case StoryChannelType.TWITTER:
      return 280;
    case StoryChannelType.LINKEDIN:
      return 3000;
  }

  return null;
};

export const getChannelVideoLength = (type: StoryChannelType): number[] => {
  switch (type) {
    case StoryChannelType.INSTAGRAM:
      return [0.5, 140];
    case StoryChannelType.FACEBOOK:
      return [0, 14400]; // 0s - 4hours
    case StoryChannelType.TWITTER:
      return [3, 60];
    case StoryChannelType.LINKEDIN:
      return [3, 1800];
  }

  return [];
};

const blinker = keyframes`
    0% { opacity: 1.0; }
    50% { opacity: 0.0; }
    100% { opacity: 1.0; }
  `;

const BlinkingErrorIcon = styled(ErrorIcon)`
  animation: ${blinker} 2s linear infinite;
`;

export function getPromptValue(channelData: StoryChannel, type: StoryChannelType, t: TFunction): string {
  const { fields } = channelData;
  const body = fields.find((field) => field.name === StoryFieldName.BODY);
  let bodyValue = '';

  if (body) {
    const additionalFields = getAdditionalFields(type, fields, t);
    const trimmedBody = trimHtml(body.value.toString(), { limit: 10000, preserveTags: false }).html;

    if (additionalFields) {
      bodyValue = `${additionalFields}\n\n${t(`pages.story.inputs.${StoryFieldName.BODY}`)}: ${trimmedBody}`;
    } else {
      bodyValue = trimmedBody;
    }
  } else {
    bodyValue = t('pages.story.windows.createWithAi.noContent').toString();
  }

  return bodyValue;
}

function getAdditionalFields(type: StoryChannelType, fields: StoryFormData[], t: TFunction): string {
  switch (type) {
    case StoryChannelType.PRINT:
      return getPrintAdditionalFields(fields, t);
    case StoryChannelType.EMAIL:
      return getEmailAdditionalFields(fields, t);
    default:
      return '';
  }
}

function getPrintAdditionalFields(fields: StoryFormData[], t: TFunction): string {
  const headlineField = fields.find((field) => field.name === StoryFieldName.HEADLINE);
  const subheadField = fields.find((field) => field.name === StoryFieldName.LEAD);

  if (headlineField && subheadField) {
    const headline = `${t(`pages.story.inputs.${headlineField.name}`)}: ${headlineField.value}`;
    const subhead = `${t(`pages.story.inputs.${subheadField.name}`)}: ${subheadField.value}`;
    return `${headline}\n${subhead}`;
  }

  return '';
}

function getEmailAdditionalFields(fields: StoryFormData[], t: TFunction): string {
  const subjectField = fields.find((field) => field.name === StoryFieldName.SUBJECT);

  if (subjectField) {
    const subjectValue = trimHtml(subjectField.value.toString(), { limit: 1000, preserveTags: false }).html;
    return `${t(`pages.story.inputs.${subjectField.name}`)}: ${subjectValue}`;
  }

  return '';
}

export const newLinesToParagraphs = (content: string) => {
  if (!content) {
    return content;
  }

  const parts = content.split('\n');

  return parts.map((part) => (part && part.trim().length > 0 ? `<p>${part}</p>` : '')).join('');
};

export const isHtmlFieldType = (channelType: StoryChannelType) => {
  switch (channelType) {
    case StoryChannelType.PRINT:
    case StoryChannelType.EMAIL:
      return true;

    default:
      return false;
  }
};

export const getSavedValuesFromLocalStorage = (localStorageKey: string): string[] => {
  const savedValues = localStorage.getItem(localStorageKey);
  return savedValues ? JSON.parse(savedValues) : [];
};

export const saveValueToLocalStorage = (newValue: string, localStorageKey: string, maxValuesToSave: number) => {
  const savedValues = getSavedValuesFromLocalStorage(localStorageKey);

  // Check if the value already exists in the saved values
  if (!savedValues.includes(newValue)) {
    savedValues.unshift(newValue); // Add the new value to the beginning of the array
    if (savedValues.length > maxValuesToSave) {
      savedValues.pop(); // Remove the oldest value if the array exceeds the limit
    }
    localStorage.setItem(localStorageKey, JSON.stringify(savedValues));
  }
};
