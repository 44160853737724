import React, { FunctionComponent, useEffect, useState } from 'react';
import styled from 'styled-components';
import { NoResultsFound, PageContainer, PageContent } from '../PageStyledComponents';
import GroupOutlinedIcon from '@material-ui/icons/GroupOutlined';
import PageHeader from '../../Shared/Layout/PageHeader';
import { formatTableTimestamp, useTypedSelector } from '../../../utils';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import Loader from '../../Shared/Loading/Loader';
import { RouteComponentProps } from 'react-router';
import { hasPermission } from '../../../utils/permissions';
import { User } from '../../../store/Auth/types';
import { TableSearchStoreModule } from '../../../store/TableSearch/types';
import { PaginationStoreModule } from '../../../store/Pagination/types';
import DataTable from '../../Shared/DataTable/DataTable';
import { usePaginationWatch } from '../../Shared/DataTable/Pagination/usePaginationWatch';
import { useTableSearchWatch } from '../../Shared/DataTable/useTableSearchWatch';
import { PrintProject } from '../../../store/PrintProjects/types';
import { storiesOperations } from '../../../store/Stories';
import { Story, StoryChannelType } from '../../../store/Stories/types';
import TableActionButton from '../../Shared/DataTable/TableActionButton/TableActionButton';
import DeleteIcon from '@material-ui/icons/Delete';
import TableActionButtonsContainer from '../../Shared/DataTable/TableActionButton/TableActionButtonsContainer';
import useOpenHandler from '../../../hooks/useOpenHandler';
import DeleteStoryWindow from './Windows/DeleteStoryWindow';
import { push } from 'connected-react-router';
import linksConstants from '../../../config/app/linksConstants';
import AddIcon from '@material-ui/icons/Add';
import EditIcon from '@material-ui/icons/Edit';
import CreateStoryWindow from './Windows/CreateStoryWindow';
import { TableLink } from '../../Shared/DataTable/ColumnCell/UrlColumnCell';
import { AttachmentThumbnail } from './Partials/Attachments/AttachmentThumbnail';
import { getStoryAttachments } from './Utils/storyUtils';
import ChannelChip from './Partials/ChannelChip';
import { Box, Tooltip } from '@material-ui/core';
import AssignmentIndIcon from '@material-ui/icons/AssignmentInd';
import CalendarEventChip from './Partials/CalendarEventChip';
import ShareOutlinedIcon from '@material-ui/icons/ShareOutlined';
import ProjectShareWindow from '../Dashboard/Windows/ProjectShareWindow';
import ProjectExpandableContent from '../Dashboard/WorkInProgressTable/ProjectExpandableContent';

type StoriesPageProps = RouteComponentProps<{}> & {};

const StoriesPage: FunctionComponent<StoriesPageProps> = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [initialLoading, setInitialLoading] = useState(true);
  const [activeStory, setActiveStory] = useState<Story | null>(null);
  const organization = useTypedSelector((state) => state.auth.organization);
  const user = useTypedSelector((state) => state.auth.user);
  const role = useTypedSelector((state) => state.auth.role);
  const stories = useTypedSelector((state) => state.stories.list);

  const isFailed = useTypedSelector((state) => state.stories.index.isFailed);
  const loading = useTypedSelector((state) => state.stories.index.isLoading);
  const pagination = useTypedSelector((state) => state.stories.pagination);
  const tableSearch = useTypedSelector((state) => state.stories.tableSearch);

  const [deleteStoryWindowOpen, onDeleteStoryWindowOpen, onDeleteStoryWindowClose] = useOpenHandler();
  const [createStoryWindowOpen, onCreateStoryWindowOpen, onCreateStoryWindowClose] = useOpenHandler();
  const [shareProjectWindowOpen, onShareProjectWindowOpen, onShareProjectWindowClose] = useOpenHandler();

  useEffect(() => {
    if (!loading) {
      setInitialLoading(false);
    }
  }, [loading]);

  usePaginationWatch(pagination, [storiesOperations.index]);
  useTableSearchWatch<PrintProject>(tableSearch, [storiesOperations.index]);

  const getActionButtons = () => {
    if (hasPermission(role, ['storiesAdd'])) {
      return [
        {
          onClick: () => onCreateStoryWindowOpen(),
          icon: <AddIcon />,
          label: t('pages.story.createStoryButton')
        }
      ];
    }

    return [];
  };

  const getUserFullName = (user: User) => {
    return `${user.firstName} ${user.lastName}`.trim() || user.email;
  };

  const title = (
    <div>
      <WelcomeBackText>{t('pages.story.heading')},</WelcomeBackText>
      <div>{getUserFullName(user)}!</div>
    </div>
  );

  return (
    <PageContainer>
      <PageHeader title={title} rightActionButtons={getActionButtons()} />
      <PageContent>
        {initialLoading && <Loader />}
        {!initialLoading && !stories.result.length && !pagination.q && (
          <>
            <NoResultsFound>
              <div style={{ marginBottom: '2rem' }}>{t('pages.story.noStories')}</div>
            </NoResultsFound>
          </>
        )}
        {((!initialLoading && stories.result.length > 0) || pagination.q) && (
          <DataTable<Story, 'actions' | 'attachments' | 'publicationWeek'>
            enableSearch={true}
            wrapperStyle={{
              padding: '0.5rem 2rem 0'
            }}
            headingStyle={{
              fontSize: '1.4rem',
              color: '#014b72',
              textAlign: 'left',
              padding: '0.5rem',
              fontWeight: 'normal'
            }}
            columnDefinitions={[
              {
                name: 'name',
                sortable: true,
                style: { minWidth: '300px', maxWidth: 'none' },
                render: (story) => (
                  <div
                    onClick={() => dispatch(push(linksConstants.STORY.EDIT(story.id)))}
                    style={{ cursor: 'pointer' }}
                  >
                    <TableLink>{story.name}</TableLink>
                    <Box display="flex" alignItems="center">
                      <AssignmentIndIcon titleAccess={t('pages.story.author')} fontSize="small" />
                      <div style={{ fontSize: '13px', marginLeft: '3px' }}>
                        {story.createdBy ? (
                          <Tooltip title={story.createdBy.email || ''} placement="bottom" arrow>
                            <div>{story.createdBy.fullName}</div>
                          </Tooltip>
                        ) : (
                          'N/A'
                        )}
                      </div>
                    </Box>
                    <div onClick={(e) => e.stopPropagation()}>
                      {story.channels
                        .filter((cd) => cd.type === StoryChannelType.CALENDAR_EVENT)
                        .map((channel) => (
                          <CalendarEventChip key={channel.id} channel={channel} />
                        ))}
                    </div>
                  </div>
                )
              },
              {
                name: 'actions',
                sortable: false,
                render: (story) => (
                  <TableActionButtonsContainer>
                    <TableActionButton
                      icon={<GroupOutlinedIcon />}
                      tooltip={t('pages.story.table.buttons.contentContributors')}
                      ButtonProps={{
                        disabled: !hasPermission(role, ['storiesContributors']),
                        onClick: () => dispatch(push(linksConstants.STORY.CONTENT_CONTRIBUTORS(story.id)))
                      }}
                    />
                    {organization && organization.masterOrgId && (
                      <TableActionButton
                        icon={<ShareOutlinedIcon />}
                        tooltip={t('common.share')}
                        ButtonProps={{
                          disabled: !hasPermission(role, ['storiesContributors']),
                          onClick: () => {
                            setActiveStory(story);
                            onShareProjectWindowOpen();
                          }
                        }}
                      />
                    )}
                    <TableActionButton
                      icon={<EditIcon />}
                      tooltip={t('pages.story.table.buttons.edit')}
                      ButtonProps={{
                        disabled: !hasPermission(role, ['storiesEdit', 'storiesEditChannels']),
                        onClick: () => dispatch(push(linksConstants.STORY.EDIT(story.id)))
                      }}
                    />
                    <TableActionButton
                      icon={<DeleteIcon />}
                      tooltip={t('pages.story.table.buttons.delete')}
                      ButtonProps={{
                        disabled: !hasPermission(role, ['storiesDelete']),
                        onClick: () => {
                          setActiveStory(story);
                          onDeleteStoryWindowOpen();
                        }
                      }}
                    />
                  </TableActionButtonsContainer>
                )
              },
              {
                name: 'attachments',
                sortable: false,
                render: (story) => {
                  const attachments = getStoryAttachments(
                    story.channels.filter((cd) => cd.type !== StoryChannelType.CALENDAR_EVENT)
                  );

                  if (!attachments.length) {
                    return <div>{t('pages.story.table.noAttachments')}</div>;
                  }

                  const first3 = attachments.slice(0, 3);

                  return (
                    <>
                      {first3.map((att) => {
                        const storyChannel = story.channels.find((channel) => channel.id === att.channelId);
                        return (
                          <AttachmentThumbnail
                            key={`att-list-${att.id}`}
                            attachment={att}
                            channelType={storyChannel && storyChannel.type}
                          />
                        );
                      })}
                      {attachments.length > 3 && (
                        <>
                          <br />
                          {t('pages.story.table.andMoreAttachments', { more: attachments.length - 3 })}
                        </>
                      )}
                    </>
                  );
                }
              },
              {
                name: 'channels',
                sortable: false,
                style: { minWidth: '300px' },
                render: (story) => {
                  const channels = story.channels.filter((cd) => cd.type !== StoryChannelType.CALENDAR_EVENT);
                  if (channels.length > 0) {
                    return (
                      <div>
                        {channels.map((channel) => (
                          <ChannelChip key={channel.id} channel={channel} />
                        ))}
                      </div>
                    );
                  } else {
                    return <div>{t('pages.story.table.noChannels')}</div>;
                  }
                }
              },
              {
                name: 'publicationWeek',
                sortable: false,
                style: { minWidth: '190px' },
                render: (story) => (
                  <div>
                    {formatTableTimestamp(story.publicationDateFrom)} - {formatTableTimestamp(story.publicationDateTo)}
                  </div>
                )
              }
            ]}
            tPath={'pages.story.table'}
            data={stories}
            tableSearchProps={{
              tableSearch,
              module: TableSearchStoreModule.STORY
            }}
            paginationProps={{
              pagination,
              module: PaginationStoreModule.STORY
              // onPageChange: () => {},
              // onPerChange: () => {}
            }}
            isFailed={isFailed}
            isLoading={loading}
            expandableKey={'isShared'}
            expandableContent={(entity, open) => <ProjectExpandableContent entity={entity} open={open} />}
          />
        )}
      </PageContent>
      {createStoryWindowOpen && (
        <CreateStoryWindow
          open={createStoryWindowOpen}
          fullScreenOnMobile={true}
          onCloseClick={onCreateStoryWindowClose}
        />
      )}
      {deleteStoryWindowOpen && (
        <DeleteStoryWindow
          story={activeStory}
          open={deleteStoryWindowOpen}
          fullScreenOnMobile={true}
          onCloseClick={onDeleteStoryWindowClose}
        />
      )}
      {shareProjectWindowOpen && (
        <ProjectShareWindow
          context="story"
          open={shareProjectWindowOpen}
          project={activeStory}
          onCloseClick={onShareProjectWindowClose}
          fullScreenOnMobile={true}
        />
      )}
    </PageContainer>
  );
};

const WelcomeBackText = styled.div`
  font-size: 1.4rem;
  font-weight: normal;
`;

export default StoriesPage;
