import { Dictionary } from 'lodash';
import { StateWithPagination, StateWithTableSearch } from '../../components/Shared/DataTable/types';
import { ShortUser } from '../Auth/types';
import { AdminOrganization } from '../AdminOrganizations/types';
import { TFunction } from 'i18next';
import { WordpressPost } from '../MyOrganization/types';

export type Story = {
  id: string;
  name: string;
  updatedAt: Date;
  createdAt: Date;
  publicationDateFrom: Date;
  publicationDateTo: Date;
  unique: 'story';
  channels: StoryChannel[];
  createdBy: ShortUser | null;
  shares: StoryShare[];
  kind?: 'story';
  organizationId: string;
  organization?: AdminOrganization;
  aiGenerationInProgress?: boolean;
};

export type StoryShare = {
  id: string;
  organizationId: string;
  storyId: string;
  createdAt: string;
  updatedAt: string;
};

export type StoryChannel = {
  id: string;
  storyId: string;
  type: StoryChannelType;
  fields: StoryFormData[];
  status: StoryChannelStatus;
  attachments: StoryChannelAttachment[];
  history: StoryChannelHistory[];
  story: Story;
  createdBy: ShortUser | null;
  wordpressPosts?: WordpressPost[];
};

export type StoryChannelHistory = {
  id: string;
  updatedAt: Date;
  createdAt: Date;
  transition: StoryChannelStatus;
  createdBy?: ShortUser | null;
};

export enum StoryChannelStatus {
  IDLE = 'IDLE',
  READY_TO_REVIEW = 'READY_TO_REVIEW',
  APPROVED = 'APPROVED',
  REJECTED = 'REJECTED',
  SCHEDULED = 'SCHEDULED',
  PUBLISHED = 'PUBLISHED',
  PUBLISH_ERROR = 'PUBLISH_ERROR'
}

export const storyChannelStatus = [
  StoryChannelStatus.IDLE,
  StoryChannelStatus.READY_TO_REVIEW,
  StoryChannelStatus.APPROVED,
  StoryChannelStatus.REJECTED,
  StoryChannelStatus.SCHEDULED,
  StoryChannelStatus.PUBLISHED,
  StoryChannelStatus.PUBLISH_ERROR
];

export type StoryContentContributor = {
  id: string;
  email: string;
  createdAt: string;
  updatedAt: string;
  lastName: string;
  firstName: string;
  permission: StoryContentContributorPermission;
  userId: string;
  storyId: string;
  infobox: string | null;
};

export enum StoryContentContributorPermission {
  MAINTAINER,
  EDITOR,
  VIEWER
}

export const storyContentContributorPermission: StoryContentContributorPermission[] = [
  StoryContentContributorPermission.MAINTAINER,
  StoryContentContributorPermission.EDITOR,
  StoryContentContributorPermission.VIEWER
];

export type StoriesState = StateWithPagination &
  StateWithTableSearch<Story> & {
    list: NormalizedStories;

    index: {
      isLoading: boolean;
      isFailed: boolean;
      error: NetworkError | null;
    };

    aiGeneration: {
      storyId: string;
      channelsDone: string[];
    };
  };

export type StoryContentContributorsState = StateWithPagination &
  StateWithTableSearch<StoryContentContributor> & {
    list: NormalizedStoryContributors;

    index: {
      isLoading: boolean;
      isFailed: boolean;
      error: NetworkError | null;
    };
  };

export type NormalizedStories = {
  entities: Dictionary<Story>;
  result: string[];
};

export type NormalizedStoryContributors = {
  entities: Dictionary<StoryContentContributor>;
  result: string[];
};

export type IndexStoriesRequestParams = {
  per: number;
  page: number;
  order?: string[];
  q: string | null;
};

export type GetStoriesResponse = {
  data: Story[];
  pagination: Pagination;
};

export type StoriesIndexActionBEGIN = {
  type: StoriesActionsConstants.INDEX_BEGIN;
};

export type StoriesIndexActionSUCCESS = {
  type: StoriesActionsConstants.INDEX_SUCCESS;
  payload: GetStoriesResponse;
};

export type StoriesIndexActionFAILED = {
  type: StoriesActionsConstants.INDEX_FAILED;
  payload: {
    error: any;
  };
};
export type IndexStoryContentContributorsRequestParams = {
  storyId: string;
  per: number;
  page: number;
  order?: string[];
  q: string | null;
};

export type GetStoryContentContributorsResponse = {
  data: Story[];
  pagination: Pagination;
};

export type StoryContentContributorsIndexActionBEGIN = {
  type: StoriesActionsConstants.INDEX_CONTRIBUTORS_BEGIN;
};

export type StoryContentContributorsIndexActionSUCCESS = {
  type: StoriesActionsConstants.INDEX_CONTRIBUTORS_SUCCESS;
  payload: GetStoryContentContributorsResponse;
};

export type StoryContentContributorsIndexActionFAILED = {
  type: StoriesActionsConstants.INDEX_CONTRIBUTORS_FAILED;
  payload: {
    error: any;
  };
};

export type RemoveStoryAction = {
  type: StoriesActionsConstants.REMOVE_STORY;
  payload: string;
};

export type BulkAiContentGenerationStartedAction = {
  type: StoriesActionsConstants.BULK_AI_CONTENT_GENERATION_STARTED;
  payload: string;
};

export type BulkAiContentGenerationChannelDoneAction = {
  type: StoriesActionsConstants.BULK_AI_CONTENT_GENERATION_CHANNEL_DONE;
  payload: {
    storyId: string;
    channel: StoryChannel;
  };
};

export type BulkAiContentGenerationDoneAction = {
  type: StoriesActionsConstants.BULK_AI_CONTENT_GENERATION_DONE;
  payload: { storyId: string; t: TFunction };
};

export type SetAiGenerationAction = {
  type: StoriesActionsConstants.SET_AI_GENERATION;
  payload: {
    storyId: string;
    channelsDone?: string[];
  };
};

export enum StoriesActionsConstants {
  REMOVE_STORY = 'PROJECTS/STORY/REMOVE',
  INDEX_BEGIN = 'PROJECTS/STORY/INDEX/BEGIN',
  INDEX_SUCCESS = 'PROJECTS/STORY/INDEX/SUCCESS',
  INDEX_FAILED = 'PROJECTS/STORY/INDEX/FAILED',
  INDEX_CONTRIBUTORS_BEGIN = 'PROJECTS/STORY/CONTRIBUTORS/INDEX/BEGIN',
  INDEX_CONTRIBUTORS_SUCCESS = 'PROJECTS/STORY/CONTRIBUTORS/INDEX/SUCCESS',
  INDEX_CONTRIBUTORS_FAILED = 'PROJECTS/STORY/CONTRIBUTORS/INDEX/FAILED',
  BULK_AI_CONTENT_GENERATION_STARTED = 'PROJECTS/STORY/BULK_AI_CONTENT_GENERATION_STARTED',
  BULK_AI_CONTENT_GENERATION_CHANNEL_DONE = 'PROJECTS/STORY/BULK_AI_CONTENT_GENERATION_CHANNEL_DONE',
  BULK_AI_CONTENT_GENERATION_DONE = 'PROJECTS/STORY/BULK_AI_CONTENT_GENERATION_DONE',
  SET_AI_GENERATION = 'PROJECTS/STORY/SET_AI_GENERATION'
}

export type StoriesActionType =
  | StoriesIndexActionBEGIN
  | StoriesIndexActionSUCCESS
  | StoriesIndexActionFAILED
  | RemoveStoryAction
  | BulkAiContentGenerationStartedAction
  | BulkAiContentGenerationChannelDoneAction
  | BulkAiContentGenerationDoneAction
  | SetAiGenerationAction;

export type StoryContentContributorsActionType =
  | StoryContentContributorsIndexActionBEGIN
  | StoryContentContributorsIndexActionSUCCESS
  | StoryContentContributorsIndexActionFAILED;

export type CreateStoryValues = Pick<Story, 'name'>;

export type InviteContributorValues = Pick<StoryContentContributor, 'email' | 'firstName' | 'lastName' | 'infobox'>;
export type QuickInviteContributorValues = Pick<StoryContentContributor, 'infobox'>;
export type UpdateContributorValues = Pick<StoryContentContributor, 'permission'>;

export enum StoryChannelType {
  PRINT,
  EMAIL,
  FACEBOOK,
  TWITTER,
  INSTAGRAM,
  CALENDAR_EVENT,
  LINKEDIN,
  BLOG
}

export const storyChannelTypes: StoryChannelType[] = [
  StoryChannelType.PRINT,
  StoryChannelType.EMAIL,
  StoryChannelType.FACEBOOK,
  StoryChannelType.TWITTER,
  StoryChannelType.INSTAGRAM,
  StoryChannelType.LINKEDIN,
  StoryChannelType.BLOG
];

export const nonSocialStoryChannelTypes: StoryChannelType[] = [
  StoryChannelType.PRINT,
  StoryChannelType.EMAIL,
  StoryChannelType.BLOG
];

export enum StoryFieldType {
  STRING,
  NUMBER,
  DATE,
  JSON
}

export enum StoryFieldName {
  HEADLINE,
  LEAD,
  BODY,
  SUBJECT,
  START_DATE,
  END_DATE,
  URL,
  NAME,
  DESCRIPTION
}

export type StoryPrintChannelValues = {
  [StoryFieldName.HEADLINE]: string;
  [StoryFieldName.LEAD]: string;
  [StoryFieldName.BODY]: string;
};

export type StoryEmailChannelValues = {
  [StoryFieldName.SUBJECT]: string;
  [StoryFieldName.BODY]: string;
};

export type StorySimpleSocialChannelValues = {
  [StoryFieldName.BODY]: string;
};

export type StoryBlogChannelValues = {
  [StoryFieldName.HEADLINE]: string;
  [StoryFieldName.BODY]: string;
};

export type StoryEventChannelValues = {
  [StoryFieldName.NAME]: string;
  [StoryFieldName.DESCRIPTION]: string;
  [StoryFieldName.URL]: string;
  [StoryFieldName.START_DATE]: Date;
  [StoryFieldName.END_DATE]: Date;
};

export type StoryFormData = {
  type: StoryFieldType;
  name: StoryFieldName;
  value: string | Date;
};

export type StoryChannelFormData = {
  channelType: StoryChannelType;
  formData: StoryFormData[];
};

export type StoryChannelAttachment = {
  id: string;
  channelId: string;
  createdAt: Date;
  updatedAt: Date;
  filename: string;
  extension: string;
  userId: string | null;
  url: string;
  thumbnailUrl: string;
  order: number;
};

export type StoryAttachmentFile = File | Blob | MediaSource;

export type PublishSocialChannelValues = {
  publishDate: string;
  publishTime: Date;
  wordpressIntegrationIds?: string[];
};

export type StoryChannelPublishError = {
  id: string;
  createdAt: Date;
  updatedAt: Date;
  message: string;
  code: number;
  publishDateId: string;
};

export type StoryClone = {
  id: string;
  createdAt: string;
  sourceId: string;
  target: Story;
  createdBy: ShortUser;
};

export type StoryClonesResponse = {
  data: StoryClone[];
};

export type GeneratedAiContent = {
  name: StoryFieldName;
  value: string;
};

export type GeneratedAiContentResponse = {
  content: GeneratedAiContent[];
};
