import React, { FunctionComponent, useEffect, useState } from 'react';
import PageHeader from '../../Shared/Layout/PageHeader';
import { PageContainer, PageContentPaper } from '../PageStyledComponents';
import {
  Box,
  Button,
  Checkbox,
  Collapse,
  Divider,
  FormControlLabel,
  Grid,
  Tooltip,
  Typography,
  useTheme
} from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import Toast from '../../Shared/Toast/Toast';
import { storiesOperations } from '../../../store/Stories';
import { push } from 'connected-react-router';
import linksConstants from '../../../config/app/linksConstants';
import KeyboardArrowLeftIcon from '@material-ui/icons/KeyboardArrowLeft';
import { Form, Formik, FormikProps } from 'formik';
import { AdminTextInput } from '../Admin/Shared/AdminFormInputs';
import * as Yup from 'yup';
import {
  CreateStoryValues,
  Story,
  StoryChannel,
  StoryChannelStatus,
  StoryChannelType,
  storyChannelTypes,
  StoryContentContributor,
  StoryFieldName
} from '../../../store/Stories/types';
import ChannelAccordion from './Partials/ChannelAccordion';
import { RouteComponentProps } from 'react-router';
import Loader from '../../Shared/Loading/Loader';
import styled from 'styled-components';
import { ReactComponent as PrintIcon } from '../../../assets/icons/channels/print.svg';
import { ReactComponent as EmailIcon } from '../../../assets/icons/channels/email.svg';
import { ReactComponent as FacebookIcon } from '../../../assets/icons/channels/facebook.svg';
import { ReactComponent as TwitterIcon } from '../../../assets/icons/channels/x.svg';
import { ReactComponent as InstagramIcon } from '../../../assets/icons/channels/instagram.svg';
import { ReactComponent as LinkedinIcon } from '../../../assets/icons/channels/linkedin.svg';
import { ReactComponent as BlogIcon } from '../../../assets/icons/channels/wordpress.svg';
import ChannelTile from './Partials/ChannelTile';
import { Range } from 'react-date-range';
import DateRangePickerComponent from './Partials/DateRangePickerComponent';
import { getFieldValue, getOrganizationChannelTypes } from './Utils/storyUtils';
import {
  addNegativeTimezoneOffset,
  createNetworkErrorObject,
  formatPublishDate,
  useTypedSelector
} from '../../../utils';
import SaveFormButton from './Partials/SaveFormButton';
import { hasPermission } from '../../../utils/permissions';
import { Alert } from '@material-ui/lab';
import { DefaultRoleName } from '../../../store/AdminRoles/types';
import { makeStyles } from '@material-ui/styles';
import CalendarEvent from './Partials/CalendarEvent';
import moment from 'moment';
import AssignmentIndIcon from '@material-ui/icons/AssignmentInd';
import CreateWithAiWindow from './Windows/CreateWithAiWindow';
import useOpenHandler from '../../../hooks/useOpenHandler';
import { setAiGeneration } from '../../../store/Stories/actions';
import CircularProgress from '@material-ui/core/CircularProgress';
import { ReactComponent as CreateWithAiIcon } from '../../../assets/icons/create_with_ai.svg';

type ManageStoryPageProps = RouteComponentProps<{ id: string }> & { location: { state: { from: string } } };

const useStyles = makeStyles({
  alertMessage: {
    '& .MuiAlert-message': {
      width: '100%',
      whiteSpace: 'pre-wrap'
    }
  }
});

const channelsOrder: StoryChannelType[] = [
  StoryChannelType.PRINT,
  StoryChannelType.EMAIL,
  StoryChannelType.FACEBOOK,
  StoryChannelType.INSTAGRAM,
  StoryChannelType.LINKEDIN,
  StoryChannelType.TWITTER,
  StoryChannelType.BLOG
];

const ManageStoryPage: FunctionComponent<ManageStoryPageProps> = ({ match, location }) => {
  const { t } = useTranslation();
  const theme = useTheme();
  const dispatch = useDispatch();
  const storyId = match.params.id;
  const fromPath = location.state && location.state.from;
  const organization = useTypedSelector((state) => state.auth.organization);
  const role = useTypedSelector((state) => state.auth.role);
  const user = useTypedSelector((state) => state.auth.user);
  const aiGeneration = useTypedSelector((state) => state.stories.aiGeneration);
  const isContentContributor = role.name === DefaultRoleName.CONTENT_CONTRIBUTOR;

  const [expanded, setExpanded] = useState<StoryChannelType | false>(false);
  const [channelsData, setChannelsData] = useState<StoryChannel[]>([]);
  const [initialLoading, setInitialLoading] = useState(true);
  const [story, setStory] = useState<Story | null>(null);
  const [publicationPeriod, setPublicationPeriod] = useState<Range[]>([]);
  const [storyContributor, setStoryContributor] = useState<StoryContentContributor | null>(null);
  const [showToast, setShowToast] = useState<boolean>(false);
  const [openInfobox, setOpenInfobox] = useState<boolean>(
    localStorage.getItem(`sc-infobox-hidden-${storyId}`) === 'true' ? false : true
  );
  const [calendarEvent, setCalendarEvent] = useState<boolean>(false);
  const [creatingCalendarEvent, setCreatingCalendarEvent] = useState<boolean>(false);
  const [calendarEventData, setCalendarEventData] = useState<StoryChannel | null>(null);
  const [calendarEventPeriod, setCalendarEventPeriod] = useState<Range[]>([]);
  const [channelTileClicked, setChannelTileClicked] = useState(false);

  const classes = useStyles();

  const [hideInfoboxForever, setHideInfoboxForever] = useState(false);
  const [createWithAiWindowOpen, onCreateWithAiWindowOpen, onCreateWithAiWindowClose] = useOpenHandler();

  useEffect(() => {
    if (aiGeneration.channelsDone.length > 0 && aiGeneration.storyId === storyId) {
      fetchStoryData();
    }
  }, [aiGeneration]);

  const handleInfoboxCb = (event: React.ChangeEvent<HTMLInputElement>) => {
    setHideInfoboxForever(event.target.checked);
  };

  const handleDissmissInfobox = () => {
    if (hideInfoboxForever) {
      localStorage.setItem(`sc-infobox-hidden-${storyId}`, 'true');
    }
    setOpenInfobox(false);
  };

  useEffect(() => {
    if (match.params.id) {
      fetchStoryData();
      if (openInfobox && isContentContributor) {
        fetchStoryContributorData();
      }
    } else {
      setInitialLoading(false);
    }
  }, []);

  useEffect(() => {
    if (expanded !== false) {
      fetchStoryData();
    }
  }, [expanded]);

  const fetchStoryContributorData = async () => {
    try {
      const contributor = await storiesOperations.showStoryContributor(match.params.id, user.id);
      setStoryContributor(contributor);
    } catch (e) {
      Toast.error(t('notifications.story.cannotFetchStoryContributor'));
    }
  };

  const fetchStoryData = async () => {
    try {
      const story = await storiesOperations.show(match.params.id);
      setStory(story);

      if (story.aiGenerationInProgress) {
        dispatch(setAiGeneration(story.id));
      } else {
        dispatch(setAiGeneration(''));
      }

      setChannelsData(story.channels.filter((cd) => cd.type !== StoryChannelType.CALENDAR_EVENT));

      const calendarEvent = story.channels.find((channel) => channel.type === StoryChannelType.CALENDAR_EVENT);
      if (calendarEvent) {
        setCalendarEventData(calendarEvent);
        setCalendarEvent(true);

        const eventStartDate = getFieldValue(calendarEvent, StoryFieldName.START_DATE);
        const eventEndDate = getFieldValue(calendarEvent, StoryFieldName.END_DATE);
        setCalendarEventPeriod([
          {
            startDate: eventStartDate
              ? addNegativeTimezoneOffset(eventStartDate)
              : addNegativeTimezoneOffset(story.publicationDateFrom),
            endDate: eventEndDate
              ? addNegativeTimezoneOffset(eventEndDate)
              : addNegativeTimezoneOffset(story.publicationDateTo),
            key: 'selection'
          }
        ]);
      }
      setPublicationPeriod([
        {
          startDate: addNegativeTimezoneOffset(story.publicationDateFrom),
          endDate: addNegativeTimezoneOffset(story.publicationDateTo),
          key: 'selection'
        }
      ]);
    } catch (e) {
      const error = createNetworkErrorObject(e);

      switch (error.statusCode) {
        case 401:
          return Toast.info(t('notifications.common.loginFirst'));
        default:
          return Toast.error(t('notifications.story.cannotFetchStory'));
      }
    } finally {
      setInitialLoading(false);
    }
  };

  if (!story) {
    return null;
  }

  const handleChannelChange = (channel: StoryChannelType) => (event: React.ChangeEvent<{}>, isExpanded: boolean) => {
    setExpanded(isExpanded ? channel : false);
  };

  const updateStory = async (values: CreateStoryValues, showToast: boolean) => {
    try {
      if (storyId && publicationPeriod && publicationPeriod[0].startDate && publicationPeriod[0].endDate) {
        await storiesOperations.update(
          storyId,
          values.name,
          moment(publicationPeriod[0].startDate).format('YYYY-MM-DD'),
          moment(publicationPeriod[0].endDate).format('YYYY-MM-DD')
        );
        if (showToast) {
          Toast.success(t('notifications.story.storySaved'));
        }
      }
    } catch (e) {
      Toast.error(t('notifications.story.errorSave'));
    }
  };

  const backButton = {
    onClick: () => {
      if (fromPath === linksConstants.STORY.CONTENT_CONTRIBUTORS(story.id)) {
        dispatch(push(linksConstants.STORY.CONTENT_CONTRIBUTORS(storyId)));
      } else if (fromPath === linksConstants.DASHBOARD.SHARING_CENTER) {
        dispatch(push(linksConstants.DASHBOARD.SHARING_CENTER));
      } else {
        dispatch(push(linksConstants.STORY.INDEX));
      }
    },
    label: t('common.back'),
    icon: <KeyboardArrowLeftIcon />
  };

  const activeChannelTypes = storyChannelTypes.filter((storyChannelType) =>
    channelsData.find((channel) => channel.type === storyChannelType)
  );

  const handleCalendarEventChange = async (event: React.ChangeEvent<HTMLInputElement>) => {
    setCalendarEvent(event.target.checked);
    if (event.target.checked) {
      setCreatingCalendarEvent(true);
      await storiesOperations.createChannel(storyId, StoryChannelType.CALENDAR_EVENT, []);
      await fetchStoryData();
      setCreatingCalendarEvent(false);
    } else if (calendarEventData) {
      await storiesOperations.removeChannel(storyId, calendarEventData.id);
    }
  };

  const isOwnerOfStory =
    !!(organization && story.organizationId === organization.id) && aiGeneration.storyId !== storyId;

  const renderBasicDataForm = (props: FormikProps<CreateStoryValues>) => (
    <Form>
      <PageContentPaper>
        <Grid container spacing={2}>
          <Grid item xs={8}>
            <AdminTextInput
              t={t}
              name="name"
              section="story"
              autoFocus
              disabled={!isOwnerOfStory || !hasPermission(role, ['storiesEdit'])}
            />
          </Grid>
          <Grid item xs={4}>
            <DateRangePickerComponent
              range={publicationPeriod}
              setRange={(period) => {
                setPublicationPeriod(period);
                props.submitForm();
              }}
              disabled={!isOwnerOfStory || !hasPermission(role, ['storiesEdit'])}
              label={t('pages.story.inputs.publicationWeek')}
            />
          </Grid>
        </Grid>
        <Box display="flex" justifyContent="space-between">
          {!calendarEvent && (
            <FormControlLabel
              control={<Checkbox checked={calendarEvent} onChange={handleCalendarEventChange} name="calendarEvent" />}
              label={t('pages.story.calendarEvent.calendarEvent')}
              disabled={!isOwnerOfStory || !hasPermission(role, ['storiesEdit'])}
            />
          )}
          <SaveFormButton
            props={props}
            saveForm={async () => {
              setShowToast(true);
              await props.submitForm();
              setShowToast(false);
            }}
            disabled={!isOwnerOfStory || !hasPermission(role, ['storiesEdit'])}
          />
        </Box>
      </PageContentPaper>
    </Form>
  );

  const getChannelIcon = (type: StoryChannelType) => {
    switch (type) {
      case StoryChannelType.PRINT:
        return <PrintIcon fill="#ffffff" width="100%" />;
      case StoryChannelType.EMAIL:
        return <EmailIcon fill="#ffffff" width="100%" />;
      case StoryChannelType.FACEBOOK:
        return <FacebookIcon fill="#ffffff" width="100%" />;
      case StoryChannelType.TWITTER:
        return <TwitterIcon fill="#ffffff" width="70%" height="auto" />;
      case StoryChannelType.INSTAGRAM:
        return <InstagramIcon fill="#ffffff" width="100%" />;
      case StoryChannelType.LINKEDIN:
        return <LinkedinIcon fill="#ffffff" width="100%" />;
      case StoryChannelType.BLOG:
        return <BlogIcon fill="#ffffff" width="100%" />;
    }
  };

  const getChannelColor = (type: StoryChannelType) => {
    switch (type) {
      case StoryChannelType.PRINT:
        return theme.palette.primary.main;
      case StoryChannelType.EMAIL:
        return theme.palette.primary.light;
      case StoryChannelType.FACEBOOK:
        return '#1877F2';
      case StoryChannelType.TWITTER:
        return '#14171a';
      case StoryChannelType.INSTAGRAM:
        return '#E1306C';
      case StoryChannelType.LINKEDIN:
        return '#0A66C2';
      case StoryChannelType.BLOG:
        return '#23282d';

      default:
        return theme.palette.primary.main;
    }
  };

  const setChannelStatus = async (
    channel: StoryChannel,
    status: StoryChannelStatus,
    prevStatus?: StoryChannelStatus
  ) => {
    try {
      await storiesOperations.updateChannelStatus(storyId, channel.id, status);

      switch (status) {
        case StoryChannelStatus.APPROVED:
          if (prevStatus === StoryChannelStatus.SCHEDULED) {
            Toast.success(t('notifications.story.storyScheduleCancelled'));
          } else if (channel.type === StoryChannelType.CALENDAR_EVENT) {
            Toast.success(t('notifications.story.calendarEventPublished'));
          } else {
            Toast.success(t('notifications.story.storyApproved'));
          }
          break;
        case StoryChannelStatus.REJECTED:
          Toast.success(t('notifications.story.storyRejected'));
          break;
        case StoryChannelStatus.SCHEDULED:
          Toast.success(t('notifications.story.storyScheduled'));
          break;
        case StoryChannelStatus.READY_TO_REVIEW:
          Toast.success(t('notifications.story.sentForApproval'));
          break;
        case StoryChannelStatus.IDLE:
          if (channel.type === StoryChannelType.CALENDAR_EVENT) {
            Toast.success(t('notifications.story.calendarEventUnpublished'));
          } else {
            Toast.success(t('notifications.story.storyIdled'));
          }
          break;
        default:
          Toast.success(t('notifications.story.storySaved'));
          break;
      }

      fetchStoryData();
    } catch (e) {
      Toast.error(t('notifications.story.errorSave'));
    }
  };

  return (
    <PageContainer>
      <PageHeader title={t('pages.story.manageStoryTitle')} leftActionButtons={[backButton]} />
      <Box marginTop={2}>
        {initialLoading && <Loader />}
        {!initialLoading && (
          <>
            <Box display="flex" alignItems="center" justifyContent="flex-end">
              {t('pages.story.author')}:&nbsp;
              <AssignmentIndIcon titleAccess={t('pages.story.author')} fontSize="small" />
              <div style={{ fontSize: '13px', marginLeft: '3px' }}>
                {story.createdBy ? (
                  <Tooltip title={story.createdBy.email || ''} placement="bottom" arrow>
                    <div>{story.createdBy.fullName}</div>
                  </Tooltip>
                ) : (
                  'N/A'
                )}
              </div>
              &nbsp;@&nbsp;{formatPublishDate(story.createdAt)}
            </Box>
            <Formik
              initialValues={{
                name: story?.name || ''
              }}
              validationSchema={Yup.object().shape({
                name: Yup.string().required()
              })}
              onSubmit={(data) => updateStory(data, showToast)}
            >
              {renderBasicDataForm}
            </Formik>
          </>
        )}
        {calendarEvent && calendarEventData && !creatingCalendarEvent && !initialLoading && (
          <CalendarEvent
            isOwnerOfStory={isOwnerOfStory}
            storyId={storyId}
            calendarEvent={calendarEvent}
            calendarEventData={calendarEventData}
            calendarEventPeriod={calendarEventPeriod}
            handleCalendarEventChange={handleCalendarEventChange}
            setCalendarEventPeriod={setCalendarEventPeriod}
            setChannelStatus={setChannelStatus}
            defaultEventName={story.name}
          />
        )}
        {isContentContributor && storyContributor && (
          <Collapse in={openInfobox}>
            <Alert variant="outlined" severity="info" className={classes.alertMessage}>
              <div
                dangerouslySetInnerHTML={{
                  __html: t('pages.story.infobox.defaultMessage')
                }}
              />
              {storyContributor.infobox && (
                <div style={{ marginTop: 15 }}>
                  <strong>{t('pages.story.infobox.additionalInstructions')}:</strong>
                  <div>{storyContributor.infobox}</div>
                </div>
              )}
              <Divider style={{ margin: '0.4rem 0' }} />
              <Box display="flex" justifyContent="flex-end" alignItems="center">
                <FormControlLabel
                  control={<Checkbox checked={hideInfoboxForever} onChange={handleInfoboxCb} size="small" />}
                  label={<Typography variant="body2">{t('pages.story.infobox.dontShowAgain')}</Typography>}
                />
                <Button size="small" color="primary" variant="contained" onClick={handleDissmissInfobox}>
                  {t('pages.story.infobox.close')}
                </Button>
              </Box>
            </Alert>
          </Collapse>
        )}
        <Box display="flex" justifyContent="space-between" alignItems="center">
          <Typography variant="h5" style={{ margin: '2rem 0' }}>
            {activeChannelTypes.length > 0 ? t('pages.story.channelsTitle') : t('pages.story.noChannels')}
          </Typography>
          {activeChannelTypes.length > 0 &&
            hasPermission(role, ['storiesEditChannels']) &&
            hasPermission(role, ['storiesAiContent']) && (
              <Button
                size="small"
                color="primary"
                variant="contained"
                disabled={aiGeneration.storyId.length > 0}
                onClick={async () => {
                  await fetchStoryData();
                  onCreateWithAiWindowOpen();
                }}
              >
                {aiGeneration.storyId.length > 0 ? (
                  <CircularProgress size={11} color="secondary" style={{ marginRight: 6 }} />
                ) : (
                  <CreateWithAiIcon fill="#ffffff" width={16} height={16} style={{ marginRight: 6 }} />
                )}
                {t('pages.story.createWithAi')}
              </Button>
            )}
        </Box>
        {aiGeneration.storyId === storyId && (
          <Alert variant="filled" severity="warning" className={classes.alertMessage} style={{ marginBottom: 10 }}>
            {t('pages.story.contentCreationInProgress').toString()}
          </Alert>
        )}
        {channelsData.map(
          (channelData) =>
            channelData.type !== StoryChannelType.CALENDAR_EVENT && (
              <ChannelAccordion
                key={`channel-accordion-${channelData.type}`}
                isOwnerOfStory={isOwnerOfStory}
                storyId={storyId}
                channelId={channelData.id}
                channel={channelData}
                channelsData={channelsData}
                channelType={channelData.type}
                expanded={expanded}
                setChannelStatus={setChannelStatus}
                handleChannelChange={handleChannelChange}
                deleteChannel={() => {
                  fetchStoryData();
                  setExpanded(false);
                }}
                disabled={aiGeneration.storyId === storyId}
                fetchStoryData={fetchStoryData}
              />
            )
        )}

        {!getOrganizationChannelTypes(organization).every((item) => channelsData.map((cd) => cd.type).includes(item)) &&
          channelsData.length !== 0 &&
          hasPermission(role, ['storiesEditChannels']) && (
            <Typography variant="h5" style={{ margin: '2rem 0' }}>
              {t('pages.story.needMoreChannels')}
            </Typography>
          )}
        <ProjectsRow>
          {hasPermission(role, ['storiesEditChannels']) &&
            getOrganizationChannelTypes(organization)
              .sort((a, b) => (channelsOrder.indexOf(a) < channelsOrder.indexOf(b) ? -1 : 1))
              .map((storyChannelType) => {
                const channelData = channelsData.find((channel) => channel.type === storyChannelType);
                if (!channelData) {
                  return (
                    <ChannelTile
                      key={`channel-tile-${storyChannelType}`}
                      title={t(`pages.story.channels.${storyChannelType}`)}
                      color={getChannelColor(storyChannelType)}
                      icon={getChannelIcon(storyChannelType)}
                      onClick={async () => {
                        if (aiGeneration.storyId !== storyId && !channelTileClicked) {
                          setChannelTileClicked(true);
                          await storiesOperations.createChannel(storyId, storyChannelType, []);
                          await fetchStoryData();
                          setExpanded(storyChannelType);
                          setChannelTileClicked(false);
                        }
                      }}
                      disabled={aiGeneration.storyId === storyId}
                    />
                  );
                }
              })}
        </ProjectsRow>
      </Box>
      {createWithAiWindowOpen && (
        <CreateWithAiWindow
          open={createWithAiWindowOpen}
          onCloseClick={onCreateWithAiWindowClose}
          fullScreenOnMobile
          activeChannelTypes={activeChannelTypes}
          channelTypes={getOrganizationChannelTypes(organization)}
          channelsData={channelsData}
          storyId={storyId}
          userId={user.id}
        />
      )}
    </PageContainer>
  );
};

const ProjectsRow = styled.div`
  display: flex;
`;

export default ManageStoryPage;
