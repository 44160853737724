import { AdminRole } from '../AdminRoles/types';
import { AyrshareSocialAccount, MyOrganizationSwatchColorSmall } from '../MyOrganization/types';
import { PasswordPolicy, SystemSetting } from '../SystemSettings/types';
import { AdminKeyword } from '../AdminKeywords/types';
import { AdminOrganization } from '../AdminOrganizations/types';

export type AuthState = {
  user: User;
  passwordPolicy: PasswordPolicy | null;
  role: AdminRole;
  authenticated: boolean;
  version: string;
  designerUrl: string;
  mainStorageUrl: string;
  externalDataCenterUrl: string;
  externalDataCenterId: number | null;
  dataCenters: {
    dc1: string;
    dc2: string;
    dc3: string;
  };
  webAppUrl: string;
  socialAccounts: AyrshareSocialAccount[];
  designerBackendUrl: string;
  designerAt: string;
  organization: AdminOrganization | null;
  organizations: AuthOrganization[];
  organizationId: string | null;
  token: string;
  systemSettings: SystemSetting[];
  chatId: string;
  relativeNucleusPath: string;
  colorGuidePdfUrl: string;
  colors: MyOrganizationSwatchColorSmall[];
};

export type AuthUserType = {
  id: string;
  name: string;
  createdAt: Date;
  updatedAt: Date;
};

export type AuthOrganization = {
  id: string;
  isOwner: boolean;
  name: string;
  readMoreLink: string | null;
};

export type User = {
  id: string;
  email: string;
  createdAt: Date;
  updatedAt: Date;
  verified: boolean;
  firstName: string | null;
  lastName: string | null;
  type: AuthUserType;
  keywords: AdminKeyword[];
  passwordUpdatedAt: Date;
  brandfolderCollection: string | null;
};

export type MyAccountUpdateValues = {
  email: string;
  role: string;
  firstName: string;
  lastName: string;
};

export type MyAccountUpdatePayload = {
  firstName: string;
  lastName: string;
};

export type OrganizationData = {
  sponsorLink: string | null;
  name: string | null;
  contactPerson: string | null;
  contactEmail: string | null;
  facebookLink: string | null;
  linkedinLink: string | null;
  instagramLink: string | null;
  twitterLink: string | null;
  youtubeLink: string | null;
  websiteLink: string | null;
  addressLine1: string | null;
  addressLine2: string | null;
  city: string | null;
  state: string | null;
  zip: string | null;
  phone: string | null;
  readMoreLink: string | null;
  donateOnlineLink: string | null;
  phoneAlternative: string | null;
  bulletin: boolean | null;
  email: boolean | null;
  flyer: boolean | null;
  newsletter: boolean | null;
  directory: boolean | null;
};

export type LoginFormValues = {
  email: string;
  password: string;
  rememberLogin: boolean;
};

export type LoginRequestResponse = {
  user: User;
  colors: MyOrganizationSwatchColorSmall[];
  role: AdminRole;
  passwordPolicy: PasswordPolicy;
  version: string;
  mainStorageUrl: string;
  dataCenters: {
    dc1: string;
    dc2: string;
    dc3: string;
  };
  webAppUrl: string;
  designerUrl: string;
  externalDataCenterUrl: string;
  externalDataCenterId: number | null;
  designerBackendUrl: string;
  designerAt: string;
  organizationId: string;
  token: string;
  organization: AdminOrganization | null;
  organizations: AuthOrganization[];
  systemSettings: SystemSetting[];
  chatId: string;
  relativeNucleusPath: string;
  colorGuidePdfUrl: string;
};

export type ShortUser = {
  id: string | null;
  email: string | null;
  fullName: string | null;
};

export type LoginAction = {
  type: AuthActionsConstants.LOGIN;
  payload: LoginRequestResponse;
};

export type LogoutAction = {
  type: AuthActionsConstants.LOGOUT;
};

export type ValidateTokenRequestResponse = LoginRequestResponse;

export type ValidateTokenAction = {
  type: AuthActionsConstants.VALIDATE_TOKEN;
  payload: ValidateTokenRequestResponse;
};

export type ResetPasswordInstructionsFormValues = {
  email: string;
};

export type ResetPasswordInstructionsRequestPayload = ResetPasswordInstructionsFormValues;

export type ResendVerificationFormValues = {
  email: string;
};

export type ResendVerificationRequestPayload = ResendVerificationFormValues;

export type SignUpFormValues = {
  email: string;
};

export type AuthTicketValidationData = {
  ticketId: string;
  ticketType: 'AV' | 'FP';
};

export type ValidateAuthTicketRequestParams = {
  ticketType: 'AV' | 'FP';
  ticketId: string;
};

export type SetPasswordFormValues = {
  password: string;
  confirmPassword: string;
};

export type ChangePasswordFormValues = {
  previousPassword: string;
  password: string;
  confirmPassword: string;
};

export type VerifyUserRequestParameters = {
  ticketId: string;
};

export type VerifyUserRequestPayload = {
  password: string;
  confirmPassword: string;
};

export type SetPasswordRequestParameters = {
  ticketId: string;
};

export type SetPasswordRequestPayload = {
  password: string;
  confirmPassword: string;
};

export type SetChangePasswordRequestPayload = {
  previousPassword: string;
  password: string;
  confirmPassword: string;
};

export type UpdateAccountAction = {
  type: AuthActionsConstants.UPDATE_ACCOUNT;
  payload: User;
};

export type UpdateAccountRequestPayload = {
  firstName?: string | null;
  lastName?: string | null;
};

export type SwitchOrganizationAction = {
  type: AuthActionsConstants.SWITCH_ORGANIZATION;
  payload: {
    organizationId: string;
  };
};

export type UpdateColorsAction = {
  type: AuthActionsConstants.UPDATE_COLORS;
  payload: MyOrganizationSwatchColorSmall[];
};

export type GetOrganizationSocialAccountsDataAction = {
  type: AuthActionsConstants.GET_ORGANIZATION_SOCIAL_ACCOUNT;
  payload: AyrshareSocialAccount[];
};

export enum AuthActionsConstants {
  GET_ORGANIZATION_SOCIAL_ACCOUNT = 'AUTH/GET_ORGANIZATION_SOCIAL_ACCOUNT',
  UPDATE_COLORS = 'AUTH/UPDATE_COLORS',
  SWITCH_ORGANIZATION = 'AUTH/SWITCH_ORGANIZATION',
  LOGIN = 'AUTH/LOGIN',
  LOGOUT = 'AUTH/LOGOUT',
  VALIDATE_TOKEN = 'AUTH/VALIDATE_TOKEN',
  UPDATE_ACCOUNT = 'AUTH/UPDATE_ACCOUNT'
}

export type AuthActionType =
  | LoginAction
  | ValidateTokenAction
  | UpdateAccountAction
  | SwitchOrganizationAction
  | GetOrganizationSocialAccountsDataAction
  | UpdateColorsAction;
