import React, { FunctionComponent, useState } from 'react';
import TableActionButtonsContainer from '../../../Shared/DataTable/TableActionButton/TableActionButtonsContainer';
import { TableSearchStoreModule } from '../../../../store/TableSearch/types';
import { PaginationStoreModule } from '../../../../store/Pagination/types';
import DataTable from '../../../Shared/DataTable/DataTable';
import { usePaginationWatch } from '../../../Shared/DataTable/Pagination/usePaginationWatch';
import { useTableSearchWatch } from '../../../Shared/DataTable/useTableSearchWatch';
import { formatTableTimestamp, useTypedSelector } from '../../../../utils';
import { storiesOperations } from '../../../../store/Stories';
import { Story, StoryContentContributor } from '../../../../store/Stories/types';
import { useTranslation } from 'react-i18next';
import useOpenHandler from '../../../../hooks/useOpenHandler';
import DeleteIcon from '@material-ui/icons/Delete';
import { hasPermission } from '../../../../utils/permissions';
import TableActionButton from '../../../Shared/DataTable/TableActionButton/TableActionButton';
import DeleteStoryContributorWindow from '../Windows/DeleteStoryContributorWindow';

type StoryContributorsTableProps = {
  story: Story;
  onContributorRemoved: () => void;
};

const StoryContributorsTable: FunctionComponent<StoryContributorsTableProps> = ({ story, onContributorRemoved }) => {
  const { t } = useTranslation();
  const user = useTypedSelector((state) => state.auth.user);
  const role = useTypedSelector((state) => state.auth.role);
  const contributors = useTypedSelector((state) => state.storyContributors.list);
  const isFailed = useTypedSelector((state) => state.storyContributors.index.isFailed);
  const isLoading = useTypedSelector((state) => state.storyContributors.index.isLoading);
  const pagination = useTypedSelector((state) => state.storyContributors.pagination);
  const tableSearch = useTypedSelector((state) => state.storyContributors.tableSearch);
  const [activeContributor, setActiveContributor] = useState<StoryContentContributor | null>(null);

  const [deleteContributorWindowOpen, onDeleteContributorWindowOpen, onDeleteContributorWindowClose] = useOpenHandler();

  usePaginationWatch(pagination, [storiesOperations.indexStoryContributors.bind(null, story.id)], [story.id]);
  useTableSearchWatch<StoryContentContributor>(tableSearch, [
    storiesOperations.indexStoryContributors.bind(null, story.id)
  ]);

  return (
    <>
      <DataTable<StoryContentContributor, 'actions'>
        enableSearch={true}
        columnDefinitions={[
          {
            name: 'email',
            sortable: true,
            render: (user) => <>{user.email}</>
          },
          {
            name: 'actions',
            render: (contributor) => (
              <TableActionButtonsContainer>
                <TableActionButton
                  icon={<DeleteIcon />}
                  tooltip={t('pages.story.contentContributors.table.buttons.delete')}
                  ButtonProps={{
                    disabled: !hasPermission(role, ['storiesContributors']) || user.id === contributor.userId,
                    onClick: () => {
                      setActiveContributor(contributor);
                      onDeleteContributorWindowOpen();
                    }
                  }}
                />
              </TableActionButtonsContainer>
            )
          },
          {
            name: 'firstName',
            render: (user) => <>{user.firstName || '---'}</>,
            sortable: true
          },
          {
            name: 'lastName',
            render: (user) => <>{user.lastName || '---'}</>,
            sortable: true
          },
          {
            name: 'createdAt',
            render: (user) => <>{formatTableTimestamp(user.createdAt)}</>,
            sortable: true
          }
        ]}
        tPath={'pages.story.contentContributors.table'}
        data={contributors}
        tableSearchProps={{
          tableSearch,
          module: TableSearchStoreModule.STORY_CONTENT_CONTRIBUTORS
        }}
        paginationProps={{
          pagination,
          module: PaginationStoreModule.STORY_CONTENT_CONTRIBUTORS
          // onPageChange: () => {},
          // onPerChange: () => {}
        }}
        isFailed={isFailed}
        isLoading={isLoading}
      />

      {deleteContributorWindowOpen && (
        <DeleteStoryContributorWindow
          story={story}
          contributor={activeContributor}
          open={deleteContributorWindowOpen}
          fullScreenOnMobile={true}
          onCloseClick={onDeleteContributorWindowClose}
          onSuccessfulSubmit={onContributorRemoved}
        />
      )}
    </>
  );
};

export default StoryContributorsTable;
