import { TemplateTypeName } from '../Templates/types';
import { Dictionary } from 'lodash';
import { ProjectNote } from '../Notes/types';
import { ShortUser } from '../Auth/types';
import { ProjectCheckedOutAction, TestFlagToggledAction } from '../Dashboard/types';
import { StateWithPagination, StateWithTableSearch } from '../../components/Shared/DataTable/types';
import { MailingList } from '../MailingLists/types';
import { ProjectShare } from '../SharingCenter/types';
import { AdminOrganization } from '../AdminOrganizations/types';

export type EmailProject = {
  id: string;
  name: string;
  kind: 'email';
  subject: string | null;
  flipBookState: null;
  test: boolean;
  templateId: string;
  templateDescription: string;
  type: TemplateTypeName;
  updatedAt: Date;
  createdAt: Date;
  status: EmailProjectStatus;
  source: string | null;
  updatedByAt: Date;
  updatedBy?: ShortUser;
  checkedInBy?: ShortUser;
  publishDate: string;
  publishDateExpanded?: EmailProjectPublishDate;
  notes: ProjectNote[];
  emailProjectUnique: 'emailProjectUnique';
  exactTime: Date | null;
  mailingListId: string | null;
  automated: boolean;
  thumbnailUrl: string | null;
  shares: ProjectShare[];
  isShared: boolean;
  organization?: AdminOrganization;
  organizationId: string;
  shareContext?: boolean;
};

export enum EmailProjectPublishDateStatus {
  IDLE = 'IDLE',
  SCHEDULED = 'SCHEDULED',
  PUBLISHED = 'PUBLISHED'
}

export type EmailProjectPublishDate = {
  id: string;
  updatedAt: Date;
  createdAt: Date;
  date: Date;
  exactTime: Date | null;
  status: EmailProjectPublishDateStatus;
  createdBy?: ShortUser | null;
  mailingListId: string | null;
  campaignId: string | null;
  mailingList?: MailingList;
  projectId: string;
  project: EmailProject;
};

export enum EmailProjectTemplateTypeDisplayName {
  email = 'Newsletter',
  print = 'Newsletter'
}

export enum EmailProjectStatus {
  IN_PROGRESS = 'IN_PROGRESS',
  SCHEDULED = 'SCHEDULED',
  PUBLISHED = 'PUBLISHED'
}

export const emailProjectStatus = [
  EmailProjectStatus.IN_PROGRESS,
  EmailProjectStatus.SCHEDULED,
  EmailProjectStatus.PUBLISHED
];

export type IndexEmailUserProjectsRequestParams = {
  per: number;
  page: number;
  order?: string[];
  q: string | null;
};

export type EmailProjectAttachment = {
  id: string;
  createdAt: Date;
  updatedAt: Date;
  filename: string;
  userId: string | null;
  extension: string;
  projectId: string;
  downloads: number;
};

export type EmailProjectsState = StateWithPagination &
  StateWithTableSearch<EmailProject> & {
    projects: NormalizedEmailProjects;

    index: {
      isLoading: boolean;
      isFailed: boolean;
      error: NetworkError | null;
    };
  };

export type NormalizedEmailProjects = {
  entities: Dictionary<EmailProject>;
  result: string[];
};

export type EmailProjectsIndexResponse = {
  data: EmailProject[];
  pagination: Pagination;
};

export type EmailProjectClone = {
  id: string;
  createdAt: string;
  sourceId: string;
  target: EmailProject;
  createdBy: ShortUser;
};

export type EmailProjectClonesResponse = {
  data: EmailProjectClone[];
};

export type EmailProjectsIndexActionBEGIN = {
  type: EmailProjectsActionsConstants.INDEX_BEGIN;
};

export type EmailProjectsIndexActionSUCCESS = {
  type: EmailProjectsActionsConstants.INDEX_SUCCESS;
  payload: EmailProjectsIndexResponse;
};

export type EmailProjectsIndexActionFAILED = {
  type: EmailProjectsActionsConstants.INDEX_FAILED;
  payload: {
    error: any;
  };
};

export type RemoveEmailProjectAction = {
  type: EmailProjectsActionsConstants.REMOVE_PROJECT;
  payload: string;
};

export type ShareEmailProjectAction = {
  type: EmailProjectsActionsConstants.SHARE_PROJECT;
  payload: string;
};

export type PublishEmailProjectAction = {
  type: EmailProjectsActionsConstants.PUBLISH_PROJECT;
  payload: EmailProject;
};

export type CancelEmailProjectPublicationAction = {
  type: EmailProjectsActionsConstants.CANCEL_PUBLISH_PROJECT;
  payload: EmailProject;
};

export type CheckInEmailProjectAction = {
  type: EmailProjectsActionsConstants.CHECK_IN_PROJECT;
  payload: {
    projectId: string;
    user: ShortUser;
  };
};

export type SaveEmailProjectSubjectAction = {
  type: EmailProjectsActionsConstants.SAVE_PROJECT_SUBJECT;
  payload: EmailProject;
};

export enum EmailProjectsActionsConstants {
  SAVE_PROJECT_SUBJECT = 'PROJECTS/EMAIL/SAVE_PROJECT_SUBJECT',
  CHECK_IN_PROJECT = 'PROJECTS/EMAIL/CHECK_IN_PROJECT',
  CANCEL_PUBLISH_PROJECT = 'PROJECTS/EMAIL/CANCEL_PUBLISH_PROJECT',
  PUBLISH_PROJECT = 'PROJECTS/EMAIL/PUBLISH_PROJECT',
  REMOVE_PROJECT = 'PROJECTS/EMAIL/REMOVE',
  SHARE_PROJECT = 'PROJECTS/EMAIL/SHARE',
  INDEX_BEGIN = 'PROJECTS/EMAIL/INDEX/BEGIN',
  INDEX_SUCCESS = 'PROJECTS/EMAIL/INDEX/SUCCESS',
  INDEX_FAILED = 'PROJECTS/EMAIL/INDEX/FAILED'
}

export type Asset = {
  id?: string;
  type: string;
  attributes: {
    attachmentCount: number;
    approved: boolean;
    description: string;
    extension: string;
    name: string;
    thumbnailUrl: string;
    printuiEditorLink: string | null;
  };
};

export type Section = {
  id: string;
  type: string;
  attributes: {
    name: string;
    default_asset_type: string;
    position: number;
  };
};

export type Pin = {
  id: string;
  type: string;
  attributes: {
    label: string;
    query: string;
    createdAt: string;
    updatedAt: string;
  };
};

export type Attachment = {
  id: string;
  type: string;
  attributes: {
    downloadUrl: string;
    extension: string;
    extractedText: string;
    filename: string;
    folderPath: string;
    height: number;
    mimetype: string;
    position: number;
    size: number;
    thumbnailUrl: string;
    thumbnailed: boolean;
    url: string;
    viewThumbnailRetina: string;
    width: number;
  };
};

export type PaginationData = {
  totalPages: number;
  page: number;
  per: number;
  total: number;
  q: string | null;
};

export type EmailProjectsActionType =
  | EmailProjectsIndexActionBEGIN
  | EmailProjectsIndexActionSUCCESS
  | EmailProjectsIndexActionFAILED
  | PublishEmailProjectAction
  | CancelEmailProjectPublicationAction
  | RemoveEmailProjectAction
  | CheckInEmailProjectAction
  | ProjectCheckedOutAction
  | SaveEmailProjectSubjectAction
  | TestFlagToggledAction;

export type BrandfolderAssetsResponse = {
  data: Asset[];
  pagination: PaginationData;
};

export type UserImage = {
  id: string;
  filename: string;
  extension: string;
  width: number;
  height: number;
  projectId: string;
  sticky: boolean;
  thumbnailUrl: string;
  userId: string;
  createdAt: Date;
  updatedAt: Date;
};

export type UserImagesResponse = {
  data: UserImage[];
  pagination: PaginationData;
};

export type BrandfolderSectionsResponse = {
  data: Section[];
};

export type BrandfolderPinsResponse = {
  data: Pin[];
};

export type BrandfolderAttachmentsResponse = {
  data: Attachment[];
  pagination: PaginationData;
};

export type EmailCampaignStandardResponse = {
  data: CampaignAnalyticsItem[];
};

export type RawMailingList = {
  MailingListID: string;
  SegmentID?: number;
};

export type CampaignSummary = {
  CampaignID: string;
  CampaignName: string;
  CampaignSubject: string;
  MailingLists: RawMailingList[];
  CampaignDeliveredOn: string;
  To: string;
  From: string;
  TotalOpens: number;
  UniqueOpens: number;
  TotalBounces: number;
  TotalComplaints: number;
  TotalForwards: number;
  UniqueForwards: number;
  TotalUnsubscribes: number;
  TotalLinkClicks: number;
  UniqueLinkClicks: number;
  Sent: number;
  CampaignIsArchived: boolean;
  CampaignType: string;
};

export type CampaignAnalyticsItem = {
  context: string;
  contextName: string;
  total: number;
  unique: number;
  ContextDescription: {};
};

export type SendersResponse = {
  data: SenderData[];
};

export type SenderData = {
  ID: string;
  Name: string;
  Email: string;
  CreatedOn: string;
  IsEnabled: boolean;
  SpfVerified: boolean;
  DkimVerified: boolean;
  DkimPublic: string;
};
