import { httpRequest } from '../../utils';
import { adminReportsRequests } from './index';
import {
  AdminReportsGenerateCsvValues,
  AdPlacementReportFormValues,
  AdPlacementReportParameters,
  DailyAdsReportResponse,
  DailyOrganizationEmailActivityReportResponse,
  DailyOrganizationPrintActivityReportResponse,
  DetailedEmailCampaignsReportResponse,
  EmailCampaignsReportResponse,
  GetReportFormatParameter,
  OrganizationMailingListSizeReportResponse,
  ReportDateRange,
  ReportFormat,
  SubmittedPrintProjectsReportResponse
} from './types';
import { createUrl } from '../../utils/httpRequest';
import { saveAs } from 'file-saver';

const getReportData = async <R>(
  format: ReportFormat,
  requestFn: Function,
  jsonFormatResponseItems: number,
  range?: AdminReportsGenerateCsvValues
): Promise<R | null> => {
  const requestParams: GetReportFormatParameter = {
    format,
    items: format === 'json' ? jsonFormatResponseItems : 0
  };

  if (range) {
    requestParams.range = range.dateRange;

    if (range.dateRange === ReportDateRange.CUSTOM) {
      requestParams.from = range.startDate;
      requestParams.to = range.endDate;
    }
  }

  const requestData = requestFn({
    requestParams
  });

  if (format === 'csv') {
    const url = createUrl(requestData.query, requestParams);
    await saveAs(url);
    return null;
  }

  return await httpRequest.runRequest<R>(requestData);
};

export const emailCampaignsReport = async (format: ReportFormat, range?: AdminReportsGenerateCsvValues) => {
  return getReportData<EmailCampaignsReportResponse>(format, adminReportsRequests.emailCampaignsReport, 10, range);
};

export const organizationMailingListSize = async (format: ReportFormat, range?: AdminReportsGenerateCsvValues) => {
  return getReportData<OrganizationMailingListSizeReportResponse>(
    format,
    adminReportsRequests.organizationMailingListSize,
    10,
    range
  );
};

export const dailyOrganizationEmailActivity = async (format: ReportFormat, range?: AdminReportsGenerateCsvValues) => {
  return getReportData<DailyOrganizationEmailActivityReportResponse>(
    format,
    adminReportsRequests.dailyOrganizationEmailActivity,
    10,
    range
  );
};

export const dailyOrganizationPrintActivity = async (format: ReportFormat, range?: AdminReportsGenerateCsvValues) => {
  return getReportData<DailyOrganizationPrintActivityReportResponse>(
    format,
    adminReportsRequests.dailyOrganizationPrintActivity,
    10,
    range
  );
};

export const dailyAds = async (format: ReportFormat, range?: AdminReportsGenerateCsvValues) => {
  return getReportData<DailyAdsReportResponse>(format, adminReportsRequests.dailyAds, 10, range);
};

export const submittedPrintProjectsReport = async (format: ReportFormat, range?: AdminReportsGenerateCsvValues) => {
  return getReportData<SubmittedPrintProjectsReportResponse>(
    format,
    adminReportsRequests.submittedPrintProjectsReport,
    10,
    range
  );
};

export const detailedEmailCampaignsReport = async (format: ReportFormat, range?: AdminReportsGenerateCsvValues) => {
  return getReportData<DetailedEmailCampaignsReportResponse>(
    format,
    adminReportsRequests.detailedEmailCampaignsReport,
    10,
    range
  );
};

export const adPlacementReport = async (values: AdPlacementReportFormValues, range: AdminReportsGenerateCsvValues) => {
  const requestParams: AdPlacementReportParameters = {
    organizationNumber: values.organizationNumber,
    dataCenter: values.dataCenter,
    size: values.size,
    statuses: (['available', 'sold', 'oversold'] as const).filter((type) => values[type]).join(',')
  };

  if (range) {
    requestParams.range = range.dateRange;

    if (range.dateRange === ReportDateRange.CUSTOM) {
      requestParams.from = range.startDate;
      requestParams.to = range.endDate;
    }
  }

  const requestData = adminReportsRequests.adPlacementReport({ requestParams });

  const url = createUrl(requestData.query, requestParams);
  await saveAs(url);
  return null;
};
